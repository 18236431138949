import { Box, Button, Card, useOpenClose } from '@palmetto/palmetto-components';
import React, { useMemo } from 'react';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import { TransactionPlanIdsDifferWarningIcon } from '../Tooltip';
import { useGetPaymentPlanQuery } from '@/services/paymentPlans';
import {
  AccountFundingSummaryComponentProps,
  RightAmountBox,
  SubtitleBox,
  useGetLeftAmountElements,
  useGetRightAmounts,
} from './AccountFundingSummary.utils';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { AccountPaymentPlanModal } from './modals/AccountPaymentPlanModal';
import { RequirePayoutsAccountingAdminUser } from '../auth/RequirePayoutsAccountingAdminUser';

export const AccountFundingSummary = ({
  project,
  transactions,
  orgPlanDiffersAccountPlan,
  orgPlanName,
  account,
  refetchProjectAndTransactions,
}: AccountFundingSummaryComponentProps) => {
  const { data: paymentPlan, isLoading } = useGetPaymentPlanQuery(
    project?.planId ? { id: project?.planId as string } : skipToken,
  );

  const {
    isOpen: isAccountPaymentPlanModalOpen,
    handleOpen: handleOpenAccountPaymentPlanModal,
    handleClose: handleCloseAccountPaymentPlanModal,
  } = useOpenClose();

  const { projectTotalValue, payoutAmountToDate, amountRemaining } = useGetRightAmounts({
    project,
    transactions,
  })();

  const leftAmounts =
    useGetLeftAmountElements({
      transactions,
      projectTotalCost: projectTotalValue,
      paymentPlan,
    })() || [];

  return (
    <Box gap="md" background="primary" radius="md">
      <AccountPaymentPlanModal
        isModalOpen={isAccountPaymentPlanModalOpen}
        handleClose={handleCloseAccountPaymentPlanModal}
        project={project}
        paymentPlan={paymentPlan}
        refetchProjectAndTransactions={refetchProjectAndTransactions}
        account={account}
      />
      <Card padding={'lg'}>
        <Card.Header>
          <Box
            direction="row"
            justifyContent="space-between"
            style={{
              marginLeft: '-1em',
            }}
          >
            <SubtitleBox>
              PAYMENT PLAN
              {orgPlanDiffersAccountPlan ? (
                <Box as="span" display={'inline-block'} margin={'0 0 0 xs'}>
                  <TransactionPlanIdsDifferWarningIcon organizationPlanName={orgPlanName} />
                </Box>
              ) : null}
            </SubtitleBox>

            <RequirePayoutsAccountingAdminUser>
              <Button
                iconPrefix="settings"
                size="sm"
                variant="secondary"
                tone="neutral"
                disabled={isLoading}
                onClick={handleOpenAccountPaymentPlanModal}
              >
                Change Plan
              </Button>
            </RequirePayoutsAccountingAdminUser>
          </Box>
        </Card.Header>
        <Box
          direction={'row'}
          style={{
            marginTop: '10px',
          }}
        >
          <Box
            style={{
              flex: '1',
            }}
          >
            <Box direction="row">
              {leftAmounts.map((element) => (
                <React.Fragment key={element.id}>{element.component}</React.Fragment>
              ))}
            </Box>
          </Box>
          <Box
            direction="column"
            justifyContent="center"
            style={{
              flex: '1',
            }}
          >
            <Box direction="row" justifyContent="center">
              <RightAmountBox title="Total Project Value" amount={currencyFormatter.format(projectTotalValue)} />
              <RightAmountBox title="Payout Amount to Date" amount={currencyFormatter.format(payoutAmountToDate)} />
              <RightAmountBox title="Amount Remaining" amount={currencyFormatter.format(amountRemaining)} />
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};
