import { ActivationPackagePhotoCategory, RequirementFlagSettingsType } from 'types';
import FormSection from '../Forms/FormSection';
import { PhotosList } from '../MilestonePackagePartials/Documents/PhotosList';

export const SiteImprovement = () => {
  return (
    <FormSection
      title="Site Improvement"
      description="if applicable, photos of the site improvements made such as tree removal or main panel upgrade."
    >
      <PhotosList
        title="Site Improvement Photos"
        category={ActivationPackagePhotoCategory.siteImprovements}
        baseCategory="Activation Photo Documentation"
        type={RequirementFlagSettingsType.siteImprovement}
      />
    </FormSection>
  );
};
