import { Link, useParams } from 'react-router-dom';
import { isPalmettoFinanceUser } from '../auth/RequirePalmettoFinanceUser';
import { useGetAccountQuery, useGetAccountCedDetailsQuery } from '../../services/accounts';
import { useGetCurrentDesignQuery } from '../../services/systemDesign';
import { PropertyListBox } from '../PropertyListBox/PropertyListBox';
// import { GlideECEligibility, energyCommunityMap } from 'types';
// import { FontColor, IconName } from '@palmetto/palmetto-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useGetAddersQuery } from '../../services/adders';
import { useGetQuotesQuery } from '../../services/quotes';
import { QuoteStatus } from '../../types/Quotes';
import RequirePermissions from '../auth/requirePermissions';
import { Button, useOpenClose } from '@palmetto/palmetto-components';
import { AccountPaymentMethod } from './modals/AccountPaymentMethod';
import { GetSalesRepAuthLinkModal } from '../SalesRepVerification/GetSalesRepAuthLinkModal';
import { useGetPaymentMethodsByAccountIdQuery } from '@/services/paymentMethods';
import {
  AccountDocument,
  AdderType,
  BatteryType,
  CedProjectStatus,
  AttestmentTypeLabelMap,
  DataFilterOperation,
  DomesticContentEligibilityLabels,
  DomesticContentEligibilityTypes,
  ExternalReferenceType,
  FinancialAccountPaymentMethodType,
  FundType,
  HVACDiscountDetails,
  HVACEquipmentDetails,
  HVACEquipmentTypeLabels,
  HVACInstallKitDetails,
  HVACServiceDetails,
  ProgramType,
  ProgramTypeLabels,
  UserPermissions,
  CedProjectStatusLabel,
} from 'types';
import { useAuth } from '../auth/authProvider';
import { convertIsoToDateMed } from '../Underwriting/CreditApplications';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import { CedProgramNameLabels, CedProgramTypes } from 'types';
import { formatPhoneNumber } from '@/helpers/formatPhoneNumber';
import usePermissions from '@/hooks/usePermissions';
import { DateTime } from 'luxon';
import { ReCheckSalesRepModal } from './modals/ReCheckSalesRepModal';
import { useGetSalesRepProviderUserBySalesRepIdQuery } from '@/services/salesReps';
import { DirectPayOptInOutModal } from './DirectPayOptInOutModal';
import { useLazyGetTrancheByTrancheNameAndFundKeyQuery } from '@/services/funds';
import { useEffect, useState } from 'react';
import { useGetOrganizationSplitPaySettingsQuery } from '@/services/organizations';
import { DATE_FORMAT } from '@/helpers/dates';

const getSystemDesignLabel = (programType?: ProgramType) => {
  switch (programType) {
    case ProgramType.hvac:
      return 'System Design';
    case ProgramType.solar:
    case ProgramType.doePr:
    case ProgramType.newHomes:
      return 'PV System';
    default:
      return '';
  }
};

const getSystemDesignProperties = (account: AccountDocument<any>, systemDesign: any) => {
  switch (account?.programType) {
    case ProgramType.hvac:
      return [
        ...(systemDesign?.equipment?.map((e: HVACEquipmentDetails) => ({
          label: `${HVACEquipmentTypeLabels[e.type]} - ${e.name}`,
          value: e.quantity,
        })) || []),
        ...(systemDesign?.installKits?.map((i: HVACInstallKitDetails) => ({
          label: `Install Kit - ${i.name}`,
          value: i.quantity,
        })) || []),
        ...(systemDesign?.services?.map((s: HVACServiceDetails) => ({
          label: `Service - ${s.name}`,
          value: s.quantity,
        })) || []),
        ...(systemDesign?.discounts?.map((s: HVACDiscountDetails) => ({
          label: `Discount - ${s.name}`,
          value: 1,
        })) || []),
      ];
    case ProgramType.solar:
    case ProgramType.doePr:
    case ProgramType.newHomes:
      const solarAccount = account as AccountDocument<ProgramType.doePr | ProgramType.solar>;
      return [
        {
          label: 'System Size (kW)',
          value: systemDesign?.systemSizeKw ?? solarAccount?.systemDesign?.systemSizeKw ?? '--',
        },
        {
          label: 'Year-1 Production (kWh)',
          value:
            systemDesign?.systemFirstYearProductionKwh?.toFixed(0) ??
            solarAccount?.systemDesign?.systemFirstYearProductionKwh?.toFixed(0) ??
            '--',
        },
        {
          label: 'Panel Count',
          value:
            systemDesign?.totalPanelCount > 0
              ? systemDesign?.totalPanelCount
              : (solarAccount?.systemDesign?.totalPanelCount ?? '--'),
        },
        {
          label: 'Panel Manufacturer',
          value: systemDesign?.panelManufacturer ?? solarAccount?.systemDesign?.panelManufacturer ?? '--',
        },
        { label: 'Panel Model', value: systemDesign?.panelModel ?? solarAccount?.systemDesign?.panelModel ?? '--' },
        {
          label: 'Inverter Manufacturer',
          value:
            systemDesign?.inverters?.[0]?.manufacturer ?? solarAccount?.systemDesign?.inverterManufacturer ?? '--',
        },
        {
          label: 'Mounting Type',
          value: systemDesign?.mountingType ?? solarAccount?.systemDesign?.mountingType ?? '--',
        },
        {
          label: 'Hardware Manufacturer',
          value: systemDesign?.mountingManufacturer ?? solarAccount?.systemDesign?.mountingManufacturer ?? '--',
        },
      ];
    default:
      return [];
  }
};

const formatDateToUsaShortStandard = (dateString: string) => {
  const date = DateTime.fromFormat(dateString, 'dd/MM/yyyy');
  if (!date.isValid) {
    return dateString;
  }

  return date.toFormat('MM/dd/yyyy');
};

export function AccountDetails() {
  const { claims } = useAuth();
  const userPermissions = usePermissions();

  const { id } = useParams<{ id: any }>();
  const { data: account, isLoading }: any = useGetAccountQuery(id);
  const { data: splitPaySettings } = useGetOrganizationSplitPaySettingsQuery(
    { alias: account?.licensedOrganizationId },
    { skip: !account?.licensedOrganizationId },
  );
  const { data: paymentMethod = [] } = useGetPaymentMethodsByAccountIdQuery({ id });
  const { data: salesRepProviderUser, isLoading: isSalesRepInfoLoading } =
    useGetSalesRepProviderUserBySalesRepIdQuery(
      {
        salesRepId: account?.salesRepVerification?.salesRepReferenceId,
      },
      { skip: !account?.salesRepVerification?.salesRepReferenceId },
    );

  const { data: cedDetails }: any = useGetAccountCedDetailsQuery(id);
  const { data: systemDesign }: any = useGetCurrentDesignQuery(
    { id, programType: account?.programType },
    { skip: !account?.programType },
  );
  const { data: quotes } = useGetQuotesQuery(
    { id, programType: account?.programType },
    { skip: !account?.programType },
  );
  const activeQuote = quotes?.find((quote) => quote.status === QuoteStatus.active);
  const solarReadinessCost = activeQuote?.additionalCostAdders?.find(
    (adder: any) => adder.type === AdderType.solarReadiness,
  )?.cost;
  const { data: addersData } = useGetAddersQuery(id);
  const { isCosignerEnabled, salesRepVerification } = useFlags();
  const { isOpen: isAchModalOpen, handleOpen: openAchModal, handleClose: closeModal } = useOpenClose();
  const {
    isOpen: isReCheckModalOpen,
    handleOpen: openReCheckModal,
    handleClose: closeReCheckModal,
  } = useOpenClose();
  const {
    isOpen: isDirectPayOptOutModalOpen,
    handleOpen: openDirectPayOptOutModal,
    handleClose: closeDirectPayOptOutModal,
  } = useOpenClose();
  const {
    isOpen: isDirectPayOptInModalOpen,
    handleOpen: openDirectPayOptInModal,
    handleClose: closeDirectPayOptInModal,
  } = useOpenClose();

  const [trancheDetails, setTrancheDetails] = useState<Record<string, any>>({});

  const [getTrancheByTrancheNameAndFundKeyQuery] = useLazyGetTrancheByTrancheNameAndFundKeyQuery();

  useEffect(() => {
    if (account?.tranches) {
      const fetchTrancheDetails = async () => {
        const trancheDetails: Record<string, any> = {};

        const trancheRequests = account.tranches.map(async (tranche: string) => {
          const fundKey = extractFundKeyUsingTrancheName(tranche);
          const { data } = await getTrancheByTrancheNameAndFundKeyQuery({
            fundKey,
            trancheName: tranche,
          });

          trancheDetails[tranche] = data;
        });

        await Promise.all(trancheRequests);

        setTrancheDetails(trancheDetails);
      };

      fetchTrancheDetails();
    }
  }, [account?.tranches, getTrancheByTrancheNameAndFundKeyQuery]);

  const programReferenceId = account?.externalReferenceIds?.find(
    (ref: any) => ref.type === ExternalReferenceType.doe,
  )?.id;
  const primaryAccountApplicant = account?.applicants.find((applicant: any) => applicant.type === 'primary');
  const secondaryAccountApplicant = account?.applicants.find((applicant: any) => applicant.type === 'secondary');
  const showAdditionalApplicant = isCosignerEnabled && secondaryAccountApplicant !== undefined;
  const {
    address1: primeAddress1,
    address2: primeAddress2 = '',
    city: primeCity,
    state: primeState,
    zip: primeZip,
  } = primaryAccountApplicant?.address || {};
  const { address1, address2 = '', city, state, zip } = account?.address || {};
  // const energyCommunity = account?.itcAdderQualifications?.ecEligibilityCriteria
  //   ? energyCommunityMap[account.itcAdderQualifications?.ecEligibilityCriteria as GlideECEligibility].map(
  //       (v: string) => v,
  //     )
  //   : false;

  const formattedPrimaryApplicantCreditDOB = primaryAccountApplicant?.creditDOB
    ? formatDateToUsaShortStandard(primaryAccountApplicant?.creditDOB)
    : '';

  const isLightReachAdmin = isPalmettoFinanceUser(claims) && userPermissions.includes(UserPermissions.admin);
  const programTypesShouldVerifySalesRep = [ProgramType.solar, ProgramType.newHomes];
  const shouldVerifySalesRep =
    salesRepVerification && programTypesShouldVerifySalesRep.includes(account?.programType);
  const domesticContentEligibilityStatus = account?.domesticContentEligibilityStatus ?? '';
  const dcAttestmentType =
    systemDesign?.domesticContentAttestment?.attestmentType &&
      (domesticContentEligibilityStatus === DomesticContentEligibilityTypes.confirmed ||
        domesticContentEligibilityStatus === DomesticContentEligibilityTypes.underConsideration)
      ? ' - ' +
      AttestmentTypeLabelMap[
      systemDesign.domesticContentAttestment.attestmentType as keyof typeof AttestmentTypeLabelMap
      ]
      : '';
  const accountIsHVAC = account?.programType === ProgramType.hvac;
  const accountDetailsProperties = {
    heading: 'Account Details',
    editPath: `/accounts/${id}/edit`,
    values: [
      { label: 'Account Id', value: id },
      { label: 'External Reference', value: account?.externalReference },
      { label: 'Program Type', value: ProgramTypeLabels[account?.programType as ProgramType] },
      { label: 'Program Reference ID', value: programReferenceId },
      ...(isLightReachAdmin
        ? [
          {
            label: 'Date of Birth (From Credit Report)',
            value: formattedPrimaryApplicantCreditDOB,
          },
        ]
        : []),
      { label: 'Language', value: account?.language },
      {
        label: 'Primary Contact',
        value: [
          `${primaryAccountApplicant?.firstName} ${primaryAccountApplicant?.lastName}`,
          primaryAccountApplicant?.email,
          primaryAccountApplicant?.phoneNumber ? formatPhoneNumber(primaryAccountApplicant.phoneNumber) : '',
        ],
      },
      ...(showAdditionalApplicant
        ? [
          {
            label: 'Additional Contact',
            value: [
              `${secondaryAccountApplicant?.firstName} ${secondaryAccountApplicant?.lastName}`,
              secondaryAccountApplicant?.email,
              secondaryAccountApplicant?.phoneNumber
                ? formatPhoneNumber(secondaryAccountApplicant.phoneNumber)
                : '',
            ],
          },
        ]
        : []),
      {
        label: 'Mailing Address',
        value: `${primeAddress1} ${primeAddress2} ${primeCity}, ${primeState} ${primeZip}`,
      },
      { label: 'Property Address', value: `${address1} ${address2} ${city}, ${state} ${zip}` },
      // {
      //   label: 'Energy Community',
      //   value: energyCommunity || 'No',
      //   icon: 'nature' as IconName,
      //   iconColor: energyCommunity ? ('primary' as FontColor) : ('grey-300' as FontColor),
      // },
      { label: 'Utility', value: account?.utility?.utilityName },
      ...(accountIsHVAC && account?.propertyDetails
        ? [
          { label: 'Home Size (sq ft)', value: account.propertyDetails.homeSize },
          { label: 'Number of Existing Systems', value: account.propertyDetails.existingSystems },
        ]
        : []),
      { label: 'Status', value: account?.status },
      ...(account?.itcAdderQualifications?.domesticContentEligible === true
        ? [
          {
            label: 'Domestic Content Eligibility',
            value:
              DomesticContentEligibilityLabels[
              domesticContentEligibilityStatus as keyof typeof DomesticContentEligibilityLabels
              ] + dcAttestmentType,
          },
        ]
        : []),
      ...(account?.ptoGreenlight?.greenlightStatus
        ? [
          {
            label: 'PTO Greenlight Granted',
            value: DateTime.fromISO(
              account?.ptoGreenlight?.tranchedClosedOn || account?.ptoGreenlight?.manualOverrideDate,
            )
              .toLocal()
              .toFormat(DATE_FORMAT),
          },
        ]
        : []),
    ],
  };

  const supportDetailsProperties = {
    heading: 'Account Support',
    showInternalOnly: true,
    values: [
      { label: 'Payment Provider', value: account?.inboundPayments ? account.inboundPayments.provider : 'Stripe' },
      {
        label: 'Provider ID',
        value: account?.inboundPayments ? (
          <Link to={account.inboundPayments.providerUrl}>{account.inboundPayments.providerId}</Link>
        ) : (
          account?.stripeCustomerId
        ),
      },
      {
        label: 'Payment Method',
        value:
          paymentMethod.length > 0 && paymentMethod[0].bankAccount ? (
            <Button variant="primary" tone="neutral" size="xs" onClick={() => openAchModal()}>
              {FinancialAccountPaymentMethodType.bankAccount}
            </Button>
          ) : paymentMethod.length > 0 && paymentMethod[0].card ? (
            <Button variant="primary" tone="neutral" size="xs" onClick={() => { }}>
              {FinancialAccountPaymentMethodType.card}
            </Button>
          ) : (
            <Button variant="primary" size="xs" onClick={() => openAchModal()}>
              Add
            </Button>
          ),
      },
    ],
  };

  const extractFundKeyUsingTrancheName = (tranche: string): string => {
    return tranche.replace(/-\d{8}$/, '');
  };

  const generateTrancheLinks = (fundType: FundType) => {
    if (!account?.tranches) return '---';

    const filteredTranches = account.tranches.filter((tranche: string) => tranche.includes(fundType));

    if (filteredTranches.length === 0) return '---';

    return filteredTranches.map((tranche: string) => {
      const fundKey = extractFundKeyUsingTrancheName(tranche);
      const advancedFilters = encodeURIComponent(
        JSON.stringify([
          {
            id: 'tranche',
            operation: DataFilterOperation.Equal,
            selectedValues: [tranche],
          },
        ]),
      );

      return (
        <Link key={tranche} to={`/admin/funds/${fundKey}?advancedFilters=${advancedFilters}`}>
          {tranche}
        </Link>
      );
    });
  };

  const getTrancheStatus = (fundType: FundType) => {
    if (!account?.tranches || !trancheDetails) return '---';

    const matchingTranche = account.tranches.find((tranche: string) => tranche.includes(fundType));
    if (!matchingTranche) return '---';

    return trancheDetails[matchingTranche]?.trancheStatus || '---';
  };

  const assetSaleProperties = {
    heading: 'Asset Sale',
    showInternalOnly: true,
    values: [
      {
        label: 'Tax Equity',
        value: generateTrancheLinks(FundType.taxEquity),
      },
      {
        label: 'Tax Equity Status',
        value: getTrancheStatus(FundType.taxEquity),
      },
      {
        label: 'Debt Equity',
        value: generateTrancheLinks(FundType.debt),
      },
      {
        label: 'Debt Equity Status',
        value: getTrancheStatus(FundType.debt),
      },
    ],
  };

  const pvSystemProperties = {
    heading: getSystemDesignLabel(account?.programType),
    editPath: `/accounts/${id}/system-design`,
    values: getSystemDesignProperties(account, systemDesign),
    shouldFold: true,
  };
  const addersMap = activeQuote?.adders?.reduce((acc: any, type: string) => {
    const adders = addersData?.adders?.filter((adder: any) => adder.type === type);

    if (adders?.length) {
      acc[type] = adders;
    } else {
      acc[type] = [];
    }

    return acc;
  }, {});
  const batteryAdderyType = addersMap?.arbitrageBattery ? BatteryType.arbitrageBattery : BatteryType.backupBattery;
  const addersProperties = {
    heading: 'Adders',
    editPath: `/accounts/${id}/adders`,
    values: [
      ...(addersMap?.[batteryAdderyType]
        ? addersMap[batteryAdderyType].map((adder: any) => ({
          label: `${addersMap?.arbitrageBattery ? 'Arbitrage' : 'Backup'} Battery`,
          value: [
            `${adder?.manufacturer ?? ''} ${adder?.model ?? ''}`,
            adder?.quantity ? `x${adder.quantity}` : 'Included',
          ],
        }))
        : []),
      ...(addersMap?.electricalUpgrade ? [{ label: 'Electrical Upgrade', value: 'Included' }] : []),
      ...(activeQuote?.adders?.includes(AdderType.solarReadiness)
        ? [{ label: 'Solar Readiness', value: currencyFormatter.format(solarReadinessCost ?? 0) }]
        : []),
      ...(!activeQuote?.adders?.length ? [{ label: 'None', value: '' }] : []),
    ],
  };

  const salesRepProperties = {
    heading: 'Sales Rep',
    editPath: `/accounts/${id}/edit`,
    values: [
      { label: 'Name', value: account?.salesRepName },
      { label: 'License', value: account?.salesRepLicenseNumber },
      { label: 'Email', value: account?.salesRepEmail },
      { label: 'Phone', value: account?.salesRepPhoneNumber },
      ...(shouldVerifySalesRep
        ? [
          {
            label: 'Recheck Status',
            value: account?.salesRepVerification?.status ? (
              <Button variant="primary" tone="neutral" size="xs" onClick={() => openReCheckModal()}>
                {account?.salesRepVerification?.status?.toUpperCase()}
              </Button>
            ) : (
              <GetSalesRepAuthLinkModal />
            ),
          },
        ]
        : []),
    ],
  };

  const partnerOrgProperties = {
    heading: 'Partner Org',
    values: [
      ...(isPalmettoFinanceUser(claims)
        ? [{
          label: 'Name',
          value: (
            <Link
              to={`${location.origin}/settings/organizations/${account?.organizationId}`}
            >
              {account?.organizationId?.toUpperCase()}
            </Link>
          ),
        },
        {
          label: 'Licensed Partner Org',
          value: (
            <Link
              to={`${location.origin}/settings/organizations/${account?.licensedOrganizationId}`}
            >
              {account?.licensedOrganizationId?.toUpperCase()}
            </Link>
          ),
        },]
        : [
          { label: 'Name', value: account?.organizationId?.toUpperCase() },
          { label: 'Licensed Partner Org', value: account?.licensedOrganizationId?.toUpperCase() || '' },
        ]),
    ],
  };

  const cedSplitPayProperties = {
    heading: 'Greentech Direct Pay',
    values: [
      { label: 'Greentech Direct Pay Enabled', value: cedDetails?.cedSplitPayEnabled ? 'Yes' : 'No' },
      { label: 'Greentech Project Id', value: cedDetails?.cedProjectId || '' },
      ...(cedDetails?.projectStatus
        ? [
          {
            label: 'Greentech Project Status',
            value: CedProjectStatusLabel[cedDetails?.projectStatus as keyof typeof CedProjectStatusLabel],
          },
        ]
        : []),
      ...(isPalmettoFinanceUser(claims)
        ? [
          {
            label: 'Greentech Program Name',
            value: cedDetails?.programName ? CedProgramNameLabels[cedDetails?.programName as CedProgramTypes] : '',
          },
          {
            label: 'Parallel Payment Amount',
            value: currencyFormatter.format(cedDetails?.parallelPaymentAmount ?? 0),
          },
          { label: 'Parallel Payment Status', value: cedDetails?.parallelPaymentStatus || '' },
          {
            label: 'Ship Confirmation Date',
            value: cedDetails?.shipConfirmationDate
              ? convertIsoToDateMed(cedDetails?.shipConfirmationDate, { zone: 'UTC' })
              : '',
          },
        ]
        : []),
      ...((isLightReachAdmin || userPermissions.includes(UserPermissions.orgAccountingAdmin)) &&
        account?.ced?.cedSplitPayEnabled
        ? [
          {
            label: '',
            value: (
              <Button
                variant="tertiary"
                tone="danger"
                size="xs"
                onClick={openDirectPayOptOutModal}
                disabled={account?.ced?.shipConfirmationDate}
              >
                Opt Out
              </Button>
            ),
          },
        ]
        : []),
      ...((isLightReachAdmin || userPermissions.includes(UserPermissions.orgAccountingAdmin)) &&
        !account?.ced?.cedSplitPayEnabled
        ? [
          {
            label: '',
            value: (
              <Button
                variant="tertiary"
                tone="primary"
                size="xs"
                onClick={openDirectPayOptInModal}
                disabled={account?.cancellation?.isCancelled}
              >
                Opt In
              </Button>
            ),
          },
        ]
        : []),
    ],
  };

  const pricingDetailsBoxProperties =
    account?.priceSheetMappingId && isLightReachAdmin
      ? {
        heading: 'Pricing Details',
        values: [
          {
            label: 'Price Mappings',
            value: (
              <Link
                to={`${location.origin}/settings/organizations/${account.licensedOrganizationId}/pricing/mappings?id=${account?.priceSheetMappingId}`}
              >
                Account Pricing
              </Link>
            ),
          },
        ],
      }
      : null;

  return (
    <>
      <DirectPayOptInOutModal
        isModalOpen={isDirectPayOptOutModalOpen}
        handleCloseModal={closeDirectPayOptOutModal}
        type="optOut"
        accountId={id}
      />
      <DirectPayOptInOutModal
        isModalOpen={isDirectPayOptInModalOpen}
        handleCloseModal={closeDirectPayOptInModal}
        type="optIn"
        accountId={id}
      />
      {!isLoading && account && cedDetails && (
        <>
          <PropertyListBox {...accountDetailsProperties} />
          <RequirePermissions permissions={[UserPermissions.lightReachSupport]}>
            <AccountPaymentMethod
              isModalOpen={isAchModalOpen}
              closeModal={closeModal}
              paymentMethods={paymentMethod}
            />
            <PropertyListBox {...supportDetailsProperties} />
          </RequirePermissions>
          <PropertyListBox {...pvSystemProperties} />
          {account.programType !== ProgramType.hvac && <PropertyListBox {...addersProperties} />}
          <PropertyListBox {...salesRepProperties} />
          {shouldVerifySalesRep && !isSalesRepInfoLoading && salesRepProviderUser && (
            <ReCheckSalesRepModal
              isModalOpen={isReCheckModalOpen}
              closeModal={closeReCheckModal}
              salesRepAccountData={salesRepProviderUser}
              salesRepReCheckStatus={account?.salesRepVerification?.status}
            />
          )}
          <PropertyListBox {...partnerOrgProperties} />
          {pricingDetailsBoxProperties && <PropertyListBox {...pricingDetailsBoxProperties} />}
          {(account?.ced?.cedSplitPayEnabled ||
            account?.ced?.projectStatus === CedProjectStatus.optOut ||
            splitPaySettings?.cedSplitPayEnabled) && <PropertyListBox {...cedSplitPayProperties} />}
          <RequirePermissions
            permissions={[UserPermissions.lightReachPaymentAdmin, UserPermissions.lightReachSupport]}
            checkAllPermissions={false}
          >
            <PropertyListBox {...assetSaleProperties} />
          </RequirePermissions>
        </>
      )}
    </>
  );
}
