import { Button, Modal, useOpenClose } from '@palmetto/palmetto-components';
import { CompleteQueueReviewModal } from './CompleteQueueReviewModal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CompleteQueueRevampReviewModal } from './CompleteQueueRevampReviewModal';

interface CompleteReviewProps {
  disableApproval?: boolean;
  disableAllApprovals?: boolean;
  isForMilestonePackage?: boolean;
  canSubmitReview: boolean;
  handleFormSave: () => Promise<void>;
}

export const CompleteQueueReview = ({
  disableApproval,
  disableAllApprovals,
  isForMilestonePackage = true,
  canSubmitReview,
  handleFormSave,
}: CompleteReviewProps) => {
  const { flagDrivenPackageReview = false } = useFlags();

  const { isOpen: isModifyOpen, handleClose, handleToggle } = useOpenClose();
  const title = 'Complete Review';

  return (
    <>
      <Modal
        isOpen={isModifyOpen}
        onDismiss={() => {
          handleClose();
        }}
        maxWidth="4xl"
        ariaLabelledBy="completeReviewHeader"
        fullScreenMobile
        overflow="scroll"
      >
        <Modal.Header
          id="completeReviewHeader"
          title={title}
          onDismiss={() => {
            handleClose();
          }}
        />
        {flagDrivenPackageReview ? (
          <CompleteQueueRevampReviewModal
            handleClose={handleClose}
            handleFormSave={handleFormSave}
            isForMilestonePackage={isForMilestonePackage}
          />
        ) : (
          <CompleteQueueReviewModal
            handleClose={() => {
              handleClose();
            }}
            disableApproval={disableApproval}
            disableAllApprovals={disableAllApprovals}
            isForMilestonePackage={isForMilestonePackage}
            handleFormSave={handleFormSave}
          />
        )}
      </Modal>

      {flagDrivenPackageReview ? (
        <Button
          variant="primary"
          size="md"
          style={{ width: 'fit-content' }}
          onClick={handleToggle}
          disabled={!canSubmitReview || disableAllApprovals || disableApproval}
        >
          Complete Review
        </Button>
      ) : (
        <Button
          variant="primary"
          size="md"
          style={{ width: 'fit-content' }}
          onClick={handleToggle}
          disabled={!canSubmitReview}
        >
          Complete Review
        </Button>
      )}
    </>
  );
};
