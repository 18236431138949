import { ObjectId } from 'mongodb';
import { MilestoneRequirement } from './Milestones';

export enum NTPPackageHVAC {
  workOrder = 'workOrder',
  proofOfLoad = 'proofOfLoad',
}

export enum InstallDocumentTypes {
  dxf = 'dxf',
  planSet = 'planSet',
  shadeReport = 'shadeReport',
  permit = 'permit',
  productionModel = 'productionModel',
  incentiveDocuments = 'incentiveDocuments',
  electricianSignOff = 'electricianSignOff', // too bad this is an enum, it makes this optional member harder to express
  proofOfLoad = 'proofOfLoad',
  domesticContentDocuments = 'domesticContentDocuments',
  solarReadinessAdder = 'solarReadinessAdder',
  invoice = 'invoice',
  billOfMaterials = 'billOfMaterials',
  fsmChecklist = 'fsmChecklist',
  measureQuickSystemVitals = 'measureQuickSystemVitals',
  bluetoothHVACTool = 'bluetoothHVACTool',
}

export enum InstallationPackagePhotoCategory {
  projectSite = 'projectSite',
  roof = 'roof',
  electrical = 'electrical',
  storage = 'storage',
  systemCommissioning = 'systemCommissioning',
  proofOfInstallation = 'proofOfInstallation',
  hvacInstallation = 'hvacInstallation',
  voltage = 'voltageReading',
  laserTherm = 'laserTherm',
  staticPressure = 'staticPressure',
  indoorSystem = 'indoorSystem',
  outdoorSystem = 'outdoorSystem',
  zoningSystem = 'zoningSystem',
  thermostat = 'thermostat',
  accessories = 'accessories',
  deltaT = 'deltaT',
  externalStaticPressure = 'externalStaticPressure',
}

export enum LegacyActivationDocumentTypes {
  ptoConfirmation = 'ptoConfirmation',
  extendedWarranty = 'extendedWarranty',
  incentives = 'incentives',
}

export enum ActivationDocumentTypes {
  ptoConfirmation = 'ptoConfirmation',
  extendedWarranty = 'extendedWarranty',
  incentives = 'incentives',
  netMeteringApproval = 'netMeteringApproval',
  conditionalApproval = 'conditionalApproval',
  electricianSignOff = 'electricianSignOff',
}

export enum PostActivationDocumentTypes {
  ptoConfirmation = 'ptoConfirmation',
}

export enum BillingDocumentTypes {
  incomingCedInvoice = 'incomingCedInvoice',
}

export enum ActivationPackagePhotoCategory {
  siteImprovements = 'siteImprovements',
}

export enum DocumentTypes {
  contract = 'contract',
  disclosure = 'disclosure',
  supporting = 'supporting',
  utilityBill = 'utilityBill',
  voidedCheck = 'voidedCheck',
  siteAssessment = 'siteAssessment',
}

export enum InboundPaymentsAccountStatusDocumentTypes {
  concordStatement = 'concordStatement',
}

// documents that are no longer uploaded, but may still be in the system
export enum DeprecatedDocumentTypes {
  employmentVerification = 'employmentVerification',
  incomeVerification = 'incomeVerification',
  creditReport = 'creditReport',
}

export enum InterconnectionAgreementDocumentType {
  interconnectionAgreement = 'interconnectionAgreement',
}

export const NTPPackageDocumentTypes = [NTPPackageHVAC.workOrder, NTPPackageHVAC.proofOfLoad];

export const InstallPackageDocumentTypes = [
  InstallDocumentTypes.planSet,
  InstallDocumentTypes.productionModel,
  InstallDocumentTypes.shadeReport,
  InstallDocumentTypes.permit,
  ActivationDocumentTypes.extendedWarranty,
  ActivationDocumentTypes.incentives,
  DocumentTypes.utilityBill,
  InstallationPackagePhotoCategory.projectSite,
  InstallationPackagePhotoCategory.roof,
  InstallationPackagePhotoCategory.electrical,
  InstallationPackagePhotoCategory.storage,
  InstallationPackagePhotoCategory.systemCommissioning,
];

export const ActivationPackageDocumentTypes = [
  ActivationDocumentTypes.ptoConfirmation,
  ActivationDocumentTypes.extendedWarranty,
  ActivationDocumentTypes.incentives,
  DocumentTypes.utilityBill,
  ActivationPackagePhotoCategory.siteImprovements,
  ActivationDocumentTypes.netMeteringApproval,
];

export const PostActivationPackageDocumentTypes = [ActivationDocumentTypes.ptoConfirmation];

export enum UnderwritingDocumentTypes {
  identityVerification = 'identityVerification',
  titleVerification = 'titleVerification',
}

export enum PricingDocumentType {
  priceSheet = 'priceSheet',
}

export enum CancellationDocumentTypes {
  cancellation = 'cancellation',
  reactivation = 'reactivation',
}

export enum FlagDocumentTypes {
  flag = 'flag',
}

export enum HVACDocumentTypes {
  salesAgreement = 'salesAgreement',
}

export enum DoePrDocumentTypes {
  siteAssessment = 'siteAssessment',
}

export type AllDocumentTypes =
  | DocumentTypes
  | InstallDocumentTypes
  | InstallationPackagePhotoCategory
  | ActivationDocumentTypes
  | PostActivationDocumentTypes
  | ActivationPackagePhotoCategory
  | UnderwritingDocumentTypes
  | PricingDocumentType
  | CancellationDocumentTypes
  | BillingDocumentTypes
  | DeprecatedDocumentTypes
  | InterconnectionAgreementDocumentType
  | FlagDocumentTypes
  | NTPPackageHVAC
  | HVACDocumentTypes
  | DoePrDocumentTypes;

export enum DocumentStatus {
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
  voided = 'voided',
}

export enum ViewUrlType {
  original = 'original',
  preview = 'preview',
  gallery = 'gallery',
  thumbnail = 'thumbnail',
}

export interface DocumentReference {
  type: string;
  id: string | number | ObjectId;
}

export interface DocumentFile {
  originalName: string;
  contentType: string;
  sizeKB: number;
  md5Hash: string;
  filePath: string;
  viewUrls?: {
    url: string;
    type: ViewUrlType;
  }[];
}

export interface DocumentUploadFields {
  type: keyof typeof DocumentTypes | PricingDocumentType;
  externalReference?: string | number;
  grouped?: string;
  name?: string;
  isReviewable?: string;
}

export interface DocumentDocument {
  id?: ObjectId | string;
  accountId?: ObjectId | string;
  applicationId?: ObjectId | string;
  archived: boolean;
  type: AllDocumentTypes;
  status: keyof typeof DocumentStatus;
  internalReferences?: DocumentReference[];
  externalReference?: string | number;
  files: DocumentFile[];
  name?: string;
  meta?: {
    createdAt: string;
  };
}

export interface DocumentFileApiResponse extends DocumentFile {
  viewUrls: {
    url: string;
    type: ViewUrlType;
  }[];
}
export interface DocumentApiResponse extends DocumentDocument {
  files: DocumentFileApiResponse[];
}

export const InstallationPackagePhotoCategoryArr = Object.values(InstallationPackagePhotoCategory);

export const InstallDocumentTypesArr = Object.values(InstallDocumentTypes);
export const ActivationDocumentTypesArr = Object.values(ActivationDocumentTypes);
export const ActivationPackagePhotoCategoryArr = Object.values(ActivationPackagePhotoCategory);
export const DocumentTypesArr = Object.values(DocumentTypes);
export const DeprecatedDocumentTypesArr = Object.values(DeprecatedDocumentTypes);
export const UnderwritingDocumentTypesArr = Object.values(UnderwritingDocumentTypes);
export const HVACDocumentTypesArr = Object.values(HVACDocumentTypes);
export const DoePrDocumentTypesArr = Object.values(DoePrDocumentTypes);
export const AllDocumentTypesArr = [
  ...new Set([
    ...Object.values(BillingDocumentTypes),
    ...Object.values(NTPPackageHVAC),
    ...Object.values(InstallDocumentTypes),
    ...Object.values(DocumentTypes),
    ...InstallationPackagePhotoCategoryArr,
    ...Object.values(ActivationDocumentTypes),
    ...Object.values(ActivationPackagePhotoCategory),
    ...Object.values(UnderwritingDocumentTypes),
    ...Object.values(CancellationDocumentTypes),
    ...Object.values(InterconnectionAgreementDocumentType),
    ...Object.values(FlagDocumentTypes),
    ...Object.values(HVACDocumentTypes),
    ...Object.values(DoePrDocumentTypes),
  ]),
];
export const SeparateDocumentTypes = [
  ...new Set([
    ...Object.values(NTPPackageHVAC),
    ...InstallationPackagePhotoCategoryArr,
    ...Object.values(ActivationPackagePhotoCategory),
  ]),
];

export const documentTitles: Record<AllDocumentTypes, string> = {
  contract: 'Contract',
  creditReport: 'Credit Report',
  disclosure: 'Disclosure',
  domesticContentDocuments: 'Domestic Content Documents',
  dxf: 'DXF',
  employmentVerification: 'Employment Verification',
  identityVerification: 'Identity Verification',
  incomeVerification: 'Income Verification',
  incomingCedInvoice: 'Incoming CED Invoice',
  permit: 'Permit',
  planSet: 'Plan Set',
  productionModel: 'Production Model',
  shadeReport: 'Shade Report',
  supporting: 'Supporting',
  titleVerification: 'Title Verification',
  utilityBill: 'Utility Bill',
  projectSite: 'Project Site',
  roof: 'Roof',
  electrical: 'Electrical',
  storage: 'Storage',
  systemCommissioning: 'System Commissioning',
  voidedCheck: 'Voided Check',
  ptoConfirmation: 'PTO Confirmation',
  extendedWarranty: 'Extended Warranty',
  incentives: 'Incentives',
  siteImprovements: 'Site Improvements',
  priceSheet: 'Price Sheet',
  cancellation: 'Cancellation',
  reactivation: 'Reactivation',
  netMeteringApproval: 'Net Metering Approval',
  interconnectionAgreement: 'Interconnection Agreement',
  flag: 'Flag',
  incentiveDocuments: 'Incentive Documents',
  conditionalApproval: 'Conditional Approval',
  electricianSignOff: 'Electrician Sign-Off',
  proofOfLoad: 'Proof of Load',
  workOrder: 'Work Order',
  voltageReading: 'Voltage Reading',
  laserTherm: 'Laser Thermometer',
  staticPressure: 'Static Pressure',
  hvacInstallation: 'HVAC Installation',
  proofOfInstallation: 'Proof of Installation',
  salesAgreement: 'Sales Agreement',
  solarReadinessAdder: 'Solar Readiness Adder',
  invoice: 'Invoice',
  billOfMaterials: 'Bill of Materials',
  siteAssessment: 'Site Assessment',
  fsmChecklist: 'FSM Checklist',
  measureQuickSystemVitals: 'Measure Quick System Vitals',
  bluetoothHVACTool: 'Bluetooth HVAC Tool',
  indoorSystem: 'Indoor System',
  outdoorSystem: 'Outdoor System',
  zoningSystem: 'Zoning System',
  thermostat: 'Thermostat',
  accessories: 'Accessories',
  deltaT: 'Delta T',
  externalStaticPressure: 'External Static Pressure',
};

export const documentRequirementMap: { [Key in AllDocumentTypes]?: MilestoneRequirement } = {
  [InstallDocumentTypes.dxf]: MilestoneRequirement.dxfApproved,
  [InstallDocumentTypes.permit]: MilestoneRequirement.permitApproved,
  [InstallDocumentTypes.incentiveDocuments]: MilestoneRequirement.incentiveDocumentsApproved,
  [InstallDocumentTypes.planSet]: MilestoneRequirement.planSetApproved,
  [InstallDocumentTypes.productionModel]: MilestoneRequirement.productionModelApproved,
  [InstallDocumentTypes.shadeReport]: MilestoneRequirement.shadeReportApproved,
  [InstallDocumentTypes.proofOfLoad]: MilestoneRequirement.proofOfLoad,
  [InstallationPackagePhotoCategory.electrical]: MilestoneRequirement.electricalPhotosApproved,
  [InstallationPackagePhotoCategory.projectSite]: MilestoneRequirement.projectSitePhotosApproved,
  [InstallationPackagePhotoCategory.roof]: MilestoneRequirement.roofPhotosApproved,
  [InstallationPackagePhotoCategory.storage]: MilestoneRequirement.storagePhotosApproved,
  [InstallationPackagePhotoCategory.systemCommissioning]: MilestoneRequirement.systemCommissioningApproved,
  [InstallationPackagePhotoCategory.proofOfInstallation]: MilestoneRequirement.proofOfInstallation,
  [InstallationPackagePhotoCategory.hvacInstallation]: MilestoneRequirement.hvacInstallation,
  [InstallationPackagePhotoCategory.voltage]: MilestoneRequirement.voltageReadings,
  [InstallationPackagePhotoCategory.laserTherm]: MilestoneRequirement.laserThermometerReading,
  [InstallationPackagePhotoCategory.staticPressure]: MilestoneRequirement.staticPressureReadings,
  [InstallationPackagePhotoCategory.indoorSystem]: MilestoneRequirement.indoorSystemApproved,
  [InstallationPackagePhotoCategory.outdoorSystem]: MilestoneRequirement.outdoorSystemApproved,
  [InstallationPackagePhotoCategory.zoningSystem]: MilestoneRequirement.zoningSystemApproved,
  [InstallationPackagePhotoCategory.thermostat]: MilestoneRequirement.thermostatApproved,
  [InstallationPackagePhotoCategory.accessories]: MilestoneRequirement.accessoriesApproved,
  [InstallationPackagePhotoCategory.deltaT]: MilestoneRequirement.deltaTApproved,
  [InstallationPackagePhotoCategory.externalStaticPressure]: MilestoneRequirement.externalStaticPressureApproved,
  [ActivationDocumentTypes.extendedWarranty]: MilestoneRequirement.extendedWarranty,
  [ActivationDocumentTypes.incentives]: MilestoneRequirement.incentives,
  [ActivationDocumentTypes.ptoConfirmation]: MilestoneRequirement.ptoConfirmation,
  [ActivationPackagePhotoCategory.siteImprovements]: MilestoneRequirement.siteImprovements,
  [UnderwritingDocumentTypes.identityVerification]: MilestoneRequirement.identityVerified,
  [UnderwritingDocumentTypes.titleVerification]: MilestoneRequirement.titleApproved,
  [DocumentTypes.voidedCheck]: MilestoneRequirement.paymentMethodSubmitted,
  [InstallDocumentTypes.fsmChecklist]: MilestoneRequirement.fsmChecklistApproved,
  [InstallDocumentTypes.measureQuickSystemVitals]: MilestoneRequirement.measureQuickSystemVitalsApproved,
  [InstallDocumentTypes.bluetoothHVACTool]: MilestoneRequirement.bluetoothHVACToolApproved,
};

export enum DocumentSortField {
  ID_DESC = 'idDescending',
  ID_ASC = 'idAscending',
}

export enum DocumentRejectionReasons {
  missingDocumentation = 'Missing Documentation',
  illegibleDocumentation = 'Illegible Documentation',
  incorrectDocumentation = 'Incorrect Documentation',
  other = 'Other',
}
export const documentsNotRequireReview: AllDocumentTypes[] = [DocumentTypes.utilityBill];

export type DocumentRejectionMessageData = {
  customerName: string;
  epcName: string;
  customerAccountLink: string;
  customerAddress: string;
  ntpDocType: string;
};

export const InstallationPackagePhotoCategoryTitles = {
  projectSite: documentTitles.projectSite,
  roof: documentTitles.roof,
  electrical: documentTitles.electrical,
  storage: documentTitles.storage,
  systemCommissioning: documentTitles.systemCommissioning,
};
