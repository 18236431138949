import { Box } from '@palmetto/palmetto-components';
import { PaymentPlan } from './PaymentPlan';
import BankAccount from './BankAccount';
import { OrganizationNode } from '@/types/Organizations';
import { PaymentsOrgSetting } from '@/components/settings/organizations/Payments/PaymentsOrgSetting';
import { PaymentPlanHistory } from './PaymentPlanHistory';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface PaymentSettingsProps {
  organization: OrganizationNode;
  isLoading?: boolean;
}

export const PaymentSettings = ({ organization }: PaymentSettingsProps) => {
  const { showBankAccountSettingsSection = false } = useFlags(); //Following FIN-2498 requirement we hide the account tab for the time being.
  return (
    <Box
      childGap={{
        base: 'sm',
        tablet: 'md',
        desktop: 'lg',
      }}
      padding="lg 0 0 0"
    >
      <Box>
        <PaymentsOrgSetting organization={organization} />
      </Box>
      <Box flex="auto">
        <PaymentPlan organization={organization} />
      </Box>
      <Box flex="auto">
        <PaymentPlanHistory organization={organization} />
      </Box>
      {showBankAccountSettingsSection && (
        <Box flex="auto">
          <BankAccount organization={organization} />
        </Box>
      )}
    </Box>
  );
};
