import { DocumentFile, DocumentDocument, documentTitles, RequirementFlagSettingsType, UserPermissions } from 'types';
import { Box, Button, Icon, Popover, toast, useBreakpoint, useOpenClose } from '@palmetto/palmetto-components';
import { useArchiveDocumentMutation } from '../../../services/documents';
import RequirePermissions from '../../auth/requirePermissions';
import DateTimeDisplay from '../../DateTime';
import styles from './DocumentListItem.module.scss';
import { Flag } from '../../QueueFlags/Flag';
import { useContext } from 'react';
import { QueueFlagsContext } from '../../QueueFlags/QueueFlagsContext';
import { FlagInfo } from '../../QueueFlags/FlagInfo';
import { trimFileNames } from '@/helpers/trimFileNames';
import { FlaggedListItems } from '@/components/QueueFlags/FlaggedListItems';
import { DateTime } from 'luxon';
import classNames from 'classnames';

interface DocumentListItemProps {
  allowArchive?: boolean;
  item: DocumentDocument;
  showDocumentActions?: boolean;
  showTypeLabel?: boolean;
  baseCategory?: string;
  type?: RequirementFlagSettingsType;
  onClick: (fileIndex: number) => void;
}

export function DocumentListItem({
  allowArchive = true,
  item,
  showDocumentActions = true,
  showTypeLabel = true,
  baseCategory,
  type,
  onClick,
}: DocumentListItemProps) {
  const { type: documentType, files, meta } = item || {};
  const { isOpen, handleToggle, handleClose } = useOpenClose();
  const [archiveDocument, { isLoading: isArchiving }] = useArchiveDocumentMutation();
  const documentTitle = documentTitles[documentType] || (documentType as string);
  const originalViewUrl = files[0]?.viewUrls?.[0]?.url;
  const fileName = files[0]?.originalName;
  const isImage = files[0]?.contentType?.includes('image');
  const { getFlag, shouldHide } = useContext(QueueFlagsContext);
  const itemId = item?.id?.toString() || '';
  const currentFlag = files.some((file: DocumentFile, index: number) => {
    const dataKeyWithSuffix = `${itemId}-${index}`;
    return getFlag([dataKeyWithSuffix, itemId], file.originalName, false);
  });
  const { isPhone } = useBreakpoint();

  const handleArchive = async () => {
    if (currentFlag) {
      toast.error('Please upload a new document to fix the flagged issue(s). You cannot archive this document.');
      return;
    }
    const confirmed = window.confirm(
      'Are you sure you want to Archive this Document? This action cannot be undone.',
    );

    if (!confirmed) return;

    try {
      await archiveDocument({
        id: item?.id?.toString() || '',
        accountId: item?.accountId?.toString() || '',
        invalidateAllTags: false,
      }).unwrap();
      toast.success('Document archived successfully');
      handleClose();
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error archiving uploaded document');
    } finally {
      handleClose();
    }
  };
  const documentCreatedAtDate = DateTime.fromISO(meta?.createdAt || '');
  const documentCreatedAtFormat =
    documentCreatedAtDate.year === DateTime.now().year ? 'LLL d, h:mm a' : 'LLL d, yyyy h:mm a';

  return (
    <Box borderWidth="xs 0 0 0" borderColor="separator" background={currentFlag ? 'danger' : 'primary'}>
      <Box padding={{ base: 'md 0', tablet: '0' }} direction="row" width="100" alignItems="center">
        <Box width="100" direction="row" alignItems="center">
          {currentFlag && (
            <>
              <Box display="block" childGap="xs" padding={{ base: 'md lg', tablet: 'sm md md lg' }}>
                <Icon name="flag" size="lg" color="danger" />
              </Box>
              <Box
                display="block"
                childGap="2xs"
                padding={{ base: '0 lg', tablet: 'md md md 0' }}
                margin="0 2xl 0 0"
                width={{ base: '2xl' }}
              >
                {files.length > 1 ? (
                  <FlaggedListItems files={files} itemId={itemId} />
                ) : (
                  files.map((file, index) => {
                    const dataKey = getFlag([itemId], file.originalName, false) ? itemId : `${itemId}-${index}`;
                    return <FlagInfo key={dataKey} dataKey={dataKey} fileName={file.originalName} type={type} />;
                  })
                )}
              </Box>
            </>
          )}
          <Box onClick={() => onClick(0)} style={{ cursor: 'pointer' }}>
            {isImage ? (
              <Box
                width={{ base: 'md', tablet: 'lg' }}
                margin={{ base: '0 0 0 sm', tablet: 'sm lg sm lg' }}
                borderWidth="xs"
                borderColor="default"
              >
                <img className={styles.image} src={originalViewUrl} alt={fileName} loading="lazy" />
              </Box>
            ) : (
              <Box
                alignItems="flex-start"
                width={{ base: 'md', tablet: 'lg' }}
                padding={{ base: 'sm', tablet: 'sm md md lg' }}
                margin={
                  currentFlag
                    ? { base: '0 xl 0 0', tablet: '0 2xl 0 0' }
                    : { base: '0 2xl 0 0', tablet: '0 4xl 0 0' }
                }
              >
                <Icon name="document" size="3xl" color="body-tertiary" />
              </Box>
            )}
          </Box>
          <Box
            width={currentFlag ? { base: 'xl', tablet: isImage ? '2xl' : '25' } : { base: '2xl', tablet: '50' }}
            display="block"
            childGap="xs"
            padding={{ base: '0 lg', tablet: 'md md md 0' }}
            onClick={() => onClick}
          >
            <Box display="block">
              {files.map((file: DocumentFile, index: number) => {
                const dataKeyWithSuffix = `${itemId}-${index}`;
                const fileFlag = getFlag([dataKeyWithSuffix, itemId], file.originalName, false);

                return (
                  <Box
                    className={classNames({
                      [styles.fileName]: isPhone,
                    })}
                    key={`${file.md5Hash}-${index}`}
                    title={file.originalName}
                    display="flex"
                    direction="row"
                  >
                    {trimFileNames(file.originalName)}
                    {fileFlag && files.length > 1 && (
                      <Box margin="2xs 0 0 xs">
                        <Icon name="flag" size="xs" color="danger" />
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
            {documentTitle && showTypeLabel && <Box fontSize="xs">{documentTitle}</Box>}
          </Box>
          <Box
            width={{ base: '2xl', tablet: '3xl' }}
            childGap="xs"
            padding={{ base: '0 lg', tablet: 'md md md 0' }}
            margin={{ base: '0 0 xs 0', tablet: '0 0 0 4xl' }}
            direction={{ base: 'column', tablet: 'row' }}
            justifyContent={{ base: 'flex-start', tablet: 'flex-end' }}
          >
            <DateTimeDisplay
              displayLocalDateTime={true}
              value={meta?.createdAt || ''}
              excludeTime={true}
              dateFormat={documentCreatedAtFormat}
              fontSize="md"
              hoverDateFormat="DD h:mm:ss a"
            />
          </Box>
          <Box
            direction={{ base: 'column', tablet: 'row' }}
            childGap="xs"
            borderColor="separator"
            borderWidth={{ tablet: '0 sm 0 sm' }}
          >
            <Button
              iconPrefix="arrows-maximize"
              variant="tertiary"
              size="md"
              tone="neutral"
              onClick={() => onClick(0)}
            />
            <Button
              iconPrefix="launch-app"
              variant="tertiary"
              href={originalViewUrl}
              as="a"
              size="md"
              target="_blank"
              tone="neutral"
            />
            <Button
              iconPrefix="download"
              href={originalViewUrl}
              download
              as="a"
              variant="tertiary"
              size="md"
              tone="neutral"
            />
          </Box>
          {showDocumentActions && allowArchive && (
            <RequirePermissions
              permissions={[UserPermissions.admin, UserPermissions.editor]}
              checkAllPermissions={false}
            >
              <Popover
                content={
                  <Box childGap="xs">
                    <Button
                      size="sm"
                      variant="secondary"
                      tone="neutral"
                      isLoading={isArchiving}
                      onClick={handleArchive}
                    >
                      Archive
                    </Button>
                  </Box>
                }
                isOpen={isOpen}
                onClickOutside={() => handleClose()}
                placement="bottom-start"
                offsetFromTarget={-20}
                contentContainerProps={{
                  padding: 'sm',
                  background: 'grey-800',
                }}
              >
                <Box
                  childGap="xs"
                  padding={{ base: '0 lg', tablet: 'md lg md 0' }}
                  direction={{ base: 'column', tablet: 'row' }}
                  justifyContent={{ base: 'flex-start', tablet: 'flex-end' }}
                  alignItems={{ base: 'center' }}
                >
                  {!shouldHide && item.id && (
                    <Flag
                      baseCategory={baseCategory}
                      dataKey={`${itemId}-0`}
                      fileName={fileName}
                      documentType={documentType}
                      type={type}
                      padding="xs"
                      borderColor="separator"
                      borderWidth="0 sm 0 0"
                    />
                  )}
                  <Button
                    iconPrefix="dots"
                    variant="tertiary"
                    size="md"
                    tone="neutral"
                    aria-label="documentActions"
                    onClick={handleToggle}
                  />
                </Box>
              </Popover>
            </RequirePermissions>
          )}
        </Box>
      </Box>
    </Box>
  );
}
