import {
  Box,
  Button,
  Card,
  FileUpload,
  Modal,
  SelectInput,
  FormikTextareaInput,
  FormikSelectInputNative,
  toast,
  useBreakpoint,
} from '@palmetto/palmetto-components';
import { useCallback, useContext, useMemo, useState } from 'react';
import {
  FlagDocumentTypes,
  FlagProblem,
  FlagPurposeLabel,
  FlagPurposeType,
  FlagReason,
  RequirementAcceptanceCriterion,
  RequirementFlagSettingsType,
  UserPermissions,
} from 'types';
import { Field, Formik, Form } from 'formik';
import { QueueFlagsContext } from './QueueFlagsContext';
import { useUploadDocumentMutation } from '../../services/documents';
import { useGetRequirementFlagSettingByTypeQuery } from '../../services/requirementFlagSettings';
import RequirePermissions from '../auth/requirePermissions';
import { trimFileNames } from '@/helpers/trimFileNames';
import { isErrorWithData } from '@/services/helpers';
import { HTTP_STATUS_CODES } from '@/constants';

interface NewAddFlagModalProps {
  accountId: string;
  isOpen: boolean;
  onDismiss: () => void;
  dataKey: string;
  fileName?: string;
  baseCategory?: string;
  showUploadButton?: boolean;
  documentType?: string;
  type?: RequirementFlagSettingsType;
}

export const NewAddFlagModal = ({
  accountId,
  isOpen,
  onDismiss,
  dataKey,
  fileName,
  baseCategory,
  showUploadButton = false,
  documentType,
  type,
}: NewAddFlagModalProps) => {
  const { addFlag, isLoading } = useContext(QueueFlagsContext);
  const [flagFiles, setFlagFiles] = useState<FileList | undefined>();
  const [selectedCriterion, setSelectedCriterion] = useState<{ label: string; value: string }>();
  const [uploadDocument, { isLoading: isUploadingDocument }] = useUploadDocumentMutation();
  const { isPhone } = useBreakpoint();
  const { data: setting } = useGetRequirementFlagSettingByTypeQuery({ type }, { skip: !type });

  const potentialCriteriaProblems =
    useMemo(() => {
      return setting?.acceptanceCriteria?.reduce(
        (acc: Record<string, any>, criterion: RequirementAcceptanceCriterion) => {
          const idAsString = criterion.id.toString();
          acc[idAsString] = [];
          return acc;
        },
        {},
      );
    }, [setting?.acceptanceCriteria]) || {};
  const handleFileUpload = useCallback((event: any) => {
    setFlagFiles(event.target.files);
  }, []);
  const reasonOptions = useMemo(() => {
    return Object.values(FlagReason).map((value) => ({ value: value, label: value }));
  }, []);
  const purposeOptions = useMemo(() => {
    return Object.entries(FlagPurposeLabel).map(([key, value]) => ({ value: key, label: value }));
  }, []);

  const handleHide = useCallback(() => {
    onDismiss();
    setFlagFiles(undefined);
  }, [onDismiss]);

  const handleSubmit = async (values: any) => {
    if (isLoading || isUploadingDocument) {
      return;
    }
    let uploadedDocumentId = '';

    const problems = [] as FlagProblem[];
    Object.keys(potentialCriteriaProblems).forEach((key) => {
      const criteriaProblems = values[key];
      if (Array.isArray(criteriaProblems) && criteriaProblems?.length > 0) {
        criteriaProblems.forEach((problem: { label: string; value: string }) => {
          problems.push({ id: problem.value, displayText: problem.label, acceptanceCriteriaId: key });
        });
      }
    });

    try {
      if (flagFiles) {
        const data = new FormData();
        data.append('type', FlagDocumentTypes.flag);

        for (let i = 0; i < flagFiles.length; i++) {
          data.append(`files[${i}]`, flagFiles[i]);
        }

        const uploadedDocumentResult = (await uploadDocument({
          accountId,
          formData: data,
          invalidateAllTags: false,
        }).unwrap()) as any;
        uploadedDocumentId = uploadedDocumentResult[0].id;
        uploadedDocumentResult?.[0]?.files.length > 1
          ? toast.success(`Documents uploaded`)
          : toast.success(`Document uploaded`);
      }

      await addFlag({
        dataKey,
        fileName,
        note: values.note,
        ...(values?.reason ? { reason: values.reason as FlagReason } : {}),
        ...(problems.length > 0 ? { problems } : {}),
        purposeType: values.purpose as FlagPurposeType,
        baseCategory,
        fileUpload: uploadedDocumentId,
        documentType,
        type,
      });
    } catch (e: any) {
      if (
        e.status === HTTP_STATUS_CODES.CLIENT_ERROR_ENTITY_TOO_LARGE ||
        e.originalStatus === HTTP_STATUS_CODES.CLIENT_ERROR_ENTITY_TOO_LARGE
      ) {
        toast.error('File size too large. Please upload files smaller than 125MB.');
      } else if (isErrorWithData(e)) {
        toast.error(e.data.message);
      } else {
        toast.error('An error occurred while uploading documents');
      }
    }
    handleHide();
  };

  const truncatedFileName = trimFileNames(fileName, dataKey);

  return (
    <Modal
      isOpen={isOpen}
      maxWidth="4xl"
      ariaLabelledBy="addFlagHeader"
      fullScreenMobile
      overflow="auto"
      onDismiss={handleHide}
    >
      <Modal.Header id="addFlagHeader" title={`Flag ${truncatedFileName}`} onDismiss={handleHide} />
      <Formik
        initialValues={
          { ...potentialCriteriaProblems, purpose: FlagPurposeType.rejection, note: '' } as Record<string, any>
        }
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, submitForm, values }) => (
          <Form noValidate>
            <Modal.Body background="secondary" childGap="lg">
              <Box childGap="md">
                <Field
                  label="Purpose"
                  name="purpose"
                  id="purpose"
                  options={purposeOptions}
                  component={FormikSelectInputNative}
                  autoComplete="off"
                  isDisabled={isSubmitting}
                />
                {setting && setting?.acceptanceCriteria?.length ? (
                  <Card>
                    <Card.Header title="Describe the issue(s)"></Card.Header>
                    <Card.Section childGap="sm">
                      <SelectInput
                        key="selectAcceptanceCriteria"
                        id="selectAcceptanceCriteria"
                        name="selectAcceptanceCriteria"
                        label="Where is the problem?"
                        value={selectedCriterion}
                        onChange={(event) => {
                          Object.keys(potentialCriteriaProblems).forEach((key) => {
                            const previousCriterionSelectionId = Object.keys(values).find(
                              (valueKey) => valueKey === key,
                            );

                            if (previousCriterionSelectionId) {
                              setFieldValue(previousCriterionSelectionId, []);
                            }
                          });
                          setSelectedCriterion(event.target.value);
                        }}
                        options={setting?.acceptanceCriteria?.map((criterion) => ({
                          label: criterion?.displayText,
                          value: criterion?.id.toString(),
                        }))}
                        isClearable
                        menuPortalTarget={document.body}
                      />
                      {selectedCriterion && (
                        <SelectInput
                          key={selectedCriterion.value}
                          id={selectedCriterion.value}
                          name={selectedCriterion.value}
                          label="Select one or more problems"
                          value={values[selectedCriterion.value]}
                          onChange={(event) => setFieldValue(selectedCriterion.value, event.target.value)}
                          options={setting?.acceptanceCriteria
                            ?.find((criterion) => criterion?.id.toString() === selectedCriterion.value)
                            ?.problems?.map((problem) => ({
                              label: problem?.displayText,
                              value: problem?.id.toString(),
                            }))}
                          isMulti
                          isClearable
                          menuPortalTarget={document.body}
                        />
                      )}
                    </Card.Section>
                  </Card>
                ) : (
                  <Field
                    label="Reason"
                    name="reason"
                    id="reason"
                    options={reasonOptions}
                    component={FormikSelectInputNative}
                    autoComplete="off"
                    isDisabled={isSubmitting}
                  />
                )}
                <Field
                  type="text"
                  label="Notes"
                  name="note"
                  id="note"
                  component={FormikTextareaInput}
                  isDisabled={isSubmitting}
                />
                {showUploadButton && (
                  <RequirePermissions
                    permissions={[UserPermissions.admin, UserPermissions.editor]}
                    checkAllPermissions={false}
                  >
                    <Box direction="row" childGap="md" alignItems="center">
                      {isUploadingDocument ? (
                        <Button
                          variant="secondary"
                          tone="neutral"
                          iconPrefix="upload"
                          isDisabled={true}
                          isLoading={isUploadingDocument || isLoading}
                          size="sm"
                        >
                          Upload
                        </Button>
                      ) : (
                        <FileUpload
                          labelText="Upload"
                          id={`upload-${FlagDocumentTypes.flag}-photos`}
                          name={`${FlagDocumentTypes.flag}-files`}
                          accept="image/*,.pdf"
                          size="sm"
                          multiple
                          onChange={handleFileUpload}
                          buttonText={!isPhone ? 'Upload file(s)' : null}
                          onClick={(event: any) => event.stopPropagation()}
                          isDisabled={isUploadingDocument || isLoading}
                          files={flagFiles}
                        />
                      )}
                    </Box>
                  </RequirePermissions>
                )}
              </Box>
            </Modal.Body>
            <Modal.Footer>
              <Button
                as="button"
                onClick={handleHide}
                variant="secondary"
                tone="neutral"
                size="md"
                disabled={isUploadingDocument || isLoading}
              >
                Cancel
              </Button>
              <Button
                size="md"
                type="submit"
                onClick={submitForm}
                color="danger"
                disabled={isUploadingDocument || isLoading}
              >
                Flag Item
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
