import FormSection from '@/components/Forms/FormSection';
import { DocumentList } from '../Documents/DocumentList';
import { Box } from '@palmetto/palmetto-components';
import { InstallDocumentTypes } from 'types';

export const InstallationDocuments = () => {
  const documentTypeFilter = [
    InstallDocumentTypes.fsmChecklist,
    InstallDocumentTypes.measureQuickSystemVitals,
    InstallDocumentTypes.bluetoothHVACTool,
  ];

  return (
    <FormSection
      title="Installation Commissioning Document"
      description={
        <Box fontSize="sm" childGap="xs" color="body-secondary">
          <p>Please provide proof of installation commissioning in the form of one or all of:</p>
          <Box childGap="2xs" as="ul">
            <li>
              A Checklist from your Field Service Management (FSM) System that matches LightReach Installation
              Requirements
            </li>
            <li>Measure Quick System Vitals Report</li>
            <li>Bluetooth HVAC Tool PDF Report</li>
          </Box>
        </Box>
      }
    >
      <DocumentList
        title="Installation Commissioning Documents"
        documentTypeFilter={documentTypeFilter}
        baseCategory="HVAC Installation Documents"
      />
    </FormSection>
  );
};
