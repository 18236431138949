import { NotesDrawer } from '../NotesDrawer/NotesDrawer';
import { toast, Alert, Box, useOpenClose } from '@palmetto/palmetto-components';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router';
import { QueueStatus, QueueType, UserPermissions, AttestmentData, RequirementFlagSettingsType } from 'types';
import { Formik, FormikValues } from 'formik';
import { ReviewPackageActions } from '../../types/ReviewPackage';
import { useGetAccountQuery } from '../../services/accounts';
import { MainContainer } from '../MainContainer';
import { useSaveNTPPackageMutation, useSubmitNTPPackageMutation } from '../../services/ntpPackage';
import { QueueFlagsProvider } from '../QueueFlags/QueueFlagsContext';
import { useGetQueueByAccountIdAndTypeQuery } from '../../services/queues';
import { QueueBanner } from '../MilestonePackagePartials/QueueBanner';
import { useAuth } from '../auth/authProvider';
import { isPalmettoFinanceUser } from '../auth/RequirePalmettoFinanceUser';
import FormSection from '../Forms/FormSection';
import { SkeletonBox } from '../SkeletonBox';
import { Footer } from '../MilestonePackagePartials/Footer';
import { isErrorWithData } from '../../services/helpers';
import { WorkOrder } from './WorkOrder';
import usePermissions from '@/hooks/usePermissions';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import { AttestmentModal } from '../ProductionValueCheck/AttestmentModal';
import { calculateTotalCost } from '@/helpers/calculateTotalCost';
import { HVACWorkOrderInfo } from './HVACWorkOrderInfo';
import { FlagBox } from '../QueueFlags/FlagBox';
import { EnhancedFormikForm } from '../EnhancedFormikForm';
import { LoadCalculation } from './LoadCalculation';
import { SystemSection } from '../SystemDesign/hvac/SystemSection';

const attestmentLabel =
  'By submitting this package, I attest that all equipment listed is reflected in the work order provided to the customer, and that any equipment changes at installation must be updated to receive funding.';

interface NTPPackageProps {
  canSubmitPackage: boolean;
}

export const NTPPackage = ({ canSubmitPackage }: NTPPackageProps) => {
  const navigate = useNavigate();
  const { claims } = useAuth();
  const isFinCoUser = isPalmettoFinanceUser(claims);
  const permissions = usePermissions();

  const [triggerSubmit] = useSubmitNTPPackageMutation();
  const [triggerSave] = useSaveNTPPackageMutation();
  const [attestmentData, setAttestmentData] = useState<AttestmentData>();
  const [submitFormValues, setSubmitFormValues] = useState<FormikValues | undefined>();
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userOnlyHasReadPermissions = permissions?.every((permission) => permission.includes(UserPermissions.reader));

  const { id = '', action = 'review' } = useParams<{ id: string; action: ReviewPackageActions }>();
  const { data: queue, isLoading: isQueueLoading } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.ntpPackage,
  });
  const { data: account, isLoading: isAccountLoading } = useGetAccountQuery(id);

  const {
    isOpen: isAttestmentModalOpen,
    handleClose: handleCloseAttestmentModal,
    handleOpen: handleOpenAttestmentModal,
  } = useOpenClose();

  const queueStatusIsApprovedOrConditionallyApprovedAndIsNotFinCoUser: boolean =
    !isFinCoUser &&
    queue &&
    (queue.status === QueueStatus.conditionallyApproved || queue.status === QueueStatus.approved);
  const isFirstSubmission = !queue;
  const canSubmitReview = action === ReviewPackageActions.review && queue;
  const isLoading = isAccountLoading || isQueueLoading;

  async function handleAttestmentConfirmation(attestmentData: AttestmentData) {
    setAttestmentData(attestmentData);
  }

  const handleSubmit = async (values: FormikValues) => {
    try {
      if (!attestmentData) {
        setSubmitFormValues(values);
        handleOpenAttestmentModal();
        return;
      }
      setIsSubmitting(true);
      if (!attestmentData?.attestmentText) {
        toast.error('ERROR: You need to attest this submittal.');
        handleCloseAttestmentModal();
        return;
      }

      const formattedData = {
        attestment: {
          attestmentText: attestmentData?.attestmentText || '',
        },
        systemDesign: {
          systems: values.systems,
        },
        workOrderTotalPrice: values.workOrderTotalPrice || 0,
      };

      await triggerSubmit({ accountId: id, ntpPackage: formattedData }).unwrap();
      toast.success(`Notice to Proceed Package ${!isFirstSubmission ? 'Resubmitted' : 'Submitted'}`);
      handleCloseAttestmentModal();
      navigate(`/accounts/${id}`, { replace: true });
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error submitting notice to proceed package');
      handleCloseAttestmentModal();
    } finally {
      setIsSubmitting(false);
      setAttestmentData(undefined);
    }
  };

  const handleSave = async ({ accountId, data }: { accountId: string; data: FormikValues }) => {
    const formattedData = {
      attestment: {
        attestmentText: data.attestmentText,
      },
      systemDesign: {
        systems: data.systems,
      },
      workOrderTotalPrice: data.workOrderTotalPrice || 0,
    };
    try {
      await triggerSave({ accountId, ntpPackage: formattedData }).unwrap();
      toast.success('Notice to Proceed Package Saved');
    } catch (e) {
      console.error(e);
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(errorMessage);
      } else {
        console.error(e);
        toast.error('Error saving Notice to Proceed Package');
      }
    }
  };

  useEffect(() => {
    if (!action || !ReviewPackageActions[action]) {
      navigate(`/accounts/${id}`, { replace: true });
    }
    if (action === ReviewPackageActions.review && !isFinCoUser) {
      if (queue) {
        navigate(`/accounts/${id}/ntp-package/edit`, { replace: true });
      } else {
        navigate(`/accounts/${id}/ntp-package/create`, { replace: true });
      }
    }
  }, [id, navigate, action, isFinCoUser, queue]);

  useEffect(() => {
    if (submitFormValues && attestmentData) {
      handleSubmit(submitFormValues);
    }
  }, [submitFormValues, attestmentData]);

  if (isLoading) {
    return (
      <MainContainer>
        <FormSection title="Loading...">
          <SkeletonBox height="25px" width="100" />
        </FormSection>
      </MainContainer>
    );
  }

  return (
    <QueueFlagsProvider queueId={queue?.id}>
      <NotesDrawer isOpen={isNotesOpen} setIsOpen={setIsNotesOpen} queueType={QueueType.ntpPackage} />
      <Helmet>
        <title>{`${account?.primaryApplicantName} Notice to Proceed Package`}</title>
      </Helmet>
      <MainContainer>
        {queue && (queue.status === QueueStatus.rejected || isFinCoUser) ? (
          <QueueBanner setIsNotesOpen={setIsNotesOpen} queueType={QueueType.ntpPackage} isFinCoUser={isFinCoUser} />
        ) : null}
        {action === ReviewPackageActions.review && !canSubmitReview ? (
          <Alert
            variant="warning"
            title="NTP Package Not Submitted"
            message="This notice-to-proceed package is not ready to be reviewed yet. It has not been submitted."
            hasIcon
          />
        ) : null}
        <Formik
          initialValues={{
            attestmentText: account?.ntpAttestment?.attestmentText || null,
            systems: account?.systemDesign?.systems || [],
            workOrderTotalPrice: account?.workOrderTotalPrice || 0,
          }}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validateOnChange={false}
        >
          {({ values, handleSubmit }) => {
            const totalSystemDesignCost = calculateTotalCost(values);
            return (
              <EnhancedFormikForm onSubmit={handleSubmit}>
                {action === ReviewPackageActions.review && (
                  <FormSection>
                    <HVACWorkOrderInfo
                      isSubmitting={isSubmitting}
                      isLoading={isLoading}
                      userOnlyHasReadPermissions={userOnlyHasReadPermissions}
                    />
                  </FormSection>
                )}
                <WorkOrder allowArchive={!queueStatusIsApprovedOrConditionallyApprovedAndIsNotFinCoUser} />
                <LoadCalculation />
                <SystemSection />
                <Box alignItems="flex-end" margin="md" padding={{ base: 'lg 0 0 0' }} gap="xs">
                  <Box direction="row" gap="xs" alignItems="center">
                    <Box fontWeight="medium" fontSize="md">
                      Total Number of Systems:
                    </Box>
                    <Box fontWeight="bold" fontSize="lg">
                      {values.systems.length}
                    </Box>
                  </Box>
                  <Box direction="row" gap="xs" alignItems="center">
                    <Box fontWeight="medium" fontSize="md">
                      Total System Design Cost:
                    </Box>
                    <Box fontWeight="bold" fontSize="lg">
                      {currencyFormatter.format(totalSystemDesignCost)}
                    </Box>
                  </Box>
                </Box>
                {action === ReviewPackageActions.edit && (
                  <FormSection>
                    <FlagBox
                      dataKey="System Cost Discrepancy"
                      type={RequirementFlagSettingsType.systemCostDescrepancyHVAC}
                    />
                  </FormSection>
                )}
                <FormSection>
                  <FlagBox dataKey="System Design Issue" type={RequirementFlagSettingsType.systemDesignIssueHVAC} />
                </FormSection>

                <AttestmentModal
                  isOpen={isAttestmentModalOpen}
                  onDismiss={handleCloseAttestmentModal}
                  onAttestmentConfirmed={handleAttestmentConfirmation}
                  label={attestmentLabel}
                  title={'NTP Submission Confirmation'}
                  isLoading={isLoading || isSubmitting}
                />
                <Footer
                  canSubmitPackage={canSubmitPackage}
                  isLoading={isLoading || isSubmitting}
                  isFirstSubmission={isFirstSubmission}
                  canSubmitReview={canSubmitReview}
                  onSave={handleSave}
                  disabled={queueStatusIsApprovedOrConditionallyApprovedAndIsNotFinCoUser}
                />
              </EnhancedFormikForm>
            );
          }}
        </Formik>
      </MainContainer>
    </QueueFlagsProvider>
  );
};
