import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ResponsiveProvider, ThemeProvider } from '@palmetto/palmetto-components';
import { IntercomProvider } from 'react-use-intercom';
import { store } from './store';
import { Accounts, Account } from './components';
import { AuthProvider } from './components/auth/authProvider';
import { Queues } from './components/TaskQueues/Queues';
import { Settings } from './components/Settings';
import Error from './Error';
import { CreateAccount } from './components/Account/CreateAccount';
import { EditAccount } from './components/Account/EditAccount';
import { ApplicationForm } from './components/CreditCheck/ApplicationForm';
import { CreateQuote } from './components/Quotes/CreateQuote';
import RequirePermissions from './components/auth/requirePermissions';
import { NTPMilestonePackage } from './components/NTPMilestonePackage/NTPMilestonePackage';
import { ActivationMilestonePackage } from './components/ActivationMilestonePackage/ActivationMilestonePackage';
import getConfigValue from './config';
import { Pricing } from './components/Pricing/Pricing';
import { Markets } from './components/Markets/Markets';
import RequirePalmettoFinanceUser from './components/auth/RequirePalmettoFinanceUser';
import { PriceSheetDetails } from './components/Pricing/PriceSheetDetails/PriceSheetDetails';
import { PriceSheetMappings } from './components/Pricing/PriceSheetDetails/PriceSheetMappings';
import PriceSheet from './components/Pricing/PriceSheetDetails/PriceSheet';
import { CreateMapping } from './components/Pricing/Mappings/CreateMapping';
import { EditMapping } from './components/Pricing/Mappings/EditMapping';
import { YourProfile } from './components/YourProfile';
import EditPriceSheet from './components/Pricing/PriceSheetDetails/EditPriceSheet';
import { Funds } from './components/Funds/Funds';
import { Fund } from './components/Funds/Fund';
import { AssignAccounts } from './components/Funds/AssignAccounts';
import { ProductionValueCheck } from './components/ProductionValueCheck/ProductionValueCheck';
import { FlagGuard } from './components/FlagGuard/FlagGuard';
import { Queue } from './components/Queues/Queue';
import AuthenticatingWrapper from './AuthenticatingWrapper';
import { BulkEditMappings } from './components/Pricing/Mappings/BulkEdit/BulkEditMappings';
import './index.css';
import { ApplicationSuccess } from './components/CreditCheck/ApplicationSuccess';
import { DisclosureForm } from './components/Disclosures/DisclosureForm';
import { SalesRepAuthentication } from './components/SalesRepVerification/SalesRepAuthentication';
import { DisclosureConfirmation } from './components/Disclosures/DisclosureConfirmation';
import { Payouts } from '@/components/Payouts/admin/Payouts';
import { PostActivationMilestonePackage } from './components/PostActivationMilestonePackage/PostActivationMilestonePackage';
import { InstallMilestonePackageFactory } from './components/InstallMilestonePackage/InstallMilestonePackageFactory';
import { DomesticContentValueConfirmation } from './components/DomesticContentValueConfirmation/DomesticContentValueConfirmation';
import { SalesReps } from './components/SalesReps/SalesReps';
import { ContractTemplates } from './components/AdminContracts/ContractTemplates';
import { SalesRepDetails } from './components/SalesReps/SalesRepDetails';
import { UserPermissions } from 'types';
import { AdminTasks } from './components/AdminTasks/AdminTasks';
import { QualityControlSettings } from './components/QualityControl/QualityControlSettings';
import { VerificationSuccess } from './components/SalesRepVerification/VerificationSuccess';
import { FundHistory } from './components/Funds/FundHistory';
import { FundOverview } from './components/Funds/FundOverview';
import { VerificationError } from './components/SalesRepVerification/VerificationError';
import { UnAssignAccounts } from './components/Funds/UnAssignAccounts';
import { AdvancePtoForm } from './components/PermissionToOperate/AdvancePtoForm';

const intercomAppId = getConfigValue('VITE_APP_INTERCOM_ID') || '';

const launchDarklyClientId = getConfigValue('VITE_APP_LAUNCH_DARKLY_CLIENT_ID') || '';
const anonymousUserKey = 'unknown-user-key';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthenticatingWrapper />,
    errorElement: <Error />,
    children: [
      {
        path: '/admin/funds',
        element: (
          <RequirePalmettoFinanceUser>
            <RequirePermissions permissions={[UserPermissions.lightReachPaymentAdmin]}>
              <Funds />
            </RequirePermissions>
          </RequirePalmettoFinanceUser>
        ),
      },
      {
        path: '/admin/funds/:key',
        element: (
          <RequirePalmettoFinanceUser>
            <RequirePermissions permissions={[UserPermissions.lightReachPaymentAdmin]}>
              <Fund />
            </RequirePermissions>
          </RequirePalmettoFinanceUser>
        ),
        children: [
          {
            index: true,
            element: <FundOverview />,
          },
          {
            path: '/admin/funds/:key/history',
            element: <FundHistory />,
          },
        ],
      },
      {
        path: '/admin/funds/:key/assign',
        element: (
          <RequirePalmettoFinanceUser>
            <RequirePermissions permissions={[UserPermissions.lightReachPaymentAdmin]}>
              <AssignAccounts />
            </RequirePermissions>
          </RequirePalmettoFinanceUser>
        ),
      },
      {
        path: '/admin/funds/:key/unassign',
        element: (
          <RequirePalmettoFinanceUser>
            <RequirePermissions permissions={[UserPermissions.lightReachPaymentAdmin]}>
              <UnAssignAccounts />
            </RequirePermissions>
          </RequirePalmettoFinanceUser>
        ),
      },
      {
        path: '/admin/markets',
        element: (
          <RequirePalmettoFinanceUser>
            <Markets />
          </RequirePalmettoFinanceUser>
        ),
      },
      {
        path: '/admin/contracts',
        element: (
          <RequirePalmettoFinanceUser>
            <ContractTemplates />
          </RequirePalmettoFinanceUser>
        ),
      },
      {
        path: '/admin/payouts/*',
        element: (
          <RequirePermissions
            permissions={[UserPermissions.admin, UserPermissions.orgAccountingAdmin]}
            checkAllPermissions={false}
          >
            <Payouts />
          </RequirePermissions>
        ),
      },
      {
        path: '/admin/pricing/:programType/*',
        element: (
          <RequirePalmettoFinanceUser>
            <Pricing />
          </RequirePalmettoFinanceUser>
        ),
      },
      {
        path: '/admin/pricing/:programType/mappings/create',
        element: (
          <RequirePalmettoFinanceUser>
            <RequirePermissions permissions={[UserPermissions.pricingAdmin]}>
              <CreateMapping />
            </RequirePermissions>
          </RequirePalmettoFinanceUser>
        ),
      },
      {
        path: '/admin/pricing/:programType/mappings/edit/:id',
        element: (
          <RequirePalmettoFinanceUser>
            <RequirePermissions permissions={[UserPermissions.pricingAdmin]}>
              <EditMapping />
            </RequirePermissions>
          </RequirePalmettoFinanceUser>
        ),
      },
      { path: '/admin/pricing/:programType/mappings/bulk-edit', element: <BulkEditMappings /> },
      {
        path: '/admin/pricing/:programType/price-sheet/:id',
        element: (
          <RequirePalmettoFinanceUser>
            <PriceSheet />
          </RequirePalmettoFinanceUser>
        ),
        children: [
          {
            index: true,
            element: <PriceSheetDetails />,
          },
          {
            path: '/admin/pricing/:programType/price-sheet/:id/mappings',
            element: <PriceSheetMappings />,
          },
        ],
      },
      {
        path: '/admin/pricing/:programType/price-sheet/edit/:id',
        element: (
          <RequirePermissions
            permissions={[UserPermissions.admin, UserPermissions.editor]}
            checkAllPermissions={false}
          >
            <EditPriceSheet />
          </RequirePermissions>
        ),
      },
      {
        path: '/admin/sales-reps',
        element: (
          <RequirePalmettoFinanceUser>
            <RequirePermissions permissions={[UserPermissions.admin]} checkAllPermissions={false}>
              <SalesReps />
            </RequirePermissions>
          </RequirePalmettoFinanceUser>
        ),
      },
      {
        path: '/admin/sales-reps/:id',
        element: (
          <RequirePalmettoFinanceUser>
            <RequirePermissions permissions={[UserPermissions.admin]} checkAllPermissions={false}>
              <SalesRepDetails />
            </RequirePermissions>
          </RequirePalmettoFinanceUser>
        ),
      },
      {
        path: '/admin/tasks/:taskType?',
        element: (
          <RequirePalmettoFinanceUser>
            <RequirePermissions permissions={[UserPermissions.lightReachTaskAdmin]} checkAllPermissions={false}>
              <AdminTasks />
            </RequirePermissions>
          </RequirePalmettoFinanceUser>
        ),
      },
      {
        path: '/admin/quality-control/:setting?/*',
        element: (
          <RequirePalmettoFinanceUser>
            <RequirePermissions permissions={[UserPermissions.lightReachSupport]} checkAllPermissions={false}>
              <QualityControlSettings />
            </RequirePermissions>
          </RequirePalmettoFinanceUser>
        ),
      },
      {
        path: '/account/create',
        element: (
          <RequirePermissions
            permissions={[UserPermissions.admin, UserPermissions.editor]}
            checkAllPermissions={false}
          >
            <CreateAccount />
          </RequirePermissions>
        ),
      },
      {
        path: '/account/application/success',
        element: (
          <RequirePermissions permissions={[]} allowInviteToken>
            <ApplicationSuccess />
          </RequirePermissions>
        ),
      },
      {
        path: '/accounts',
        element: <Accounts />,
      },
      {
        path: '/accounts/:id/edit',
        element: (
          <RequirePermissions
            permissions={[UserPermissions.admin, UserPermissions.editor]}
            checkAllPermissions={false}
          >
            <EditAccount />
          </RequirePermissions>
        ),
      },
      {
        path: 'accounts/:id/application/create',
        element: (
          <RequirePermissions
            permissions={[UserPermissions.admin, UserPermissions.editor]}
            checkAllPermissions={false}
            allowInviteToken
          >
            <ApplicationForm />
          </RequirePermissions>
        ),
      },
      {
        path: 'accounts/:id/disclosures',
        element: (
          <RequirePermissions
            permissions={[UserPermissions.admin, UserPermissions.editor]}
            checkAllPermissions={false}
            allowInviteToken
          >
            <DisclosureForm />
          </RequirePermissions>
        ),
      },
      {
        path: 'accounts/:id/disclosure-confirmation',
        element: (
          <RequirePermissions
            permissions={[UserPermissions.admin, UserPermissions.editor]}
            checkAllPermissions={false}
            allowInviteToken
          >
            <DisclosureConfirmation />
          </RequirePermissions>
        ),
      },
      {
        path: 'accounts/:id/quote/create',
        element: (
          <RequirePermissions
            permissions={[UserPermissions.admin, UserPermissions.editor]}
            checkAllPermissions={false}
          >
            <CreateQuote />
          </RequirePermissions>
        ),
      },
      {
        //actions are create, edit, and review
        path: 'accounts/:id/ntp-package/:action',
        element: (
          <RequirePermissions
            permissions={[UserPermissions.admin, UserPermissions.editor]}
            checkAllPermissions={false}
          >
            <NTPMilestonePackage />
          </RequirePermissions>
        ),
      },
      {
        path: 'accounts/:id/proof-of-pto/:action',
        element: (
          <RequirePermissions
            permissions={[UserPermissions.admin, UserPermissions.editor]}
            checkAllPermissions={false}
          >
            <FlagGuard required={['ptoGreenlight']} redirectTo="/">
              <AdvancePtoForm />
            </FlagGuard>
          </RequirePermissions>
        ),
      },
      {
        //actions are create, edit, and review
        path: 'accounts/:id/installation-package/:action',
        element: (
          <RequirePermissions
            permissions={[UserPermissions.admin, UserPermissions.editor]}
            checkAllPermissions={false}
          >
            <InstallMilestonePackageFactory />
          </RequirePermissions>
        ),
      },
      {
        //actions are create, edit, and review
        path: 'accounts/:id/activation-package/:action',
        element: (
          <RequirePermissions
            permissions={[UserPermissions.admin, UserPermissions.editor]}
            checkAllPermissions={false}
          >
            <ActivationMilestonePackage />
          </RequirePermissions>
        ),
      },
      {
        //actions are create, edit, and review
        path: 'accounts/:id/post-activation-package/:action',
        element: (
          <RequirePermissions
            permissions={[UserPermissions.admin, UserPermissions.editor]}
            checkAllPermissions={false}
          >
            <PostActivationMilestonePackage />
          </RequirePermissions>
        ),
      },
      {
        path: 'accounts/:id/production-value-check',
        element: (
          <RequirePermissions
            permissions={[UserPermissions.admin, UserPermissions.editor]}
            checkAllPermissions={false}
          >
            <FlagGuard required={['useProductionConfirmation']} redirectTo="/">
              <ProductionValueCheck />
            </FlagGuard>
          </RequirePermissions>
        ),
      },
      {
        path: 'accounts/:id/domestic-content-value-confirmation',
        element: (
          <RequirePermissions
            permissions={[UserPermissions.admin, UserPermissions.editor]}
            checkAllPermissions={false}
          >
            <DomesticContentValueConfirmation />
          </RequirePermissions>
        ),
      },
      {
        path: 'accounts/:id/sales-rep-verification',
        element: (
          <RequirePermissions
            permissions={[UserPermissions.admin, UserPermissions.editor]}
            checkAllPermissions={false}
            allowInviteToken
          >
            <SalesRepAuthentication />
          </RequirePermissions>
        ),
      },
      {
        path: 'accounts/sales-rep-verification/authenticated',
        element: (
          <RequirePermissions permissions={[]} allowInviteToken>
            <VerificationSuccess />
          </RequirePermissions>
        ),
      },
      {
        path: 'accounts/sales-rep-verification/error',
        element: (
          <RequirePermissions permissions={[]} allowInviteToken>
            <VerificationError />
          </RequirePermissions>
        ),
      },
      {
        path: '/accounts/:id/*',
        element: <Account />,
      },
      /**
       * @deprecated This will be replaced by the /queue route
       */
      {
        path: '/queues/*',
        element: <Queues />,
      },
      {
        path: '/queue/:type',
        element: <Queue />,
      },
      {
        path: '/profile/*',
        element: <YourProfile />,
      },
      {
        path: '/settings/*',
        element: <Settings />,
      },
    ],
  },
]);

(async (): Promise<void> => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: launchDarklyClientId,
    user: {
      anonymous: true,
      key: anonymousUserKey,
    },
  });

  root.render(
    <LDProvider>
      <Provider store={store}>
        <AuthProvider>
          <ResponsiveProvider>
            <ThemeProvider storageKey="lightreach-ui-theme">
              <IntercomProvider appId={intercomAppId} autoBoot>
                <RouterProvider router={router} />
              </IntercomProvider>
            </ThemeProvider>
          </ResponsiveProvider>
        </AuthProvider>
      </Provider>
    </LDProvider>,
  );
})();
