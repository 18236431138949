import { AsyncSelectInput } from '@/components/Forms/AsyncSelect';
import FormSection from '@/components/Forms/FormSection';
import { FlagBox } from '@/components/QueueFlags/FlagBox';
import { debounceAsync } from '@/helpers/debounceAsync';
import usePermissions from '@/hooks/usePermissions';
import { useSearchProductsMutation } from '@/services/productCatalog';
import {
  Box,
  Button,
  FormikSelectInput,
  FormikTextInput,
  SimulatedEventPayloadType,
} from '@palmetto/palmetto-components';
import { FieldArray, Field, useFormikContext } from 'formik';
import { useMemo } from 'react';
import {
  HVACAccessoryItem,
  HVACAccessoryLabels,
  HVACAccessoryType,
  HVACEquipmentItem,
  HVACEquipmentType,
  HVACEquipmentTypeLabels,
  RequirementFlagSettingsType,
  UserPermissions,
} from 'types';

interface EquipmentAndAccessoriesSectionProps {
  isInstallPackage?: boolean;
  dataKey: string;
  label: string;
  systemIndex: number;
}
const EquipmentAndAccessoriesSection = ({
  isInstallPackage = false,
  dataKey,
  label,
  systemIndex,
}: EquipmentAndAccessoriesSectionProps) => {
  const permissions = usePermissions();
  const userOnlyHasReaderPermission = permissions?.every((permission) =>
    permission.includes(UserPermissions.reader),
  );
  const { values, setFieldValue, handleBlur } = useFormikContext<any>();
  const equipmentAccessoryType = dataKey === 'accessories' ? HVACAccessoryType : HVACEquipmentType;

  const equipmentTypes = useMemo(() => {
    const equipmentAccessories = Object.values(equipmentAccessoryType);
    return equipmentAccessories
      .map((type) => ({
        label:
          dataKey === 'accessories'
            ? HVACAccessoryLabels[type as HVACAccessoryType]
            : HVACEquipmentTypeLabels[type as HVACEquipmentType],
        value: type,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [HVACEquipmentType, equipmentAccessoryType]);

  const [triggerSearch] = useSearchProductsMutation();

  const handleSearchText = async (searchText: string, productType: string) => {
    const { data } = await triggerSearch({
      query: searchText,
      limit: 10,
      page: 1,
      filters: [
        {
          filter: 'attribute',
          key: 'lightreach-product-type',
          operation: {
            equals: productType,
          },
        },
      ],
    });
    return data?.map((product) => {
      const model = product.attributes.find((attr) => attr.key === 'model-number')?.value ?? '';
      const productType =
        product.attributes.find((attr) => attr.key === 'lightreach-product-type')?.value ??
        ('' as HVACEquipmentType);
      const isSerialNumberRequired =
        product.attributes.find((attr) => attr.key === 'lightreach-serial-number-required')?.value ?? false;
      return {
        label: `${product.manufacturer} - ${model}`,
        value: product.id,
        manufacturer: product.manufacturer,
        model,
        productType,
        name: product.name,
        isSerialNumberRequired,
      };
    });
  };

  const debouncedSearch = debounceAsync(
    (value: string, equipmentType: HVACEquipmentType | HVACAccessoryType) => handleSearchText(value, equipmentType),
    400,
  );

  return (
    <FormSection
      title={label}
      description={
        <Box gap="sm" fontSize="xs">
          <Box>Update applicable {label} for the proposal.</Box>
          <Box>NOTE: any customer facing price changes require a new proposal from the sales platform.</Box>
        </Box>
      }
      baseStyle={{
        borderWidth: '0',
      }}
    >
      <FieldArray
        name={`systems.${systemIndex}[${dataKey}].items`}
        render={(arrayHelpers) => {
          return (
            <Box gap="lg">
              <Box direction={{ base: 'column', tablet: 'row' }} gap="md">
                <Field
                  label={`Add ${label}`}
                  name={`systems.${systemIndex}[${dataKey}].equipmentType`}
                  id={`systems.${systemIndex}[${dataKey}].equipmentType`}
                  placeholder="Select new equipment type..."
                  component={FormikSelectInput}
                  options={equipmentTypes}
                  isDisabled={userOnlyHasReaderPermission}
                />
                <AsyncSelectInput
                  id={`systems.${systemIndex}[${dataKey}].items.selectedItem`}
                  name={`systems.${systemIndex}[${dataKey}].items.selectedItem`}
                  label={`Search ${label}`}
                  loadOptions={(input: string) => {
                    return debouncedSearch(input, values?.systems[systemIndex]?.[dataKey]?.equipmentType?.value);
                  }}
                  isDisabled={
                    userOnlyHasReaderPermission || !values.systems?.[systemIndex]?.[dataKey]?.equipmentType
                  }
                  onChange={(event) => {
                    arrayHelpers.push({
                      type: values?.systems[systemIndex]?.[dataKey]?.equipmentType?.value,
                      manufacturer: event.target.value.manufacturer,
                      model: event.target.value.model,
                      quantity: 1,
                      productId: event.target.value.value,
                      name: event.target.value.name,
                    });
                    setFieldValue(`systems.${systemIndex}[${dataKey}].equipmentType`, null);
                  }}
                  onBlur={handleBlur}
                  isClearable
                  value={''}
                  placeholder="Search..."
                  noOptionsMessage={() => `Search for ${label}`}
                />
              </Box>
              {values?.systems[systemIndex]?.[dataKey]?.items?.map?.(
                (
                  equipmentAccessory: (HVACEquipmentItem | HVACAccessoryItem) & { isSerialNumberRequired?: boolean },
                  index: number,
                ) => {
                  return (
                    <Box
                      key={`system-${systemIndex}-${dataKey}-${equipmentAccessory.type}-${equipmentAccessory.model}-${index}`}
                      direction="row"
                      gap="lg"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box direction="column" gap="sm" width={{ base: '60', tablet: '40' }} alignSelf="center">
                        <Box fontSize="md" fontWeight="medium">
                          {dataKey === 'accessories'
                            ? HVACAccessoryLabels[equipmentAccessory.type as HVACAccessoryType]
                            : HVACEquipmentTypeLabels[equipmentAccessory.type as HVACEquipmentType]}
                        </Box>
                        <Box fontSize="sm">
                          {equipmentAccessory.manufacturer} - {equipmentAccessory.model}
                        </Box>
                        <Box fontSize="sm" style={{ fontStyle: 'italic' }}>
                          ({equipmentAccessory.name})
                        </Box>
                      </Box>
                      <Box direction="column" gap="sm" flex={{ tablet: 'auto' }}>
                        <Box direction={{ base: 'column', tablet: 'row' }} gap="sm" justifyContent="space-between">
                          {isInstallPackage && (
                            <Box width={{ base: '100', tablet: '3xl' }}>
                              {equipmentAccessory.type !== 'other' ? (
                                <>
                                  <Box padding="0 0 xs 0">
                                    {equipmentAccessory.quantity <= 1 || !equipmentAccessory.quantity
                                      ? `Serial Number${equipmentAccessory?.isSerialNumberRequired ? ' *' : ''}`
                                      : `Serial Numbers${equipmentAccessory?.isSerialNumberRequired ? ' *' : ''}`}
                                  </Box>
                                  <FieldArray
                                    name={`systems.${systemIndex}[${dataKey}].items.${index}.serialNumbers`}
                                    render={() => {
                                      return values?.systems?.[systemIndex]?.[dataKey]?.items?.[
                                        index
                                      ]?.serialNumbers.map((serial: string, serialIndex: number) => {
                                        return (
                                          <Box padding="0 0 sm 0" key={serialIndex}>
                                            <Field
                                              placeholder={
                                                values.systems[systemIndex][dataKey]?.items[index]?.serialNumbers
                                                  .length === 1
                                                  ? 'Serial Number'
                                                  : `Serial Number ${serialIndex + 1}`
                                              }
                                              name={`systems.${systemIndex}[${dataKey}].items.${index}.serialNumbers.${serialIndex}`}
                                              id={`systems.${systemIndex}[${dataKey}].items.${index}.serialNumbers.${serialIndex}`}
                                              component={FormikTextInput}
                                              width="100"
                                              isDisabled={userOnlyHasReaderPermission}
                                              onChange={(event: SimulatedEventPayloadType) => {
                                                const serialNumbers = [
                                                  ...(values.systems[systemIndex][dataKey]?.items[index]
                                                    ?.serialNumbers || []),
                                                ];
                                                serialNumbers[serialIndex] = event.target.value.trim();
                                                setFieldValue(
                                                  `systems.${systemIndex}[${dataKey}].items.${index}.serialNumbers`,
                                                  serialNumbers,
                                                );
                                              }}
                                            />
                                          </Box>
                                        );
                                      });
                                    }}
                                  />
                                </>
                              ) : (
                                <Box width={{ base: '50', tablet: '20' }}></Box>
                              )}
                            </Box>
                          )}
                          <Field
                            label="Quantity"
                            type="number"
                            name={`systems.${systemIndex}[${dataKey}].items.${index}.quantity`}
                            id={`systems.${systemIndex}[${dataKey}].items.${index}.quantity`}
                            component={FormikTextInput}
                            maxWidth="lg"
                            width="100"
                            min={1}
                            isDisabled={userOnlyHasReaderPermission || isInstallPackage}
                            onChange={(event: SimulatedEventPayloadType) => {
                              let value = event.target.value.trim() === '' ? 1 : parseInt(event.target.value, 10);
                              if (isNaN(value) || value < 1) {
                                value = 1;
                              }
                              setFieldValue(`systems.${systemIndex}[${dataKey}].items.${index}.quantity`, value);
                            }}
                            onBlur={(event: SimulatedEventPayloadType) => {
                              if (event.target.value.trim() === '') {
                                setFieldValue(`systems.${systemIndex}[${dataKey}].items.${index}.quantity`, 1);
                              }
                            }}
                          />
                          <Box padding="lg 0 0 0">
                            <Button
                              as="button"
                              variant="tertiary"
                              tone="danger"
                              isDisabled={userOnlyHasReaderPermission}
                              onClick={() => arrayHelpers.remove(index)}
                              style={{ padding: '0' }}
                            >
                              Delete
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  );
                },
              ) ?? null}
              <Box gap="xl" alignSelf="flex-end">
                <Field
                  label="Total Cost"
                  name={`systems.${systemIndex}[${dataKey}].totalCost`}
                  id={`systems.${systemIndex}[${dataKey}].totalCost`}
                  component={FormikTextInput}
                  prefix="$"
                  width="xl"
                  isDisabled={userOnlyHasReaderPermission || isInstallPackage}
                  onFocus={(event: SimulatedEventPayloadType) => {
                    if (event.target.value === '0') {
                      setFieldValue(`systems.${systemIndex}[${dataKey}].totalCost`, '');
                    }
                  }}
                  onBlur={(event: SimulatedEventPayloadType) => {
                    const value = parseFloat(event.target.value);
                    setFieldValue(`systems.${systemIndex}[${dataKey}].totalCost`, isNaN(value) ? 0 : value);
                  }}
                  onChange={(event: SimulatedEventPayloadType) => {
                    const value = event.target.value;
                    if (value.endsWith('.')) {
                      setFieldValue(`systems.${systemIndex}[${dataKey}].totalCost`, value);
                    } else {
                      const numericValue = Number(value);
                      if (!isNaN(numericValue)) {
                        setFieldValue(`systems.${systemIndex}[${dataKey}].totalCost`, value);
                      }
                    }
                  }}
                />
              </Box>
              {isInstallPackage && (
                <FlagBox
                  baseCategory="equipment"
                  dataKey={`Installed ${label} Issue ${systemIndex + 1}`}
                  type={
                    dataKey === 'accessories'
                      ? RequirementFlagSettingsType.accessoriesHVAC
                      : RequirementFlagSettingsType.equipmentHVAC
                  }
                />
              )}
            </Box>
          );
        }}
      />
    </FormSection>
  );
};

export default EquipmentAndAccessoriesSection;
