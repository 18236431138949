import { useGetApprovedVendorsQuery } from '@/services/approvedVendors';
import { isNumber } from 'lodash';
import { AccountDocument, ApprovedVendorSettings, ProgramType, SystemDesign } from 'types';

export const setPvSystemInitialValues = (
  account: AccountDocument<ProgramType.solar | ProgramType.doePr>,
): SystemDesign => {
  const { systemDesign } = account;
  const { data: approvedVendorList } = useGetApprovedVendorsQuery({
    programType: account.programType || ProgramType.solar,
  });
  const { mounting: avlMountingHardwareOptions } =
    approvedVendorList ?? ({ mounting: [] } as unknown as ApprovedVendorSettings);
  let mountingHardwareWithAddedVals: any = [];

  if (systemDesign) {
    mountingHardwareWithAddedVals = systemDesign?.mountingHardware?.map((hardware) => {
      return {
        ...hardware,
        mountingHardwareModels: hardware.mountingHardwareModels?.map((model) => {
          const isAvailableInCurrentAvl = avlMountingHardwareOptions.some(
            (item: any) => item.value === model.value && item.category === hardware.type,
          );
          return {
            ...model,
            value: `${model.manufacturer}${model.sku}`,
            label: isAvailableInCurrentAvl
              ? `${model.manufacturer} - ${model.sku}`
              : `${model.manufacturer} - ${model.sku}*`,
          };
        }),
      };
    });
  }

  const initialValues = {
    systemSizeKw: systemDesign?.systemSizeKw ?? '',
    systemFirstYearProductionKwh: systemDesign?.systemFirstYearProductionKwh ?? '',
    totalPanelCount: systemDesign?.totalPanelCount || 0,
    panelManufacturer: systemDesign?.panelManufacturer ?? '',
    panelModel: systemDesign?.panelModel ?? '',
    inverterModels: systemDesign?.inverters?.length
      ? systemDesign.inverters.map((inverter) => {
          return { model: inverter.model, count: inverter.count };
        })
      : [{}],
    inverterManufacturer: systemDesign?.inverters?.[0]?.manufacturer ?? '',
    mountingType: systemDesign?.mountingType ?? '',
    mountingManufacturer: systemDesign?.mountingManufacturer ?? '',
    mountingHardware: mountingHardwareWithAddedVals ?? [],
    newMountingHardwareType: '',
    arrays: systemDesign?.arrays
      ? systemDesign.arrays.map((array) => ({
          ...array,
          monthlySolarAccess: array.monthlySolarAccess?.map((item) => item.accessPercent),
          inverter: isNumber(array.inverter) ? array.inverter + 1 : array.inverter,
        }))
      : [],
    firstYearMonthlyProductionKwh:
      systemDesign?.firstYearMonthlyProductionKwh?.map((item: any) => item.productionKwh as number) ?? [],
  };
  return initialValues as unknown as SystemDesign;
};
