import { NotesDrawer } from '../NotesDrawer/NotesDrawer';
import { toast, useOpenClose, Alert, DateInput, FormikTextInput } from '@palmetto/palmetto-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router';
import {
  QueueStatus,
  QueueType,
  DeepPartial,
  InstallHVACPackage as IInstallHVACPackage,
  RequirementFlagSettingsType,
  HVACSystem,
  HVACEquipmentType,
  HVACAccessoryType,
} from 'types';
import { Field, Formik, FormikValues } from 'formik';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { ReviewPackageActions } from '../../types/ReviewPackage';
import { useGetAccountQuery } from '../../services/accounts';
import { MainContainer } from '../MainContainer';
import { QueueFlagsProvider } from '../QueueFlags/QueueFlagsContext';
import { useGetQueueByAccountIdAndTypeQuery } from '../../services/queues';
import { PreventSubmissionModal } from '../ProductionValueCheck/PreventSubmissionModal';
import { QueueBanner } from '../MilestonePackagePartials/QueueBanner';
import { useAuth } from '../auth/authProvider';
import { isPalmettoFinanceUser } from '../auth/RequirePalmettoFinanceUser';
import FormSection from '../Forms/FormSection';
import { SkeletonBox } from '../SkeletonBox';
import { Footer } from '../MilestonePackagePartials/Footer';
import { isErrorWithData } from '../../services/helpers';
import { convertToISODateTimeString, DATE_FORMAT } from '../../helpers/dates';
import { HVACAttestationModal } from '../ProductionValueCheck/HvacAttestationModal';
import {
  useSaveInstallHVACPackageMutation,
  useSubmitInstallHVACPackageMutation,
} from '@/services/installHVACPackage';
import { SystemPhotos } from '../MilestonePackagePartials/hvac/SystemPhotos';
import { AttestationSection } from '../MilestonePackagePartials/hvac/AttestationSection';
import { FlagBox } from '../QueueFlags/FlagBox';
import { EnhancedFormikForm } from '../EnhancedFormikForm';
import { SystemSection } from '../SystemDesign/hvac/SystemSection';
import { InstallationDocuments } from '../MilestonePackagePartials/hvac/InstallationDocuments';
import { useGetClassificationsQuery } from '@/services/productCatalog';

interface InstallPackageProps {
  canSubmitInstallPackage: boolean;
}

function isEquipmentSerialNumberRequired(categories: any[], productType: HVACEquipmentType | HVACAccessoryType) {
  if (productType === HVACEquipmentType.other || productType === HVACAccessoryType.other) {
    return false;
  }

  const category = categories.find((obj) => {
    const productTypeAttr = obj.defaultAttributes.find(
      (attr: any) => attr.key === 'lightreach-product-type' && attr.value === productType,
    );
    return productTypeAttr;
  });

  if (category) {
    const serialAttr = category.defaultAttributes.find(
      (attr: any) => attr.key === 'lightreach-serial-number-required',
    );
    return serialAttr ? (serialAttr.value === 'true' ? true : false) : false;
  }

  return false;
}

const InstallHVACPackageSchema = yup.object().shape({
  systemInstallDate: yup.string().required('System Install Date is a required field.'),
  incentiveAttestationCheckbox: yup.boolean().oneOf([true], 'Incentive Attestation is a required field.'),
  permitAttestationCheckbox: yup.boolean().oneOf([true], 'Permit Attestation is a required field.'),
});

const incentiveAttestLabel =
  'I attest that the customer has been informed of all potential incentive eligibility, and that their system is not eligible for tax credit';

const permitAttestLabel =
  'I attest that I have or will apply for any and all necessary permits with the local authority having jurisdiction';

export const formatInstallHVACPackagesData = (values: FormikValues): DeepPartial<IInstallHVACPackage> => {
  return {
    systemDesign: {
      systems: values.systems.map((system: HVACSystem) => ({
        systemCategory: system.systemCategory,
        conditionedArea: Number(system.conditionedArea),
        name: system.name,
        ahriNumber: system.ahriNumber,
        equipment: {
          totalCost: system.equipment.totalCost,
          items: system.equipment.items.map((item) => ({
            type: item.type,
            name: item.name,
            manufacturer: item.manufacturer,
            model: item.model,
            quantity: item.quantity,
            productId: item.productId,
            serialNumbers: item.serialNumbers,
          })),
        },
        accessories: system?.accessories
          ? {
              totalCost: system.accessories.totalCost,
              items: (system.accessories?.items || []).map((item) => ({
                type: item.type,
                name: item.name,
                manufacturer: item.manufacturer,
                model: item.model,
                quantity: item.quantity,
                productId: item.productId,
                serialNumbers: item.serialNumbers,
              })),
            }
          : undefined,
        sitePrep: system?.sitePrep
          ? {
              totalCost: system.sitePrep.totalCost,
              items: (system.sitePrep?.items || []).map((item) => ({
                name: item.name,
              })),
            }
          : undefined,
        discounts: system?.discounts
          ? {
              totalCost: system.discounts.totalCost,
              items: (system.discounts?.items || []).map((item) => ({
                name: item.name,
              })),
            }
          : undefined,
      })),
    },
    systemInstallDate: values.systemInstallDate
      ? { palmetto: convertToISODateTimeString(values.systemInstallDate) }
      : undefined,
    incentiveAttestation: values.incentiveAttestationCheckbox
      ? { attestmentText: Boolean(values.incentiveAttestationCheckbox) ? incentiveAttestLabel : '' }
      : undefined,
    permitAttestation: values.permitAttestationCheckbox
      ? { attestmentText: Boolean(values.permitAttestationCheckbox) ? permitAttestLabel : '' }
      : undefined,
  };
};

export const InstallHVACPackage = ({ canSubmitInstallPackage }: InstallPackageProps) => {
  const navigate = useNavigate();
  const { claims } = useAuth();
  const isFinCoUser = isPalmettoFinanceUser(claims);

  const { id = '', action } = useParams<{ id: string; action: ReviewPackageActions }>();
  const { data: queue, isLoading: isQueueLoading } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.installPackage,
  });
  const { data: account, isLoading: isAccountLoading } = useGetAccountQuery(id);

  const systemInstallDate = (values: any): Date | null => {
    return values.systemInstallDate ? DateTime.fromFormat(values.systemInstallDate, DATE_FORMAT).toJSDate() : null;
  };

  const handleDateChange = useCallback((date: Date | [Date, Date] | null, setFieldValue: any) => {
    if (!date || !(date instanceof Date)) return;
    setFieldValue('systemInstallDate', DateTime.fromJSDate(date).toUTC().toFormat(DATE_FORMAT));
  }, []);

  const minDate = DateTime.fromISO('2022-01-01').toJSDate();
  const maxDate = DateTime.local().endOf('day').toJSDate();
  const [triggerSubmit] = useSubmitInstallHVACPackageMutation();
  const [triggerSave] = useSaveInstallHVACPackageMutation();
  const { data: classifications } = useGetClassificationsQuery({ path: 'hvac' });
  const { isOpen: isPreventSubmissionOpen, handleClose: setPreventSubmissionClose } = useOpenClose();
  const {
    isOpen: isAttestationOpen,
    handleOpen: setAttestationSubmissionOpen,
    handleClose: setAttestationSubmissionClose,
  } = useOpenClose();
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [userAttestation, setUserAttestation] = useState(false);
  const isLoading = isAccountLoading || isQueueLoading;

  useEffect(() => {
    if (!action || !ReviewPackageActions[action]) {
      navigate(`/accounts/${id}`, { replace: true });
    }
    if (action === ReviewPackageActions.review && !isFinCoUser) {
      if (queue) {
        navigate(`/accounts/${id}/installation-package/edit`, { replace: true });
      } else {
        navigate(`/accounts/${id}/installation-package/create`, { replace: true });
      }
    }
  }, [id, navigate, action, isFinCoUser, queue]);

  const accountInitialValues = useMemo(() => {
    if (!account || !classifications || !classifications.length) {
      return {};
    }

    const systems = account.systemDesign.systems.reduce((acc: HVACSystem[], system: HVACSystem) => {
      const equipment = system.equipment.items.map((item) => {
        const isSerialNumberRequired = isEquipmentSerialNumberRequired(classifications, item.type);
        const serialNumbers =
          item.serialNumbers && item.serialNumbers.length
            ? item.serialNumbers
            : item.type !== HVACEquipmentType.other
              ? Array(item.quantity).fill('')
              : [''];
        return {
          ...item,
          isSerialNumberRequired,
          serialNumbers,
        };
      });
      const accessories = system.accessories?.items.map((item) => {
        const isSerialNumberRequired = isEquipmentSerialNumberRequired(classifications, item.type);
        const serialNumbers =
          item.serialNumbers && item.serialNumbers.length
            ? item.serialNumbers
            : item.type !== HVACAccessoryType.other
              ? Array(item.quantity).fill('')
              : [];
        return {
          ...item,
          isSerialNumberRequired,
          serialNumbers,
        };
      });
      const updatedSystem = {
        ...system,
        equipment: {
          ...system.equipment,
          items: equipment,
        },
        accessories: accessories
          ? {
              ...system.accessories,
              items: accessories,
            }
          : [],
      } as HVACSystem;
      acc.push(updatedSystem);
      return acc;
    }, [] as HVACSystem[]);
    return {
      systemInstallDate: account?.systemInstallDate?.palmetto
        ? DateTime.fromISO(account.systemInstallDate.palmetto).toUTC().toFormat(DATE_FORMAT)
        : '',
      systems,
    };
  }, [account, classifications]);

  const canSubmitReview = action === ReviewPackageActions.review && queue;
  const initialValues = {
    ...accountInitialValues,
  };

  const handleSubmit = async (values: FormikValues, { setSubmitting }: any) => {
    const formValues = formatInstallHVACPackagesData(values);

    if (!userAttestation) {
      setAttestationSubmissionOpen();
      return;
    }
    try {
      setSubmitting(true);
      await triggerSubmit({ accountId: id, installPackage: formValues as IInstallHVACPackage }).unwrap();
      toast.success('Install Package Submitted');
      navigate(`/accounts/${id}`);
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error submitting install package');
    } finally {
      setSubmitting(false);
      setUserAttestation(false);
      setAttestationSubmissionClose();
    }
  };

  const handleSave = async ({ accountId, data }: { accountId: string; data: FormikValues }) => {
    try {
      const formValues = formatInstallHVACPackagesData(data);
      await triggerSave({ accountId, installPackage: formValues }).unwrap();
      toast.success('Install Package Saved');
    } catch (e) {
      console.error(e);
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(errorMessage);
      } else {
        console.error(e);
        toast.error('Error saving Install Package');
      }
    }
  };

  const queueStatusIsApprovedOrConditionallyApprovedAndIsNotFinCoUser: boolean =
    !isFinCoUser &&
    queue &&
    (queue.status === QueueStatus.conditionallyApproved || queue.status === QueueStatus.approved);

  if (isLoading) {
    return (
      <MainContainer>
        <FormSection title="Loading...">
          <SkeletonBox height="25px" width="100" />
        </FormSection>
      </MainContainer>
    );
  }

  const attestationModalText =
    'By submitting this package, I attest that all equipment listed is accurate to the equipment installed for the customer, and that all equipment is working to manufacturer specifications.';

  return (
    <>
      <QueueFlagsProvider queueId={queue?.id}>
        <NotesDrawer isOpen={isNotesOpen} setIsOpen={setIsNotesOpen} queueType={QueueType.installPackage} />
        <Helmet>
          <title>{`${account?.primaryApplicantName} Install Package`}</title>
        </Helmet>
        <MainContainer>
          {queue && (queue.status === QueueStatus.rejected || isFinCoUser) ? (
            <QueueBanner
              setIsNotesOpen={setIsNotesOpen}
              queueType={QueueType.installPackage}
              isFinCoUser={isFinCoUser}
            />
          ) : null}
          {action === ReviewPackageActions.review && !canSubmitReview ? (
            <Alert
              variant="warning"
              title="Install Package Not Submitted"
              message="This install package is not ready to be reviewed yet. It has not been submitted."
              hasIcon
            />
          ) : null}
          <Formik
            initialValues={initialValues}
            validationSchema={InstallHVACPackageSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ setFieldValue, values, setSubmitting, isSubmitting, touched, errors }) => (
              <>
                <EnhancedFormikForm>
                  <FormSection title="System Install Date">
                    <FlagBox
                      dataKey="System Install Date"
                      baseCategory="systemInstallDate"
                      type={RequirementFlagSettingsType.systemInstallDateHVAC}
                      displayWithNoQueue
                    >
                      <Field
                        label=""
                        name="systemInstallDate"
                        id="systemInstallDate"
                        dateFormat={DATE_FORMAT}
                        width="2xl"
                        value={values.systemInstallDate}
                        component={DateInput}
                        datePickerProps={{
                          maxDate,
                          minDate,
                          onChange: (date: Date | [Date, Date] | null) => handleDateChange(date, setFieldValue),
                          openToDate: systemInstallDate(values) ?? undefined,
                        }}
                        textInputProps={{
                          onClear: () => setFieldValue('systemInstallDate', ''),
                        }}
                        isDisabled={isSubmitting}
                        error={touched.systemInstallDate && (errors.systemInstallDate as string)}
                      />
                    </FlagBox>
                  </FormSection>
                  <InstallationDocuments />
                  <SystemPhotos />
                  <SystemSection isInstallPackage />
                  {action !== ReviewPackageActions.review && (
                    <>
                      <AttestationSection
                        incentiveAttestLabel={incentiveAttestLabel}
                        permitAttestLabel={permitAttestLabel}
                        setFieldValue={setFieldValue}
                        values={values}
                        isSubmitting={isSubmitting}
                        touched={touched}
                        errors={errors}
                      />
                    </>
                  )}
                  <Footer
                    canSubmitPackage={canSubmitInstallPackage}
                    isLoading={isLoading}
                    isFirstSubmission={!queue}
                    canSubmitReview={canSubmitReview}
                    onSave={handleSave}
                    disabled={queueStatusIsApprovedOrConditionallyApprovedAndIsNotFinCoUser}
                  />
                </EnhancedFormikForm>
                <HVACAttestationModal
                  title="Install Submission Confirmation"
                  label={attestationModalText}
                  values={values}
                  handleSubmit={handleSubmit}
                  isOpen={isAttestationOpen}
                  onDismiss={setAttestationSubmissionClose}
                  onAttestationConfirm={setUserAttestation}
                  isLoading={isSubmitting}
                  setSubmitting={setSubmitting}
                />
              </>
            )}
          </Formik>
          <PreventSubmissionModal isOpen={isPreventSubmissionOpen} onDismiss={setPreventSubmissionClose} />
        </MainContainer>
      </QueueFlagsProvider>
    </>
  );
};
