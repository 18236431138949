import FormSection from '../Forms/FormSection';
import { AddersDescription } from '../InstallMilestonePackage/InstallationFormSectionDescriptions';
import { AddersList } from '../Adders/AddersList';
import { addersTitle } from '../../types/Quotes';
import { Flag, ProgramType, AdderType } from 'types';
import { Box } from '@palmetto/palmetto-components';

interface AddersProps {
  adders: AdderType[];
  flags?: Flag[];
  isLoading: boolean;
  programType: ProgramType;
}

export const Adders = ({ adders, flags = [], isLoading, programType }: AddersProps) => {
  const hasAdders = adders?.length > 0;

  const filteredUnresolvedFlags = flags.filter(
    (flag) => ['Backup Battery', 'Arbitrage Battery', 'Electrical Upgrade'].includes(flag.dataKey) && !flag.resolved,
  );

  const unresolvedAdders = filteredUnresolvedFlags
    .map((flag) => {
      const key = (Object.keys(addersTitle) as (keyof typeof addersTitle)[]).find(
        (value) => addersTitle[value] === flag.dataKey,
      );
      return key as AdderType | undefined;
    })
    .filter((adderType): adderType is AdderType => adderType !== undefined && !adders?.includes(adderType));

  return hasAdders || unresolvedAdders.length > 0 ? (
    <FormSection description={AddersDescription} title="Adders">
        <AddersList selectedAdders={adders} isLoading={isLoading} enableFlags programType={programType} />
      {unresolvedAdders.length > 0 && (
        <Box margin="lg 0 0 0">
          <AddersList
            selectedAdders={unresolvedAdders}
            isLoading={isLoading}
            enableFlags
            oldQuoteFlag
            programType={programType}
          />
        </Box>
      )}
    </FormSection>
  ) : null;
};
