import { useNavigate } from 'react-router-dom';
import { Box, Button, Icon } from '@palmetto/palmetto-components';
import { MilestoneType } from '../../types/Milestone';
import { Flag, PackageRejectionInfo } from 'types';
import DateTimeDisplay from '../DateTime';

const milestoneLinkMap: {
  [Key in MilestoneType]?: string;
} = {
  [MilestoneType.noticeToProceed]: 'ntp-package/create',
  [MilestoneType.install]: 'installation-package/create',
  [MilestoneType.activation]: 'activation-package/create',
  [MilestoneType.postActivation]: 'post-activation-package/create',
};
interface RejectionBannerProps {
  milestone: { type: MilestoneType, name?: string };
  className?: string;
  rejectionInfo?: PackageRejectionInfo;
  customViewPathLink?: string;
  flaggedItems?: Flag[];
  completedAt: Date;
}

const getActiveFlags = (flaggedItems?: Flag[]) => {
  if (!flaggedItems) {
    return [];
  }
  return flaggedItems.filter((flag) => !flag.resolved);
}

const RejectionBannerV2 = ({
  milestone,
  className,
  customViewPathLink,
  flaggedItems,
  completedAt
}: RejectionBannerProps) => {
  const navigate = useNavigate();
  const handleViewNavigate = () => {
    if (customViewPathLink) {
      return navigate(customViewPathLink);
    }
    return navigate(`${milestoneLinkMap[milestone.type]}`);
  };

  const activeFlags = getActiveFlags(flaggedItems);

  return (
    <Box
      borderWidth="xs"
      borderColor="danger"
      radius="md"
      direction={{ base: 'column', tablet: 'row' }}
      alignItems="stretch"
      fontSize="sm"
      overflow="hidden"
      background="danger"
      shadow="xs"
      height="auto"
      className={className}
    >
      <Box
        padding="lg"
        direction="row"
      >
        <Icon name="package-rejected" size="2xl" color="danger" />
      </Box>
      <Box flex="auto" childGap="2xs" padding="lg" fontSize="md" color='danger' >
        <Box fontWeight="bold">{milestone.name} Rejected</Box>
        <Box>Please review {activeFlags.length} flags to avoid project and payment delays.</Box>
        <Box fontSize='xs' direction={{ base: 'column', tablet: 'row' }} childGap="2xs" color="body-primary">
          <DateTimeDisplay fontSize='xs' value={completedAt.toString()} dateFormat={'DD'} excludeTime /> <DateTimeDisplay fontSize='xs' value={completedAt.toString()} dateFormat="DD" showDuration inline /> ago
        </Box>
      </Box>
      <Box padding={{ base: '0 lg lg lg', tablet: 'lg' }} justifyContent="center">
        <Button
          as="a"
          variant="primary"
          tone="danger"
          size={{ base: 'sm', desktop: 'md' }}
          aria-label="view rejected milestone"
          navigate={handleViewNavigate}
        >
          Resolve Flags
        </Button>
      </Box>
    </Box>
  );
};

export default RejectionBannerV2;
