import { BatchReviewStatus } from 'types';
import { BatchesList } from '../BatchesList';
import { useGetBatchesByReviewStatusQuery } from '@/services/payouts';
import { BatchComponent } from '@/types/Payouts';

export const Queued = () => {
  const { data: batches, isLoading: isLoadingBatches } = useGetBatchesByReviewStatusQuery({
    batchReviewStatus: BatchReviewStatus.rejected,
  });

  return (
    <>{!isLoadingBatches && batches && <BatchesList batches={batches} originComponent={BatchComponent.queued} />}</>
  );
};
