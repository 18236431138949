import { Box, FormikSelectInputNative } from '@palmetto/palmetto-components';
import { Field } from 'formik';
import { useMemo } from 'react';
import { ConstructionAdderType, type ProgramType } from 'types';
import { useGetConstructionAddersQuery } from '../../../../services/pricing';
import { sortByLabel } from '../util';
import { useParams } from 'react-router-dom';

const removeAdderOption = { label: 'x--Remove Adder--x', value: 'remove' };

export const BulkEditAddersFormSection = () => {
  const { programType } = useParams<{ programType: string }>();

  const { data: arbitrageBatteryAdders = [] } = useGetConstructionAddersQuery({
    type: ConstructionAdderType.arbitrageBattery,
    programType: programType as ProgramType,
  });
  const { data: arbitrageBatteryCostAdders = [] } = useGetConstructionAddersQuery({
    type: ConstructionAdderType.arbitrageBatteryCost,
    programType: programType as ProgramType,
  });
  const { data: arbitrageBatteryPriceCaps = [] } = useGetConstructionAddersQuery({
    type: ConstructionAdderType.arbitrageBatteryPriceCap,
    programType: programType as ProgramType,
  });
  const { data: electricalUpgradeAdders = [] } = useGetConstructionAddersQuery({
    type: ConstructionAdderType.electricalUpgrade,
    programType: programType as ProgramType,
  });
  const { data: backupBatteryAdders = [] } = useGetConstructionAddersQuery({
    type: ConstructionAdderType.backupBattery,
    programType: programType as ProgramType,
  });
  const { data: backupBatteryKwhAdders = [] } = useGetConstructionAddersQuery({
    type: ConstructionAdderType.backupBatteryKwh,
    programType: programType as ProgramType,
  });
  const { data: backupBatteryPriceCaps = [] } = useGetConstructionAddersQuery({
    type: ConstructionAdderType.backupBatteryPriceCap,
    programType: programType as ProgramType,
  });

  const arbitrageBatteryOptions = useMemo(
    () => [
      removeAdderOption,
      ...arbitrageBatteryAdders.map((aba: any) => ({ label: aba.name, value: aba.id })).sort(sortByLabel),
    ],
    [arbitrageBatteryAdders],
  );
  const arbitrageBatteryCostOptions = useMemo(
    () => [
      removeAdderOption,
      ...arbitrageBatteryCostAdders.map((abc: any) => ({ label: abc.name, value: abc.id })).sort(sortByLabel),
    ],
    [arbitrageBatteryCostAdders],
  );
  const arbitrageBatteryPriceCapsOptions = useMemo(
    () => [
      removeAdderOption,
      ...arbitrageBatteryPriceCaps.map((priceCap: any) => ({ label: priceCap.name, value: priceCap.id })),
    ],
    [arbitrageBatteryPriceCaps],
  );
  const electricalUpgradeOptions = useMemo(
    () => [
      removeAdderOption,
      ...electricalUpgradeAdders.map((ela: any) => ({ label: ela.name, value: ela.id })).sort(sortByLabel),
    ],
    [electricalUpgradeAdders],
  );
  const backupBatteryOptions = useMemo(
    () => [
      removeAdderOption,
      ...backupBatteryAdders.map((bub: any) => ({ label: bub.name, value: bub.id })).sort(sortByLabel),
    ],
    [backupBatteryAdders],
  );
  const backupBatteryKwhOptions = useMemo(
    () => [
      removeAdderOption,
      ...backupBatteryKwhAdders.map((bub: any) => ({ label: bub.name, value: bub.id })).sort(sortByLabel),
    ],
    [backupBatteryKwhAdders],
  );
  const backupBatteryPriceCapOptions = useMemo(
    () => [
      removeAdderOption,
      ...backupBatteryPriceCaps.map((priceCap: any) => ({ label: priceCap.name, value: priceCap.id })),
    ],
    [backupBatteryPriceCaps],
  );

  return (
    <Box childGap="lg">
      <Field
        name="electricalUpgrade"
        id="electricalUpgrade"
        label="Electrical Upgrade"
        component={FormikSelectInputNative}
        options={electricalUpgradeOptions}
      />
      <Field
        name="arbitrageBattery"
        id="arbitrageBattery"
        label="Arbitrage Battery"
        component={FormikSelectInputNative}
        options={arbitrageBatteryOptions}
      />
      <Field
        name="arbitrageBatteryCost"
        id="arbitrageBatteryCost"
        label="Arbitrage Battery (Cost)"
        component={FormikSelectInputNative}
        options={arbitrageBatteryCostOptions}
      />
      <Field
        name="arbitrageBatteryPriceCap"
        id="arbitrageBatteryPriceCap"
        label="Arbitrage Battery Price Caps"
        component={FormikSelectInputNative}
        options={arbitrageBatteryPriceCapsOptions}
      />
      <Field
        name="backupBattery"
        id="backupBattery"
        label="Backup Battery (Cost)"
        component={FormikSelectInputNative}
        options={backupBatteryOptions}
      />
      <Field
        name="backupBatteryKwh"
        id="backupBatteryKwh"
        label="Backup Battery ($/kWh)"
        component={FormikSelectInputNative}
        options={backupBatteryKwhOptions}
      />
      <Field
        name="backupBatteryPriceCap"
        id="backupBatteryPriceCap"
        label="Backup Battery Price Caps"
        component={FormikSelectInputNative}
        options={backupBatteryPriceCapOptions}
      />
    </Box>
  );
};
