import { Box, FormikSelectInputNative, Button } from '@palmetto/palmetto-components';
import { Field, useFormikContext } from 'formik';
import {
  mapAvlManufacturerOptions,
  mapAvlModelNumberOptions,
  maybePrependCustomFreetextOption,
} from '../SystemDesign/AvlFormUtils';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { useGetApprovedVendorsQuery } from '@/services/approvedVendors';
import { ApprovedVendorSettings, ProgramType, AdderType } from 'types';

interface BatteryInputsProps {
  fieldName: string;
  isLoading: boolean;
  programType: ProgramType;
  index: number;
}

export const BatteryInputs = ({ fieldName, isLoading, programType, index }: BatteryInputsProps) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const { data: approvedVendorList, isLoading: isAppSettingsLoading } = useGetApprovedVendorsQuery(
    { programType },
    { skip: !programType },
  );
  const { storage } = approvedVendorList ?? ({ storage: [] } as unknown as ApprovedVendorSettings);
  const filteredStorage = storage.filter((item) => {
    const isBackup = fieldName === AdderType.backupBattery;
    return isBackup ? item.backup : item.arbitrage;
  });
  const battery = values[fieldName]?.[index];
  const storageManufacturerOptions = useMemo(() => {
    if (isAppSettingsLoading) {
      return [];
    }
    const options = mapAvlManufacturerOptions(filteredStorage);
    return maybePrependCustomFreetextOption(options, battery.manufacturer);
  }, [isAppSettingsLoading, filteredStorage, fieldName]);
  const storageModelOptions = useMemo(() => {
    if (isAppSettingsLoading) {
      return [];
    }
    const options = mapAvlModelNumberOptions(filteredStorage, battery.manufacturer);
    return maybePrependCustomFreetextOption(options, battery.model);
  }, [fieldName, isAppSettingsLoading, filteredStorage, values]);

  const batteryCountOptions = Array.from(Array(10)).map((_val, index) => ({ value: index + 1, label: index + 1 }));

  const setManufacturer = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(fieldName, [{
        type: fieldName,
        manufacturer: event.target.value,
        model: '',
        quantity: '',
      }]);
    },
    [fieldName, setFieldValue],
  );

  return values?.[fieldName] ? (
    <Box
      direction={{ base: 'column', tablet: 'row' }}
      alignItems="center"
      childGap={{ base: 'md', tablet: 'md' }}
      width="100"
      style={{ margin: '0 0 20px 0' }}
    >
      <Field
        label="Manufacturer"
        name={`${fieldName}[${index}].manufacturer`}
        id={`${fieldName}[${index}].manufacturer`}
        options={storageManufacturerOptions}
        component={FormikSelectInputNative}
        isDisabled={isLoading}
        onChange={setManufacturer}
        style={{
          visibility: index === 0 ? 'visible' : 'hidden',
        }}
      />
      {<Button
        variant="secondary"
        tone="neutral"
        onClick={() => {
          values[fieldName].splice(index, 1);
          setFieldValue(fieldName, values[fieldName]);
        }}
        iconPrefix="remove"
        size="sm"
        style={{
          padding: "10px",
          margin: "25px 15px 0 0",
          visibility: index === 0 ? 'hidden' : 'visible',
        }}
      />}
      <Field
        label="Model"
        name={`${fieldName}[${index}].model`}
        id={`${fieldName}[${index}].model`}
        options={storageModelOptions}
        component={FormikSelectInputNative}
        isDisabled={isLoading}
        style={{
        }}
      />
      <Field
        label="Quantity"
        name={`${fieldName}[${index}].quantity`}
        id={`${fieldName}[${index}].quantity`}
        options={batteryCountOptions}
        component={FormikSelectInputNative}
        isDisabled={isLoading}
        style={{
          width: '225px',
        }}
      />
    </Box>
  ) : null;
};
