import { Box, SimulatedEventPayloadType, TextInput } from '@palmetto/palmetto-components';
import { CommonFormSection } from '../ProductionValueCheck/Form';
import { useGetQuotesQuery } from '@/services/quotes';
import { useParams } from 'react-router-dom';
import { useGetAccountQuery } from '@/services/accounts';
import { QuoteStatus } from '@/types/Quotes';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { FlagBox } from '../QueueFlags/FlagBox';
import { RequirementFlagSettingsType } from 'types';

interface HVACWorkOrderInfoInterface {
  isSubmitting: boolean;
  isLoading: boolean;
  userOnlyHasReadPermissions: boolean;
}

export const HVACWorkOrderInfo = ({
  isSubmitting,
  isLoading,
  userOnlyHasReadPermissions,
}: HVACWorkOrderInfoInterface) => {
  const { id } = useParams<{ id: any }>();
  const { data: account }: any = useGetAccountQuery(id);
  const { data: quotes } = useGetQuotesQuery(
    { id, programType: account?.programType },
    { skip: !account?.programType },
  );

  const [percentageDifference, setPercentageDifference] = useState<number>(0);
  const [costDiscrepancy, setCostDiscrepancy] = useState<boolean>(false);

  const { setFieldValue, values } = useFormikContext<any>();

  const activeQuote = quotes?.find((quote: any) => quote.status === QuoteStatus.active);
  const quoteTotalSystemCost = activeQuote?.totalSystemCost || 0;

  const calculatePercentageDifference = (quoteCost: number, workOrderCost: number) => {
    if (quoteCost === 0) return 0;
    const difference = workOrderCost - quoteCost;
    return (difference / quoteCost) * 100;
  };

  const calculateDecimalPlaces = (percentageDiff: number) => {
    if (percentageDiff === 0) return 2;
    if (Math.abs(percentageDiff) < 0.01) return 3;
    return 2;
  };

  useEffect(() => {
    if (values.workOrderTotalPrice > 0) {
      const percentageDiff = calculatePercentageDifference(quoteTotalSystemCost, values.workOrderTotalPrice);
      setCostDiscrepancy(true);
      setPercentageDifference(percentageDiff);
    } else {
      setCostDiscrepancy(false);
    }
  }, [activeQuote, values.workOrderTotalPrice]);

  const handleBlur = (event: SimulatedEventPayloadType) => {
    const value = parseFloat(event.target.value);
    setFieldValue(`workOrderTotalPrice`, isNaN(value) ? 0 : value);

    if (value > 0 && value !== quoteTotalSystemCost) {
      const percentageDiff = calculatePercentageDifference(quoteTotalSystemCost, value);
      setCostDiscrepancy(true);
      setPercentageDifference(percentageDiff);
    } else {
      setCostDiscrepancy(false);
    }
  };

  return (
    <Box width={'100'}>
      <CommonFormSection title="Quote Total Financed Amount">
        <Box fontWeight={'medium'} fontSize={'lg'}>
          {currencyFormatter.format(activeQuote?.totalSystemCost)}
        </Box>
      </CommonFormSection>
      <CommonFormSection title="Work Order Total Price">
        <Box direction="column" gap="lg" width="xl">
          <TextInput
            label=""
            name={`workOrderTotalPrice`}
            id={`workOrderTotalPrice`}
            value={values.workOrderTotalPrice}
            type="number"
            prefix="$"
            width="2xl"
            isDisabled={isSubmitting || isLoading || userOnlyHasReadPermissions}
            onFocus={(event: SimulatedEventPayloadType) => {
              if (event.target.value === '0') {
                setFieldValue(`workOrderTotalPrice`, '');
              }
            }}
            onBlur={handleBlur}
            onChange={(event: SimulatedEventPayloadType) => {
              const value = parseFloat(event.target.value);
              setFieldValue(`workOrderTotalPrice`, value);
            }}
          />
        </Box>
      </CommonFormSection>
      {costDiscrepancy && (
        <CommonFormSection title="Cost Difference">
          <Box fontWeight={'medium'} fontSize={'lg'}>
            {percentageDifference.toFixed(calculateDecimalPlaces(percentageDifference))}%
          </Box>
        </CommonFormSection>
      )}
      <CommonFormSection title=" ">
        <FlagBox dataKey="System Cost Discrepancy" type={RequirementFlagSettingsType.systemCostDescrepancyHVAC} />
      </CommonFormSection>
    </Box>
  );
};
