import { Badge, Box } from '@palmetto/palmetto-components';
import { PayoutEvent, PayoutEventMap, PayoutEventType, ProjectTransactionDocument } from 'types';
import { useMemo } from 'react';

interface TransactionStatusBadgeProps {
  transaction: ProjectTransactionDocument;
}

const flatLabelMap = Object.values(PayoutEventMap).flat();

export const TransactionEventBadge = ({ transaction }: TransactionStatusBadgeProps) => {
  const badge = useMemo(() => {
    if (transaction.eventType === PayoutEventType.materials) {
      const message =
        transaction.event === PayoutEvent.estimate
          ? 'Materials Estimate'
          : transaction.event === PayoutEvent.invoice
            ? 'Materials Invoice'
            : 'Unknown Event';
      return (
        <Badge
          variant={`${transaction.event === PayoutEvent.invoice ? 'primary' : 'secondary'}`}
          message={message}
        />
      );
    } else {
      const labelItem = flatLabelMap.find((item) => item.event === transaction.event);
      return labelItem?.name ? (
        <Badge variant={labelItem?.badgeVariant || 'default'} message={labelItem?.name} />
      ) : null;
    }
  }, []);

  return badge;
};
