import { useFlags } from 'launchdarkly-react-client-sdk';
import { AccountDocument, ProgramType, monthlyPaymentCapProgramTypes } from 'types';

export const isMonthlyPaymentCapMet = (
  account: AccountDocument,
  escalationRate: number,
  systemPricingDetails: Array<{
    totalMonthlyPayment: number;
    monthlyPayment: number;
  }>,
): boolean => {
  const { monthlyPaymentCap: monthlyPaymentCapFlag = false } = useFlags();
  const { monthlyPaymentCap } = account;
  const isMonthlyPaymentCapApplicable =
    monthlyPaymentCap && monthlyPaymentCapProgramTypes.includes(account.programType || ProgramType.solar);

  if (monthlyPaymentCapFlag && isMonthlyPaymentCapApplicable) {
    const isNonZeroEscalationRate = typeof escalationRate !== 'undefined' ? escalationRate > 0 : 0;
    const cap = isNonZeroEscalationRate
      ? monthlyPaymentCap.greaterThanZeroEscalationRate
      : monthlyPaymentCap.zeroEscalationRate;

    const doesYear1MonthlyPaymentMeetMonthlyPaymentCap =
      (systemPricingDetails[0].totalMonthlyPayment || systemPricingDetails[0].monthlyPayment) <= cap;
    if (!doesYear1MonthlyPaymentMeetMonthlyPaymentCap) {
      return false;
    }
  }

  return true;
};
