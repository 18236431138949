import { FC, SyntheticEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  SelectInputNative,
  Popover,
  useBreakpoint,
  TextInput,
  TextInputSize,
  Icon,
} from '@palmetto/palmetto-components';
import classNames from 'classnames';
import { DataFilterType, DropdownOption, SearchableCheckboxFilterList } from './SearchableCheckboxFilterList';
import styles from './FilterButton.module.scss';
import { DateTime } from 'luxon';
import { TextBoxFilter } from './TextBoxFilter';
import { DateInputFilter } from './DateInputFilter';

export enum DataFilterOperation {
  Equal = 'Equal',
  NotEqual = 'NotEqual',
  InRange = 'InRange',
  LessThan = 'LessThan',
  GreaterThan = 'GreaterThan',
}

export interface AppliedDataFilter {
  id: string;
  selectedValues?: string[];
  operation?: DataFilterOperation;
}

export interface FilterChange {
  filterId: string;
  selectedValues?: string[];
  operation?: DataFilterOperation;
}

export interface DataFilterDisplay {
  id: string;
  label: string;
  description?: string;
  type: DataFilterType;
  displayValuePrefix?: string;
  displayValueSuffix?: string;
  operations: DataFilterOperation[];
  selectOptions?: DropdownOption[];
}

export const DataFilterOperationDisplay = {
  Equal: { label: 'is' },
  NotEqual: { label: 'is not' },
  InRange: { label: 'is between ' },
  LessThan: { label: 'is less than' },
  GreaterThan: { label: 'is greater than' },
} as const;

interface FilterButtonProps {
  className?: string;
  size?: TextInputSize;
  appliedFilter: AppliedDataFilter;
  dataFilter: DataFilterDisplay;
  onRemoveFilter: (filterId: string) => void;
  onApplyFilters: () => void;
  onFilterChange: (change: FilterChange) => void;
  startOpen?: boolean;
}

export function getLabel({
  isMissingInputValue,
  operation,
  displayValuePrefix,
  selectedValues,
  selectOptions,
  displayValueSuffix,
  type,
  appliedFilter,
}: {
  isMissingInputValue: boolean;
  operation?: DataFilterOperation;
  displayValuePrefix?: string;
  selectedValues?: string[];
  selectOptions?: DropdownOption[];
  displayValueSuffix?: string;
  type: DataFilterType;
  appliedFilter?: AppliedDataFilter;
}) {
  let labelSectionB = `${!isMissingInputValue && operation ? DataFilterOperationDisplay[operation].label : 'is'} ${
    isMissingInputValue
      ? 'missing value'
      : `${displayValuePrefix ?? ''}${
          selectedValues?.map((item) => selectOptions?.find((option) => option.value === item)?.label)?.join(', ') ||
          ''
        }${displayValueSuffix || ''}`
  }`;

  if (
    !isMissingInputValue &&
    operation &&
    selectOptions?.length === 0 &&
    selectedValues &&
    selectedValues?.length > 0
  ) {
    labelSectionB = `${DataFilterOperationDisplay[operation].label} ${selectedValues?.join(', ')}`;
  }

  if ([DataFilterType.DateRange].includes(type) && appliedFilter?.selectedValues?.length === 2) {
    labelSectionB = `${DataFilterOperationDisplay['InRange'].label} ${DateTime.fromJSDate(new Date(appliedFilter.selectedValues[0])).toFormat('MM/dd/yyyy')} - ${DateTime.fromJSDate(new Date(appliedFilter.selectedValues[1])).toFormat('MM/dd/yyyy')}`;
  }
  return labelSectionB;
}

export const FilterButton: FC<FilterButtonProps> = ({
  className,
  size = 'sm',
  appliedFilter,
  dataFilter,
  onRemoveFilter,
  onFilterChange,
  onApplyFilters,
  startOpen = false,
}) => {
  const { isPhone } = useBreakpoint();
  const [isPopoverOpen, setPopoverOpen] = useState(startOpen);
  const [removePopoverOpen, setRemovePopoverOpen] = useState(false);
  const minimumCharactersToStartSearch = 3;
  const minimumRecordsToShowSearchInput = 6;
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setPopoverOpen(startOpen);
  }, [startOpen]);

  const { id: appliedFilterId, operation, selectedValues } = appliedFilter;

  const {
    operations = [],
    label,
    selectOptions = [],
    displayValuePrefix,
    displayValueSuffix,
    type,
  } = dataFilter ?? {};

  const togglePopover = () => {
    setPopoverOpen(!isPopoverOpen);
  };

  const handleDone = (event?: SyntheticEvent | MouseEvent | KeyboardEvent) => {
    if ((event?.target as HTMLElement)?.className?.includes('react-datepicker')) {
      return;
    }
    onApplyFilters();
    setPopoverOpen(false);
  };

  const isMissingInputValue = !isPopoverOpen && !selectedValues?.length;

  const labelContent = (
    <>
      {label}{' '}
      <span className="font-weight-regular">
        {getLabel({
          isMissingInputValue,
          operation,
          displayValuePrefix,
          selectedValues,
          selectOptions,
          displayValueSuffix,
          type,
          appliedFilter,
        })}
      </span>
    </>
  );

  const operationOptions = operations?.map((op) => ({
    value: op,
    label: DataFilterOperationDisplay[op].label,
  }));

  const filteredOptions =
    searchTerm.length >= minimumCharactersToStartSearch
      ? selectOptions.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()))
      : selectOptions;

  return (
    <Box
      direction="row"
      position="relative"
      className={classNames(styles.appliedFilter, className)}
      margin={{ base: '0 sm sm 0', tablet: '0 2xs 2xs 0' }}
    >
      <Popover
        trapFocus
        hasArrow={false}
        offsetFromTarget={2}
        content={
          <>
            <Box
              padding="sm"
              borderColor="separator"
              borderWidth="0 0 xs 0"
              maxHeight={{ base: '3xl', tablet: '4xl' }}
              childGap="sm"
            >
              {[DataFilterType.Select, DataFilterType.Text].includes(type) && (
                <SelectInputNative
                  id="defaultSelect"
                  label="choose a condition"
                  hideLabel
                  options={operationOptions}
                  placeholder="choose..."
                  size={size}
                  value={operation || DataFilterOperation.Equal}
                  onChange={(event) => {
                    const selectedOperation = event.target.value as DataFilterOperation;
                    onFilterChange({
                      filterId: appliedFilterId,
                      selectedValues: selectedValues,
                      operation: selectedOperation,
                    });
                  }}
                />
              )}

              {[DataFilterType.DateRange].includes(type) && (
                <DateInputFilter
                  defStartDate={
                    appliedFilter?.selectedValues ? new Date(appliedFilter.selectedValues[0]) : undefined
                  }
                  defEndDate={appliedFilter?.selectedValues ? new Date(appliedFilter.selectedValues[1]) : undefined}
                  onFilterChange={onFilterChange}
                  appliedFilterId={appliedFilter.id}
                />
              )}

              {selectOptions.length >= minimumRecordsToShowSearchInput && (
                <TextInput
                  id="searchTermForFiltersList"
                  type="text"
                  value={searchTerm}
                  autoComplete="off"
                  hideLabel
                  label="Search Term"
                  placeholder="Search..."
                  prefix={<Icon name="search" />}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onClear={() => setSearchTerm('')}
                  size="sm"
                />
              )}

              {/* multi-select from filtered list of options */}
              <Box overflow="auto" childGap="sm">
                {[DataFilterType.Text].includes(type) && (
                  <TextBoxFilter
                    selectedValues={selectedValues}
                    handleChange={(event) => {
                      onFilterChange({
                        filterId: appliedFilterId,
                        selectedValues: [event.target.value],
                        operation,
                      });
                    }}
                  />
                )}
                {[DataFilterType.Select].includes(type) && (
                  <SearchableCheckboxFilterList
                    selectOptions={filteredOptions}
                    filterType={type}
                    selectedValues={selectedValues}
                    handleChange={(event) => {
                      let values = selectedValues ?? [];

                      if (event.target.checked) {
                        values.push(event.target.id);
                      } else {
                        values = values.filter((selection) => selection !== event.target.id);
                      }

                      onFilterChange({
                        filterId: appliedFilterId,
                        selectedValues: values,
                        operation,
                      });
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box justifyContent="center" background="primary" padding="sm">
              <Button variant="primary" size={size} onClick={handleDone} className="shadow-sm">
                Done
              </Button>
            </Box>
          </>
        }
        isOpen={isPopoverOpen}
        placement="bottom-start"
        contentContainerProps={{
          padding: '0',
          width: '318px',
          shadow: 'lg',
          radius: 'sm',
          overflow: 'hidden',
          background: 'secondary',
        }}
        onClickOutside={handleDone}
        portalTarget={document.body}
        withPortal
      >
        <Button
          variant="secondary"
          tone="neutral"
          size={size}
          onClick={togglePopover}
          className={classNames(styles.appliedFilterBtn, 'shadow-ws')}
          style={{
            backgroundColor: isMissingInputValue ? 'var(--color-background-danger)' : undefined,
          }}
        >
          {labelContent}
        </Button>
      </Popover>
      <Popover
        isOpen={removePopoverOpen}
        content="remove filter"
        placement="top"
        contentContainerProps={{
          padding: 'xs',
          background: 'primary',
          radius: 'sm',
          fontSize: 'xs',
          color: 'body-primary',
          fontWeight: 'bold',
        }}
        portalTarget={document.body}
        withPortal
      >
        <Button
          aria-label={`clear ${label} filter`}
          variant="secondary"
          tone="neutral"
          size={size}
          iconPrefix="remove"
          onClick={() => onRemoveFilter(appliedFilterId)}
          onMouseOver={() => setRemovePopoverOpen(true)}
          onMouseOut={() => setRemovePopoverOpen(false)}
          className={classNames(styles.appliedFilterRemove, {
            'shadow-xs': isPhone,
          })}
        />
      </Popover>
    </Box>
  );
};
