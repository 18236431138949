import { Box, Card } from '@palmetto/palmetto-components';
import { ProgramType } from 'types';
import { useGetOrganizationPaymentPlanHistoryQuery } from '@/services/organizations';
import { PaymentPlanHistoryTable } from '@/components/Payouts/PaymentPlanHistoryTable';
import PaymentCardHeader from '../components/PaymentCardHeader';
import { OrganizationNode } from '@/types/Organizations';

interface PaymentPlanProps {
  organization: OrganizationNode;
}

export const PaymentPlanHistory = ({ organization }: PaymentPlanProps) => {
  const { data: paymentPlanHistory } = useGetOrganizationPaymentPlanHistoryQuery({
    alias: organization.alias, programType: organization?.programTypes?.[0] ?? ProgramType.solar,
  });
  const renderPaymentPlan = (content: React.ReactNode) => (
    <Card>
      <PaymentCardHeader
        cardTitle="Payment Plan History"
      />
      {content}
    </Card>
  );

  if (!paymentPlanHistory) {
    return renderPaymentPlan(
      <Box alignItems="center" justifyContent="space-evenly" height="300px">
        <Box>No Payment Plan History</Box>
      </Box>,
    );
  }

  return renderPaymentPlan(
    <PaymentPlanHistoryTable
      paymentPlans={paymentPlanHistory}
    />,
  );
};
