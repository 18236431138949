import { Box, FormikTextInput } from '@palmetto/palmetto-components';
import { Field, useFormikContext } from 'formik';
import FormSection from '../Forms/FormSection';
import { FlagBox } from '../QueueFlags/FlagBox';
import { RequirementFlagSettingsType } from 'types';

export const SolarSerialNumber = () => {
  const { isSubmitting } = useFormikContext<any>();
  return (
    <FormSection
      description={
        <Box childGap="xs" color="body-secondary">
          <p>Provide the serial number from one panel in the solar array.</p>
        </Box>
      }
      title="Solar Module Serial Number"
    >
      <FlagBox dataKey="Serial Number" type={RequirementFlagSettingsType.serialNumber} displayWithNoQueue>
        <Box
          direction={{ base: 'column', tablet: 'row' }}
          alignItems="center"
          childGap={{ base: 'lg', tablet: '3xl' }}
          width="100"
        >
          <Field
            hideLabel
            name="solarModuleSerialNumber"
            id="solarModuleSerialNumber"
            component={FormikTextInput}
            type="text"
            isDisabled={isSubmitting}
          />
        </Box>
      </FlagBox>
    </FormSection>
  );
};
