import { useFormikContext } from 'formik';
import { CreateQuoteFormikContext } from './CreateQuote';
import { useGetAvailableAddersQuery } from '@/services/availableAdders';
import { useParams } from 'react-router-dom';
import FormSection from '../Forms/FormSection';
import { Box, CheckboxInput, TextInput } from '@palmetto/palmetto-components';
import { getAdderValue, isAdderAvailable } from '@/helpers/availableAdders';
import {
  ApprovedVendorSettingValue,
  BatteryPricingMethodOption,
  BatteryType,
  ConstructionAdderType,
  ProgramType,
  VendorEquipmentTypes,
} from 'types';
import { useGetAccountQuery } from '@/services/accounts';
import { useGetApprovedVendorsByTypeQuery } from '@/services/approvedVendors';
import { mapAvlManufacturerOptions, mapAvlModelNumberOptions } from '../SystemDesign/AvlFormUtils';
import { useEffect, useMemo } from 'react';
import { useGetProductPriceCapsQuery } from '@/services/pricing';
import { useDebounce } from '@/hooks/useDebounce';
import { BatteryAdder } from './BatteryAdder';

export const LeafConnector = () => (
  <Box
    borderColor="separator"
    borderWidth="0 0 sm sm"
    width="25px"
    height="32px"
    display="block"
    margin="0 xs sm lg"
  />
);

export const Adders = () => {
  const { id } = useParams<{ id: any }>();
  const { data: accountData } = useGetAccountQuery(id);
  const { data: availableAddersData } = useGetAvailableAddersQuery(id);
  const { data: storageOptions, isLoading: storageOptionsIsLoading } = useGetApprovedVendorsByTypeQuery({
    equipmentType: VendorEquipmentTypes.storage,
    programType: accountData?.programType,
  });

  const { values, setFieldValue, errors } = useFormikContext<CreateQuoteFormikContext>();

  const debouncedBatteryCost = useDebounce(values?.battery?.cost, 400);

  useEffect(() => {
    setFieldValue('debouncedBatteryCost', debouncedBatteryCost);
  }, [debouncedBatteryCost]);

  useEffect(() => {
    if (accountData?.programType === ProgramType.doePr && availableAddersData?.availableAdders) {
      const maxCost = getAdderValue(ConstructionAdderType.solarReadiness, availableAddersData.availableAdders);
      setFieldValue('solarReadinessMaxCost', maxCost);
    }
  }, [availableAddersData, accountData]);

  const hasAddersAvailable = (availableAddersData?.availableAdders || [])?.length > 0;
  const isElectricalUpgradeAvailable = isAdderAvailable(
    ConstructionAdderType.electricalUpgrade,
    availableAddersData?.availableAdders,
  );
  const isSolarReadinessAvailable = isAdderAvailable(
    ConstructionAdderType.solarReadiness,
    availableAddersData?.availableAdders,
  );
  const isBackupBatteryAvailable = isAdderAvailable(
    ConstructionAdderType.backupBattery,
    availableAddersData?.availableAdders,
  );
  const isBackupBatterKwhAvailable = isAdderAvailable(
    ConstructionAdderType.backupBatteryKwh,
    availableAddersData?.availableAdders,
  );
  const isArbitrageBatteryAvailable = isAdderAvailable(
    ConstructionAdderType.arbitrageBatteryCost,
    availableAddersData?.availableAdders,
  );
  const isArbitrageBatterKwhAvailable = isAdderAvailable(
    ConstructionAdderType.arbitrageBattery,
    availableAddersData?.availableAdders,
  );

  const { data: backupBatteryPriceCapsData } = useGetProductPriceCapsQuery(
    {
      accountId: id,
      productType: BatteryType.backupBattery,
    },
    { skip: !isBackupBatteryAvailable },
  );
  const { data: arbitrageBatteryPriceCapsData } = useGetProductPriceCapsQuery(
    {
      accountId: id,
      productType: BatteryType.arbitrageBattery,
    },
    { skip: !isArbitrageBatteryAvailable },
  );

  const backupStorageOptions = storageOptions?.data?.filter((battery: ApprovedVendorSettingValue) => battery.backup);
  const arbitrageStorageOptions = storageOptions?.data?.filter(
    (battery: ApprovedVendorSettingValue) => battery.arbitrage,
  );
  const backupBatteryManufacturerOptions = useMemo(() => {
    return storageOptionsIsLoading ? [] : mapAvlManufacturerOptions(backupStorageOptions || []);
  }, [storageOptionsIsLoading, backupStorageOptions]);

  const backupBatteryModelOptions = useMemo(() => {
    return storageOptionsIsLoading || !values.battery?.manufacturer
      ? []
      : mapAvlModelNumberOptions(backupStorageOptions || [], values.battery?.manufacturer.value);
  }, [storageOptionsIsLoading, backupStorageOptions, values?.battery?.manufacturer]);

  const arbitrageBatteryManufacturerOptions = useMemo(() => {
    return storageOptionsIsLoading ? [] : mapAvlManufacturerOptions(arbitrageStorageOptions || []);
  }, [storageOptionsIsLoading, arbitrageStorageOptions]);

  const arbitrageBatteryModelOptions = useMemo(() => {
    return storageOptionsIsLoading || !values.battery?.manufacturer
      ? []
      : mapAvlModelNumberOptions(arbitrageStorageOptions || [], values.battery?.manufacturer.value);
  }, [storageOptionsIsLoading, arbitrageStorageOptions, values?.battery?.manufacturer]);

  const backupBatteryPricingOptions = useMemo(() => {
    const options = [];
    const kWhRate = getAdderValue(ConstructionAdderType.backupBatteryKwh, availableAddersData?.availableAdders) || 0;
    if (kWhRate) {
      options.push({
        label: `+ $${kWhRate}/kWh`,
        value: BatteryPricingMethodOption.kwhRate,
        id: 'backupBatteryKwhIncluded',
      });
    }
    const totalCostEnabled = availableAddersData?.availableAdders?.find(
      (adder: any) => adder.adderType === ConstructionAdderType.backupBattery && adder.enabled,
    );

    if (totalCostEnabled) {
      options.push({
        label: `Total Cost`,
        value: BatteryPricingMethodOption.rawCost,
        id: 'backupBatteryIncluded',
      });
    }
    return options;
  }, [availableAddersData?.availableAdders, accountData]);

  const arbitrageBatteryPricingOptions = useMemo(() => {
    const options = [];
    const kWhRate = getAdderValue(ConstructionAdderType.arbitrageBattery, availableAddersData?.availableAdders) || 0;
    if (kWhRate) {
      options.push({
        label: `+ $${kWhRate}/kWh`,
        value: BatteryPricingMethodOption.kwhRate,
        id: 'arbitrageBatteryKwhIncluded',
      });
    }
    const totalCostEnabled = availableAddersData?.availableAdders?.find(
      (adder: any) => adder.adderType === ConstructionAdderType.arbitrageBatteryCost && adder.enabled,
    );

    if (totalCostEnabled) {
      options.push({
        label: `Total Cost`,
        value: BatteryPricingMethodOption.rawCost,
        id: 'arbitrageBatteryIncluded',
      });
    }
    return options;
  }, [availableAddersData?.availableAdders]);

  const backupBatteryPriceCaps = useMemo(() => {
    if (
      backupBatteryPriceCapsData?.length &&
      values?.battery?.type === BatteryType.backupBattery &&
      values?.battery?.pricingMethod === BatteryPricingMethodOption.rawCost &&
      values?.battery.manufacturer &&
      values?.battery.models.length
    ) {
      const selectedManufacturerValue = values?.battery?.manufacturer?.value;
      const backupBatteryPriceCap = values?.battery?.models.reduce((acc, model) => {
        const priceCap = backupBatteryPriceCapsData?.find(
          (cap: any) => cap.model === model.modelNumber.value && cap.manufacturer === selectedManufacturerValue,
        )?.maxPrice;
        const quantity = model.quantity.value;

        return acc + (priceCap * quantity || 0);
      }, 0);

      return backupBatteryPriceCap;
    }
  }, [backupBatteryPriceCapsData, values.battery]);

  const arbitrageBatteryPriceCaps = useMemo(() => {
    if (
      arbitrageBatteryPriceCapsData?.length &&
      values?.battery?.type === BatteryType.arbitrageBattery &&
      values?.battery?.pricingMethod === BatteryPricingMethodOption.rawCost &&
      values?.battery.manufacturer &&
      values?.battery.models.length
    ) {
      const selectedManufacturerValue = values?.battery?.manufacturer?.value;
      const arbitrageBatteryPriceCap = values?.battery?.models.reduce((acc, model) => {
        const priceCap = arbitrageBatteryPriceCapsData?.find(
          (cap: any) => cap.model === model.modelNumber.value && cap.manufacturer === selectedManufacturerValue,
        )?.maxPrice;
        const quantity = model.quantity.value;

        return acc + (priceCap * quantity || 0);
      }, 0);

      return arbitrageBatteryPriceCap;
    }
  }, [arbitrageBatteryPriceCapsData, values.battery]);

  useEffect(() => {
    if (accountData?.programType === ProgramType.doePr) {
      setFieldValue('battery', {
        type: BatteryType.backupBattery,
        pricingMethod: BatteryPricingMethodOption.embedded,
        manufacturer: '',
        models: [{ modelNumber: '', quantity: { label: '1', value: 1 } }],
      });
    }
  }, [accountData]);

  return hasAddersAvailable ? (
    <FormSection title="Adders" description="Select all that apply">
      <Box direction="row" wrap gap="lg">
        {isElectricalUpgradeAvailable && (
          <CheckboxInput
            id="electricalUpgradeIncluded"
            name="electricalUpgradeIncluded"
            label="Electrical Upgrade"
            helpText={`(+ $${getAdderValue(ConstructionAdderType.electricalUpgrade, availableAddersData?.availableAdders) || 0})`}
            onChange={(event) => {
              setFieldValue('productId', undefined);
              setFieldValue('electricalUpgradeIncluded', event.target.checked);
            }}
            isChecked={values?.electricalUpgradeIncluded || false}
          />
        )}
      </Box>
      {isSolarReadinessAvailable && (
        <Box>
          <CheckboxInput
            id="solarReadiness"
            name="solarReadiness"
            label="Solar Readiness"
            helpText={`(+ $${getAdderValue(ConstructionAdderType.solarReadiness, availableAddersData?.availableAdders) || 0})`}
            onChange={(event) => {
              setFieldValue('solarReadinessIncluded', event.target.checked);
              if (!event.target.checked) {
                setFieldValue('solarReadinessCost', 0);
              }
            }}
            isChecked={values?.solarReadinessIncluded || false}
          />
          {values?.solarReadinessIncluded && (
            <Box margin="md 0 0 sm" direction="row" childGap="md">
              <LeafConnector />
              <TextInput
                id="solarReadinessCost"
                value={values.solarReadinessCost}
                type="number"
                label="Total Cost"
                prefix="$"
                placeholder={`$${getAdderValue(ConstructionAdderType.solarReadiness, availableAddersData?.availableAdders) || 0} Maximum`}
                onChange={(event) => {
                  setFieldValue('solarReadinessCost', parseInt(event.target.value));
                }}
                error={errors.solarReadinessCost}
                isRequired
                maxWidth="2xl"
              />
            </Box>
          )}
        </Box>
      )}
      <Box gap="lg">
        <BatteryAdder
          batteryType={BatteryType.arbitrageBattery}
          isBatteryAvailable={isArbitrageBatterKwhAvailable || isArbitrageBatteryAvailable}
          label="Arbitrage Battery"
          manufacturerOptions={arbitrageBatteryManufacturerOptions}
          modelOptions={arbitrageBatteryModelOptions}
          pricingOptions={arbitrageBatteryPricingOptions}
          priceCap={arbitrageBatteryPriceCaps}
          isLoading={storageOptionsIsLoading}
          programType={accountData?.programType}
        />
        <BatteryAdder
          batteryType={BatteryType.backupBattery}
          isBatteryAvailable={isBackupBatterKwhAvailable || isBackupBatteryAvailable}
          label="Backup Battery"
          manufacturerOptions={backupBatteryManufacturerOptions}
          modelOptions={backupBatteryModelOptions}
          pricingOptions={backupBatteryPricingOptions}
          priceCap={backupBatteryPriceCaps}
          isLoading={storageOptionsIsLoading}
          programType={accountData?.programType}
        />
      </Box>
    </FormSection>
  ) : null;
};
