export function debounceAsync(
  func: (input: string, ...rest: any) => Promise<any>,
  delay: number,
): (input: string, ...rest: any) => Promise<any> {
  let timer: NodeJS.Timeout | null = null;
  let pendingPromise: Promise<any> | null = null;

  return (input: string, ...rest: any) => {
    if (timer) {
      clearTimeout(timer);
    }

    pendingPromise = new Promise((resolve, reject) => {
      timer = setTimeout(async () => {
        try {
          if (!input || input === '' || input.length < 3) {
            resolve([]);
          }
          const result = await func(input, ...rest);
          resolve(result);
        } catch (error) {
          reject(error);
        } finally {
          timer = null;
        }
      }, delay);
    });

    return pendingPromise;
  };
}
