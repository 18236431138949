import React from 'react';
import { Box, useTheme } from '@palmetto/palmetto-components';
import DateTimeDisplay from '@/components/DateTime';
import { DocumentDocument, DocumentFile, documentTitles, ViewUrlType } from 'types';

interface ProjectTransactionDetailsViewProps {
  transaction: any;
}

const ListItemElement = ({ label, value }: { label: string; value: string }) => {
  return (
    <Box borderWidth="0 0 xs 0" borderColor="separator" flex="auto" direction="row" padding="xs 0">
      <Box flex="auto" as="h3" fontWeight={'bold'}>
        {label}
      </Box>
      <Box>{value}</Box>
    </Box>
  );
};

const FileLink = ({ file }: { file: DocumentFile }) => {
  const originalView = file?.viewUrls?.find((f) => f.type === ViewUrlType.original);

  if (!originalView?.url) {
    return <Box>File not available</Box>;
  }

  return (
    <a
      href={originalView.url}
      target="_blank"
      rel="noopener noreferrer"
      title={file.originalName}
      style={{ textDecoration: 'underline' }}
    >
      {file.originalName || 'View Document'}
    </a>
  );
};

const DocumentListItem = ({ document }: { document: DocumentDocument }) => {
  const documentTitle = documentTitles[document.type] || document.type;
  const file = document.files && document.files.length > 0 ? document.files[0] : null;

  return (
    <Box borderWidth="0 0 xs 0" borderColor="separator" flex="auto" direction="row" padding="xs 0">
      <Box flex="auto" as="h3" fontWeight={'bold'}>
        {documentTitle}
      </Box>
      <Box>{file ? <FileLink file={file} /> : <Box>No file available</Box>}</Box>
    </Box>
  );
};

export const ProjectTransactionDetailsView: React.FC<ProjectTransactionDetailsViewProps> = ({ transaction }) => {
  const { theme } = useTheme();
  const createdByName =
    transaction.history && transaction.history.length > 0 && transaction.history[0].user?.name
      ? transaction.history[0].user.name
      : transaction.meta?.createdBy || '-';

  const hasDocuments =
    transaction.documents?.length > 0 &&
    transaction.documents.some((doc: DocumentDocument) => doc.files && doc.files.length > 0);

  return (
    <Box background={theme === 'dark' ? 'grey-800' : 'primary'} padding="md">
      <Box display="flex" direction="column" gap="xs" alignItems="center" width="100%">
        <Box width="50%">
          <ListItemElement label="Created By" value={createdByName} />

          <Box borderWidth="0 0 xs 0" borderColor="separator" flex="auto" direction="row" padding="xs 0">
            <Box flex="auto" as="h3" fontWeight={'bold'}>
              Created At
            </Box>
            <Box>
              {transaction.meta?.createdAt ? (
                <DateTimeDisplay displayLocalDateTime={true} value={transaction.meta.createdAt} />
              ) : (
                '-'
              )}
            </Box>
          </Box>

          <ListItemElement label="Account ID" value={transaction.projectExternalId || '-'} />
          <ListItemElement label="Payee Type" value={transaction.payeeType || '-'} />

          {transaction.organizationPlanName && (
            <ListItemElement label="Organization Plan Name" value={transaction.organizationPlanName || '-'} />
          )}

          {transaction.isManual && <ListItemElement label="Manual Transaction" value="Yes" />}

          {hasDocuments &&
            transaction.documents.map((document: DocumentDocument, index: number) => (
              <DocumentListItem key={`doc-${index}`} document={document} />
            ))}

          <Box borderWidth="0 0 xs 0" borderColor="separator" flex="auto" direction="column" padding="xs 0">
            <Box flex="auto" as="h3" fontWeight={'bold'} padding="0 0 xs 0">
              History
            </Box>
            {transaction.history && transaction.history.length > 0 ? (
              <Box direction="column" gap="xs">
                {transaction.history.map((historyItem: any, index: number) => (
                  <Box
                    key={index}
                    background={theme === 'dark' ? 'grey-700' : 'grey-50'}
                    padding="xs"
                    radius="sm"
                    margin="0 0 xs 0"
                    borderWidth="xs"
                    borderColor={theme === 'dark' ? 'grey-600' : 'grey-100'}
                  >
                    <Box direction="row" justifyContent="space-between" padding="0 0 xs 0">
                      <Box fontWeight="bold" fontSize="sm">
                        {historyItem.date && (
                          <DateTimeDisplay displayLocalDateTime={true} value={historyItem.date} />
                        )}
                      </Box>
                      <Box fontSize="sm">{historyItem.user?.name || ''}</Box>
                    </Box>
                    <Box fontSize="sm">{historyItem.summary || '-'}</Box>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box>No history available</Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
