import { uniqBy } from 'lodash';
export enum BatchComponent {
  queued = 'queued',
  paid = 'paid',
}
import { Clientize, PayoutEvent, PayoutEventMap, ProjectTransactionWithAdditionalData } from 'types';

export type ProjectTransactionClientData = Clientize<ProjectTransactionWithAdditionalData>;

export const materialsLabels = [
  { event: PayoutEvent.estimate, name: 'Materials Estimate', order: 1, badgeVariant: 'info' },
  { event: PayoutEvent.invoice, name: 'Materials Invoice', order: 2, badgeVariant: 'primary' },
];
export const flattenedPaymentEventLabelInfo = uniqBy(
  [...Object.values(PayoutEventMap).flat(), ...materialsLabels],
  'event',
);
