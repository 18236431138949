import { Alert, Box, Button, Icon, toast } from '@palmetto/palmetto-components';
import { Helmet } from 'react-helmet';
import { BatteryPricingMethodOption, BatteryType } from 'types';
import PageHeader from '../PageHeader';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { QuoteStatus } from '../../types/Quotes';
import { isErrorWithData } from '../../services/helpers';
// import { GlideECEligibility, energyCommunityMap } from 'types';
import { MainContainer } from '../MainContainer';
import { Form, Formik } from 'formik';
import { EscalationRates } from './EscalationRates';
import { Adders } from './Adders';
import { PricingTable } from './PricingTable';
import { useGetAccountQuery } from '@/services/accounts';
import { useCreateQuoteMutation, useGetQuotesQuery } from '@/services/quotes';
import * as yup from 'yup';
import { useGetAccountPricing } from './hooks/useGetAccountPricing';
import { FormActionsSection } from './FormActionsSection';

export interface CreateQuoteFormikContext {
  productId?: string;
  selectedEscalationRate?: number;
  electricalUpgradeIncluded?: boolean;
  solarReadinessIncluded?: boolean;
  solarReadinessCost?: number;
  solarReadinessMaxCost?: number;
  battery?: {
    type: BatteryType;
    pricingMethod: BatteryPricingMethodOption;
    manufacturer: { label: string; value: string };
    models: { modelNumber: { label: string; value: string }; quantity: { label: string; value: number } }[];
    cost?: number;
  };
  debouncedBatteryCost?: number;
  isBatterySelected?: boolean;
  pricingOptionsLimitedByMonthlyPaymentCap?: boolean;
}

const createQuoteSchema = yup.object().shape({
  productId: yup.string().required('Select a pricing option'),
  battery: yup.object().shape({
    type: yup
      .string()
      .when('$isBatterySelected', ([isBatterySelected], schema) =>
        isBatterySelected ? schema.oneOf(Object.values(BatteryType)) : schema.nullable(),
      ),
    pricingMethod: yup
      .string()
      .when('$isBatterySelected', ([isBatterySelected], schema) =>
        isBatterySelected ? schema.oneOf(Object.values(BatteryPricingMethodOption)) : schema.nullable(),
      ),
    manufacturer: yup.object().shape({
      label: yup.string(),
      value: yup
        .string()
        .when('$isBatterySelected', ([isBatterySelected], schema) =>
          isBatterySelected ? schema.required('Battery Manufacturer is required') : schema.nullable(),
        ),
    }),
    models: yup.array().when('$isBatterySelected', ([isBatterySelected], schema) =>
      isBatterySelected
        ? schema
            .of(
              yup.object().shape({
                modelNumber: yup.object().shape({
                  label: yup.string(),
                  value: yup.string().required('Battery Model Number is required'),
                }),
                quantity: yup.object().shape({
                  label: yup.string(),
                  value: yup.number().required('Battery Quantity is required'),
                }),
              }),
            )
            .min(1, 'At least one battery model is required')
        : schema
            .of(
              yup.object().shape({
                modelNumber: yup.object().shape({
                  label: yup.string(),
                  value: yup.string().nullable(),
                }),
                quantity: yup.object().shape({
                  label: yup.string(),
                  value: yup.number().nullable(),
                }),
              }),
            )
            .nullable(),
    ),
    cost: yup
      .number()
      .when('pricingMethod', {
        is: BatteryPricingMethodOption.rawCost,
        then: (schema) => schema.required('Battery cost is required'),
        otherwise: (schema) => schema.nullable(),
      })
      .min(0, 'Invalid battery cost: must be greater than 0'),
  }),
  solarReadinessCost: yup
    .number()
    .when('solarReadinessIncluded', {
      is: true,
      then: (schema) => schema.required('Solar readiness cost is required'),
      otherwise: (schema) => schema.nullable(),
    })
    .min(0, 'Invalid amount: must be greater than 0')
    .max(yup.ref('solarReadinessMaxCost'), ({ max }) => `Value must not exceed the maximum allowed value of ${max}`),
  electricalUpgradeIncluded: yup.boolean(),
});

function flattenErrors(errors: any, prefix = '') {
  const flattened: Record<string, any> = {};

  Object.keys(errors).forEach((key) => {
    const value = errors[key];
    const newKey = prefix ? `${prefix}.${key}` : key;

    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (typeof item === 'object' && item !== null) {
          Object.assign(flattened, flattenErrors(item, `${newKey}[${index}]`));
        } else {
          flattened[`${newKey}[${index}]`] = item;
        }
      });
    } else if (typeof value === 'object' && value !== null) {
      Object.assign(flattened, flattenErrors(value, newKey));
    } else {
      flattened[newKey] = value;
    }
  });

  return flattened;
}

export const CreateQuote = () => {
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const { data: accountData, isLoading: accountIsLoading } = useGetAccountQuery(id);
  const { data: quotes, isLoading: quotesIsLoading } = useGetQuotesQuery(
    { id, programType: accountData?.programType },
    { skip: !accountData?.programType },
  );
  const isMissingSystemDetails =
    !accountData?.systemDesign?.systemSizeKw || !accountData?.systemDesign?.systemFirstYearProductionKwh;
  const { isLoading: pricingIsLoading, error: pricingError } = useGetAccountPricing();

  const [createQuote] = useCreateQuoteMutation();

  // const energyCommunity = accountData?.itcAdderQualifications?.ecEligibilityCriteria
  //   ? energyCommunityMap[accountData.itcAdderQualifications?.ecEligibilityCriteria as GlideECEligibility].map(
  //       (v: string) => v,
  //     )
  //   : false;
  const isLoading = pricingIsLoading || accountIsLoading || quotesIsLoading;

  const hasActiveOrContractedQuotes = quotes?.some(
    (quote: any) => quote.status === QuoteStatus.active || quote.status === QuoteStatus.contracted,
  );

  const handleSubmit = async (
    values: CreateQuoteFormikContext,
    { setSubmitting }: { setSubmitting: (submitting: boolean) => void },
  ) => {
    setSubmitting(true);
    const quoteData = {
      productId: values.productId,
      electricalUpgradeIncluded: values?.electricalUpgradeIncluded,
      ...(values?.battery
        ? {
            battery: {
              type: values.battery.type,
              pricingMethod: values.battery.pricingMethod,
              cost: values.battery.cost,
              manufacturer: values.battery.manufacturer?.value,
              models: values.battery.models.map((model) => ({
                modelNumber: model.modelNumber.value,
                quantity: model.quantity.value,
              })),
            },
          }
        : {}),
      externalReference: accountData?.externalReference || undefined,
    } as any;
    if (values.solarReadinessIncluded) {
      quoteData.solarReadinessCost = values.solarReadinessCost;
    }

    try {
      await createQuote({
        quoteData,
        id,
        programType: accountData?.programType,
      }).unwrap();
      toast.success('Quote submitted successfully');
      navigate(`/accounts/${id}`);
    } catch (e: any) {
      console.error(e);
      toast.error(e.data?.message ?? 'Error creating quote');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Create Quote</title>
      </Helmet>
      <MainContainer>
        <PageHeader
          eyebrow={
            <Link to={`/accounts/${id}`} className="display-flex align-items-center flex-direction-row">
              <Icon name="caret-left" size="xs" color="primary" />
              <span className="font-color-primary m-left-2xs">{accountData?.friendlyName}</span>
            </Link>
          }
          title="Create Quote"
          description="Choose an escalation rate and pricing option to create a quote"
        />
        {!isLoading && !isMissingSystemDetails && pricingError && (
          <Box childGap="md">
            <Alert variant="warning" message={isErrorWithData(pricingError) && pricingError.data.message} />
          </Box>
        )}
        {!isLoading && isMissingSystemDetails ? (
          <Box childGap="md">
            <Alert variant="warning" message="System Size must be provided before creating a quote" />
            <Box fontWeight="bold" margin="0 0 sm 0">
              <Link to={`/accounts/${id}`}>
                <Icon name="caret-left" size="xs" color="primary" />
                <span className="font-color-primary m-left-2xs">Back to Account</span>
              </Link>
            </Box>
          </Box>
        ) : !isLoading && hasActiveOrContractedQuotes ? (
          <Box childGap="md">
            <Alert
              hasIcon
              variant="warning"
              message=" This account already has an active or contracted quote. It must be voided before a new quote can be
          created."
            />
            <Box fontWeight="bold" margin="0 0 sm 0">
              <Link to={`/accounts/${id}`}>
                <Icon name="caret-left" size="xs" color="primary" />
                <span className="font-color-primary m-left-2xs">Back to Account</span>
              </Link>
            </Box>
          </Box>
        ) : (
          <Box childGap="lg" padding={{ base: '0 lg', desktop: '0' }}>
            <Box
              radius="md"
              borderWidth="xs"
              borderColor="separator"
              direction={{ base: 'column', tablet: 'row' }}
              padding="lg"
              alignItems={{ tablet: 'center' }}
              childGap={{ base: 'sm', tablet: '2xl' }}
            >
              <Box>Solar Energy System</Box>
              <Box direction="row" childGap={{ base: 'lg', desktop: '2xl' }}>
                <Box childGap="2xs">
                  <Box fontSize={{ base: 'md', desktop: 'lg' }} fontWeight="medium">
                    {accountData?.systemDesign?.systemSizeKw} kW
                  </Box>
                  <div>System Size</div>
                </Box>
                <Box childGap="2xs">
                  <Box fontSize={{ base: 'md', desktop: 'lg' }} fontWeight="medium">
                    {accountData?.systemDesign?.systemFirstYearProductionKwh} kWh
                  </Box>
                  <div>Year-1 Est. Production</div>
                </Box>
                {/* <Box childGap="2xs">
                  <Box fontSize={{ base: 'md', desktop: 'lg' }} fontWeight="medium">
                    {energyCommunity ? energyCommunity.join(', ') : 'No'}
                  </Box>
                  <Box direction="row" gap="2xs">
                    Energy Community <Icon name="nature" color={energyCommunity ? 'primary' : 'grey-300'} />
                  </Box>
                </Box> */}
              </Box>
            </Box>
            <Formik
              initialValues={{ pricingOptionsLimitedByMonthlyPaymentCap: false }}
              onSubmit={handleSubmit}
              enableReinitialize={true}
              validationSchema={createQuoteSchema}
              validateOnChange={false}
            >
              {({ isSubmitting, errors, values }) => {
                const flatErrors = Object.keys(errors).length ? flattenErrors(errors) : {};
                return (
                  <Form id="createQuoteForm">
                    <EscalationRates />
                    <Adders />
                    <PricingTable />
                    {Object.keys(flatErrors).length > 0 &&
                      Object.keys(flatErrors).map((key) => {
                        return (
                          <Box childGap="md">
                            <Alert variant="danger" message={`Error: ${flatErrors[key]}`} />
                          </Box>
                        );
                      })}
                    <FormActionsSection
                      isLoading={isLoading}
                      isSubmitting={isSubmitting}
                      selectedElement={values.productId}
                      onCancel={() => {
                        navigate(`/accounts/${id}`);
                      }}
                    />
                  </Form>
                );
              }}
            </Formik>
          </Box>
        )}
      </MainContainer>
    </>
  );
};
