import FormSection from '@/components/Forms/FormSection';
import { Accordion, Box, Button, FormikSelectInputNative, FormikTextInput } from '@palmetto/palmetto-components';
import { Field, FieldArray, useFormikContext } from 'formik';
import { HVACSystem, HVACSystemCategory, RequirementFlagSettingsType, UserPermissions } from 'types';
import DiscountsSection from './DiscountsSection';
import EquipmentAndAccessoriesSection from './EquipmentAndAccessoriesSection';
import usePermissions from '@/hooks/usePermissions';
import SitePrepSection from './SitePrepSection';
import { useState } from 'react';
import { FlagBox } from '@/components/QueueFlags/FlagBox';

const systemCategoryOptions = Object.keys(HVACSystemCategory)
  .sort()
  .map((key) => ({
    label: key,
    value: key,
  }));

export const SystemSection = ({ isInstallPackage = false }) => {
  const { values } = useFormikContext<any>();
  const [openPanels, setOpenPanels] = useState<Record<string, boolean>>({
    '1': true,
  });
  const permissions = usePermissions();
  const userOnlyHasReaderPermission = permissions?.every((permission) =>
    permission.includes(UserPermissions.reader),
  );
  return (
    <FieldArray
      name="systems"
      render={({ push, remove }) => {
        return (
          <Accordion>
            <Box>
              {values?.systems?.map((_system: HVACSystem, index: number) => {
                const panelIndex = `${index + 1}`;
                return (
                  <Accordion.Panel isOpen={openPanels[panelIndex]} key={`system-${index}`}>
                    <Accordion.PanelSummary
                      hasCaret="right"
                      onToggle={() => {
                        setOpenPanels({ ...openPanels, [panelIndex]: !openPanels[panelIndex] });
                      }}
                      isDetailsOpen={openPanels[panelIndex]}
                      padding={{ desktop: !openPanels[panelIndex] ? 'xl 0' : 'xl 0 0 0', base: 'xl lg' }}
                    >
                      <Box>
                        <Box direction="row">
                          <Box direction="row" gap="xs" alignItems="center">
                            <Box fontSize="xl" fontWeight="medium">
                              {values?.systems?.[index]?.name}
                            </Box>
                            {values?.systems?.length > 1 ? (
                              <Button
                                variant="tertiary"
                                tone="neutral"
                                size="sm"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  const confirmed = window.confirm('Are you sure you want to remove this system?');
                                  if (!confirmed) return;
                                  remove(index);
                                }}
                                iconPrefix="trash"
                              />
                            ) : null}
                          </Box>
                        </Box>
                        {!openPanels[panelIndex] &&
                        (values?.systems?.[index]?.systemCategory || values?.systems?.[index]?.conditionedArea) ? (
                          <Box fontSize="md" fontWeight="regular" margin="md 0 0 0">
                            {values?.systems?.[index]?.systemCategory}
                            {values?.systems?.[index]?.conditionedArea
                              ? ` | ${values?.systems?.[index]?.conditionedArea.toLocaleString()} sq ft`
                              : ''}{' '}
                          </Box>
                        ) : null}
                      </Box>
                    </Accordion.PanelSummary>
                    <FormSection baseStyle={{ display: 'block' }}>
                      <Box
                        direction="row"
                        justifyContent="space-between"
                        gap="lg"
                        display="block"
                        id={`system-${index}`}
                      >
                        <Box gap="lg">
                          <Box
                            direction={{ base: 'column', desktop: 'row' }}
                            gap="xl"
                            padding={{ desktop: 'xl 0', base: '0 lg' }}
                          >
                            <Field
                              label="Name"
                              name={`systems.${index}.name`}
                              id={`systems.${index}.name`}
                              component={FormikTextInput}
                              defaultValue={`System ${index + 1}`}
                              isDisabled={userOnlyHasReaderPermission}
                              width={{ tablet: '4xl' }}
                            />
                            <Field
                              label="System Category"
                              name={`systems.${index}.systemCategory`}
                              id={`systems.${index}.systemCategory`}
                              component={FormikSelectInputNative}
                              options={systemCategoryOptions}
                              isRequired
                              isDisabled={userOnlyHasReaderPermission}
                              width={{ tablet: '4xl' }}
                            />
                            <Field
                              type="number"
                              label="Conditioned Area (sq ft)"
                              name={`systems.${index}.conditionedArea`}
                              id={`systems.${index}.conditionedArea`}
                              component={FormikTextInput}
                              autoComplete="off"
                              isDisabled={userOnlyHasReaderPermission}
                              isRequired
                              width={{ tablet: '3xl' }}
                            />
                          </Box>
                          <EquipmentAndAccessoriesSection
                            systemIndex={index}
                            dataKey="equipment"
                            label="Equipment & Materials"
                            isInstallPackage={isInstallPackage}
                          />
                          <EquipmentAndAccessoriesSection
                            systemIndex={index}
                            dataKey="accessories"
                            label="Accessories"
                            isInstallPackage={isInstallPackage}
                          />
                          <SitePrepSection systemIndex={index} isInstallPackage={isInstallPackage} />
                          <DiscountsSection systemIndex={index} isInstallPackage={isInstallPackage} />
                          {isInstallPackage ? (
                            <FormSection baseStyle={{ borderWidth: '0' }}>
                              <FlagBox
                                dataKey={`AHRI Number ${index + 1}`}
                                baseCategory="AhriNumber"
                                type={RequirementFlagSettingsType.ahriNumber}
                                displayWithNoQueue
                              >
                                <Field
                                  type="text"
                                  name={`systems.${index}.ahriNumber`}
                                  id={`systems.${index}.ahriNumber`}
                                  width="2xl"
                                  autoComplete="off"
                                  component={FormikTextInput}
                                  isRequired
                                />
                              </FlagBox>
                            </FormSection>
                          ) : null}
                        </Box>
                      </Box>
                    </FormSection>
                  </Accordion.Panel>
                );
              })}
              <Button
                variant="secondary"
                iconPrefix="add"
                style={{ alignSelf: 'flex-end', marginTop: '16px' }}
                onClick={() => {
                  const closeExistingPanels = Object.keys(openPanels).reduce((acc, key) => {
                    return { ...acc, [key]: false };
                  }, {});
                  setOpenPanels({ ...closeExistingPanels, [`${values.systems.length + 1}`]: true });
                  push({
                    name: `System ${values.systems.length + 1}`,
                    systemCategory: '',
                    equipment: {
                      totalCost: 0,
                      items: [],
                    },
                    accessories: {
                      totalCost: 0,
                      items: [],
                    },
                    sitePrep: {
                      totalCost: 0,
                      items: [],
                    },
                    discounts: {
                      totalCost: 0,
                      items: [],
                    },
                  });
                }}
              >
                Add System
              </Button>
            </Box>
          </Accordion>
        );
      }}
    />
  );
};
