import React, { useCallback, useState } from 'react';
import { useAuth } from '../auth/authProvider';
import { isPalmettoFinanceUser } from '../auth/RequirePalmettoFinanceUser';
import { AddFlagModal } from './AddFlagModal';
import { useAddFlagToQueueMutation, useGetQueueByIdQuery, useResolveFlagMutation } from '../../services/queues';
import { Flag, FlagPayload, FlagStatus, QueueDocument, RequirementFlagSettingsType } from 'types';
import { skipToken } from '@reduxjs/toolkit/query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { NewAddFlagModal } from './RevampAddFlagModal';
import ResolveFlagModal from './ResolveFlagModal';
export interface QueueFlagsContextValue {
  isModalShowing: boolean;
  flags: Flag[];
  shouldHide: boolean;
  showModal: (
    dataKey: string,
    type?: RequirementFlagSettingsType,
    fileName?: string,
    baseCategory?: string,
    documentType?: string,
  ) => void;
  showResolveModal: (dataKey: string, fileName?: string) => void;
  hideModal: () => void;
  addFlag: ({ dataKey, fileName, note, reason, fileUpload, type, purposeType }: FlagPayload) => Promise<void>;
  resolveFlag: (dataKey: string, status: string, fileName?: string) => Promise<void>;
  getFlag: (dataKey: string | string[], fileName?: string, includeResolved?: boolean) => Flag | undefined;
  getAllFlags: (includeResolved?: boolean) => Flag[];
  isLoading: boolean;
  setLoading: (loading: boolean) => void;
  queue?: QueueDocument;
}

export interface QueueFlagsProviderProps {
  children?: React.ReactNode;
  queueId: string;
}

const QueueFlagsContext = React.createContext<QueueFlagsContextValue>({
  isModalShowing: false,
  flags: [],
  shouldHide: true,
  showModal: () => {},
  showResolveModal: () => {},
  hideModal: () => {},
  addFlag: async () => {},
  resolveFlag: async () => {},
  getFlag: () => undefined,
  getAllFlags: () => [],
  isLoading: false,
  setLoading: () => {},
  queue: undefined,
});
const { Provider } = QueueFlagsContext;

const QueueFlagsProvider = ({ children, queueId }: QueueFlagsProviderProps) => {
  const { flagDrivenPackageReview = false } = useFlags();
  const [isModalShowing, setIsModalShowing] = React.useState(false);
  const [saveFlag] = useAddFlagToQueueMutation();
  const [resolveCurrentFlag] = useResolveFlagMutation();
  const { data: queue } = useGetQueueByIdQuery(queueId ? { id: queueId } : skipToken);
  const [isResolveModalShowing, setIsResolveModalShowing] = useState(false);
  const { claims } = useAuth();
  const isFinCoUser = isPalmettoFinanceUser(claims);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [flagTarget, setFlagTarget] = React.useState<{
    dataKey: string;
    fileName: string;
    baseCategory?: string;
    documentType?: string;
    type?: RequirementFlagSettingsType;
  }>({
    dataKey: '',
    fileName: '',
  });
  const addFlag = useCallback(
    async ({
      dataKey,
      fileName,
      note,
      reason,
      problems,
      baseCategory,
      fileUpload,
      type,
      documentType,
      purposeType,
    }: FlagPayload) => {
      setLoading(true);
      const result = await saveFlag({
        id: queueId,
        flag: {
          dataKey,
          fileName,
          reason,
          problems,
          note,
          category: baseCategory,
          fileUpload,
          type,
          documentType,
          purposeType,
        },
      }).unwrap();
      setLoading(false);
      return result;
    },
    [queueId, saveFlag],
  );
  const showResolveModal = useCallback((dataKey: string, fileName?: string) => {
    setFlagTarget({ dataKey, fileName: fileName || '' });
    setIsResolveModalShowing(true);
  }, []);

  const hideResolveModal = useCallback(() => {
    setIsResolveModalShowing(false);
  }, []);

  const resolveFlag = useCallback(
    async (dataKey: string, status: string = FlagStatus.closed, fileName?: string) => {
      setLoading(true);
      try {
        await resolveCurrentFlag({ id: queueId, dataKey, fileName, status }).unwrap();
      } catch (e) {
        setLoading(false);
        throw e;
      }
      setLoading(false);
      hideResolveModal();
    },
    [queueId, resolveCurrentFlag, hideResolveModal],
  );

  const showModal = useCallback(
    (
      dataKey: string,
      type?: RequirementFlagSettingsType,
      fileName?: string,
      baseCategory?: string,
      documentType?: string,
    ) => {
      setFlagTarget({ dataKey, fileName: fileName || '', baseCategory, type, documentType });
      setIsModalShowing(true);
    },
    [],
  );
  const hideModal = useCallback(() => {
    setIsModalShowing(false);
  }, []);

  const getFlag = useCallback(
    (dataKeys: string | string[], fileName?: string, includeResolved?: boolean) => {
      const keys = Array.isArray(dataKeys) ? dataKeys : [dataKeys];
      return queue?.flags?.find(
        (flag: Flag) =>
          (includeResolved || !flag.resolved) &&
          keys.includes(flag.dataKey) &&
          (!fileName || flag.fileName === fileName),
      );
    },
    [queue?.flags],
  );

  const getAllFlags = useCallback(
    (includeResolved?: boolean) => {
      if (!queue?.flags) {
        return [];
      }
      if (includeResolved) {
        return queue?.flags || [];
      } else {
        return queue?.flags.filter((flag: Flag) => !flag.resolved);
      }
    },
    [queue?.flags],
  );

  return (
    <Provider
      value={{
        isModalShowing,
        addFlag,
        resolveFlag,
        shouldHide: !Boolean(queue?.id) || !isFinCoUser,
        showModal,
        showResolveModal,
        hideModal,
        flags: queue?.flags || [],
        getFlag,
        getAllFlags,
        isLoading,
        setLoading,
        queue,
      }}
    >
      {flagDrivenPackageReview && flagTarget?.type ? (
        <NewAddFlagModal
          accountId={queue?.account?.id}
          isOpen={isModalShowing}
          onDismiss={hideModal}
          dataKey={flagTarget.dataKey}
          fileName={flagTarget.fileName}
          baseCategory={flagTarget.baseCategory}
          type={flagTarget.type}
          documentType={flagTarget.documentType}
          showUploadButton={isFinCoUser}
        />
      ) : (
        <AddFlagModal
          accountId={queue?.account?.id}
          isOpen={isModalShowing}
          onDismiss={hideModal}
          dataKey={flagTarget.dataKey}
          fileName={flagTarget.fileName}
          baseCategory={flagTarget.baseCategory}
          documentType={flagTarget.documentType}
          showUploadButton={isFinCoUser}
        />
      )}

      {isResolveModalShowing && (
        <ResolveFlagModal
          isOpen={isResolveModalShowing}
          onDismiss={hideResolveModal}
          onResolve={(status: string) => resolveFlag(flagTarget.dataKey, status, flagTarget.fileName)}
          dataKey={flagTarget.dataKey}
          fileName={flagTarget.fileName}
        />
      )}

      {children}
    </Provider>
  );
};

export { QueueFlagsProvider, QueueFlagsContext };
