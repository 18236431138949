import { currencyFormatter } from '@/helpers/currencyFormatter';
import { percentageFormatter } from '@/helpers/percentageFormatter';
import { ProjectTransactionClientData } from '@/types/Payouts';
import { Box, Icon } from '@palmetto/palmetto-components';
import { useMemo } from 'react';
import {
  AccountDocument,
  DeepConvertType,
  PayeeType,
  PaymentPlan,
  PaymentPlanEventSetting,
  PayoutEvent,
  PayoutEventMap,
  ProgramType,
  ProjectDocument,
} from 'types';

export const SubtitleBox = ({ children }: { children: any }) => (
  <Box as="h3" color="grey-500" fontWeight={'bold'} padding={'0 0 0 0'} display={'inline-block'}>
    {children}
  </Box>
);

export const SmallText = ({ children }: { children: any }) => (
  <Box as="span" color="grey-600" fontSize="2xs" fontWeight="regular">
    {children}
  </Box>
);

export const RightAmountBox = ({ title, amount }: { title: string; amount: string }) => (
  <Box
    alignItems="flex-end"
    style={{
      flex: '1',
    }}
  >
    <Box color="grey-500" fontSize="xs" fontWeight="medium">
      {title}
    </Box>
    <Box color="grey-700" fontSize="sm" padding="2xs 0 0 0" fontWeight="medium">
      {amount}
    </Box>
  </Box>
);

export const LeftAmountBox = ({ amount, percent }: { amount: string; percent?: string }) => (
  <Box
    color="grey-700"
    fontSize="xs"
    fontWeight="medium"
    style={{
      flex: 1,
    }}
  >
    {amount}
    <br />
    {percent && (
      <Box as="span" color="grey-400" fontSize="xs" padding="2xs 0 0 0" fontWeight="medium">
        {percent}
      </Box>
    )}
  </Box>
);

export const LeftContainerBox = ({ children }: { children: any }) => (
  <Box
    margin="0 sm 0 0"
    style={{
      flex: '1',
      paddingBottom: '2px',
    }}
  >
    {children}
  </Box>
);

export const LeftAmountParentBox = ({
  amount,
  eventPercent,
  title,
  checkbox,
}: {
  amount: number;
  eventPercent: number;
  title: string;
  checkbox?: boolean;
}) => (
  <LeftContainerBox>
    <Box
      direction="row"
      style={{
        minHeight: '3.5em',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <SubtitleBox>{title}</SubtitleBox>
      {checkbox ? (
        <Box margin="0 0 0 xs">
          <Icon name="check" color="success" />
        </Box>
      ) : null}
    </Box>
    <Box borderColor="separator" borderWidth="xs 0 0 0" padding="xs 0 0 0">
      <LeftAmountBox amount={currencyFormatter.format(amount)} percent={percentageFormatter.format(eventPercent)} />
    </Box>
  </LeftContainerBox>
);

export interface AccountFundingSummaryProps {
  project?: ProjectDocument;
  transactions?: ProjectTransactionClientData[];
}

export interface AccountFundingSummaryComponentProps extends AccountFundingSummaryProps {
  orgPlanDiffersAccountPlan: boolean;
  orgPlanName: string;
  refetchProjectAndTransactions: () => void;
  account: AccountDocument;
}

export const useGetLeftAmountElements = ({
  transactions,
  projectTotalCost,
  paymentPlan,
}: {
  transactions: AccountFundingSummaryProps['transactions'];
  projectTotalCost: number;
  paymentPlan: DeepConvertType<PaymentPlan, any, string> | undefined;
}) => {
  const leftAmounts = useMemo(() => {
    const elements: {
      component: any;
      amount: number;
      id: PayoutEvent | PayeeType;
    }[] = [];

    if (!paymentPlan) {
      return;
    }

    (paymentPlan?.paymentSchedule as any as PaymentPlanEventSetting[])?.forEach((element) => {
      const labelElement = PayoutEventMap[paymentPlan.programType as ProgramType].find(
        (mapElement) => mapElement.event === element.event,
      );
      const expectedFullAmount = element.paymentPercent * projectTotalCost;
      const matchingTransactions = transactions.filter((transElement) => transElement.event === element.event);
      const transactionsAmmount = matchingTransactions.reduce((acc, transaction) => acc + transaction.amount, 0);
      elements.push({
        component: (
          <LeftAmountParentBox
            amount={transactionsAmmount}
            eventPercent={element.paymentPercent}
            title={labelElement?.name ?? element.event}
            checkbox={transactionsAmmount >= expectedFullAmount}
          />
        ),
        amount: transactionsAmmount,
        id: element.event,
      });
    });

    const totalPayoutAmountDirectPayTransactions = transactions.filter(
      (element) => element.payeeType === PayeeType.vendor,
    );

    if (totalPayoutAmountDirectPayTransactions.length) {
      const totalPayoutAmountDirectPayAmount = totalPayoutAmountDirectPayTransactions.reduce(
        (acc, transaction) => acc + transaction.amount,
        0,
      );
      elements.push({
        component: (
          <LeftAmountParentBox
            amount={totalPayoutAmountDirectPayAmount}
            eventPercent={totalPayoutAmountDirectPayAmount / projectTotalCost}
            title="Vendor Direct Pay"
            checkbox={false}
          />
        ),
        amount: totalPayoutAmountDirectPayAmount,
        id: PayeeType.vendor,
      });
    }

    return elements;
  }, [transactions, paymentPlan, projectTotalCost]);

  return () => leftAmounts;
};

export const useGetRightAmounts = ({ project, transactions }: AccountFundingSummaryProps) => {
  const totalPayoutAmount = useMemo(
    () => transactions.reduce((acc, transaction) => acc + transaction.amount, 0),
    [transactions],
  );
  const projectTotalValue = project?.totalCost ?? 0;
  const payoutAmountToDate = totalPayoutAmount;
  const amountRemaining = projectTotalValue - totalPayoutAmount;

  return () => ({
    projectTotalValue,
    payoutAmountToDate,
    amountRemaining,
  });
};
