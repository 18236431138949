import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, useOpenClose } from '@palmetto/palmetto-components';
import { Documents } from '../Documents';
import { Tasks } from '../Tasks';
import { isPalmettoFinanceUser } from '../auth/RequirePalmettoFinanceUser';
import { Quotes } from '../Quotes/Quotes';
import { AccountMilestones } from './AccountMilestones';
import { Contracts } from '../Contracts/ContractList';
import {
  DocumentStatus,
  DocumentTypesArr,
  InstallDocumentTypesArr,
  DeprecatedDocumentTypesArr,
  BillingDocumentTypes,
  QueueType,
  ProgramType,
  HVACDocumentTypesArr,
  NTPPackageDocumentTypes,
  DoePrDocumentTypesArr,
  QueueStatus,
} from 'types';
import { InstallMilestonePackageContainer } from '../InstallMilestonePackage/InstallMilestonePackageContainer';
import RejectionBanner from '../Reject/RejectionBanner';
import { MilestoneType } from '../../types/Milestone';
import { ActivationMilestonePackageContainer } from '../ActivationMilestonePackage/ActivationMilestonePackageContainer';
import { Underwriting } from '../Underwriting/Underwriting';
import { AccountDetails } from './AccountDetails';
import CancelAccount from '../Cancel/CancelAccount';
import AdminStipulation from '../Stipulations/AdminStipulation';
import { useGetAccountQuery } from '../../services/accounts';
import {
  AccountMilestoneWithRequirements,
  AccountMilestoneStatus,
  AccountRequirementDocument,
  AccountRequirementStatus,
  MilestoneRequirement,
  Packages,
} from 'types';
import { useAuth } from '../auth/authProvider';
import { useGetQueueByAccountIdAndTypeQuery } from '@/services/queues';
import { NTPMilestonePackageContainer } from '../NTPMilestonePackage/NTPMilestonePackageContainer';
import { PostActivationMilestonePackageContainer } from '../PostActivationMilestonePackage/PostActivationMilestonePackagerContainer';
import { DirectPayOptInOutModal } from './DirectPayOptInOutModal';
import RejectionBannerV2 from '../Reject/RejectionBannerV2';
import MilestoneCompletionBanner from './MilestoneCompletionBanner';

const getMostRecentObj = (array: any[]) => {
  return array?.reduce((prev: any, current: any) => {
    return prev.date > current.date ? prev : current;
  });
};

const getPreviousCompletedMilestones = (milestones: AccountMilestoneWithRequirements[]): AccountMilestoneWithRequirements[] => {
  if (!milestones) return [];
  return milestones.filter((milestone: AccountMilestoneWithRequirements) => milestone.status === AccountMilestoneStatus.approved);
};

const getPackageObjectFromRequirements = (requirements: AccountRequirementDocument[]) => {
  if (!requirements) return;
  return requirements.find((requirement: AccountRequirementDocument) => Object.keys(Packages).includes(requirement.type));
}

const SpecificLanguageWhenDomesticContentIsRejected =
  'LightReach review determined that this project does not satisfy the Domestic Content equipment thresholds. If you would like to re-review of ITC-DC Qualification, please provide additional document(s) when resubmitting the Install Package.';

const Overview = () => {
  const { id } = useParams<{ id: any }>();
  const { adminStipulations, enableCancelAccountButton, viewActivationMilestonePackage, flagDrivenPackageReview } = useFlags();
  const { data: account } = useGetAccountQuery(id);
  const { data: installationQueue } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.installPackage,
  });
  const { data: activationQueue } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.activationPackage,
  });

  const {
    isOpen: isDirectPayOptInModalOpen,
    handleOpen: openDirectPayOptInModal,
    handleClose: closeDirectPayOptInModal,
  } = useOpenClose();
  const { data: domesticContentQueue } = useGetQueueByAccountIdAndTypeQuery({
    accountId: id,
    type: QueueType.domesticContent,
  });

  const noticeToProceedMilestone: AccountMilestoneWithRequirements = account?.milestones?.find(
    (milestone: AccountMilestoneWithRequirements) => milestone.type === MilestoneType.noticeToProceed,
  );
  const installMilestone: AccountMilestoneWithRequirements = account?.milestones?.find(
    (milestone: AccountMilestoneWithRequirements) => milestone.type === MilestoneType.install,
  );
  const activationMilestone: AccountMilestoneWithRequirements = account?.milestones?.find(
    (milestone: AccountMilestoneWithRequirements) => milestone.type === MilestoneType.activation,
  );

  const noticeToProceedRejections: string[] | undefined = useMemo(() => {
    if (!noticeToProceedMilestone || noticeToProceedMilestone.status !== AccountMilestoneStatus.rejected) {
      return;
    }
    const noticeToProceedPackage = noticeToProceedMilestone.requirements.find(
      (req: AccountRequirementDocument) => req.type === MilestoneRequirement.ntpPackage,
    );
    const rejectedRequirements = noticeToProceedPackage?.requirements
      .filter((req: AccountRequirementDocument) => req.status === AccountRequirementStatus.rejected)
      .map((rejectedRequirement: AccountRequirementDocument) => rejectedRequirement.name);
    return rejectedRequirements;
  }, [noticeToProceedMilestone]);

  const installRejections: string[] | undefined = useMemo(() => {
    if (!installMilestone || installMilestone.status !== AccountMilestoneStatus.rejected) {
      return;
    }
    const installPackage = installMilestone.requirements.find(
      (req: AccountRequirementDocument) => req.type === MilestoneRequirement.installPackage,
    );
    const rejectedRequirements = installPackage?.requirements
      .filter((req: AccountRequirementDocument) => req.status === AccountRequirementStatus.rejected)
      .map((rejectedRequirement: AccountRequirementDocument) => rejectedRequirement.name);
    return rejectedRequirements;
  }, [installMilestone]);

  const activationRejections: string[] | undefined = useMemo(() => {
    if (!activationMilestone || activationMilestone.status !== AccountMilestoneStatus.rejected) {
      return;
    }
    const activationPackage = activationMilestone.requirements.find(
      (req: AccountRequirementDocument) => req.type === MilestoneRequirement.activationPackage,
    );
    const rejectedRequirements = activationPackage?.requirements
      .filter((req: AccountRequirementDocument) => req.status === AccountRequirementStatus.rejected)
      .map((rejectedRequirement: AccountRequirementDocument) => rejectedRequirement.name);
    return rejectedRequirements;
  }, [activationMilestone]);

  const domesticContentAdditionalRejectionContext: string | undefined = useMemo(() => {
    if (!domesticContentQueue || domesticContentQueue?.status !== QueueStatus.rejected) {
      return;
    }
    return SpecificLanguageWhenDomesticContentIsRejected;
  }, [installMilestone]);

  const { claims } = useAuth();

  const documentFilters = useMemo(() => {
    let documentFilters: any[] = isPalmettoFinanceUser(claims)
      ? [...DocumentTypesArr, BillingDocumentTypes.incomingCedInvoice]
      : DocumentTypesArr;

    if (account?.programType === ProgramType.doePr) {
      documentFilters = [...documentFilters, ...DoePrDocumentTypesArr];
    }
    if (account?.programType === ProgramType.hvac) {
      documentFilters = [...documentFilters, ...HVACDocumentTypesArr];
    }

    return documentFilters;
  }, [account, claims]);

  const milestonesCompleted = getPreviousCompletedMilestones(account?.milestones);
  const latestCompletedMilestone = milestonesCompleted.length > 0 ? getMostRecentObj(milestonesCompleted) as AccountMilestoneWithRequirements : {} as any;
  const mostRecentPackageRejectionInfo = activationQueue?.rejectionReasons
    ? getMostRecentObj(activationQueue?.rejectionReasons).rejectionInfo
    : [];
  const noRejectionOnPackage = !noticeToProceedRejections?.length &&
    !installRejections?.length &&
    !activationRejections?.length;
  const currentPackage = getPackageObjectFromRequirements(account?.currentMilestone?.requirements);
  const previousPackage = getPackageObjectFromRequirements(latestCompletedMilestone?.requirements);
  const isNonHvac = account?.programType !== ProgramType.hvac;
  const showPackageCompletionBanner = noRejectionOnPackage &&
    currentPackage?.status === AccountRequirementStatus.pending &&
    flagDrivenPackageReview;

  return (
    <Box childGap="lg">
      {currentPackage && showPackageCompletionBanner && (
        <MilestoneCompletionBanner
          completedPackage={{ type: previousPackage?.type, name: previousPackage?.name } as unknown as { type: Packages, name: string }}
          currentPackage={{ type: currentPackage.type, name: currentPackage?.name } as unknown as { type: Packages, name: string }}
          completedAt={previousPackage?.completedAt}
        />
      )}
      {noticeToProceedRejections && noticeToProceedRejections.length ? (
        flagDrivenPackageReview ? <RejectionBannerV2
          key="noticeToProceed-rejected-banner"
          milestone={{ type: MilestoneType.noticeToProceed, name: "Notice to Proceed Package" }}
          completedAt={latestCompletedMilestone.completedAt}
        /> :
          <RejectionBanner
            key="noticeToProceed-rejected-banner"
            milestone={MilestoneType.noticeToProceed}
            rejections={noticeToProceedRejections}
          />
      ) : undefined}
      {installRejections && installRejections.length ? (
        flagDrivenPackageReview ? <RejectionBannerV2
          key="install-rejected-banner"
          milestone={{ type: MilestoneType.install, name: "Installation Package" }}
          customViewPathLink={
            domesticContentAdditionalRejectionContext
              ? `/accounts/${id}/domestic-content-value-confirmation`
              : undefined
          }
          flaggedItems={installationQueue?.flags}
          completedAt={latestCompletedMilestone.completedAt}
        /> : <RejectionBanner
          key="install-rejected-banner"
          milestone={MilestoneType.install}
          rejections={installRejections}
          additionalRejectionContext={domesticContentAdditionalRejectionContext}
          customViewPathLink={
            domesticContentAdditionalRejectionContext
              ? `/accounts/${id}/domestic-content-value-confirmation`
              : undefined
          }
        />
      ) : null}
      {activationRejections && activationRejections.length ? (
        flagDrivenPackageReview ? <RejectionBannerV2
          key="activation-rejected-banner"
          milestone={{ type: MilestoneType.activation, name: "Activation Package" }}
          rejectionInfo={mostRecentPackageRejectionInfo}
          completedAt={latestCompletedMilestone.completedAt}
        /> : <RejectionBanner
          key="activation-rejected-banner"
          milestone={MilestoneType.activation}
          rejections={activationRejections}
          rejectionInfo={mostRecentPackageRejectionInfo}
        />
      ) : null}
      <AccountMilestones />
      <Box
        direction={{ base: 'column', desktop: 'row' }}
        alignItems="flex-start"
        childGap={{ base: 'lg', desktop: '3xl' }}
        width="100"
      >
        <Box width={{ base: '100', desktop: '30' }}>
          <AccountDetails />
          <Box direction="row" gap="sm">
            {enableCancelAccountButton && <CancelAccount />}
            {adminStipulations && <AdminStipulation />}
          </Box>
        </Box>
        <Box width={{ base: '100', desktop: '70' }} childGap="lg">
          <Tasks />
          <Box>
            <Underwriting />
          </Box>
          <Quotes />
          <Contracts />

          <Documents
            documentTypeFilter={documentFilters}
            additionalDocumentsToFetch={[
              {
                types: InstallDocumentTypesArr,
                status: [DocumentStatus.approved],
              },
              {
                types: NTPPackageDocumentTypes,
                status: [DocumentStatus.approved],
              },
              { types: DeprecatedDocumentTypesArr },
            ]}
          />
          {account?.programType === ProgramType.hvac && <NTPMilestonePackageContainer />}
          <InstallMilestonePackageContainer />
          {viewActivationMilestonePackage && isNonHvac && <ActivationMilestonePackageContainer />}
          {account?.programType === ProgramType.doePr && <PostActivationMilestonePackageContainer />}
        </Box>
      </Box>
      <DirectPayOptInOutModal
        isModalOpen={isDirectPayOptInModalOpen}
        handleCloseModal={closeDirectPayOptInModal}
        type="optIn"
        accountId={id}
      />
    </Box>
  );
};

export default Overview;
