import { ObjectId } from 'mongodb';
import { ProgramType } from './ProgramTypes';
import { AttestmentData, DomesticContentAttestmentData } from './Attestment';

export enum PanelOrientation {
  landscape = 'landscape',
  portrait = 'portrait',
  combination = 'combination',
}

export enum MountingType {
  ground = 'ground',
  roof = 'roof',
  combination = 'combination',
}

export interface MonthlyProduction {
  month: number;
  productionKwh: number;
}

export interface MonthlySolarAccess {
  month: number;
  solarAccess: number;
}

export interface SystemAsset {
  manufacturer: string;
  model: string;
  count: number;
}

export interface SystemArray {
  panelManufacturer: string;
  panelModel: string;
  panelCount: number;
  panelWattage: number;
  orientation: PanelOrientation;
  tilt: number;
  azimuth: number;
  annualTSRF: number;
  size: number;
  monthlySolarAccess: {
    month: number;
    accessPercent: number;
  }[];
  inverter: string;
}

export interface ToleranceException {
  note?: string;
  exceptionGivenBy: string;
}

/** @deprecated */
interface SystemDesignDocumentDeprecated {
  /** @deprecated */
  inverterManufacturer?: string;
  /** @deprecated */
  inverterModel?: string;
  /** @deprecated */
  inverterCount?: number;
  /** @deprecated */
  mountingModel?: string;
  /** @deprecated */
  totalMountingCount?: number;
  /** @deprecated */
  storageManufacturer?: string;
  /** @deprecated */
  storageModel?: string;
  /** @deprecated */
  totalStorageCount?: number;
}

export interface SystemDesign extends SystemDesignDocumentDeprecated {
  accountId: ObjectId;
  isCurrent: boolean;
  isApproved?: boolean;
  systemFirstYearProductionKwh: number;
  systemSizeKw: number;
  inverters?: Partial<SystemAsset>[] | [];
  panelManufacturer?: string;
  panelModel?: string;
  totalPanelCount?: number;
  arrays?: Partial<SystemArray>[] | [];
  firstYearMonthlyProduction?: Partial<MonthlyProduction>[] | [];
  mountingType?: string;
  storage?: Partial<SystemAsset>[] | [];
  totalStorageCapacityKwh?: number;
  inverterManufacturer?: string;
  inverterModel?: string;
  inverterCount?: number;
  firstYearMonthlyProductionKwh?: Array<Partial<MonthlyProduction>> | [];
  mountingManufacturer?: string;
  dateCreated: Date;
  toleranceException?: ToleranceException | null;
  attestment?: AttestmentData | null;
  domesticContentAttestment?: DomesticContentAttestmentData | null;
  mountingHardware?: MountingHardware[] | [];
}

export interface MountingHardware {
  type: string;
  mountingHardwareModels: MountingHardwareSelectedOption[];
}

export interface MountingHardwareSelectedOption {
  value?: string;
  label?: string;
  manufacturer: string;
  category: string;
  sku: string;
}

export enum HVACEquipmentType {
  heatPump = 'heatPump',
  airHandler = 'airHandler',
  heatStrip = 'heatStrip',
  furnace = 'furnace',
  waterHeater = 'waterHeater',
  evaporatorCoil = 'evaporatorCoil',
  ductlessOutdoor = 'ductlessOutdoor',
  ductlessIndoor = 'ductlessIndoor',
  airConditioner = 'airConditioner',
  packagedAC = 'packagedAC',
  packagedHeatPump = 'packagedHeatPump',
  packagedGasAC = 'packagedGasAC',
  packagedDualFuel = 'packagedDualFuel',
  other = 'other',
}

export enum HVACAccessoryType {
  thermostat = 'thermostat',
  airCleaner = 'airCleaner',
  airFilter = 'airFilter',
  dehumidifier = 'dehumidifier',
  ductlessControls = 'ductlessControls',
  humidifier = 'humidifier',
  txvKit = 'txvKit',
  ventilator = 'ventilator',
  zoning = 'zoning',
  other = 'other',
  electronicAirCleaner = 'electronicAirCleaner',
  uvSystem = 'uvSystem',
  accessories = 'accessories',
}

export const HVACEquipmentTypeLabels: Record<HVACEquipmentType, string> = {
  [HVACEquipmentType.airHandler]: 'Air Handler',
  [HVACEquipmentType.heatPump]: 'Heat Pump',
  [HVACEquipmentType.heatStrip]: 'Heat Strip',
  [HVACEquipmentType.furnace]: 'Furnace',
  [HVACEquipmentType.waterHeater]: 'Water Heater',
  [HVACEquipmentType.evaporatorCoil]: 'Evaporator Coil',
  [HVACEquipmentType.ductlessOutdoor]: 'Ductless Outdoor',
  [HVACEquipmentType.ductlessIndoor]: 'Ductless Indoor',
  [HVACEquipmentType.airConditioner]: 'Air Conditioner',
  [HVACEquipmentType.packagedAC]: 'Packaged AC',
  [HVACEquipmentType.packagedHeatPump]: 'Packaged Heat Pump',
  [HVACEquipmentType.packagedGasAC]: 'Packaged Gas AC',
  [HVACEquipmentType.packagedDualFuel]: 'Packaged Dual Fuel',
  [HVACEquipmentType.other]: 'Other',
} as const;

export const HVACAccessoryLabels: Record<HVACAccessoryType, string> = {
  [HVACAccessoryType.thermostat]: 'Thermostat',
  [HVACAccessoryType.airCleaner]: 'Air Cleaner',
  [HVACAccessoryType.airFilter]: 'Air Filter',
  [HVACAccessoryType.dehumidifier]: 'Dehumidifier',
  [HVACAccessoryType.ductlessControls]: 'Ductless Controls',
  [HVACAccessoryType.humidifier]: 'Humidifier',
  [HVACAccessoryType.txvKit]: 'TXV Kit',
  [HVACAccessoryType.ventilator]: 'Ventilator',
  [HVACAccessoryType.zoning]: 'Zoning',
  [HVACAccessoryType.other]: 'Other',
  [HVACAccessoryType.electronicAirCleaner]: 'Electronic Air Cleaner',
  [HVACAccessoryType.uvSystem]: 'UV System',
  [HVACAccessoryType.accessories]: 'Accessories',
} as const;

/** @deprecated */
export interface LegacyHVACEquipmentDetails {
  type: HVACEquipmentType;
  name: string;
  manufacturer: string;
  model: string;
  quantity: number;
  cost?: number;
  productId?: string;
  serialNumbers?: string[];
}

/** @deprecated */
export interface LegacyHVACInstallKitDetails {
  name: string;
  quantity: number;
  cost?: number;
}

/** @deprecated */
export interface LegacyHVACServiceDetails {
  name: string;
  cost?: number;
  quantity: number;
}

/** @deprecated */
export interface HVACDiscountDetails {
  name: string;
  cost: number;
}

/** @deprecated */
export interface LegacyHVACSystemDesign {
  accountId: ObjectId;
  isCurrent: boolean;
  equipment: LegacyHVACEquipmentDetails[];
  installKits?: LegacyHVACInstallKitDetails[];
  services?: LegacyHVACServiceDetails[];
  discounts?: HVACDiscountDetails[];
  ahriNumber?: string;
}

export interface HVACEquipmentItem {
  type: HVACEquipmentType;
  name: string;
  manufacturer: string;
  model: string;
  quantity: number;
  productId?: string;
  serialNumbers?: string[];
}

export interface HVACAccessoryItem extends Omit<HVACEquipmentItem, 'type'> {
  type: HVACAccessoryType;
}

export interface HVACSitePrepItem {
  name: string;
}

export interface HVACDiscountItem {
  name: string;
}

export enum HVACSystemCategory {
  'Conventional Ducted Split System' = 'Conventional Ducted Split System',
  'Conventional Ducted System' = 'Conventional Ducted System',
  'Package' = 'Package',
  'Ductless Mini Split' = 'Ductless Mini Split',
  'Ducted Mini Split' = 'Ducted Mini Split',
  'Multiple Systems' = 'Multiple Systems',
}

export interface HVACSystem {
  systemCategory: HVACSystemCategory;
  conditionedArea: number;
  name?: string;
  equipment: { items: HVACEquipmentItem[]; totalCost?: number };
  accessories?: { items: HVACAccessoryItem[]; totalCost?: number };
  sitePrep?: { items: HVACSitePrepItem[]; totalCost?: number };
  discounts?: { items: HVACDiscountItem[]; totalCost?: number };
  ahriNumber?: string;
}

export interface HVACSystemDesign {
  accountId: ObjectId;
  isCurrent: boolean;
  systems: HVACSystem[];
}

export type SystemDesignReturnType<T extends ProgramType> = T extends ProgramType.hvac
  ? HVACSystemDesign
  : SystemDesign;

export enum DomesticContentMountingHardwareType {
  fastenerAttachment = 'fastenerAttachment',
  fastenerClamp = 'fastenerClamp',
  fastenerOther = 'fastenerOther',
  fastenerSplice = 'fastenerSplice',
  fastenerMlpe = 'fastenerMlpe',
  rail = 'rail',
}

export const DomesticContentMountingHardwareLabels: Record<DomesticContentMountingHardwareType, string> = {
  [DomesticContentMountingHardwareType.rail]: 'Rail',
  [DomesticContentMountingHardwareType.fastenerClamp]: 'Fastener - Clamp',
  [DomesticContentMountingHardwareType.fastenerAttachment]: 'Fastener - Attachment',
  [DomesticContentMountingHardwareType.fastenerSplice]: 'Fastener - Splice',
  [DomesticContentMountingHardwareType.fastenerMlpe]: 'Fastener - MLPE',
  [DomesticContentMountingHardwareType.fastenerOther]: 'Fastener - Other',
} as const;
