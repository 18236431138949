import { Helmet } from 'react-helmet';
import {
  generatePath,
  matchPath,
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Box } from '@palmetto/palmetto-components';
import PageHeader from '../PageHeader';
import { MainContainer } from '../MainContainer';
import { RequirementFlagSettings } from './RequirementFlagSettings';
import { useEffect } from 'react';

const baseRoute = '/admin/quality-control/requirement-flag-settings';
const routes = {
  requirementReviewSettings: 'requirement-flag-settings/reviewSetting?',
};

export function QualityControlSettings() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    const { setting = '' } = params;
    const pathPattern =
      Object.values(routes).find((pattern: string) => matchPath(`${baseRoute}/${pattern}`, location.pathname)) || '';
    const path = generatePath(`${baseRoute}/${pathPattern}`, { ...params, setting });
    navigate(path);
  }, []);

  return (
    <>
      <Helmet>
        <title>Quality Control</title>
      </Helmet>
      <MainContainer maxWidth="100">
        <PageHeader title="Quality Control"></PageHeader>
        <Box direction="row" borderWidth="0 0 xs 0" borderColor="separator">
          <NavLink to={''} style={{ textDecoration: 'none', fontWeight: '500' }} end>
            {({ isActive }) => (
              <Box
                as="span"
                padding="md"
                className={isActive ? 'tab-active' : ''}
                hover={{ color: 'primary' }}
                style={{ transitionDuration: '0s' }}
              >
                Requirement Flag Settings
              </Box>
            )}
          </NavLink>
        </Box>
        <Routes>
          <Route path={''} element={<RequirementFlagSettings />} />
        </Routes>
      </MainContainer>
    </>
  );
}
