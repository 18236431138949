import { FieldArray, Field, useFormikContext } from 'formik';
import FormSection from '@/components/Forms/FormSection';
import { Box, Button, FormikTextInput, SimulatedEventPayloadType, TextInput } from '@palmetto/palmetto-components';
import { HVACDiscountItem, UserPermissions } from 'types';
import usePermissions from '@/hooks/usePermissions';

const DiscountsSection = ({
  systemIndex,
  isInstallPackage = false,
}: {
  systemIndex: number;
  isInstallPackage: boolean;
}) => {
  const permissions = usePermissions();
  const userOnlyHasReaderPermission = permissions?.every((permission) =>
    permission.includes(UserPermissions.reader),
  );
  const { values, setFieldValue } = useFormikContext<any>();
  return (
    <FormSection
      title="Discounts"
      description={
        <Box gap="sm" fontSize="xs">
          <Box>List all that apply</Box>
        </Box>
      }
      baseStyle={{
        borderWidth: '0',
      }}
    >
      <FieldArray
        name={`systems.${systemIndex}.discounts.items`}
        render={(arrayHelpers) => (
          <Box gap="xl" alignSelf="flex-end">
            {values?.systems[systemIndex]?.discounts?.items && values?.systems[systemIndex]?.discounts?.items.length
              ? values?.systems[systemIndex]?.discounts?.items.map((_discount: HVACDiscountItem, index: number) => (
                  <Box
                    key={`system-${systemIndex}-discount-${index}`}
                    direction="row"
                    gap="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box direction="column" gap="sm" width="512px">
                      <Field
                        label={`Discount ${index + 1}`}
                        name={`systems.${systemIndex}.discounts.items.${index}.name`}
                        id={`systems.${systemIndex}.discounts.items.${index}.name`}
                        component={FormikTextInput}
                        placeholder="Enter Discount Name"
                        isDisabled={userOnlyHasReaderPermission}
                      />
                    </Box>
                    <Box width="lg"></Box>
                    <Box direction="column" gap="lg" width="xl" position="relative"></Box>
                    <Box padding="lg 0 0 0">
                      <Button
                        as="button"
                        variant="tertiary"
                        tone="danger"
                        isDisabled={userOnlyHasReaderPermission}
                        onClick={() => {
                          arrayHelpers.remove(index);
                          if (values?.systems[systemIndex]?.discounts?.items?.length === 1) {
                            setFieldValue(`systems.${systemIndex}.discounts.totalCost`, 0);
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  </Box>
                ))
              : null}
            <Box alignItems="flex-start">
              <Button
                as="button"
                variant="secondary"
                size="sm"
                iconPrefix="add"
                isDisabled={userOnlyHasReaderPermission}
                onClick={() =>
                  arrayHelpers.push({
                    name: '',
                    quantity: 1,
                  })
                }
              >
                Add Discount
              </Button>
            </Box>
            {values?.systems[systemIndex]?.discounts?.items?.length ? (
              <Box alignSelf="flex-end">
                <TextInput
                  label="Total Discount"
                  name={`systems.${systemIndex}.discounts.totalCost`}
                  id={`systems.${systemIndex}.discounts.totalCost`}
                  value={values?.systems[systemIndex]?.discounts?.totalCost}
                  type="number"
                  prefix="- $"
                  width="xl"
                  isDisabled={userOnlyHasReaderPermission || isInstallPackage}
                  onFocus={(event: SimulatedEventPayloadType) => {
                    const value = parseFloat(event.target.value);
                    if (event.target.value === '0') {
                      setFieldValue(`systems.${systemIndex}.discounts.totalCost`, '');
                    } else {
                      setFieldValue(`systems.${systemIndex}.discounts.totalCost`, isNaN(value) ? 0 : value);
                    }
                  }}
                  onBlur={(event: SimulatedEventPayloadType) => {
                    const value = parseFloat(event.target.value);
                    setFieldValue(`systems.${systemIndex}.discounts.totalCost`, isNaN(value) ? 0 : value);
                  }}
                  onChange={(event: SimulatedEventPayloadType) => {
                    const value = event.target.value;
                    setFieldValue(`systems.${systemIndex}.discounts.totalCost`, `${value}`);
                  }}
                />
              </Box>
            ) : null}
          </Box>
        )}
      />
    </FormSection>
  );
};

export default DiscountsSection;
