import { useState } from 'react';
import { DateTime } from 'luxon';
import { DateInput } from '@palmetto/palmetto-components';
import { DataFilterOperation } from './FilterButton';
import { FilterChange } from './FilterButton';

export const DateInputFilter = ({
  onFilterChange,
  appliedFilterId,
  defStartDate,
  defEndDate,
}: {
  onFilterChange: (change: FilterChange) => void;
  appliedFilterId: string;
  defStartDate?: Date;
  defEndDate?: Date;
}) => {
  const defaultStartDate = defStartDate ? defStartDate : DateTime.now().minus({ days: 2 }).toJSDate();
  const defaultEndDate = defEndDate ? defEndDate : DateTime.now().plus({ day: 2 }).endOf('day').toUTC().toJSDate();
  const [startDate, setStartDate] = useState<Date | null>(defaultStartDate || null);
  const [endDate, setEndDate] = useState<Date | null>(defaultEndDate || null);

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const setDate = ([startDate, endDate]: [Date | null, Date | null]) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <DateInput
      datePickerProps={{
        onChange: (date) => {
          setDate(date as [Date | null, Date | null]);
          if ((date as Date[])[0] && (date as Date[])[1]) {
            onFilterChange({
              filterId: appliedFilterId,
              selectedValues: (date as Date[]).map((element) => element.toISOString()),
              operation: DataFilterOperation.InRange,
            });
          }
        },
        selected: startDate,
        selectsRange: true,
        startDate: startDate,
        endDate: endDate,
      }}
      textInputProps={{
        onClear: handleClear,
        id: 'rangePickerDateForFilter',
        name: 'rangePickerDateForFilter',
        label: 'Select Date Range',
      }}
    />
  );
};
