import { Table } from '@palmetto/palmetto-components';
import { PaymentPlanHistory, PayoutEventMap } from 'types';
import DateTimeDisplay from '../DateTime';

const columns = [
  {
    heading: 'Name',
    dataKey: 'paymentPlanName',
    render: (cell: any) => {
      return (
        <div>
          {cell}
        </div>
      );
    }
  },
  {
    heading: 'Payment Events',
    dataKey: 'paymentSchedule',
    render: (cell: any, row: any) => {
      return cell.map((event: any) => {
        return (
          <div key={event.event}>
            {PayoutEventMap[row.programType as keyof typeof PayoutEventMap].find(e => e.event === event.event)?.name} -  {event?.paymentPercent ? `${event.paymentPercent * 100}%` : '-'}
          </div>
        );
      });
    }
  },
  {
    heading: 'Effective Date',
    dataKey: 'effectiveDate',
    render: (cell: any) => {
      return (
        <div>
          <DateTimeDisplay value={cell} showDuration={false} fontSize="xs" dateFormat="DD h:mm:ss a" inline />
        </div>
      );
    }
  },
  {
    heading: 'Created By',
    dataKey: 'setBy',
    render: (cell: any) => {
      return (
        <div>
          {cell?.user?.username ? cell?.user?.username : 'LightReach'}
        </div>
      );
    }
  }
];

export const PaymentPlanHistoryTable = (
  paymentPlans
    : { paymentPlans: PaymentPlanHistory[] }) => {
  return paymentPlans ? <Table rowKey="id" columns={columns} rows={paymentPlans.paymentPlans} align="left"
    isScrollable={{
      x: false,
      y: true,
    }} /> : null;
}; 