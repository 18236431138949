import { Box, Button, Card, Column, Table, useBreakpoint, useOpenClose } from '@palmetto/palmetto-components';
import { useParams } from 'react-router-dom';
import {
  AdderType,
  ApprovedVendorSettingValue,
  ConstructionAdderType,
  ProgramType,
  VendorEquipmentTypes,
  adderMap,
  UserPermissions,
} from 'types';
import { useGetConstructionAddersQuery } from '../../../services/pricing';
import { useCallback, useMemo, useState } from 'react';
import { currencyFormatter, kwhPriceFormatter } from '../../../helpers/currencyFormatter';
import { CreateKwhAdderModal } from './CreateKwhAdderModal';
import RequirePalmettoFinanceUser from '../../auth/RequirePalmettoFinanceUser';
import RequirePermissions from '../../auth/requirePermissions';
import { EditAdderModal } from './EditAdderModal';
import { CreateCostAdderModal } from './CreateCostAdderModal';
import { useGetApprovedVendorsByTypeQuery } from '../../../services/approvedVendors';
import { CreateBatteryPriceCapModal } from './CreateBatteryPriceCapModal';
import { CreateMaxAmountAdderModal } from './CreateMaxAmountAdderModal';

export const Adder = () => {
  const { isPhone } = useBreakpoint();
  const [selected, setSelected] = useState<any>(null);
  const { programType = ProgramType.solar, adderType } = useParams<{ programType: string; adderType: string }>();
  const {
    data = [],
    isLoading,
    isFetching,
  } = useGetConstructionAddersQuery({ type: adderType as string, programType }, { skip: !adderType });
  const { data: avlStorage } = useGetApprovedVendorsByTypeQuery({
    equipmentType: VendorEquipmentTypes.storage,
    programType: programType as ProgramType,
  });
  const { isOpen: isEditModalOpen, handleOpen: openEditModal, handleClose: closeEditModal } = useOpenClose();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const handleClick = useCallback(
    (row: any) => {
      setSelected(row);
      openEditModal();
    },
    [openEditModal],
  );

  const columnConfig = useMemo(() => {
    switch (adderType) {
      case ConstructionAdderType.arbitrageBatteryPriceCap:
      case ConstructionAdderType.backupBatteryPriceCap:
        return [
          {
            heading: 'Name',
            dataKey: 'name',
            render: (_: any, row: any) => (
              <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClick(row)}>
                {row.name}
              </span>
            ),
          },
          // rest of columns come from avl batteries
          ...(avlStorage?.data?.filter((storage: ApprovedVendorSettingValue) => {
              const isBackup = adderType === ConstructionAdderType.backupBatteryPriceCap;
              return isBackup ? storage.backup : storage.arbitrage;
            })
            .map((storage: ApprovedVendorSettingValue) => ({
            heading: (
              <Box>
                <Box>{storage.manufacturer}</Box>
                <Box>{storage.modelNumber}</Box>
              </Box>
            ),
            dataKey: storage.id,
            render: (_: any, row: any) => {
              const data =
                adderType === ConstructionAdderType.backupBatteryPriceCap
                  ? row?.backupBatteryPriceCaps
                  : row?.arbitrageBatteryPriceCaps;
              return `$${data?.find((b: any) => b.batteryId === storage.id)?.maxPrice ?? ''}`;
            },
          })) ?? []),
        ];
      case AdderType.backupBattery:
      case AdderType.arbitrageBatteryCost:
        switch (programType) {
          case ProgramType.doePr:
            return [
              {
                heading: 'Name',
                dataKey: 'name',
                render: (_: any, row: any) => (
                  <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClick(row)}>
                    {row.name}
                  </span>
                ),
              },
              {
                heading: '0% ESC',
                dataKey: 'paymentFactors',
                render: (paymentFactors: any) => {
                  const record = paymentFactors?.find((pf: any) => pf.escalationRate === 0);
                  return record?.value ?? '';
                },
              },
              {
                heading: '1% ESC',
                dataKey: 'paymentFactors',
                render: (paymentFactors: any) => {
                  const record = paymentFactors?.find((pf: any) => pf.escalationRate === 0.01);
                  return record?.value ?? '';
                },
              },
            ];
          default:
            return [
              {
                heading: 'Name',
                dataKey: 'name',
                render: (_: any, row: any) => (
                  <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClick(row)}>
                    {row.name}
                  </span>
                ),
              },
              {
                heading: '0% ESC',
                dataKey: 'paymentFactors',
                render: (paymentFactors: any) => {
                  const record = paymentFactors?.find((pf: any) => pf.escalationRate === 0);
                  return record?.value ?? '';
                },
              },
              {
                heading: '0.99% ESC',
                dataKey: 'paymentFactors',
                render: (paymentFactors: any) => {
                  const record = paymentFactors?.find((pf: any) => pf.escalationRate === 0.0099);
                  return record?.value ?? '';
                },
              },
              {
                heading: '1.99% ESC',
                dataKey: 'paymentFactors',
                render: (paymentFactors: any) => {
                  const record = paymentFactors?.find((pf: any) => pf.escalationRate === 0.0199);
                  return record?.value ?? '';
                },
              },
              {
                heading: '2.99% ESC',
                dataKey: 'paymentFactors',
                render: (paymentFactors: any) => {
                  const record = paymentFactors?.find((pf: any) => pf.escalationRate === 0.0299);
                  return record?.value ?? '';
                },
              },
              {
                heading: '3.5% ESC',
                dataKey: 'paymentFactors',
                render: (paymentFactors: any) => {
                  const record = paymentFactors?.find((pf: any) => pf.escalationRate === 0.035);
                  return record?.value ?? '';
                },
              },
            ];
        }

      case AdderType.solarReadiness:
      default:
        return [
          {
            heading: 'Name',
            dataKey: 'name',
            render: (_: any, row: any) => (
              <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleClick(row)}>
                {row.name}
              </span>
            ),
          },
          { heading: '$/kwh', dataKey: 'kwhAdderBand', render: (cell: any) => kwhPriceFormatter.format(cell) },
        ];
    }
  }, [adderType, avlStorage, handleClick]);

  const renderAdderModal = () => {
    switch (adderType) {
      case AdderType.backupBattery:
      case AdderType.arbitrageBatteryCost: {
        return <CreateCostAdderModal isModalOpen={isModalOpen} closeModal={closeModal} adderType={adderType} />;
      }
      case AdderType.backupBatteryPriceCap:
      case AdderType.arbitrageBatteryPriceCap: {
        return (
          <CreateBatteryPriceCapModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            adderType={adderType}
            programType={programType as ProgramType}
          />
        );
      }
      case AdderType.solarReadiness: {
        return (
          <CreateMaxAmountAdderModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            adderType={adderType}
            programType={programType as ProgramType}
          />
        );
      }
      default: {
        return (
          <CreateKwhAdderModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            adderType={adderType}
            programType={programType as ProgramType}
          />
        );
      }
    }
  };

  return (
    <>
      {renderAdderModal()}
      <EditAdderModal
        isModalOpen={isEditModalOpen}
        closeModal={closeEditModal}
        adderId={selected?.id}
        adderName={selected?.name}
      />
      <Card padding="sm">
        <Card.Header>
          <Box direction="row" justifyContent="space-between">
            <Box fontSize="md" fontWeight="medium">
              {adderMap[programType as ProgramType][adderType as AdderType]}
            </Box>
            <RequirePalmettoFinanceUser>
              <RequirePermissions permissions={[UserPermissions.pricingAdmin]}>
                <Button
                  as="button"
                  variant="primary"
                  iconPrefix="add"
                  size={{ base: 'sm', desktop: 'md' }}
                  aria-label="create adder"
                  onClick={openModal}
                >
                  {!isPhone && 'Option'}
                </Button>
              </RequirePermissions>
            </RequirePalmettoFinanceUser>
          </Box>
        </Card.Header>
        <Table
          rowKey="id"
          columns={columnConfig as Column[]}
          rows={data}
          isLoading={isLoading || isFetching}
          isScrollable={{ x: true }}
        />
      </Card>
    </>
  );
};
