import { useEditAccountDirectPayMutation } from '@/services/accounts';
import { isErrorWithData } from '@/services/helpers';
import { Box, Button, Modal, toast } from '@palmetto/palmetto-components';

export const DirectPayOptInOutModal = ({
  isModalOpen,
  handleCloseModal,
  type,
  accountId,
}: {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  type: 'optIn' | 'optOut';
  accountId: string;
}) => {
  const [editDirectPay, { isLoading }] = useEditAccountDirectPayMutation();

  const updateDirectPay = async () => {
    try {
      await editDirectPay({
        enabled: type === 'optIn',
        provider: 'ced',
        id: accountId,
      }).unwrap();
      toast.success('Direct Pay updated successfully');
      handleCloseModal();
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(`Error updating direct pay: ${errorMessage}`);
      } else {
        console.error(e);
        toast.error('Error updating direct pay');
      }
    }
  };
  return (
    <Modal ariaLabelledBy="titleBasic" isOpen={isModalOpen} onDismiss={handleCloseModal} maxWidth="5xl">
      <Modal.Header
        id="titleBasic"
        title={`Opt ${type === 'optIn' ? 'In' : 'Out'} of Direct Pay`}
        onDismiss={handleCloseModal}
      />
      <Modal.Body childGap="md" background="secondary">
        <Box>Are you sure you want to opt this account {type === 'optIn' ? 'in to' : 'out of'} Direct Pay?</Box>
      </Modal.Body>
      <Modal.Footer justifyContent="space-between">
        <Button onClick={handleCloseModal} variant="secondary" tone="neutral">
          Cancel
        </Button>
        <Button
          variant="primary"
          className="shadow-xs"
          onClick={updateDirectPay}
          style={{ whiteSpace: 'nowrap' }}
          isDisabled={isLoading}
          isLoading={isLoading}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
