import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { AdderSection } from './AdderSection';
import { BatteryInputs } from './BatteryInputs';
import { Box, Button } from '@palmetto/palmetto-components';
import { FlagBox } from '../QueueFlags/FlagBox';
import { AdderType, ProgramType, RequirementFlagSettingsType } from 'types';
import classNames from 'classnames';

interface AddersListProps {
  selectedAdders: AdderType[];
  isLoading: boolean;
  enableFlags?: boolean;
  oldQuoteFlag?: boolean;
  programType: ProgramType;
}

interface AddModelButtonProps {
  values: Record<string, any>;
  type: AdderType;
  setFieldValue: (field: string, value: any) => void;
}

const AddModelButton: React.FC<AddModelButtonProps> = ({ values, type, setFieldValue }) => {
  return (
    <Button
      onClick={() => {
        setFieldValue(type, [
          ...values?.[type],
          {
            type,
            manufacturer: values?.[type]?.[0]?.manufacturer || '',
            model: '',
            quantity: '',
          },
        ]);
      }}
      variant="secondary"
      tone="neutral"
      size="sm"
      iconPrefix="add"
      className={classNames({
        'shadow-xs': true,
      })}
      aria-label="add battery"
      style={{ width: 'fit-content', marginLeft: 'auto' }}
    >
      Add Model
    </Button>
  );
};

export const AddersList = ({
  selectedAdders,
  isLoading,
  enableFlags = false,
  oldQuoteFlag = false,
  programType,
}: AddersListProps) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const selectedAddersMap = useMemo(
    () =>
      selectedAdders?.reduce(
        (acc, adder) => {
          acc[adder] = true;
          return acc;
        },
        {} as Record<AdderType, boolean>,
      ),
    [selectedAdders],
  );
  const sectionProps = {
    [AdderType.arbitrageBattery]: {
      label: 'Arbitrage Battery',
      children: oldQuoteFlag ? (
        <Box fontWeight="bold" fontSize="sm">
          Flag no longer applicable because the adder was removed.
        </Box>
      ) : (
        <>
          {values?.[AdderType.arbitrageBattery]?.map((_: any, index: number) => (
            <BatteryInputs
              key={index}
              index={index}
              fieldName={AdderType.arbitrageBattery}
              isLoading={isLoading}
              programType={programType}
            />
          ))}
          <AddModelButton values={values} type={AdderType.arbitrageBattery} setFieldValue={setFieldValue} />
        </>
      ),
    },
    [AdderType.backupBattery]: {
      label: 'Backup Battery',
      children: oldQuoteFlag ? (
        <Box fontWeight="bold" fontSize="sm">
          Flag no longer applicable because the adder was removed.
        </Box>
      ) : (
        <>
          {values?.[AdderType.backupBattery]?.map((_: any, index: number) => (
            <BatteryInputs
              key={index}
              index={index}
              fieldName={AdderType.backupBattery}
              isLoading={isLoading}
              programType={programType}
            />
          ))}
          <AddModelButton values={values} type={AdderType.backupBattery} setFieldValue={setFieldValue} />
        </>
      ),
    },
    [AdderType.electricalUpgrade]: {
      label: 'Electrical Upgrade',
      children: oldQuoteFlag ? (
        <Box fontWeight="bold" fontSize="sm">
          Flag no longer applicable because the adder was removed.
        </Box>
      ) : (
        <Box fontWeight="bold" fontSize="sm">
          Included
        </Box>
      ),
    },
    [AdderType.solarReadiness]: {
      label: 'Solar Readiness',
      children: oldQuoteFlag ? (
        <Box fontWeight="bold" fontSize="sm">
          Flag no longer applicable because the adder was removed.
        </Box>
      ) : (
        <Box fontWeight="bold" fontSize="sm">
          Included
        </Box>
      ),
    },
  };

  return (
    <>
      {selectedAddersMap?.arbitrageBattery && (
        <>
          {enableFlags ? (
            <FlagBox
              dataKey={sectionProps[AdderType.arbitrageBattery].label}
              type={RequirementFlagSettingsType.arbitrageBattery}
              displayWithNoQueue
              isLast={!(selectedAddersMap?.backupBattery || selectedAddersMap?.electricalUpgrade)}
            >
              {sectionProps[AdderType.arbitrageBattery].children}
            </FlagBox>
          ) : (
            <AdderSection title={sectionProps[AdderType.arbitrageBattery].label}>
              {sectionProps[AdderType.arbitrageBattery].children}
            </AdderSection>
          )}
        </>
      )}
      {selectedAddersMap?.backupBattery && (
        <>
          {enableFlags ? (
            <FlagBox
              dataKey={sectionProps[AdderType.backupBattery].label}
              type={RequirementFlagSettingsType.backupBattery}
              displayWithNoQueue
              isLast={!selectedAddersMap?.electricalUpgrade}
            >
              {sectionProps[AdderType.backupBattery].children}
            </FlagBox>
          ) : (
            <AdderSection title={sectionProps[AdderType.backupBattery].label}>
              {sectionProps[AdderType.backupBattery].children}
            </AdderSection>
          )}
        </>
      )}
      {selectedAddersMap?.electricalUpgrade && (
        <>
          {enableFlags ? (
            <FlagBox
              dataKey={sectionProps[AdderType.electricalUpgrade].label}
              type={RequirementFlagSettingsType.electricalUpgrade}
              displayWithNoQueue
            >
              {sectionProps[AdderType.electricalUpgrade].children}
            </FlagBox>
          ) : (
            <AdderSection title={sectionProps[AdderType.electricalUpgrade].label}>
              {sectionProps[AdderType.electricalUpgrade].children}
            </AdderSection>
          )}
        </>
      )}
      {selectedAddersMap?.solarReadiness && (
        <>
          {enableFlags ? (
            <FlagBox
              dataKey={sectionProps[AdderType.solarReadiness].label}
              type={RequirementFlagSettingsType.solarReadiness}
              displayWithNoQueue
            >
              {sectionProps[AdderType.solarReadiness].children}
            </FlagBox>
          ) : (
            <AdderSection title={sectionProps[AdderType.solarReadiness].label}>
              {sectionProps[AdderType.solarReadiness].children}
            </AdderSection>
          )}
        </>
      )}
    </>
  );
};
