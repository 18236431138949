import { useFormikContext } from 'formik';
import FormSection from '../Forms/FormSection';
import { RadioGroup } from '@palmetto/palmetto-components';
import { useMemo } from 'react';
import { percentageFormatter } from '@/helpers/percentageFormatter';
import { CreateQuoteFormikContext } from './CreateQuote';
import { useGetAccountPricing } from './hooks/useGetAccountPricing';

export const EscalationRates = () => {
  const { values, setFieldValue } = useFormikContext<CreateQuoteFormikContext>();
  const { escalationRates } = useGetAccountPricing(values);

  const escalationRadioButtonOptions = useMemo(() => {
    const labels = (escalationRates || [])?.map((rate) => ({
      label: percentageFormatter.format(rate),
      value: rate.toString(),
      id: rate.toString(),
    }));
    return labels.sort((a: any, b: any) => a.value - b.value);
  }, [escalationRates]);

  return (
    <FormSection title="Annual Escalation Rate">
      {escalationRadioButtonOptions?.length ? (
        <RadioGroup
          name="escalationRate"
          value={values.selectedEscalationRate?.toString() || escalationRates[0].toString()}
          onChange={(event) => {
            setFieldValue('productId', undefined);
            setFieldValue('selectedEscalationRate', Number(event.target.value));
          }}
          options={escalationRadioButtonOptions}
        />
      ) : null}
    </FormSection>
  );
};
