import { FlagBox } from '@/components/QueueFlags/FlagBox';
import { Box, Button, FormikSelectInput, SimulatedEventPayloadType } from '@palmetto/palmetto-components';
import { FieldArray, Field, useFormikContext } from 'formik';
import { useMemo, useState } from 'react';
import { DomesticContentMountingHardwareLabels, RequirementFlagSettingsType } from 'types';

const DomesticContentMountingEquipSelectionSection = ({
  manufacturerOptions,
  userOnlyHasReadPermissions = false,
  userIsEditing = false,
}: any) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);

  const productSelectionOptions = useMemo(() => {
    return manufacturerOptions.filter((item: any) => item.category === values.newMountingHardwareType);
  }, [manufacturerOptions, values?.newMountingHardwareType]);

  const mountingHardwareOptions = useMemo(() => {
    const mappedMountingHardwareOptions = Object.keys(DomesticContentMountingHardwareLabels).map((key) => ({
      label: DomesticContentMountingHardwareLabels[key as keyof typeof DomesticContentMountingHardwareLabels],
      value: key,
    }));

    const existingMountingHardwareSelections =
      values?.mountingHardware?.map((hardwareType: any) => hardwareType.type) || [];

    return mappedMountingHardwareOptions.filter(
      (mountingHardwareOption) => !existingMountingHardwareSelections.includes(mountingHardwareOption.value),
    );
  }, [values?.mountingHardware]);

  return (
    <FieldArray
      name="mountingHardware"
      render={(arrayHelpers) => {
        return (
          <Box gap="lg">
            {values?.mountingHardware?.map?.((e: any, index: number) => {
              return (
                <Box
                  key={`${e.type}-${e.sku}-${index}`}
                  direction="row"
                  gap="lg"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box direction="column" gap="sm" width="512px">
                    <Box>Mounting Hardware</Box>
                    <Box
                      fontSize="md"
                      background="default"
                      borderWidth="xs"
                      borderColor="default"
                      padding="xs"
                      radius="md"
                    >
                      {
                        DomesticContentMountingHardwareLabels[
                          e.type as keyof typeof DomesticContentMountingHardwareLabels
                        ]
                      }
                    </Box>
                    <Box direction="row" gap="sm">
                      <Field
                        label={`Please add all ${DomesticContentMountingHardwareLabels[e.type as keyof typeof DomesticContentMountingHardwareLabels]} model numbers. Begin typing to search for a brand or model number`}
                        name={`mountingHardware.${index}.mountingHardwareModels`}
                        id={`mountingHardware.${index}.mountingHardwareModels`}
                        component={FormikSelectInput}
                        options={productSelectionOptions || []}
                        isMulti
                        isClearable
                        menuIsOpen={openDropdownIndex === index}
                        isDisabled={userOnlyHasReadPermissions}
                        onFocus={() => {
                          setFieldValue(`newMountingHardwareType`, values?.mountingHardware[index].type);
                          setOpenDropdownIndex(index);
                        }}
                        onMenuOpen={() => {
                          setOpenDropdownIndex(index);
                        }}
                        onMenuClose={() => {
                          setOpenDropdownIndex(null);
                        }}
                        onChange={(event: SimulatedEventPayloadType) => {
                          const selectedOption = event.target.value;

                          setFieldValue(`mountingHardware.${index}.mountingHardwareModels`, selectedOption);
                          // Explicitly keep the menu open after selection
                          setOpenDropdownIndex(index);
                          return true;
                        }}
                      />
                    </Box>
                  </Box>
                  <Box direction="column" gap="sm" flex="auto">
                    <Box direction="row" gap="sm" justifyContent="space-between">
                      <Box padding="lg 0 0 0">
                        <Button
                          as="button"
                          variant="tertiary"
                          tone="danger"
                          isDisabled={userOnlyHasReadPermissions}
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          Delete
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
            {mountingHardwareOptions.length > 0 && (
              <Box width="512px">
                <Field
                  label="Add Mounting Hardware"
                  name="mountingHardware"
                  id="newMountingHardwareType"
                  placeholder="Select new mounting hardware type..."
                  component={FormikSelectInput}
                  options={mountingHardwareOptions}
                  isDisabled={userOnlyHasReadPermissions}
                  onChange={(event: SimulatedEventPayloadType) => {
                    const selectedOption = event.target.value.value;
                    arrayHelpers.push({
                      type: event.target.value.value,
                    });

                    setFieldValue(`newMountingHardwareType`, selectedOption);
                  }}
                />
              </Box>
            )}
            {userIsEditing && (
              <FlagBox
                baseCategory="mountingHardware"
                dataKey="Installed Mounting Hardware Issue"
                type={RequirementFlagSettingsType.mountingHardware}
              />
            )}
          </Box>
        );
      }}
    />
  );
};

export default DomesticContentMountingEquipSelectionSection;
