import { AdderDetails, AdderType, AdderValues } from 'types';

export const mapAddersValuesFromDocument = (adders?: AdderDetails[], selectedAdders?: AdderType[]): AdderValues => {
  return (
    selectedAdders?.reduce((acc, selectedAdder) => {
      const matchedAdders = adders?.filter((adder) => adder.type === selectedAdder);

      if (matchedAdders?.length) {
        acc[selectedAdder] = matchedAdders;
      } else {
        acc[selectedAdder] = [{ type: selectedAdder, manufacturer: '', model: '', quantity: 0 }];
      }
      return acc;
    }, {} as AdderValues) ?? ({} as AdderValues)
  );
};

const adderTypeToLabel: Partial<Record<AdderType, string>> = {
  [AdderType.arbitrageBattery]: 'Arbitrage Battery ($/kWh)',
  [AdderType.arbitrageBatteryCost]: 'Arbitrage Battery (cost)',
  [AdderType.arbitrageBatteryPriceCap]: 'Arbitrage Battery Price Caps',
  [AdderType.backupBattery]: 'Backup Battery (cost)',
  [AdderType.backupBatteryPriceCap]: 'Backup Battery Price Caps',
  [AdderType.backupBatteryKwh]: 'Backup Battery ($/kWh)',
  [AdderType.electricalUpgrade]: 'Electrical Upgrade',
  [AdderType.solarReadiness]: 'Solar Readiness',
};

export const getAdderTypeLabel = (type: AdderType) => {
  return adderTypeToLabel[type];
};
