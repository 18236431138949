import { Box, Details, Icon, useOpenClose } from '@palmetto/palmetto-components';
import { AccountStipulationType, AccountMilestoneStipulationDocument } from 'types';
import { stipulationTypeToLabel } from '../../Account/PauseBanner';

import EventBy from './EventBy';

const StipulationDetails: React.FC<{
  reason: string;
  isOpen: boolean;
  handleToggle: () => void;
  isSatisfied: boolean;
  isSatisfiedNote?: string;
  description?: string;
}> = ({ reason, isOpen, handleToggle, isSatisfied, isSatisfiedNote, description }) => (
  <Details
    isOpen={isOpen}
    margin="xs 0 0 0"
    display="block"
    background="primary"
    radius="md"
    borderWidth="xs"
    borderColor="separator"
    width="100"
  >
    <Details.Summary
      isDetailsOpen={isOpen}
      onToggle={handleToggle}
      justifyContent="space-between"
      display="flex"
      direction="row"
      alignItems="center"
      padding="md"
    >
      <Box>{reason}</Box>
      <Box fontSize="xs" direction="row" alignItems="center" childGap="xs" hover={{ color: 'body-primary' }}>
        <Icon name={isOpen ? 'caret-up' : 'caret-down'} />
      </Box>
    </Details.Summary>
    <Box padding="md" childGap="sm" borderWidth="xs 0 0 0" borderColor="separator">
      <Box display="block" childGap="xs">
        {isSatisfiedNote && (
          <Box display="block" childGap="xs">
            <Box as="span" fontWeight="bold">
              Satisfied Note:
            </Box>
            <Box as="div">{isSatisfiedNote}</Box>
          </Box>
        )}
        {!isSatisfied && description && (
          <Box display="block" childGap="xs">
            <Box as="span" fontWeight="bold">
              Description:
            </Box>
            <Box as="div">{description}</Box>
          </Box>
        )}
      </Box>
    </Box>
  </Details>
);

const AccountStipulation = ({ event }: { event: any }) => {
  const { isOpen, handleToggle } = useOpenClose();
  const eventData = event?.data || {};
  const { isSatisfied = false, stipulationType, description, reason, isSatisfiedNote } = eventData;

  const type = stipulationType as AccountStipulationType;
  const shouldShowDetails = (reason && description) || (reason && isSatisfiedNote);
  const stipulationLabel = stipulationTypeToLabel[type]?.toLowerCase() || '';

  return (
    <Box display="block" childGap="sm">
      <Box display="block">
        <Box as="span" display="inline-block" margin="0 2xs 0 0" childGap="md">
          <EventBy event={event} /> {isSatisfied ? 'cleared' : 'flagged'} {stipulationLabel}
        </Box>
      </Box>

      {shouldShowDetails && (
        <StipulationDetails
          reason={reason}
          isOpen={isOpen}
          handleToggle={handleToggle}
          isSatisfied={isSatisfied}
          isSatisfiedNote={isSatisfiedNote}
          description={description}
        />
      )}
    </Box>
  );
};

export default AccountStipulation;
