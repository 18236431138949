import { Box, Button, Icon, Modal } from '@palmetto/palmetto-components';

interface ChangePlanConfirmationModalProps {
  isOpen: boolean;
  isUpdating: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ChangePlanConfirmationModal = ({ isOpen, isUpdating, onClose, onConfirm }: ChangePlanConfirmationModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Confirm Payment Plan Change" onDismiss={onClose} maxWidth="4xl">
      <Modal.Body padding="0">
        <Box direction="row" childGap="sm" background="warning" padding="lg" maxWidth="5x1" alignItems="center">
          <Icon name="t-warning" size="xl" color="warning-500" />
          <Box textAlign="left">
            <Box fontSize="md" fontWeight="bold">
              Confirm Payment Plan Change
            </Box>
            <Box>Are you sure you want to change the payment plan?</Box>
          </Box>
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Box justifyContent="flex-end">
          <Box direction="row" childGap="sm">
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button isLoading={isUpdating} variant="primary" onClick={onConfirm}>
              Confirm Change
            </Button>
          </Box>
        </Box>
      </Modal.Footer>
    </Modal>
  );
};
