export enum QuoteStatus {
  active = 'active',
  contracted = 'contracted',
  voided = 'voided',
}

export const addersTitle = {
  arbitrageBattery: 'Arbitrage Battery',
  backupBattery: 'Backup Battery',
  electricalUpgrade: 'Electrical Upgrade',
  solarReadiness: 'Solar Readiness',
};
