import { Box, Icon } from '@palmetto/palmetto-components';
import { RequirementFlagSettingsType } from 'types';
import { Flag, FlagProps } from './Flag';
import { useContext } from 'react';
import { QueueFlagsContext } from './QueueFlagsContext';
import { FlagInfo } from './FlagInfo';

interface FlagBoxProps extends FlagProps {
  children?: React.ReactNode;
  displayWithNoQueue?: boolean;
  isLast?: boolean;
  type: RequirementFlagSettingsType;
}

export const FlagBox = ({
  children,
  dataKey,
  displayWithNoQueue = false,
  fileName,
  isLast = true,
  baseCategory,
  type,
}: FlagBoxProps) => {
  const { getFlag, shouldHide } = useContext(QueueFlagsContext);
  const currentFlag = getFlag(dataKey, fileName, false);

  return currentFlag || !shouldHide || displayWithNoQueue ? (
    <Box
      background={currentFlag ? 'danger' : 'primary'}
      direction={{ base: 'column', tablet: 'row' }}
      alignItems={{ tablet: 'center' }}
      gap={{ base: 'lg', tablet: 'lg' }}
      width="100"
      radius="md"
      padding="lg"
      borderColor="separator"
      borderWidth="xs"
      margin={isLast ? '0' : '0 0 lg 0'}
    >
      {currentFlag && (
        <Box>
          <Icon name="flag" size="lg" color="danger" />
        </Box>
      )}
      <Box style={{ width: children && !currentFlag ? '30%' : children && currentFlag ? '26%' : '100%' }} gap="2xs">
        <Box fontWeight="medium" fontSize="md">
          {dataKey}
        </Box>
        <FlagInfo {...{ dataKey, fileName, type }} />
      </Box>
      {children && (
        <Box direction={{ base: 'column', tablet: 'column' }} childGap="lg" width={{ base: '100', tablet: '70' }}>
          {children}
        </Box>
      )}
      {!shouldHide && (
        <Box alignItems="flex-end">
          <Flag {...{ dataKey, fileName, baseCategory, type }} />
        </Box>
      )}
    </Box>
  ) : null;
};
