import { Box, SelectInput } from '@palmetto/palmetto-components';
import { useFormikContext } from 'formik';
import { isFieldSelected } from './MappingFormFactory';
import { useGetConstructionAddersQuery } from '@/services/pricing';
import { ApprovedVendorSettingValue, ConstructionAdderType, ProgramType, VendorEquipmentTypes } from 'types';
import { useGetApprovedVendorsByTypeQuery } from '@/services/approvedVendors';
import { sortByLabel } from './util';
import { ReactNode, useEffect, useMemo } from 'react';
import { percentageFormatter } from '@/helpers/percentageFormatter';
import { currencyFormatter, kwhPriceFormatter } from '@/helpers/currencyFormatter';

const getBatteryLabel = (avlStorage: ApprovedVendorSettingValue[], batteryId: string): ReactNode => {
  const storage = avlStorage?.find((battery: any) => battery.id === batteryId);
  return (
    <Box key={batteryId}>
      <Box>{storage?.manufacturer}</Box>
      <Box>{storage?.modelNumber}</Box>
    </Box>
  );
};

export const SolarConstructionAddersForm = ({ initialValues }: { initialValues: Record<any, any> }) => {
  const formik = useFormikContext<any>();
  const { data: arbitrageBatteryAdders = [] } = useGetConstructionAddersQuery({
    type: 'arbitrageBattery',
    programType: ProgramType.solar,
  });
  const { data: arbitrageBatteryCostAdders = [] } = useGetConstructionAddersQuery({
    type: 'arbitrageBatteryCost',
    programType: ProgramType.solar,
  });
  const { data: electricalUpgradeAdders = [] } = useGetConstructionAddersQuery({
    type: 'electricalUpgrade',
    programType: ProgramType.solar,
  });
  const { data: backupBatteryAdders = [] } = useGetConstructionAddersQuery({
    type: 'backupBattery',
    programType: ProgramType.solar,
  });
  const { data: backupBatteryKwhAdders = [] } = useGetConstructionAddersQuery({
    type: 'backupBatteryKwh',
    programType: ProgramType.solar,
  });
  const { data: backupBatteryPriceCaps = [] } = useGetConstructionAddersQuery({
    type: ConstructionAdderType.backupBatteryPriceCap,
    programType: ProgramType.solar,
  });
  const { data: arbitrageBatteryPriceCaps = [] } = useGetConstructionAddersQuery({
    type: ConstructionAdderType.arbitrageBatteryPriceCap,
    programType: ProgramType.solar,
  });
  const { data: avlStorage } = useGetApprovedVendorsByTypeQuery({
    equipmentType: VendorEquipmentTypes.storage,
    programType: ProgramType.solar,
  });

  useEffect(() => {
    if (
      initialValues.arbitrageBattery &&
      arbitrageBatteryAdders.length &&
      !formik.values.selectedArbitrageBattery &&
      !formik.touched.selectedArbitrageBattery
    ) {
      const selectedArbitrageBattery = arbitrageBatteryAdders.find(
        (aba: any) => aba.id === initialValues.arbitrageBattery.value,
      );
      formik.setFieldValue('selectedArbitrageBattery', selectedArbitrageBattery);
      formik.setFieldTouched('selectedArbitrageBattery', true);
    }
    if (
      initialValues.arbitrageBatteryCost &&
      arbitrageBatteryCostAdders.length &&
      !formik.values.selectedArbitrageBatteryCost &&
      !formik.touched.selectedArbitrageBatteryCost
    ) {
      const selectedArbitrageBatteryCost = arbitrageBatteryCostAdders.find(
        (aba: any) => aba.id === initialValues.arbitrageBatteryCost.value,
      );
      formik.setFieldValue('selectedArbitrageBatteryCost', selectedArbitrageBatteryCost);
      formik.setFieldTouched('selectedArbitrageBatteryCost', true);
    }
    if (
      initialValues.electricalUpgrade &&
      electricalUpgradeAdders.length &&
      !formik.values.selectedElectricalUpgrade &&
      !formik.touched.selectedElectricalUpgrade
    ) {
      const selectedElectricalUpgrade = electricalUpgradeAdders.find(
        (ela: any) => ela.id === initialValues.electricalUpgrade.value,
      );
      formik.setFieldValue('selectedElectricalUpgrade', selectedElectricalUpgrade);
      formik.setFieldTouched('selectedElectricalUpgrade', true);
    }
    if (
      initialValues.backupBattery &&
      backupBatteryAdders.length &&
      !formik.values.selectedBackupBattery &&
      !formik.touched.selectedBackupBattery
    ) {
      const selectedBackupBattery = backupBatteryAdders.find(
        (bub: any) => bub.id === initialValues.backupBattery.value,
      );
      formik.setFieldValue('selectedBackupBattery', selectedBackupBattery);
      formik.setFieldTouched('selectedBackupBattery', true);
    }
    if (
      initialValues.backupBatteryKwh &&
      backupBatteryKwhAdders.length &&
      !formik.values.selectedBackupBatteryKwh &&
      !formik.touched.selectedBackupBatteryKwh
    ) {
      const selectedBackupBatteryKwh = backupBatteryKwhAdders.find(
        (bub: any) => bub.id === initialValues.backupBatteryKwh.value,
      );
      formik.setFieldValue('selectedBackupBatteryKwh', selectedBackupBatteryKwh);
      formik.setFieldTouched('selectedBackupBatteryKwh', true);
    }
    if (
      initialValues.backupBatteryPriceCaps &&
      backupBatteryPriceCaps.length &&
      !formik.values.selectedBackupBatteryPriceCaps &&
      !formik.touched.selectedBackupBatteryPriceCaps
    ) {
      const selectedBackupBatteryPriceCaps = backupBatteryPriceCaps.find(
        (bub: any) => bub.id === initialValues.backupBatteryPriceCaps.value,
      );
      formik.setFieldValue('selectedBackupBatteryPriceCaps', selectedBackupBatteryPriceCaps);
      formik.setFieldTouched('selectedBackupBatteryPriceCaps', true);
    }
    if (
      initialValues.arbitrageBatteryPriceCaps &&
      arbitrageBatteryPriceCaps.length &&
      !formik.values.selectedArbitrageBatteryPriceCaps &&
      !formik.touched.selectedArbitrageBatteryPriceCaps
    ) {
      const selectedArbitrageBatteryPriceCaps = arbitrageBatteryPriceCaps.find(
        (bub: any) => bub.id === initialValues.arbitrageBatteryPriceCaps.value,
      );
      formik.setFieldValue('selectedArbitrageBatteryPriceCaps', selectedArbitrageBatteryPriceCaps);
      formik.setFieldTouched('selectedArbitrageBatteryPriceCaps', true);
    }
  }, [
    initialValues,
    arbitrageBatteryAdders,
    arbitrageBatteryCostAdders,
    electricalUpgradeAdders,
    backupBatteryAdders,
    backupBatteryKwhAdders,
    backupBatteryPriceCaps,
    arbitrageBatteryPriceCaps,
    formik.values,
    formik.touched,
  ]);

  const arbitrageBatteryOptions = arbitrageBatteryAdders
    .map((aba: any) => ({ label: aba.name, value: aba.id }))
    .sort(sortByLabel);
  const arbitrageBatteryCostOptions = arbitrageBatteryCostAdders
    .map((abc: any) => ({ label: abc.name, value: abc.id }))
    .sort(sortByLabel);
  const electricalUpgradeOptions = electricalUpgradeAdders
    .map((ela: any) => ({ label: ela.name, value: ela.id }))
    .sort(sortByLabel);
  const backupBatteryOptions = backupBatteryAdders
    .map((bub: any) => ({ label: bub.name, value: bub.id }))
    .sort(sortByLabel);
  const backupBatteryKwhOptions = backupBatteryKwhAdders
    .map((bub: any) => ({ label: bub.name, value: bub.id }))
    .sort(sortByLabel);
  const backupBatteryPriceCapOptions = useMemo(
    () => backupBatteryPriceCaps.map((priceCap: any) => ({ label: priceCap.name, value: priceCap.id })),
    [backupBatteryPriceCaps],
  );
  const arbitrageBatteryPriceCapOptions = useMemo(
    () => arbitrageBatteryPriceCaps.map((priceCap: any) => ({ label: priceCap.name, value: priceCap.id })),
    [arbitrageBatteryPriceCaps],
  );
  return (
    <Box childGap="xl" radius="md" borderWidth="xs" borderColor="separator" padding="lg">
      <Box as="h3" fontWeight="medium" fontSize="md">
        Construction Adders
      </Box>
      <Box childGap="xl">
        <Box direction={{ desktop: 'row', base: 'column' }} childGap={{ desktop: '4xl', base: 'sm' }}>
          <Box width={{ desktop: '3xl', base: 'auto' }} padding={{ desktop: '0 0 lg 0', base: '0 0 xs 0' }}>
            <SelectInput
              id="electricalUpgrade"
              name="electricalUpgrade"
              label="Electrical Upgrade"
              value={formik.values.electricalUpgrade}
              onChange={(event) => {
                const selectedOption = event.target.value;
                formik.setFieldValue('electricalUpgrade', selectedOption);
                const selectedElectricalUpgrade = isFieldSelected(selectedOption)
                  ? electricalUpgradeAdders.find((eua: any) => eua.id === selectedOption.value)
                  : null;
                formik.setFieldValue('selectedElectricalUpgrade', selectedElectricalUpgrade);
                formik.setFieldTouched('selectedElectricalUpgrade', true);
              }}
              options={electricalUpgradeOptions}
              onBlur={formik.handleBlur}
              isClearable
              isDisabled={formik.isSubmitting}
              error={formik.touched.electricalUpgrade && (formik.errors.electricalUpgrade as string)}
            />
          </Box>
          {formik.values.selectedElectricalUpgrade && (
            <Box
              radius="md"
              borderWidth="xs"
              borderColor="separator"
              padding="sm lg"
              flex="auto"
              width="auto"
              alignSelf={{ base: 'flex-start', desktop: 'flex-end' }}
            >
              <Box childGap="sm">
                <Box fontSize="xs" fontWeight="bold">
                  $/kWh
                </Box>
                <Box>{kwhPriceFormatter.format(formik.values.selectedElectricalUpgrade.kwhAdderBand)}</Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box direction={{ desktop: 'row', base: 'column' }} childGap={{ desktop: '4xl', base: 'sm' }}>
          <Box width={{ desktop: '3xl', base: 'auto' }} padding={{ desktop: '0 0 lg 0', base: '0 0 xs 0' }}>
            <SelectInput
              id="arbitrageBattery"
              name="arbitrageBattery"
              label="Arbitrage Battery ($/kWh)"
              value={formik.values.arbitrageBattery}
              onChange={(event) => {
                const selectedOption = event.target.value;
                formik.setFieldValue('arbitrageBattery', selectedOption);

                const selectedArbitrageBattery = isFieldSelected(selectedOption)
                  ? arbitrageBatteryAdders.find((eua: any) => eua.id === selectedOption.value)
                  : null;
                formik.setFieldValue('selectedArbitrageBattery', selectedArbitrageBattery);
                formik.setFieldTouched('selectedArbitrageBattery', true);
              }}
              options={arbitrageBatteryOptions}
              onBlur={formik.handleBlur}
              isClearable
              isDisabled={formik.isSubmitting}
              error={formik.touched.arbitrageBattery && (formik.errors.arbitrageBattery as string)}
            />
          </Box>
          {formik.values.selectedArbitrageBattery && (
            <Box
              radius="md"
              borderWidth="xs"
              borderColor="separator"
              padding="sm lg"
              flex="auto"
              alignSelf={{ base: 'flex-start', desktop: 'flex-end' }}
            >
              <Box childGap="sm">
                <Box fontSize="xs" fontWeight="bold">
                  $/kWh
                </Box>
                <Box>{kwhPriceFormatter.format(formik.values.selectedArbitrageBattery.kwhAdderBand)}</Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box direction={{ desktop: 'row', base: 'column' }} childGap={{ desktop: '4xl', base: 'sm' }}>
          <Box width={{ desktop: '3xl', base: 'auto' }} padding={{ desktop: '0 0 lg 0', base: '0 0 xs 0' }}>
            <SelectInput
              id="arbitrageBatteryCost"
              name="arbitrageBatteryCost"
              label="Arbitrage Battery (cost)"
              value={formik.values.arbitrageBatteryCost}
              onChange={(event) => {
                const selectedOption = event.target.value;
                formik.setFieldValue('arbitrageBatteryCost', selectedOption);

                const selectedArbitrageBatteryCost = isFieldSelected(selectedOption)
                  ? arbitrageBatteryCostAdders.find((eua: any) => eua.id === selectedOption.value)
                  : null;
                formik.setFieldValue('selectedArbitrageBatteryCost', selectedArbitrageBatteryCost);
                formik.setFieldTouched('selectedArbitrageBatteryCost', true);
              }}
              options={arbitrageBatteryCostOptions}
              onBlur={formik.handleBlur}
              isClearable
              isDisabled={formik.isSubmitting}
              error={formik.touched.arbitrageBatteryCost && (formik.errors.arbitrageBatteryCost as string)}
            />
          </Box>
          {formik.values.selectedArbitrageBatteryCost && (
            <Box
              radius="md"
              borderWidth="xs"
              borderColor="separator"
              padding="sm lg"
              flex="auto"
              direction="row"
              gap="4xl"
              alignSelf={{ base: 'flex-start', desktop: 'flex-end' }}
            >
              {formik.values.selectedArbitrageBatteryCost.paymentFactors.map((pf: any) => (
                <Box childGap="sm" key={pf.escalationRate}>
                  <Box fontSize="xs" fontWeight="bold">
                    {percentageFormatter.format(pf.escalationRate)}
                  </Box>
                  <Box>{pf.value}</Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Box direction={{ desktop: 'row', base: 'column' }} childGap={{ desktop: '4xl', base: 'sm' }}>
          <Box width={{ desktop: '3xl', base: 'auto' }} padding={{ desktop: '0 0 lg 0', base: '0 0 xs 0' }}>
            <SelectInput
              id="arbitrageBatteryPriceCaps"
              name="arbitrageBatteryPriceCaps"
              label="Arbitrage Battery Price Caps"
              value={formik.values.arbitrageBatteryPriceCaps}
              onChange={(event) => {
                const selectedOption = event.target.value;
                formik.setFieldValue('arbitrageBatteryPriceCaps', selectedOption);
                const selectedArbitrageBatteryPriceCaps = isFieldSelected(selectedOption)
                  ? arbitrageBatteryPriceCaps.find((eua: any) => eua.id === selectedOption.value)
                  : null;
                formik.setFieldValue('selectedArbitrageBatteryPriceCaps', selectedArbitrageBatteryPriceCaps);
                formik.setFieldTouched('selectedArbitrageBatteryPriceCaps', true);
              }}
              options={arbitrageBatteryPriceCapOptions}
              onBlur={formik.handleBlur}
              isClearable
              isDisabled={formik.isSubmitting}
              error={formik.touched.arbitrageBatteryPriceCaps && (formik.errors.arbitrageBatteryPriceCaps as string)}
            />
          </Box>
          {formik.values.selectedArbitrageBatteryPriceCaps && (
            <Box
              radius="md"
              borderWidth="xs"
              borderColor="separator"
              padding="sm lg"
              flex="auto"
              direction="row"
              gap="4xl"
              alignSelf={{ base: 'flex-start', desktop: 'flex-end' }}
            >
              {avlStorage?.data &&
                formik.values.selectedArbitrageBatteryPriceCaps.arbitrageBatteryPriceCaps?.map((priceCap: any) => (
                  <Box childGap="sm" key={priceCap.batteryId}>
                    <Box fontSize="xs" fontWeight="bold">
                      {getBatteryLabel(avlStorage.data, priceCap.batteryId)}
                    </Box>
                    <Box>{currencyFormatter.format(priceCap.maxPrice)}</Box>
                  </Box>
                ))}
            </Box>
          )}
        </Box>
        <Box direction={{ desktop: 'row', base: 'column' }} childGap={{ desktop: '4xl', base: 'sm' }}>
          <Box width={{ desktop: '3xl', base: 'auto' }} padding={{ desktop: '0 0 lg 0', base: '0 0 xs 0' }}>
            <SelectInput
              id="backupBattery"
              name="backupBattery"
              label="Backup Battery (cost)"
              value={formik.values.backupBattery}
              onChange={(event) => {
                const selectedOption = event.target.value;
                formik.setFieldValue('backupBattery', selectedOption);
                const selectedBackupBattery = isFieldSelected(selectedOption)
                  ? backupBatteryAdders.find((eua: any) => eua.id === selectedOption.value)
                  : null;
                formik.setFieldValue('selectedBackupBattery', selectedBackupBattery);
                formik.setFieldTouched('selectedBackupBattery', true);
              }}
              options={backupBatteryOptions}
              onBlur={formik.handleBlur}
              isClearable
              isDisabled={formik.isSubmitting}
              error={formik.touched.backupBattery && (formik.errors.backupBattery as string)}
            />
          </Box>
          {formik.values.selectedBackupBattery && (
            <Box
              radius="md"
              borderWidth="xs"
              borderColor="separator"
              padding="sm lg"
              flex="auto"
              direction="row"
              gap="4xl"
              alignSelf={{ base: 'flex-start', desktop: 'flex-end' }}
            >
              {formik.values.selectedBackupBattery.paymentFactors.map((pf: any) => (
                <Box childGap="sm" key={pf.escalationRate}>
                  <Box fontSize="xs" fontWeight="bold">
                    {percentageFormatter.format(pf.escalationRate)}
                  </Box>
                  <Box>{pf.value}</Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Box direction={{ desktop: 'row', base: 'column' }} childGap={{ desktop: '4xl', base: 'sm' }}>
          <Box width={{ desktop: '3xl', base: 'auto' }} padding={{ desktop: '0 0 lg 0', base: '0 0 xs 0' }}>
            <SelectInput
              id="backupBatteryKwh"
              name="backupBatteryKwh"
              label="Backup Battery ($/kWh)"
              value={formik.values.backupBatteryKwh}
              onChange={(event) => {
                const selectedOption = event.target.value;
                formik.setFieldValue('backupBatteryKwh', selectedOption);
                const selectedBackupBatteryKwh = isFieldSelected(selectedOption)
                  ? backupBatteryKwhAdders.find((eua: any) => eua.id === selectedOption.value)
                  : null;
                formik.setFieldValue('selectedBackupBatteryKwh', selectedBackupBatteryKwh);
                formik.setFieldTouched('selectedBackupBatteryKwh', true);
              }}
              options={backupBatteryKwhOptions}
              onBlur={formik.handleBlur}
              isClearable
              isDisabled={formik.isSubmitting}
              error={formik.touched.backupBatteryKwh && (formik.errors.backupBatteryKwh as string)}
            />
          </Box>
          {formik.values.selectedBackupBatteryKwh && (
            <Box
              radius="md"
              borderWidth="xs"
              borderColor="separator"
              padding="sm lg"
              flex="auto"
              alignSelf={{ base: 'flex-start', desktop: 'flex-end' }}
            >
              <Box childGap="sm">
                <Box fontSize="xs" fontWeight="bold">
                  $/kWh
                </Box>
                <Box>{kwhPriceFormatter.format(formik.values.selectedBackupBatteryKwh.kwhAdderBand)}</Box>
              </Box>
            </Box>
          )}
        </Box>
        <Box direction={{ desktop: 'row', base: 'column' }} childGap={{ desktop: '4xl', base: 'sm' }}>
          <Box width={{ desktop: '3xl', base: 'auto' }} padding={{ desktop: '0 0 lg 0', base: '0 0 xs 0' }}>
            <SelectInput
              id="backupBatteryPriceCaps"
              name="backupBatteryPriceCaps"
              label="Backup Battery Price Caps"
              value={formik.values.backupBatteryPriceCaps}
              onChange={(event) => {
                const selectedOption = event.target.value;
                formik.setFieldValue('backupBatteryPriceCaps', selectedOption);
                const selectedBackupBatteryPriceCaps = isFieldSelected(selectedOption)
                  ? backupBatteryPriceCaps.find((eua: any) => eua.id === selectedOption.value)
                  : null;
                formik.setFieldValue('selectedBackupBatteryPriceCaps', selectedBackupBatteryPriceCaps);
                formik.setFieldTouched('selectedBackupBatteryPriceCaps', true);
              }}
              options={backupBatteryPriceCapOptions}
              onBlur={formik.handleBlur}
              isClearable
              isDisabled={formik.isSubmitting}
              error={formik.touched.backupBatteryPriceCaps && (formik.errors.backupBatteryPriceCaps as string)}
            />
          </Box>
          {formik.values.selectedBackupBatteryPriceCaps && (
            <Box
              radius="md"
              borderWidth="xs"
              borderColor="separator"
              padding="sm lg"
              flex="auto"
              direction="row"
              gap="4xl"
              alignSelf={{ base: 'flex-start', desktop: 'flex-end' }}
            >
              {avlStorage?.data &&
                formik.values.selectedBackupBatteryPriceCaps.backupBatteryPriceCaps?.map((priceCap: any) => (
                  <Box childGap="sm" key={priceCap.batteryId}>
                    <Box fontSize="xs" fontWeight="bold">
                      {getBatteryLabel(avlStorage.data, priceCap.batteryId)}
                    </Box>
                    <Box>{currencyFormatter.format(priceCap.maxPrice)}</Box>
                  </Box>
                ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const DoePrConstructionAdders = ({ initialValues }: { initialValues: Record<any, any> }) => {
  const formik = useFormikContext<any>();
  const { data: solarReadinessAdders = [] } = useGetConstructionAddersQuery({
    type: 'solarReadiness',
    programType: ProgramType.doePr,
  });
  const { data: backupBatteryAdders = [] } = useGetConstructionAddersQuery({
    type: 'backupBattery',
    programType: ProgramType.doePr,
  });

  if (
    initialValues.backupBattery &&
    backupBatteryAdders.length &&
    !formik.values.selectedBackupBattery &&
    !formik.touched.selectedBackupBattery
  ) {
    const selectedBackupBattery = backupBatteryAdders.find(
      (bub: any) => bub.id === initialValues.backupBattery.value,
    );
    formik.setFieldValue('selectedBackupBattery', selectedBackupBattery);
    formik.setFieldTouched('selectedBackupBattery', true);
  }

  useEffect(() => {
    if (
      initialValues.solarReadiness &&
      solarReadinessAdders.length &&
      !formik.values.selectedSolarReadiness &&
      !formik.touched.selectedSolarReadiness
    ) {
      const selectedSolarReadiness = solarReadinessAdders.find(
        (sr: any) => sr.id === initialValues.solarReadiness.value,
      );
      formik.setFieldValue('selectedSolarReadiness', selectedSolarReadiness);
      formik.setFieldTouched('selectedSolarReadiness', true);
    }
  }, [initialValues, solarReadinessAdders, formik.values, formik.touched]);

  const solarReadinessOptions = solarReadinessAdders
    .map((sr: any) => ({ label: sr.name, value: sr.id }))
    .sort(sortByLabel);
  const backupBatteryOptions = backupBatteryAdders
    .map((bub: any) => ({ label: bub.name, value: bub.id }))
    .sort(sortByLabel);
  return (
    <Box childGap="xl" radius="md" borderWidth="xs" borderColor="separator" padding="lg">
      <Box as="h3" fontWeight="medium" fontSize="md">
        Construction Adders
      </Box>
      <Box childGap="xl">
        <Box direction={{ desktop: 'row', base: 'column' }} childGap={{ desktop: '4xl', base: 'sm' }}>
          <Box width={{ desktop: '3xl', base: 'auto' }} padding={{ desktop: '0 0 lg 0', base: '0 0 xs 0' }}>
            <SelectInput
              id="backupBattery"
              name="backupBattery"
              label="Backup Battery (cost)"
              value={formik.values.backupBattery}
              onChange={(event) => {
                const selectedOption = event.target.value;
                formik.setFieldValue('backupBattery', selectedOption);
                const selectedBackupBattery = isFieldSelected(selectedOption)
                  ? backupBatteryAdders.find((eua: any) => eua.id === selectedOption.value)
                  : null;
                formik.setFieldValue('selectedBackupBattery', selectedBackupBattery);
                formik.setFieldTouched('selectedBackupBattery', true);
              }}
              options={backupBatteryOptions}
              onBlur={formik.handleBlur}
              isClearable
              isDisabled={formik.isSubmitting}
              error={formik.touched.backupBattery && (formik.errors.backupBattery as string)}
            />
          </Box>
          {formik.values.selectedBackupBattery && (
            <Box
              radius="md"
              borderWidth="xs"
              borderColor="separator"
              padding="sm lg"
              flex="auto"
              direction="row"
              gap="4xl"
              alignSelf={{ base: 'flex-start', desktop: 'flex-end' }}
            >
              {formik.values.selectedBackupBattery.paymentFactors.map((pf: any) => (
                <Box childGap="sm" key={pf.escalationRate}>
                  <Box fontSize="xs" fontWeight="bold">
                    {percentageFormatter.format(pf.escalationRate)}
                  </Box>
                  <Box>{pf.value}</Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Box direction={{ desktop: 'row', base: 'column' }} childGap={{ desktop: '4xl', base: 'sm' }}>
          <Box width={{ desktop: '3xl', base: 'auto' }} padding={{ desktop: '0 0 lg 0', base: '0 0 xs 0' }}>
            <SelectInput
              id="solarReadiness"
              name="solarReadiness"
              label="Solar Readiness"
              value={formik.values.solarReadiness}
              onChange={(event) => {
                const selectedOption = event.target.value;
                formik.setFieldValue('solarReadiness', selectedOption);
                const selectedSolarReadiness = isFieldSelected(selectedOption)
                  ? solarReadinessAdders.find((eua: any) => eua.id === selectedOption.value)
                  : null;
                formik.setFieldValue('selectedSolarReadiness', selectedSolarReadiness);
                formik.setFieldTouched('selectedSolarReadiness', true);
              }}
              options={solarReadinessOptions}
              onBlur={formik.handleBlur}
              isClearable
              isDisabled={formik.isSubmitting}
              error={formik.touched.solarReadiness && (formik.errors.solarReadiness as string)}
            />
          </Box>
          {formik.values.selectedSolarReadiness && (
            <Box
              radius="md"
              borderWidth="xs"
              borderColor="separator"
              padding="sm lg"
              flex="auto"
              width="auto"
              alignSelf={{ base: 'flex-start', desktop: 'flex-end' }}
            >
              <Box childGap="sm">
                <Box fontSize="xs" fontWeight="bold">
                  Max Amount
                </Box>
                <Box>{currencyFormatter.format(formik.values.selectedSolarReadiness.maxAmount)}</Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const ConstructionAddersForm = ({
  programType,
  initialValues,
}: {
  programType: ProgramType;
  initialValues: Record<any, any>;
}) => {
  switch (programType) {
    case ProgramType.newHomes:
    case ProgramType.solar:
      return <SolarConstructionAddersForm initialValues={initialValues} />;
    case ProgramType.doePr:
      return <DoePrConstructionAdders initialValues={initialValues} />;
    default:
      return null;
  }
};
