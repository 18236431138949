import { Box, FormikTextInput } from "@palmetto/palmetto-components"
import FormSection from "../Forms/FormSection"
import { UtilityNameSelectInput } from "../Account/UtilityNameSelectInput"
import { Field, useFormikContext } from "formik"
import { DocumentList } from "./Documents/DocumentList"
import { DocumentTypes, InterconnectionAgreementDocumentType, ProgramType } from "types"
import { UtilityBillDescription } from "../InstallMilestonePackage/InstallationFormSectionDescriptions"

type UtilityBillProps = {
    originContext?: string;
    allowArchive?: boolean;
    account: any;
}

export const UtilityBill = ({ originContext, allowArchive, account }: UtilityBillProps) => {
    const isNewHomes = account?.programType === ProgramType.newHomes;
    const { isSubmitting } = useFormikContext<any>();
    const isCA = account?.address?.state === 'CA';
    return (
        <FormSection title={isNewHomes ? "Utility Information" : "Utility Bill"} description={UtilityBillDescription({ showUtilityBill: !isNewHomes })}>
            {!isNewHomes && (
                <DocumentList
                    documentTypeFilter={[DocumentTypes.utilityBill]}
                    title="Utility Bill"
                    showTypeLabelOnItems={false}
                    baseCategory={'Utility Bill'}
                    uploadContext={originContext}
                    allowArchive={allowArchive}
                />)
            }
            <Box direction="row">
                <UtilityNameSelectInput isDisabled={true} />
                <Field
                    type="number"
                    label="Tariff Id"
                    name="tariffId"
                    id="tariffId"
                    component={FormikTextInput}
                    autoComplete="off"
                    isDisabled={isSubmitting}
                />
            </Box>
        </FormSection>
    )
}