import { HVACSystem } from 'types';

export const calculateTotalCost = (values: { systems: HVACSystem[] }) => {
  const totalCost = values.systems.reduce((sum, system) => {
    return (
      sum +
      (system.equipment.totalCost ? Number(system.equipment.totalCost) : 0) +
      (system.accessories?.totalCost ? Number(system.accessories.totalCost) : 0) +
      (system.sitePrep?.totalCost ? Number(system.sitePrep.totalCost) : 0) -
      (system.discounts?.totalCost ? Number(system.discounts.totalCost) : 0)
    );
  }, 0);
  return totalCost;
};
