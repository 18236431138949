import FormSection from '../Forms/FormSection';
import {
  ActivationDocumentTypes,
  documentTitles,
  ProgramType,
} from 'types';
import { IncentivesDescription } from '../InstallMilestonePackage/InstallationFormSectionDescriptions';
import { DocumentList } from './Documents/DocumentList';

type IncentivesProps = {
  originContext?: string;
  allowArchive?: boolean;
  programType?: ProgramType;
};

export const Incentives = ({ originContext, allowArchive, programType }: IncentivesProps) => {
  return (
    <FormSection description={IncentivesDescription()} title="Incentive Documents">
      <DocumentList
        documentTypeFilter={[ActivationDocumentTypes.incentives]}
        title="Incentive Document"
        showTypeLabelOnItems={false}
        baseCategory={documentTitles.incentiveDocuments}
        uploadContext={originContext}
        allowArchive={allowArchive}
      />
    </FormSection>
  );
};
