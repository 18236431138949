import { currencyFormatter } from '@/helpers/currencyFormatter';
import { useGetAccountQuery } from '@/services/accounts';
import { Box, DimensionSize, Popover } from '@palmetto/palmetto-components';
import { useState } from 'react';
import { HoverOnlyTooltipComponent } from '../Tooltip';
import { DomesticContentEligibilityLabels, DomesticContentEligibilityTypes, Quote } from 'types';

export interface ListItemElementProps {
  label: string;
  value: string;
}

export interface ListItemElementPropsWithTooltip extends ListItemElementProps {
  customId: string;
  cssCustomStyle?: {
    label?: any;
    value?: any;
    textDecoration?: any;
    textDecorationThickness?: any;
  };
  tooltipText: string | null;
}

export const getDomesticElements = ({
  quote,
  totalSystemCost,
  totalAdderCost,
  pricePerWatt,
}: {
  quote: Quote;
  totalSystemCost: number;
  totalAdderCost: number;
  pricePerWatt: number;
}) => {
  const { data: account } = useGetAccountQuery(quote.accountId);
  let epcTotalPaymentDomesticContentElements: ListItemElementPropsWithTooltip[] = [];
  let epcRatePPWDomesticContentElements: ListItemElementPropsWithTooltip[] = [];
  let totalEPCPaymentElements: ListItemElementPropsWithTooltip[] = [];
  const domesticContentEligibilityStatus = account?.domesticContentEligibilityStatus ?? '';
  if (quote.domesticContentEligible) {
    let domesticContentTooltipText = '';
    let domesticContentBoostStyle = {};
    let nonDomesticContentStyle = {};
    switch (domesticContentEligibilityStatus) {
      case DomesticContentEligibilityTypes.confirmed:
        domesticContentTooltipText = `LightReach Review has determined that this project meets the ${DomesticContentEligibilityLabels.confirmed} threshold for Domestic Content, and will be paid at the DC Boost values.`;
        nonDomesticContentStyle = {
          label: {
            color: '#8C919B',
          },
          value: {
            color: '#8C919B',
            textDecoration: 'line-through',
            textDecorationThickness: '2px',
          },
        };
        break;
      case DomesticContentEligibilityTypes.epcOptOut:
        domesticContentTooltipText = `Your team has indicated this project does not satisfy the Domestic Content threshold, and will be paid at the Non-DC values.`;
        domesticContentBoostStyle = {
          label: {
            color: '#8C919B',
          },
          value: {
            color: '#8C919B',
            textDecoration: 'line-through',
            textDecorationThickness: '2px',
          },
        };
        break;
      case DomesticContentEligibilityTypes.underConsideration:
        domesticContentTooltipText = `Project meeting the IRA Domestic Content threshold receive a payment boost.`;
        nonDomesticContentStyle = {
          label: {
            color: '#8C919B',
          },
          value: {
            color: '#8C919B',
          },
        };
        break;
    }
    epcTotalPaymentDomesticContentElements = [
      {
        label: 'DC Boost',
        tooltipText: domesticContentTooltipText,
        value: `${currencyFormatter.format(totalSystemCost - totalAdderCost)}`,
        customId: 'epcTotalPaymentDomesticElementWithDC',
        cssCustomStyle: domesticContentBoostStyle,
      },
      {
        label: 'Non-DC',
        tooltipText: null,
        value: `${currencyFormatter.format(quote.totalSystemCostWithoutDomesticContent - totalAdderCost)}`,
        customId: 'epcTotalPaymentDomesticElementWithoutDC',
        cssCustomStyle: nonDomesticContentStyle,
      },
    ];
    epcRatePPWDomesticContentElements = [
      {
        label: 'DC Boost',
        tooltipText: domesticContentTooltipText,
        value: `${currencyFormatter.format(pricePerWatt)}`,
        customId: 'epcRatePPWDomesticElementWithDC',
        cssCustomStyle: domesticContentBoostStyle,
      },
      {
        label: 'Non-DC',
        tooltipText: null,
        value: `${currencyFormatter.format(quote.pricePerWattWithoutDomesticContent)}`,
        customId: 'epcRatePPWDomesticElementWithoutDC',
        cssCustomStyle: nonDomesticContentStyle,
      },
    ];
    totalEPCPaymentElements = [
      {
        label: 'DC Boost',
        tooltipText: domesticContentTooltipText,
        value: `${currencyFormatter.format(totalSystemCost)}`,
        customId: 'epcRatePPWDomesticElementWithDC',
        cssCustomStyle: domesticContentBoostStyle,
      },
      {
        label: 'Non-DC',
        tooltipText: null,
        value: `${currencyFormatter.format(quote.totalSystemCostWithoutDomesticContent)}`,
        customId: 'epcRatePPWDomesticElementWithoutDC',
        cssCustomStyle: nonDomesticContentStyle,
      },
    ];
  }
  return {
    epcTotalPaymentDomesticContentElements,
    epcRatePPWDomesticContentElements,
    totalEPCPaymentElements,
  };
};
export function ListItemElementWithMultipleProps({
  baseLabel,
  values,
}: {
  baseLabel: string;
  values: ListItemElementPropsWithTooltip[];
}) {
  return (
    <Box
      direction="row"
      padding="0 lg"
      gap="5xl"
      style={{
        lineHeight: '1.5em',
      }}
    >
      <Box width={{ base: 'xs' as DimensionSize, tablet: 'lg' }} />
      <Box borderWidth="0 0 xs 0" borderColor="separator" flex="auto" direction="row" padding="xs 0">
        <Box flex="auto">{baseLabel}</Box>
        <Box direction={'row'}>
          {values.map((listElementValue, index) => {
            return (
              <HoverOnlyTooltipComponent key={index} content={listElementValue.tooltipText} onClose={() => { }}>
                <Box
                  style={{
                    minWidth: '6em',
                    borderRight: index != values.length - 1 ? '1px solid #E0E2E7' : '0',
                    paddingRight: index != values.length - 1 ? '1em' : '0',
                    cursor: 'default',
                  }}
                  className={listElementValue.customId}
                >
                  <Box
                    style={{
                      fontSize: '14px',
                      fontWeight: 500,
                      textUnderlinePosition: 'from-font',
                      textDecorationSkipInk: 'none',
                      textAlign: 'right',
                      marginBottom: '0.2em',
                      ...(listElementValue.cssCustomStyle?.label ?? {}),
                    }}
                  >
                    {listElementValue.label}
                  </Box>
                  <Box
                    style={{
                      fontSize: '12px',
                      fontWeight: 500,
                      textAlign: 'right',
                      textDecorationLine: 'underline',
                      textUnderlineOffset: '25%',
                      textDecorationThickness: '11%',
                      textUnderlinePosition: 'from-font',
                      textDecorationSkipInk: 'none',
                      ...(listElementValue.cssCustomStyle?.value ?? {}),
                    }}
                  >
                    {listElementValue.value}
                  </Box>
                </Box>
              </HoverOnlyTooltipComponent>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export function ListItemElement({ label, value }: ListItemElementProps) {
  return (
    <Box direction="row" padding="0 lg" gap="5xl">
      <Box width={{ base: 'xs' as DimensionSize, tablet: 'lg' }} />
      <Box borderWidth="0 0 xs 0" borderColor="separator" flex="auto" direction="row" padding="xs 0">
        <Box flex="auto">{label}</Box>
        <Box>{value}</Box>
      </Box>
    </Box>
  );
}

export const getEpcTotalPaymentLabel = ({
  hasArbitrageBattery,
  hasBackupBatteryKWH,
  isHVACQuote,
}: {
  hasArbitrageBattery?: boolean;
  hasBackupBatteryKWH: boolean;
  isHVACQuote: boolean;
}) => {
  return hasArbitrageBattery
    ? 'Solar with Arbitrage Battery EPC Total Payment'
    : hasBackupBatteryKWH
      ? 'Solar with Backup Battery EPC Total Payment'
      : !isHVACQuote
        ? 'Solar-only EPC Total Payment'
        : 'EPC Total Payment';
};

export const getEpcRatePPWLabel = ({
  hasArbitrageBattery,
  hasBackupBatteryKWH,
}: {
  hasArbitrageBattery?: boolean;
  hasBackupBatteryKWH: boolean;
}) => {
  return hasArbitrageBattery
    ? 'Solar with Arbitrage Battery EPC Rate ($/W)'
    : hasBackupBatteryKWH
      ? 'Solar with Backup Battery EPC Rate ($/W)'
      : 'Solar-only EPC Rate ($/W)';
};
