import { useGetAccountQuery } from '@/services/accounts';
import { useGetQuotesQuery, useVoidQuoteMutation } from '@/services/quotes';
import { useCreateHVACSystemDesignMutation, useGetCurrentDesignQuery } from '@/services/systemDesign';
import { Box, Button, Modal, Spinner, TextLink, toast, useOpenClose } from '@palmetto/palmetto-components';
import { Formik } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ContractStatus, HvacPricingDetails, HVACSystem, ProgramType, QuoteStatus, UserPermissions } from 'types';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import { MainContainer } from '../MainContainer';
import RequirePermissions from '../auth/requirePermissions';
import { useGetContractsQuery, useVoidContractMutation } from '@/services/contract';
import { EnhancedFormikForm } from '../EnhancedFormikForm';
import { SystemSection } from '../SystemDesign/hvac/SystemSection';

export const HVACSystemDesign = () => {
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const { data: systemDesign }: any = useGetCurrentDesignQuery({
    id,
    programType: ProgramType.hvac,
  });
  const { data: account, isLoading: isAccountLoading }: any = useGetAccountQuery(id);
  const { data: quotes, isLoading: isQuotesLoading } = useGetQuotesQuery(
    { id, programType: account?.programType },
    { skip: !account?.programType },
  );
  const [voidQuote, { isLoading: isVoidingQuote }] = useVoidQuoteMutation({
    fixedCacheKey: 'useVoidQuoteMutationKey',
  });
  const { data: contracts, isLoading: areContractsLoading } = useGetContractsQuery(id);

  const isLoading = isAccountLoading || isQuotesLoading || areContractsLoading;
  const [createSystemDesign] = useCreateHVACSystemDesignMutation();
  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const [formValues, setFormValues] = useState<any>({});

  const activeContract = useMemo(() => {
    return contracts?.find(
      (contract: any) => ![ContractStatus.voided, ContractStatus.rejected].includes(contract.status),
    );
  }, [contracts]);

  const [voidContract, { isLoading: isVoidingActiveContract }] = useVoidContractMutation({
    fixedCacheKey: 'useVoidContractMutationKey',
  });

  const activeQuote = quotes?.find((quote: any) => quote.status === QuoteStatus.active);

  const calculateTotalCost = useCallback((values: { systems: HVACSystem[] }) => {
    const totalCost = values.systems.reduce((sum, system) => {
      return (
        sum +
        (system.equipment.totalCost ? Number(system.equipment.totalCost) : 0) +
        (system.accessories?.totalCost ? Number(system.accessories.totalCost) : 0) +
        (system.sitePrep?.totalCost ? Number(system.sitePrep.totalCost) : 0) -
        (system.discounts?.totalCost ? Number(system.discounts.totalCost) : 0)
      );
    }, 0);
    return totalCost;
  }, []);

  const handleSubmit = async (
    data: {
      systems: HVACSystem[];
    },
    { setSubmitting }: any,
  ) => {
    try {
      const totalSystemDesignCost = calculateTotalCost(data);
      if (activeQuote && totalSystemDesignCost !== activeQuote.totalSystemCost) {
        setFormValues(data);
        openModal();
        setSubmitting(false);
        return;
      }

      await createSystemDesign({
        systemDesign: {
          systems: data.systems.map((system) => ({
            systemCategory: system.systemCategory,
            conditionedArea: Number(system.conditionedArea),
            name: system.name,
            ahriNumber: system.ahriNumber,
            equipment: {
              totalCost: system.equipment.totalCost,
              items: system.equipment.items.map((item) => ({
                type: item.type,
                name: item.name,
                manufacturer: item.manufacturer,
                model: item.model,
                quantity: item.quantity,
                productId: item.productId,
                serialNumbers: item.serialNumbers,
              })),
            },
            accessories: system?.accessories
              ? {
                  totalCost: system.accessories.totalCost,
                  items: system.accessories?.items.map((item) => ({
                    type: item.type,
                    name: item.name,
                    manufacturer: item.manufacturer,
                    model: item.model,
                    quantity: item.quantity,
                    productId: item.productId,
                    serialNumbers: item.serialNumbers,
                  })),
                }
              : undefined,
            sitePrep: system?.sitePrep
              ? {
                  totalCost: system.sitePrep.totalCost,
                  items: system.sitePrep.items.map((item) => ({
                    name: item.name,
                  })),
                }
              : undefined,
            discounts: system?.discounts
              ? {
                  totalCost: system.discounts.totalCost,
                  items: system.discounts.items.map((item) => ({
                    name: item.name,
                  })),
                }
              : undefined,
          })),
        },
        accountId: id,
      }).unwrap();
      toast.success('System design saved successfully');
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error saving system design');
    } finally {
      setSubmitting(false);
    }
  };

  /**
   * @description Voids a quote (and an active contract, if any)
   * and generates a new system design.
   */
  const handleConfirmModalSubmit = async () => {
    closeModal();
    if (formValues) {
      try {
        if (activeContract) {
          await voidContract({
            accountId: String(id),
            contractId: String(activeContract.id),
          }).unwrap();
          toast.success('Active Contract voided successfully');
        }
        await voidQuote({ accountId: id.toString(), quoteId: activeQuote.id.toString() }).unwrap();
        toast.success('Quote voided successfully');
        await createSystemDesign({
          systemDesign: {
            equipment: formValues.equipment,
            installKits: formValues?.installKits || [],
            services: formValues?.services || [],
            discounts: formValues?.discounts || [],
          },
          accountId: id,
        }).unwrap();
        toast.success('System design saved successfully');
      } catch (e: any) {
        toast.error(e?.data?.message || 'Error saving system design');
      }
    }
  };

  const isVoidingSomething = isVoidingQuote || isVoidingActiveContract;

  return (
    <>
      <MainContainer>
        {isLoading ? (
          <Box display="block" textAlign="center" padding="lg" childGap="xl">
            <Spinner size="lg" />
          </Box>
        ) : (
          <Box overflow="hidden" direction={{ base: 'column', desktop: 'row' }} childGap="lg">
            <Formik
              initialValues={{
                systems: systemDesign?.systems ?? [
                  {
                    systemCategory: '',
                    name: 'System 1',
                    equipment: {
                      totalCost: 0,
                      items: [],
                    },
                    accessories: {
                      totalCost: 0,
                      items: [],
                    },
                    sitePrep: {
                      totalCost: 0,
                      items: [],
                    },
                    discounts: {
                      totalCost: 0,
                      items: [],
                    },
                  },
                ],
              }}
              onSubmit={handleSubmit}
              enableReinitialize={true}
              validateOnChange={false}
            >
              {({ isSubmitting, values }) => {
                const totalSystemDesignCost = calculateTotalCost(values);
                return (
                  <EnhancedFormikForm noValidate style={{ width: '100%' }}>
                    <Box gap="lg">
                      <Box
                        borderColor="separator"
                        borderWidth="0 0 xs 0"
                        padding={{ desktop: 'lg 0 xl 0', base: 'lg 3xl' }}
                        direction="row"
                        childGap="5xl"
                        fontSize="md"
                        fontWeight="medium"
                      >
                        <Box>Account Details</Box>
                        <Box childGap="sm" padding={{ base: '0 0 0 5xl' }}>
                          <Box>Home Size (sq ft): {account?.propertyDetails?.homeSize ?? 'unknown'}</Box>
                          <Box>Existing Systems: {account?.propertyDetails?.existingSystems ?? 'unknown'}</Box>
                        </Box>
                      </Box>
                    </Box>
                    <SystemSection />
                    <Box alignItems="flex-end" margin="md" padding={{ base: 'lg 0 0 0' }} gap="xs">
                      <Box direction="row" gap="xs" alignItems="center">
                        <Box fontWeight="medium" fontSize="md">
                          Total Number of Systems:
                        </Box>
                        <Box fontWeight="bold" fontSize="lg">
                          {values.systems.length}
                        </Box>
                      </Box>
                      <Box direction="row" gap="xs" alignItems="center">
                        <Box fontWeight="medium" fontSize="md">
                          Total System Design Cost:
                        </Box>
                        <Box fontWeight="bold" fontSize="lg">
                          {currencyFormatter.format(totalSystemDesignCost)}
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      direction={{
                        base: 'column',
                        tablet: 'row',
                      }}
                      childGap="sm"
                      style={{ flexShrink: 0 }}
                      padding={{ base: 'lg' }}
                      background="primary"
                    >
                      {activeQuote ? (
                        <Box alignSelf="flex-start" direction="row" gap="lg">
                          <Box gap="xs">
                            <Box fontSize="xl" fontWeight="bold">
                              {currencyFormatter.format(activeQuote.totalSystemCost)}
                            </Box>
                            <Box fontSize="md">Total Financed Amount</Box>
                          </Box>
                          <Box gap="xs">
                            <Box direction="row" alignItems="center" gap="2xs">
                              <Box fontSize="xl" fontWeight="bold">
                                {currencyFormatter.format(
                                  activeQuote.systemPricingDetails.find((d: HvacPricingDetails) => d.year === 1)
                                    ?.monthlyPayment || 0,
                                )}
                              </Box>
                              <Box fontSize="md">/mo</Box>
                            </Box>
                            <Box fontSize="md">Year 1 Monthly Payment</Box>
                          </Box>
                          <Box gap="xs">
                            <Box direction="row" alignItems="center" gap="2xs">
                              <Box fontSize="xl" fontWeight="bold">
                                {activeQuote.systemPricingDetails.length}
                              </Box>
                              <Box fontSize="md">
                                {activeQuote.systemPricingDetails.length > 1 ? `years` : `year`}
                              </Box>
                            </Box>
                            <Box fontSize="md">Customer Term Length</Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box childGap="xs" color="body-secondary" alignSelf="center">
                          <p>
                            There is no active quote for this Account. Please{' '}
                            <TextLink
                              navigate={() => navigate(`/accounts/${id}/quote-calculator`)}
                              style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            >
                              create a quote
                            </TextLink>{' '}
                            that matches the System Cost above.
                          </p>
                        </Box>
                      )}
                      <RequirePermissions
                        permissions={[UserPermissions.admin, UserPermissions.editor]}
                        checkAllPermissions={false}
                      >
                        <Box style={{ marginLeft: 'auto' }} direction="row" gap="sm">
                          <Button
                            as="a"
                            href={`/accounts/${id ? id : ''}`}
                            variant="secondary"
                            tone="neutral"
                            size="md"
                            isLoading={isSubmitting || isVoidingSomething}
                          >
                            Cancel
                          </Button>
                          <Button
                            size="md"
                            variant="primary"
                            type="submit"
                            isLoading={isSubmitting || isVoidingSomething}
                          >
                            Save
                          </Button>
                        </Box>
                      </RequirePermissions>
                    </Box>
                  </EnhancedFormikForm>
                );
              }}
            </Formik>
          </Box>
        )}
      </MainContainer>

      <Modal ariaLabelledBy="costMismatch" isOpen={isModalOpen} onDismiss={closeModal}>
        <Modal.Header id="costMismatch" title="Cost Mismatch" onDismiss={closeModal} />
        <Modal.Body>
          The total system design cost does not match the active quote cost. Would you like to void the existing
          quote {activeContract ? 'and active contract' : ''} and create a new one?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" tone="neutral" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmModalSubmit}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
