import { ObjectId } from 'mongodb';
import { OrganizationStatus } from './Organizations';

export enum UserTypes {
  organizational = 'organizational',
  account = 'account',
  machine = 'machine',
  unknown = 'unknown',
}

export enum UserPermissions {
  admin = 'admin',
  canApproveActivation = 'canApproveActivation',
  canApproveInstall = 'canApproveInstall',
  canViewVendorInvoices = 'canViewVendorInvoices',
  editor = 'editor',
  lightReachPaymentAdmin = 'lightReachPaymentAdmin',
  lightReachSupport = 'lightReachSupport',
  lightReachOrgPaymentAdmin = 'lightReachOrgPaymentAdmin',
  lightReachOrgPaymentViewer = 'lightReachOrgPaymentViewer',
  lightReachAccountingAdmin = 'lightReachAccountingAdmin',
  lightReachAccountingApprover = 'lightReachAccountingApprover',
  lightReachAccountingSupport = 'lightReachAccountingSupport',
  lightReachRequirementAdmin = 'lightReachRequirementAdmin',
  lightReachCreditAdmin = 'lightReachCreditAdmin',
  pricingAdmin = 'pricingAdmin',
  orgAccountingAdmin = 'orgAccountingAdmin',
  reader = 'reader',
  reCheckAdmin = 'reCheckAdmin',
  contractAdmin = 'contractAdmin',
  lightReachTaskAdmin = 'lightReachTaskAdmin',
  lightReachPermissionsAdmin = 'lightReachPermissionsAdmin',
  lightReachQCSettingsAdmin = 'lightReachQCSettingsAdmin',
}

export interface User {
  permissions?: UserPermissions[];
  type?: UserTypes;
  organizationId?: string;
  organizationStatus?: OrganizationStatus;
  rootOrganizationId?: string;
  rootOrganizationStatus?: OrganizationStatus;
  accountIds?: ObjectId[];
  id?: string;
}

export interface userExtended extends User {
  username: string;
}

export interface Actor {
  authIdentity: string;
  user: userExtended;
}

export interface UserInfo {
  id: string;
  name: string;
}

export interface UserWithDate {
  user: UserInfo;
  date: Date;
}
