import { QueueFlagsContext } from '@/components/QueueFlags/QueueFlagsContext';
import { Alert, Badge, Box, Button, FormikTextareaInput, Icon, Modal, toast } from '@palmetto/palmetto-components';
import { Field, Form, Formik } from 'formik';
import { useContext, useMemo } from 'react';
import { FlagPurposeType, ProgramType, QueueStatus, QueueType } from 'types';
import * as yup from 'yup';
import { useUpdateQueueMutation } from '../../../services/queues';
import { useNavigate } from 'react-router-dom';

interface CompleteQueueReviewModalFactoryProps {
  handleClose: () => void;
  handleFormSave: () => Promise<void>;
  isForMilestonePackage?: boolean;
  programType?: ProgramType;
}

interface ReviewFormValues {
  status: QueueStatus;
  note?: string;
  exceptionNote?: string;
}

const queueUpdateSchema = yup.object({
  notes: yup.string().optional().nullable(),
  exceptionNote: yup.string().optional().nullable(),
  status: yup.string().required(),
});

const getFlagAlertColor = (flagPurpose?: FlagPurposeType) => {
  switch (flagPurpose) {
    case FlagPurposeType.additionalReview:
      return 'warning';
    case FlagPurposeType.stipulation:
      return 'info';
    default: // rejected and no purpose type
      return 'danger';
  }
};

const getFlagIconColor = (flagPurpose?: FlagPurposeType) => {
  switch (flagPurpose) {
    case FlagPurposeType.additionalReview:
      return 'warning-500';
    case FlagPurposeType.stipulation:
      return 'info-500';
    default: // rejected and no purpose type
      return 'danger-500';
  }
};

const getReviewColor = (status?: string) => {
  switch (status) {
    case QueueStatus.approved:
      return 'success';
    case QueueStatus.rejected:
      return 'danger';
    case QueueStatus.onHold:
      return 'warning';
    case QueueStatus.conditionallyApproved:
      return 'info';
    default:
      return 'default';
  }
};

export const CompleteQueueRevampReviewModal = ({
  handleClose,
  handleFormSave,
  isForMilestonePackage = false,
  programType,
}: CompleteQueueReviewModalFactoryProps) => {
  const { getAllFlags, queue } = useContext(QueueFlagsContext);
  const [updateQueue] = useUpdateQueueMutation();
  const navigate = useNavigate();
  const flags = getAllFlags(false);
  console.log(flags);
  const isActivationQueue = queue?.type === QueueType.activationPackage;
  const isPostActivationQueue = queue?.type === QueueType.postActivationPackage;
  const isFinalMilestonePackage =
    isActivationQueue ||
    isPostActivationQueue ||
    (programType === ProgramType.hvac && queue?.type === QueueType.installPackage);

  const reviewStatus = useMemo(() => {
    if (!flags || !flags.length) {
      return QueueStatus.approved;
    }
    if (
      flags?.some((flag) => flag.purposeType === FlagPurposeType.rejection) ||
      flags.every((flag) => flag.purposeType === undefined)
    ) {
      return QueueStatus.rejected;
    }
    if (flags?.some((flag) => flag.purposeType === FlagPurposeType.additionalReview)) {
      return QueueStatus.onHold;
    }
    if (flags?.some((flag) => flag.purposeType === FlagPurposeType.stipulation)) {
      return QueueStatus.conditionallyApproved;
    }
    return QueueStatus.approved;
  }, [flags]);

  const handleSubmit = async (values: ReviewFormValues) => {
    try {
      const data = {
        status: values.status,
        note: values?.exceptionNote ?? values?.note ?? '',
      };
      await handleFormSave();
      await updateQueue({
        id: queue?.id,
        data,
      }).unwrap();
      toast.success('Review completed.');
      navigate(-1);
      handleClose();
    } catch (e: any) {
      toast.error(e?.data?.message || 'Error completing the review.');
    }
  };

  return (
    <Formik
      initialValues={{ status: reviewStatus }}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={queueUpdateSchema}
      validateOnChange={false}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form noValidate id="completeQueueReview">
          <Modal.Body background="secondary" childGap="lg" fontSize="sm">
            <Box direction="row" gap="sm">
              <Box as="p" fontWeight="bold" color="body-primary">
                Review Status:
              </Box>
              <Badge message={values?.status} variant={getReviewColor(values?.status)} size="lg" />
            </Box>
            {flags?.map((flag) => (
              <Alert
                key={flag.id?.toString()}
                variant={getFlagAlertColor(flag.purposeType)}
                size="sm"
                tone="neutral"
                message={
                  <Box direction="column" gap="sm">
                    <Box direction="row" gap="sm">
                      <Icon name="flag" size="sm" color={getFlagIconColor(flag.purposeType)} />
                      <Box as="span" fontWeight="bold">
                        {flag.category ? `${flag.category}:${flag.type}` : `${flag.dataKey}`} - {flag.reason}
                      </Box>
                    </Box>
                    <Box as="span">{flag.note}</Box>
                  </Box>
                }
              />
            ))}
            <Box childGap="sm">
              <Field
                name="note"
                id="note"
                label="Leave comment (Optional)"
                component={FormikTextareaInput}
                autoComplete="off"
                isDisabled={isSubmitting}
              />
              {isForMilestonePackage &&
              values.status === QueueStatus.rejected &&
              queue?.type === QueueType.ntpPackage ? (
                <Alert
                  variant="warning"
                  message="This comment will display to the EPC. Please include details on all remaining issues, 
                    including those persisting from the Notice to Proceed Package. A notification about this package rejection will be sent."
                />
              ) : programType === ProgramType.hvac ? (
                <Alert variant="warning" message="A notification about this package rejection will be sent." />
              ) : (
                <Alert
                  variant="warning"
                  message="This comment will display to the EPC. Please include details on all remaining issues, 
                    including those persisting from the Install Package. A notification about this package rejection will be sent."
                />
              )}
              {isFinalMilestonePackage && flags?.length && (
                <Field
                  label="[INTERNAL] Exception note"
                  helpText="Visible to package reviewers only and will not be visible to EPC users."
                  name="exceptionNote"
                  id="exceptionNote"
                  component={FormikTextareaInput}
                  autoComplete="off"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value) {
                      setFieldValue('status', QueueStatus.approved);
                    } else {
                      setFieldValue('status', reviewStatus);
                    }
                  }}
                  isDisabled={isSubmitting}
                />
              )}
              <Box
                as="p"
                style={{
                  marginTop: '0.4em',
                }}
              >
                Any fields updated during your review will be saved when Completing Review or Placing On Hold
              </Box>
            </Box>
          </Modal.Body>
          <Modal.Footer justifyContent="flex-end">
            <Box direction="row" gap="sm">
              <Button variant="secondary" tone="neutral" size="md" onClick={handleClose} isDisabled={isSubmitting}>
                Cancel
              </Button>
              {values.status === QueueStatus.onHold ? (
                <Button
                  size="md"
                  variant="primary"
                  tone="neutral"
                  iconPrefix="pause"
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Place on Hold
                </Button>
              ) : (
                <Button size="md" variant="primary" type="submit" isLoading={isSubmitting}>
                  Complete Review
                </Button>
              )}
            </Box>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};
