import FormSection from '@/components/Forms/FormSection';
import { PhotosList } from '../Documents/PhotosList';
import { InstallationPackagePhotoCategory, RequirementFlagSettingsType } from 'types';
import { Box } from '@palmetto/palmetto-components';
import { Link } from 'react-router-dom';

export const SystemPhotos = () => {
  return (
    <>
      <FormSection
        title="New System Photos"
        description={
          <>
            <Box>Upload photos to all applicable categories.</Box>
            <Box>
              Reference <Link to={''}>Install Photo Requirements documentation</Link> for specifics on submission
              requirements{' '}
            </Box>
          </>
        }
      >
        <PhotosList
          title="Project Site"
          description="House Number: Provide photo of the house number to verify location of Installation"
          category={InstallationPackagePhotoCategory.projectSite}
          baseCategory="Installation Photo Documentation"
          type={RequirementFlagSettingsType.projectSiteHVAC}
        />
        <PhotosList
          title="Indoor System"
          description="Complete System Photo, Filter Size and Location, 120V Electrical Switch or 240v Disconnect, 24V Wiring at Circuit Board, Gas valve shutoff (if applicable), Primary float switch (if applicable)"
          category={InstallationPackagePhotoCategory.indoorSystem}
          baseCategory="Installation Photo Documentation"
          type={RequirementFlagSettingsType.indoorSystem}
        />
        <PhotosList
          title="Outdoor System"
          description="Complete Outdoor Unit Photo (Show entire outdoor Unit in one photo including electrical and refrigerant connections), Electrical Connections inside of unit(s), Electrical Connections inside of disconnect"
          category={InstallationPackagePhotoCategory.outdoorSystem}
          baseCategory="Installation Photo Documentation"
          type={RequirementFlagSettingsType.outdoorSystem}
        />
        <PhotosList
          title="Zoning System"
          description="Zone Board, Zone Dampers, Zone Controls of Zone Sensors, Bypass Damper (if applicable)"
          category={InstallationPackagePhotoCategory.zoningSystem}
          baseCategory="Installation Photo Documentation"
          type={RequirementFlagSettingsType.zoningSystem}
        />
        <PhotosList
          title="Thermostat"
          description="Wiring at thermostat, Power up picture, Back of thermostat with model number and serial number (if applicable)"
          category={InstallationPackagePhotoCategory.thermostat}
          baseCategory="Installation Photo Documentation"
          type={RequirementFlagSettingsType.thermostat}
        />
        <PhotosList
          title="Accessories"
          description="Picture of each accessory and data plate (if applicable). e.g. Humidifier, Energy Recovery Ventilator, UV Light, Electronic Air Cleaner"
          category={InstallationPackagePhotoCategory.accessories}
          baseCategory="Installation Photo Documentation"
          type={RequirementFlagSettingsType.accessoriesHVAC}
        />
      </FormSection>
      <FormSection title="Commissioning Photos">
        <PhotosList
          title="Delta T Photo(s)"
          description="Must show both supply and return probe, whether using dual temp or individual probes"
          category={InstallationPackagePhotoCategory.deltaT}
          baseCategory="Installation Photo Documentation"
          type={RequirementFlagSettingsType.temperatureDiffOfReturnAndSupply}
        />
        <PhotosList
          title="External Static Pressure"
          description="Show where readings are captured for Supply and Return Port"
          category={InstallationPackagePhotoCategory.externalStaticPressure}
          baseCategory="Installation Photo Documentation"
          type={RequirementFlagSettingsType.staticPressureReadings}
        />
      </FormSection>
    </>
  );
};
