import {
  ActivationPackagePhotoCategory,
  InstallationPackagePhotoCategory,
  RequirementFlagSettingsType,
} from 'types';
import { BaseRejectionReasons } from './BasePackageTypes';

export type ReviewPackagePhotoCategory = InstallationPackagePhotoCategory | ActivationPackagePhotoCategory;

export enum ReviewPackageType {
  noticeToProceedHVAC = 'noticeToProceedHVAC',
  installation = 'installation',
  activation = 'activation',
}

export type ReviewPhotoSectionType = {
  id: ReviewPackagePhotoCategory;
  title: string;
  description?: string;
  documentationUrl?: string;
  reviewPackageType: ReviewPackageType;
  type: RequirementFlagSettingsType;
};

export enum CodeViolationRejectionReasons {
  codeViolation = 'Code Violation',
}

export enum ReviewPhotoRejectionReasons {
  codeViolation = 'Code Violation',
  poorWorkmanship = 'Poor Workmanship',
}

export const AllInstallPackageRejectionReasons: Record<string, any> = {
  ...BaseRejectionReasons,
  ...CodeViolationRejectionReasons,
  ...ReviewPhotoRejectionReasons,
};

export const reviewPhotoSections: Partial<Record<ReviewPackagePhotoCategory, ReviewPhotoSectionType>> = {
  [InstallationPackagePhotoCategory.projectSite]: {
    id: InstallationPackagePhotoCategory.projectSite,
    title: 'Project Site',
    description:
      'Front of house pullback, front of house with house number clearly visible, permit & plans left on site with context of location...',
    documentationUrl: 'https://help.palmetto.finance/en/articles/8306274-jco-photo-requirements',
    reviewPackageType: ReviewPackageType.installation,
    type: RequirementFlagSettingsType.projectSite,
  },
  [InstallationPackagePhotoCategory.roof]: {
    id: InstallationPackagePhotoCategory.roof,
    title: 'Roof',
    description:
      'Document all module attachment areas, horizon, structural upgrades, flashing, sealant, optimizer/microinverter mounts...',
    documentationUrl: 'https://help.palmetto.finance/en/articles/8306274-jco-photo-requirements#h_6a2daa6d8a',
    reviewPackageType: ReviewPackageType.installation,
    type: RequirementFlagSettingsType.roof,
  },
  [InstallationPackagePhotoCategory.electrical]: {
    id: InstallationPackagePhotoCategory.electrical,
    title: 'Electrical',
    description:
      'Inverter, IQ Combiner Box, Combiner Panel, Sub Panel, AC Disconnect (OCPD), Conduit Run, Grounding Electrode...',
    documentationUrl: 'https://help.palmetto.finance/en/articles/8306274-jco-photo-requirements#h_5cf8ca2e31',
    reviewPackageType: ReviewPackageType.installation,
    type: RequirementFlagSettingsType.electrical,
  },
  [InstallationPackagePhotoCategory.storage]: {
    id: InstallationPackagePhotoCategory.storage,
    title: 'Storage',
    description:
      'CT locations and direction, complete wiring and terminals, transfer switch panels, subpanels related to critical load backups...',
    documentationUrl: 'https://help.palmetto.finance/en/articles/8306274-jco-photo-requirements#h_e507c9eac4',
    reviewPackageType: ReviewPackageType.installation,
    type: RequirementFlagSettingsType.storage,
  },
  [InstallationPackagePhotoCategory.systemCommissioning]: {
    id: InstallationPackagePhotoCategory.systemCommissioning,
    title: 'System Commissioning',
    description:
      'Screenshots of the product commissioning applications should be shown fully paired with active communication...',
    documentationUrl: 'https://help.palmetto.finance/en/articles/8306274-jco-photo-requirements#h_7d6c188065',
    reviewPackageType: ReviewPackageType.installation,
    type: RequirementFlagSettingsType.systemCommissioning,
  },
  [ActivationPackagePhotoCategory.siteImprovements]: {
    id: ActivationPackagePhotoCategory.siteImprovements,
    title: 'Site Improvements',
    description:
      'Screenshots of the product commissioning applications should be shown fully paired with active communication...',
    documentationUrl: 'https://help.palmetto.finance/en/articles/8306274-jco-photo-requirements#h_7d6c188065',
    reviewPackageType: ReviewPackageType.activation,
    type: RequirementFlagSettingsType.siteImprovement,
  },
} as const;

export enum ReviewPackageActions {
  create = 'create',
  edit = 'edit',
  review = 'review',
}
