import { DateTime } from 'luxon';

export const DATE_FORMAT = 'MM-dd-yyyy';

export function convertToISODateTimeString(dateStr: string, dateFormat = DATE_FORMAT) {
  const dt = DateTime.fromFormat(dateStr, dateFormat).toUTC();

  const isoDateString = dt.toISO() || undefined;

  return isoDateString;
}
