import { Box } from '@palmetto/palmetto-components';
import { FilterBar } from '@/components/filters';
import { useCallback, useEffect, useMemo } from 'react';
import { AddFilterButtonDropdown } from '@/components/filters/AddFilterButton';
import { AppliedDataFilter, InvoiceNeededInfo } from 'types';
import { DataFilterDisplay, DataFilterOperation } from '@/components/filters/FilterButton';
import { DataFilterType } from '@/components/filters/SearchableCheckboxFilterList';
import { SortButtonDropdown, SortItem } from '@/components/filters/SortButton';

interface ProjectTransactionsFilterProps {
  invoiceNeededData: InvoiceNeededInfo[];
  appliedFilters?: AppliedDataFilter[];
  setAppliedFilters: (filters: AppliedDataFilter[]) => void;
  searchParams?: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
  searchTerm?: string;
  advancedFilters?: string;
  appliedSort?: SortItem;
  handleSortSelection: (sort: SortItem) => void;
  exportUrl?: string;
  hideSortOptions?: InvoiceNeededSort[];
  hideFilterOptions?: string[];
  placeHolderText?: string;
}
export type InvoiceNeededSort =
  | 'accountIdAsc'
  | 'accountIdDesc'
  | 'materialsProjectIdAsc'
  | 'materialsProjectIdDesc'
  | 'installStatusAsc'
  | 'installStatusDesc'
  | 'activationStatusAsc'
  | 'activationStatusDesc'
  | 'payeeNameAsc'
  | 'payeeNameDesc'
  | 'nameAsc'
  | 'nameDesc';

interface SortOption {
  id: InvoiceNeededSort;
  label: string;
}
export const invoiceNeededSortOptions: Array<SortOption> = [
  {
    id: 'accountIdAsc',
    label: 'Account Id A-Z',
  },
  { id: 'accountIdDesc', label: 'Account Id Z-A' },
  { id: 'nameAsc', label: 'Account Name A-Z' },
  { id: 'nameDesc', label: 'Account Name Z-A' },
  {
    id: 'payeeNameAsc',
    label: 'Licensed Organization A-Z',
  },
  { id: 'payeeNameDesc', label: 'Licensed Organization Z-A' },
  { id: 'materialsProjectIdAsc', label: 'Materials Project Id A-Z' },
  { id: 'materialsProjectIdDesc', label: 'Materials Project Id Z-A' },
  { id: 'installStatusAsc', label: 'Install Milestone Status A-Z' },
  { id: 'installStatusDesc', label: 'Install Milestone Status Z-A' },
  { id: 'activationStatusAsc', label: 'Activation Milestone Status A-Z' },
  { id: 'activationStatusDesc', label: 'Activation Milestone Status Z-A' },
];

const mapAndSortValueSet = (value: Set<string>) => {
  return Array.from(value)
    .map((value) => ({ value, label: value }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const InvoiceNeededFilter = ({
  invoiceNeededData: transactions,
  setAppliedFilters,
  appliedFilters = [],
  searchParams = new URLSearchParams(),
  setSearchParams,
  searchTerm,
  advancedFilters = '',
  appliedSort,
  handleSortSelection,
  hideSortOptions,
  hideFilterOptions,
  placeHolderText,
}: ProjectTransactionsFilterProps) => {
  const filters: DataFilterDisplay[] = useMemo(() => {
    const valueSets = transactions?.reduce(
      (acc, cur) => {
        if (cur.primaryPayeeName) acc.payeeNameSet.add(cur.primaryPayeeName);
        if (cur.installMilestoneStatus) acc.installStatusSet.add(cur.installMilestoneStatus);
        if (cur.activationMilestoneStatus) acc.activationStatusSet.add(cur.activationMilestoneStatus);
        return acc;
      },
      {
        installStatusSet: new Set<string>(),
        activationStatusSet: new Set<string>(),
        payeeNameSet: new Set<string>(),
      },
    );
    return [
      {
        id: 'primaryPayeeName',
        label: 'Licensed Organization',
        type: DataFilterType.Select,
        operations: [DataFilterOperation.Equal, DataFilterOperation.NotEqual],
        selectOptions: mapAndSortValueSet(valueSets?.payeeNameSet),
      },
      {
        id: 'installMilestoneStatus',
        label: 'Install Milestone Status',
        type: DataFilterType.Select,
        operations: [DataFilterOperation.Equal, DataFilterOperation.NotEqual],
        selectOptions: mapAndSortValueSet(valueSets?.installStatusSet),
      },
      {
        id: 'activationMilestoneStatus',
        label: 'Activation Milestone Status',
        type: DataFilterType.Select,
        operations: [DataFilterOperation.Equal, DataFilterOperation.NotEqual],
        selectOptions: mapAndSortValueSet(valueSets?.activationStatusSet),
      },
    ].filter((filter) => !hideFilterOptions?.includes(filter.id));
  }, [transactions, hideFilterOptions]);

  const sortOptions = useMemo(
    () => invoiceNeededSortOptions.filter((option) => !hideSortOptions?.includes(option.id)),
    [hideSortOptions],
  );

  const onSearchChange = useCallback(
    (newSearchTerm: string) => {
      if (newSearchTerm) {
        searchParams.set('search', newSearchTerm);
      } else {
        searchParams.delete('search');
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const handleApplyAdvancedFilters = useCallback(
    (filtersToApply: AppliedDataFilter[]) => {
      if (filtersToApply.some((filter) => filter.selectedValues && filter.selectedValues.length)) {
        searchParams.set('advancedFilters', JSON.stringify(filtersToApply));
        setSearchParams(searchParams);
      }
      setAppliedFilters(filtersToApply);
    },
    [setAppliedFilters, searchParams, setSearchParams],
  );

  const handleRemoveAdvancedFilter = useCallback(
    (filterId: string) => {
      const newFilters = appliedFilters.filter((filter) => filter.id !== filterId);
      if (newFilters.length) {
        searchParams.set('advancedFilters', JSON.stringify(newFilters));
      } else {
        searchParams.delete('advancedFilters');
      }
      setSearchParams(searchParams);
      setAppliedFilters(newFilters);
    },
    [appliedFilters, setAppliedFilters, searchParams, setSearchParams],
  );

  const handleClearAdvancedFilters = useCallback(() => {
    searchParams.delete('advancedFilters');
    setSearchParams(searchParams);
    setAppliedFilters([]);
  }, [setAppliedFilters, searchParams, setSearchParams]);

  useEffect(() => {
    const filters = advancedFilters || JSON.stringify([]);
    setAppliedFilters(JSON.parse(filters));
  }, [advancedFilters, searchTerm]);

  return (
    <Box background="grey-100" radius="sm" direction="row" display="block">
      <FilterBar
        filtersState={{
          searchTerm,
        }}
        handleSearchTerm={onSearchChange}
        placeholder={placeHolderText}
      >
        <Box direction="row" width="100" gap="md" alignItems="center">
          <AddFilterButtonDropdown
            dataFilters={filters}
            appliedFilters={appliedFilters}
            onRemoveFilter={handleRemoveAdvancedFilter}
            onApplyFilters={handleApplyAdvancedFilters}
            onClearFilters={handleClearAdvancedFilters}
          />
          <SortButtonDropdown
            appliedSort={appliedSort || sortOptions[0]}
            sortOptions={sortOptions}
            onSortChange={handleSortSelection}
            className="sortButton"
          />
        </Box>
      </FilterBar>
    </Box>
  );
};
