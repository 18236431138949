import { Box, FormikRadioGroup, FormikTextInput, SimulatedEventPayloadType } from '@palmetto/palmetto-components';
import FormSection from '../Forms/FormSection';
import { SyntheticEvent } from 'react';
import { Field, useFormikContext } from 'formik';

export interface IDataResponse {
  productId: string;
  type: string;
  name: string;
  escalationRate: number;
  monthlyPayments: {
    year: number;
    monthlyPayment: number;
    totalMonthlyPayment: number;
  }[];
  term: number;
  lseId: number;
  state: string;
  programType: string;
}

export interface ISelectionOption {
  label: string;
  value: string;
  id: string;
}

export function EscalatorRateSection({
  rates,
  onChange,
  disabled,
}: {
  rates: ISelectionOption[];
  onChange: (result: string) => void;
  disabled?: boolean;
}) {
  return (
    <FormSection
      title="Select Escalator Rate"
      baseStyle={{
        alignItems: {
          base: 'flex-start',
          tablet: 'flex-start',
          desktop: 'center',
        },
        borderColor: 'transparent',
      }}
    >
      <Box
        direction={{
          base: 'column',
          tablet: 'row',
        }}
        childGap={{ base: 'lg', desktop: 'xl' }}
        style={{
          maxWidth: '10em',
        }}
      >
        <Field
          component={FormikRadioGroup}
          id="rate"
          name="rate"
          options={rates}
          isDisabled={disabled}
          onChange={(data: SyntheticEvent<HTMLInputElement>) => {
            if (typeof (data.target as HTMLInputElement).value === 'undefined') {
              return;
            }
            onChange((data.target as HTMLInputElement).value);
          }}
        />
      </Box>
    </FormSection>
  );
}

export function FormResultSection({ data }: { data: IDataResponse }) {
  return (
    <Box
      style={{
        margin: '1em 0',
      }}
    >
      <Box>
        <Box
          style={{
            fontSize: '12pt',
          }}
        >
          Year 1 Monthly Payment
        </Box>
        <Box
          style={{
            fontSize: '24pt',
          }}
        >
          ${data.monthlyPayments[0].totalMonthlyPayment}/mo
        </Box>
        <Box
          style={{
            fontSize: '12pt',
            fontWeight: 'medium',
          }}
        >
          Customer Payment
        </Box>
      </Box>
    </Box>
  );
}

export function TermFormSection({
  terms,
  onChange,
  isDisabled,
}: {
  terms: ISelectionOption[];
  onChange: (data: ISelectionOption) => void;
  isDisabled?: boolean;
}) {
  return (
    <FormSection
      title="Term (Contract Length)"
      baseStyle={{
        alignItems: {
          base: 'flex-start',
          tablet: 'flex-start',
          desktop: 'center',
        },
        borderColor: 'transparent',
      }}
    >
      <Box
        direction={{
          base: 'column',
          tablet: 'row',
        }}
        childGap={{ base: 'lg', desktop: 'xl' }}
        style={{
          maxWidth: '9.8em',
        }}
      >
        <Field
          component={FormikRadioGroup}
          id="term"
          name="term"
          options={terms}
          isDisabled={isDisabled}
          onChange={(data: SyntheticEvent<HTMLInputElement>) => {
            const selectedValue = (data.target as HTMLInputElement).value;
            if (typeof selectedValue === 'undefined') {
              return;
            }
            const selected = terms.find((element) => {
              return element.value === selectedValue;
            });
            if (selected) {
              onChange(selected);
            }
          }}
        />
      </Box>
    </FormSection>
  );
}

export function TotalFinancedAmountFormSection({
  onChange,
  value,
  disabled,
}: {
  onChange: (value: number) => void;
  value: number;
  disabled?: boolean;
}) {
  const { setFieldValue } = useFormikContext<any>();
  return (
    <FormSection
      title="Total Financed Amount"
      baseStyle={{
        alignItems: {
          base: 'flex-start',
          tablet: 'flex-start',
          desktop: 'center',
        },
        borderColor: 'transparent',
        maxWidth: {
          base: '100',
          desktop: '100',
        },
      }}
    >
      <Box
        direction={{
          base: 'column',
          tablet: 'row',
        }}
        style={{
          maxWidth: '9.8em',
        }}
      >
        <Box>
          <Field
            name="totalFinancedAmount"
            id="totalFinancedAmount"
            component={FormikTextInput}
            prefix="$"
            value={value}
            isDisabled={disabled}
            onChange={(event: SimulatedEventPayloadType) => {
              const value = event.target.value;
              if (value.endsWith('.')) {
                setFieldValue(`totalFinancedAmount`, value);
                onChange(value);
              } else {
                const numericValue = Number(value);
                if (!isNaN(numericValue)) {
                  setFieldValue(`totalFinancedAmount`, value);
                  onChange(value);
                }
              }
            }}
            onBlur={(event: SimulatedEventPayloadType) => {
              const value = parseFloat(event.target.value);
              setFieldValue(`totalFinancedAmount`, isNaN(value) ? 0 : value);
            }}
            onFocus={(event: SimulatedEventPayloadType) => {
              const value = parseFloat(event.target.value);
              if (value === 0) {
                setFieldValue(`totalFinancedAmount`, '');
              }
            }}
          />
        </Box>
      </Box>
    </FormSection>
  );
}
