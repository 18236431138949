import { Box, Button } from '@palmetto/palmetto-components';

export function FormActionsSection({
  isLoading,
  selectedElement,
  isSubmitting,
  onCancel,
}: {
  isLoading: boolean;
  isSubmitting?: boolean;
  selectedElement: any;
  onCancel: () => void;
}) {
  return (
    <Box
      direction={{
        base: 'column',
        tablet: 'row',
      }}
      alignItems={{
        base: 'stretch',
        tablet: 'flex-end',
      }}
      justifyContent={{
        tablet: 'flex-end',
      }}
      childGap="sm"
      style={{ flexShrink: 0 }}
      padding={{ base: 'lg', desktop: 'lg 0 0 0' }}
    >
      <Button
        variant="secondary"
        tone="neutral"
        size="md"
        onClick={onCancel}
        isDisabled={isLoading}
        isLoading={isSubmitting}
      >
        Cancel
      </Button>
      <Button
        size="md"
        variant="primary"
        type="submit"
        isDisabled={isLoading || !selectedElement}
        isLoading={isSubmitting}
      >
        Create Quote
      </Button>
    </Box>
  );
}
