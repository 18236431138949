import { Box, CheckboxInput, FormLabel, RadioGroup, SelectInput, TextInput, Button } from '@palmetto/palmetto-components';
import { FieldArray, useFormikContext } from 'formik';
import { ChangeEvent } from 'react';
import classNames from 'classnames';
import { BatteryPricingMethodOption, BatteryType, ProgramType } from 'types';
import { CreateQuoteFormikContext } from './CreateQuote';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import { LeafConnector } from './Adders';

interface BatteryAdderProps {
  isBatteryAvailable: boolean;
  batteryType: BatteryType;
  label: string;
  manufacturerOptions: { label: string; value: string }[];
  modelOptions: { label: string; value: string }[];
  pricingOptions: { label: string; value: string; id: string }[];
  priceCap?: number;
  isLoading?: boolean;
  programType?: ProgramType;
}
const batteryCountOptions = Array.from(Array(10)).map((_val, index) => ({ value: index + 1, label: index + 1 }));

export const BatteryAdder = ({
  isBatteryAvailable,
  batteryType,
  label,
  manufacturerOptions,
  modelOptions,
  pricingOptions,
  priceCap,
  isLoading,
  programType,
}: BatteryAdderProps) => {
  const { values: { battery }, setFieldValue } = useFormikContext<CreateQuoteFormikContext>();

  return (
    <Box direction="row" wrap style={{ gap: 'var(--size-spacing-lg)' }}>
      <Box flex="auto" direction="column" gap="md">
        {isBatteryAvailable && (
          <CheckboxInput
            id={batteryType}
            name={batteryType}
            label={label}
            onChange={(event) => {
              setFieldValue('productId', undefined);
              setFieldValue('isBatterySelected', event.target.checked);
              if (event.target.checked) {
                setFieldValue('battery', {
                  type: batteryType,
                  manufacturer: '',
                  models: [{ modelNumber: '', quantity: { label: '1', value: 1 } }],
                });
              } else {
                setFieldValue('battery', undefined);
              }
            }}
            isChecked={battery?.type === batteryType}
          />
        )}
        {battery?.type === batteryType && (
          <Box childGap="lg">
            <Box direction="row" padding="0 0 0 sm">
              <LeafConnector />
              <Box
                direction={{ base: 'column', tablet: 'row' }}
                gap="lg"
                style={{ flex: "auto" }}
              >
                <Box style={{ flex: "1" }}>
                  <SelectInput
                    label="Manufacturer"
                    name={`${batteryType}Manufacturer`}
                    id={`${batteryType}Manufacturer`}
                    value={battery?.manufacturer}
                    options={manufacturerOptions}
                    isDisabled={isLoading}
                    isRequired
                    onChange={(event) => {
                      setFieldValue('battery', {
                        ...battery,
                        manufacturer: event.target.value,
                        models: [{ modelNumber: '', quantity: { label: '1', value: 1 } }],
                      });
                    }}
                  />
                </Box>
                <FieldArray
                  name={`${batteryType}models`}
                  render={(arrayHelpers) => (
                    <Box style={{ flex: "1" }} childGap="lg">
                      {battery?.models?.map((model, index) => (
                        <Box
                          key={index}
                          direction="row"
                          style={{ flex: "1" }}
                          gap="lg"
                        >
                          {index > 0 && <Button
                            variant="secondary"
                            tone="neutral"
                            onClick={() => {
                              setFieldValue('battery', {
                                ...battery,
                                models: battery?.models?.filter((_, i) => i !== index),
                              });
                            }}
                            iconPrefix="remove"
                            size="sm"
                            style={{
                              position: "absolute",
                              transform: "translate(-60px, 25px)",
                              padding: "10px"
                            }}
                          />}
                          <Box style={{ flex: "1" }}>
                            <SelectInput
                              label="Model"
                              name={`${batteryType}Model`}
                              id={`${batteryType}Model`}
                              value={model.modelNumber}
                              options={modelOptions}
                              isDisabled={isLoading}
                              isRequired
                              onChange={(event) => {
                                const existingModelData = battery?.models[index] || {
                                  modelNumber: event.target.value,
                                  quantity: { label: '1', value: 1 },
                                };
                                const updatedModelData = {
                                  ...existingModelData,
                                  modelNumber: event.target.value,
                                };
                                const updatedModels = [
                                  ...(battery?.models.slice(0, index) || []),
                                  updatedModelData,
                                  ...(battery?.models.slice(index + 1) || []),
                                ];
                                setFieldValue('battery', {
                                  ...battery,
                                  models: updatedModels,
                                });
                              }}
                            />
                          </Box>
                          <Box>
                            <SelectInput
                              label="Quantity"
                              name={`${batteryType}Quantity`}
                              id={`${batteryType}Quantity`}
                              options={batteryCountOptions}
                              value={model.quantity}
                              isDisabled={isLoading}
                              isRequired
                              onChange={(event) => {
                                const existingModelData = battery?.models[index] || {
                                  modelNumber: '',
                                  quantity: event.target.value,
                                };
                                const updatedModelData = {
                                  ...existingModelData,
                                  quantity: event.target.value,
                                };
                                const updatedModels = [
                                  ...(battery?.models.slice(0, index) || []),
                                  updatedModelData,
                                  ...(battery?.models.slice(index + 1) || []),
                                ];
                                setFieldValue('battery', {
                                  ...battery,
                                  models: updatedModels,
                                });
                              }}
                            />
                          </Box>
                        </Box>
                      ))}
                      <Box/>
                    </Box>
                  )}
                />
              </Box>
            </Box>
            <Button
              onClick={() => {
                setFieldValue('battery', {
                  ...battery,
                  models: [
                    ...(battery?.models || []),
                    { modelNumber: '', quantity: { label: '1', value: 1 } },
                  ],
                });
              }}
              variant="secondary"
              tone="neutral"
              size="sm"
              iconPrefix="add"
              className={classNames({
                'shadow-xs': true,
              })}
              aria-label="add battery"
              style={{ width: 'fit-content', marginLeft: 'auto' }}
            >
              Add Model
            </Button>
            {programType !== ProgramType.doePr && (
              <Box padding="0 0 0 4xl">
                <Box>
                  <FormLabel inputId="pricingMethod">Pricing Method</FormLabel>
                  <RadioGroup
                    name="pricingMethod"
                    description={`How you would like to price the ${label}`}
                    id="pricingMethod"
                    value={battery?.pricingMethod}
                    options={pricingOptions}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('productId', undefined);
                      setFieldValue('battery', {
                        ...battery,
                        pricingMethod: event.target.value,
                      });
                    }}
                  />
                </Box>
                {battery?.pricingMethod === BatteryPricingMethodOption.rawCost && (
                  <Box margin="md 0 0 sm" direction="row" childGap="md">
                    <LeafConnector />
                    <TextInput
                      id="required"
                      value={battery?.cost}
                      type="number"
                      label={`${label} Total Cost`}
                      prefix="$"
                      placeholder="$ e.g. 10000"
                      onChange={(event) => setFieldValue('battery', { ...battery, cost: event.target.value })}
                      isRequired
                      helpText={`${priceCap ? currencyFormatter.format(priceCap) : ''} max total cost`}
                      maxWidth="2xl"
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
