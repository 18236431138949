import { Box, DateInput } from '@palmetto/palmetto-components';
import { useFormikContext } from 'formik';
import FormSection from '../Forms/FormSection';
import { DocumentList } from './Documents/DocumentList';
import { InstallDocumentTypes } from 'types';
import { PermitDescription } from '../InstallMilestonePackage/InstallationFormSectionDescriptions';
import { useCallback } from 'react';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from '@/helpers/dates';

export const Permit = ({ allowArchive }: { allowArchive?: boolean }) => {
  const { isSubmitting, setFieldValue, values, errors, touched } = useFormikContext<any>();

  const handleDateChange = useCallback(
    (date: Date | [Date, Date] | null, field: string) => {
      if (!date || !(date instanceof Date)) return;
      const formattedDate = DateTime.fromJSDate(date).toLocal().toFormat(DATE_FORMAT);
      setFieldValue(field, formattedDate);
    },
    [setFieldValue],
  );

  const maxDate = new Date();
  return (
    <FormSection title="Permit" description={PermitDescription}>
      <DocumentList
        documentTypeFilter={[InstallDocumentTypes.permit]}
        title="Approved Permit"
        showTypeLabelOnItems={false}
        baseCategory={'Permit'}
        allowArchive={allowArchive}
      />
      <Box childGap="lg">
        <DateInput
          id='permitSubmittedDate'
          name='permitSubmittedDate'
          placeholder='MM-DD-YYYY'
          label='Permit Application Submitted'
          helpText="When the application was submitted to the local authority"
          value={values.permitSubmittedDate}
          error={touched.permitSubmittedDate && errors.permitSubmittedDate ? errors.permitSubmittedDate : undefined}
          isDisabled={isSubmitting}
          datePickerProps={{
            onChange: (date) => handleDateChange(date, 'permitSubmittedDate'),
            maxDate,
            selected: values.permitSubmittedDate ? DateTime.fromFormat(values.permitSubmittedDate, DATE_FORMAT).toJSDate() : undefined
          }}
          textInputProps={{
            width: { base: '3xl', tablet: '4xl' },
            inputProps: {
              readOnly: true
            }
          }} />
        <DateInput
          id="permitApprovedDate"
          name="permitApprovedDate"
          placeholder="MM-DD-YYYY"
          label="Permit Application Approval"
          helpText="When the permit was approved by the local authority"
          value={values.permitApprovedDate}
          error={touched.permitApprovedDate && errors.permitApprovedDate ? errors.permitApprovedDate : undefined}
          isDisabled={isSubmitting}
          datePickerProps={{
            onChange: (date) => handleDateChange(date, 'permitApprovedDate'),
            maxDate,
            selected: values.permitApprovedDate ? DateTime.fromFormat(values.permitApprovedDate, DATE_FORMAT).toJSDate() : undefined
          }}
          textInputProps={{
            width: { base: '3xl', tablet: '4xl' },
            inputProps: {
              readOnly: true,
            },
          }}
        />

        <DateInput
          id="installScheduledDate"
          name="installScheduledDate"
          placeholder="MM-DD-YYYY"
          label="Install Date"
          helpText="When the installation was completed at property address"
          value={values.installScheduledDate}
          error={touched.installScheduledDate && errors.installScheduledDate ? errors.installScheduledDate : undefined}
          isDisabled={isSubmitting}
          datePickerProps={{
            onChange: (date) => handleDateChange(date, 'installScheduledDate'),
            maxDate,
            selected: values.installScheduledDate ? DateTime.fromFormat(values.installScheduledDate, DATE_FORMAT).toJSDate() : undefined
          }}
          textInputProps={{
            width: { base: '3xl', tablet: '4xl' },
            inputProps: {
              readOnly: true,
            },
          }}
        />

        <DateInput
          id="expectedPTODate"
          name="expectedPTODate"
          placeholder="MM-DD-YYYY"
          label="Expected PTO Date"
          helpText="When your team anticipates project will be granted PTO from local utility"
          value={values.expectedPTODate}
          error={touched.expectedPTODate && errors.expectedPTODate ? errors.expectedPTODate : undefined}
          isDisabled={isSubmitting}
          datePickerProps={{
            onChange: (date) => handleDateChange(date, 'expectedPTODate'),
            selected: values.expectedPTODate ? DateTime.fromFormat(values.expectedPTODate, DATE_FORMAT).toJSDate() : undefined
          }}
          textInputProps={{
            width: { base: '3xl', tablet: '4xl' },
            inputProps: {
              readOnly: true,
            },
          }}
        />

      </Box>
    </FormSection>
  );
};
