import { Alert, Box, Button, Column, Table } from '@palmetto/palmetto-components';
import FormSection from '../Forms/FormSection';
import { useFormikContext } from 'formik';
import { CreateQuoteFormikContext } from './CreateQuote';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetDOEPRPricingQuery, useGetPricingQuery } from '@/services/pricing';
import { AdderType, BatteryPricingMethodOption, BatteryType, ProgramType } from 'types';
import { useGetAccountQuery } from '@/services/accounts';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import './quoteTable.css';
import { useGetAccountPricing } from './hooks/useGetAccountPricing';
import { useFlags } from 'launchdarkly-react-client-sdk';

type QuoteTableData = {
  productId: string;
  kwhRate: string;
  firstMonthlyPayment: string;
  solarOnlyEPCPayment: string;
  totalEPCPayment?: string;
  batteryMonthlyPayment?: string;
  totalMonthlyPayment?: string;
};

const columnConfig = [
  {
    heading: 'Solar-only Rate ($/kWh)',
    headerClassName: 'wrap',
    dataKey: 'kwhRate',
  },
  {
    heading: 'Estimated solar-only year-1 Mo. Payment',
    dataKey: 'firstMonthlyPayment',
    headerClassName: 'wrap',
  },
  {
    heading: 'Solar-only EPC Rate ($/W)',
    dataKey: 'ppw',
    headerClassName: 'wrap',
  },
  {
    heading: 'Solar-only EPC Total Payment',
    dataKey: 'solarOnlyEPCPayment',
    headerClassName: 'wrap',
  },
];

const columnConfigWithDC = [
  ...columnConfig,
  {
    heading: 'Solar-only EPC Rate ($/W) without DC',
    dataKey: 'ppwWithoutDC',
    headerClassName: 'wrap',
  },
  {
    heading: 'Solar-only EPC Total Payment without DC',
    headerClassName: 'wrap',
    dataKey: 'solarOnlyEPCPaymentWithoutDC',
  },
];

const columnConfigWithDCAndBattery = [
  ...columnConfig,
  {
    heading: 'Solar-only EPC Rate ($/W) without DC',
    dataKey: 'ppwWithoutDC',
    headerClassName: 'wrap',
  },
  {
    heading: 'Solar-only EPC Total Payment without DC',
    headerClassName: 'wrap',
    dataKey: 'solarOnlyEPCPaymentWithoutDC',
  },
  {
    heading: 'Estimated Battery Year 1 Monthly Payment',
    headerClassName: 'wrap',
    dataKey: 'batteryPayment',
  },
  {
    heading: 'Total Year 1 Monthly Payment',
    headerClassName: 'wrap',
    dataKey: 'totalMonthlyPayment',
  },
  {
    heading: 'Total EPC Payment',
    headerClassName: 'wrap',
    dataKey: 'totalEPCPayment',
  },
  {
    heading: 'Total EPC Payment without DC',
    headerClassName: 'wrap',
    dataKey: 'totalEPCPaymentWithoutDC',
  },
];

const columnConfigWithBattery = [
  ...columnConfig,
  {
    heading: 'Estimated Battery Year 1 Monthly Payment',
    headerClassName: 'wrap',
    dataKey: 'batteryPayment',
  },
  {
    heading: 'Total Year 1 Monthly Payment',
    headerClassName: 'wrap',
    dataKey: 'totalMonthlyPayment',
  },
  {
    heading: 'Total EPC Payment',
    headerClassName: 'wrap',
    dataKey: 'totalEPCPayment',
  },
];

export const PricingTable = () => {
  const { monthlyPaymentCap = false } = useFlags();
  const { id } = useParams<{ id: any }>();
  const { data: accountData } = useGetAccountQuery(id);
  const { values, setFieldValue } = useFormikContext<CreateQuoteFormikContext>();
  const { products, showDCComparison, escalationRates, isLoading } = useGetAccountPricing(values);

  useEffect(() => {
    if (
      monthlyPaymentCap &&
      products?.length &&
      products[0].resultsLimitedByMonthlyPaymentCap &&
      !values.pricingOptionsLimitedByMonthlyPaymentCap
    ) {
      setFieldValue('pricingOptionsLimitedByMonthlyPaymentCap', true);
    }
  }, [monthlyPaymentCap, products, values.pricingOptionsLimitedByMonthlyPaymentCap, setFieldValue]);

  const escalationRateAndProductData = useMemo(() => {
    const escalationRateAndProduct: Array<{ escalationRate: number; tableData: QuoteTableData[] }> = [];
    escalationRates?.forEach((rate) => {
      const productsByEscalationRate = products?.filter((product) => product.escalationRate === rate);
      const kwhRateAndEpcAndFirstMonthlyPayment =
        productsByEscalationRate?.map((product) => ({
          productId: product.productId,
          kwhRate: `$${product.kwhRate}`,
          firstMonthlyPayment: currencyFormatter.format(product.monthlyPayments[0].monthlyPayment),
          ppw: currencyFormatter.format(product.ppwRate),
          ppwWithoutDC: currencyFormatter.format(product.ppwWithoutDC),
          solarOnlyEPCPayment: currencyFormatter.format(product.solarOnlyEPCPayment),
          solarOnlyEPCPaymentWithoutDC: currencyFormatter.format(product.solarOnlyEPCPaymentWithoutDC),
          totalEPCPayment: product.totalEPCPayment ? currencyFormatter.format(product.totalEPCPayment) : undefined,
          totalEPCPaymentWithoutDC: product.totalEPCPaymentWithoutDC
            ? currencyFormatter.format(product.totalEPCPaymentWithoutDC)
            : undefined,
          batteryPayment:
            product.monthlyPayments[0]?.batteryPayment !== undefined
              ? currencyFormatter.format(product.monthlyPayments[0].batteryPayment)
              : undefined,
          totalMonthlyPayment:
            product.monthlyPayments[0]?.totalMonthlyPayment !== undefined
              ? currencyFormatter.format(product.monthlyPayments[0].totalMonthlyPayment)
              : undefined,
        })) || [];
      escalationRateAndProduct.push({
        escalationRate: rate,
        tableData: kwhRateAndEpcAndFirstMonthlyPayment,
      });
    });
    const selectedEscalationRateAndProduct =
      values.selectedEscalationRate !== undefined
        ? escalationRateAndProduct.find((products) => products.escalationRate === values.selectedEscalationRate)
        : escalationRateAndProduct[0];
    return selectedEscalationRateAndProduct;
  }, [products, accountData, escalationRates, values.selectedEscalationRate]);

  const selectProductColumn = {
    heading: '',
    dataKey: 'productId',
    sticky: 'right',
    render: (_cell: any, row: QuoteTableData) => (
      <Button
        as="button"
        onClick={() => setFieldValue('productId', row.productId)}
        variant={values.productId !== row.productId ? 'secondary' : 'primary'}
      >
        Select
      </Button>
    ),
  };

  const getColumnConfig = (): Column[] =>
    values?.battery?.pricingMethod === BatteryPricingMethodOption.rawCost
      ? showDCComparison
        ? ([...columnConfigWithDCAndBattery, selectProductColumn] as Column[])
        : ([...columnConfigWithBattery, selectProductColumn] as Column[])
      : showDCComparison
        ? ([...columnConfigWithDC, selectProductColumn] as Column[])
        : ([...columnConfig, selectProductColumn] as Column[]);

  return (
    <FormSection title="Pricing Option">
      {values.pricingOptionsLimitedByMonthlyPaymentCap && (
        <Alert variant="warning" message="Additional price options may be available with a co-applicant"></Alert>
      )}
      <Box display="block">
        <Table
          rowKey="productId"
          columns={getColumnConfig()}
          rows={escalationRateAndProductData?.tableData || []}
          isLoading={isLoading}
          isScrollable={{
            x: true,
          }}
        />
      </Box>
    </FormSection>
  );
};
