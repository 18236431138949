import { AccountStatus } from './Entities';
import { StipulationType } from './Stipulations';
import { WebhookEventType } from './Webhooks';

export enum MilestoneType {
  pending = 'pending',
  customerAgreement = 'customerAgreement',
  initialAccountDetails = 'initialAccountDetails',
  noticeToProceed = 'noticeToProceed',
  install = 'install',
  activation = 'activation',
  postActivation = 'postActivation',
  title = 'title',
  /**
   * @deprecated
   */
  installComplete = 'installComplete',
  /**
   * @deprecated
   */
  installPackageComplete = 'installPackageComplete',
  /**
   * @deprecated
   */
  noticeToProceedIssued = 'noticeToProceedIssued',
  /**
   * @deprecated
   */
  systemActivated = 'systemActivated',
  /**
   * @deprecated
   */
  systemActivation = 'systemActivation',
}

export enum MilestoneReviewStatus {
  approved = 'approved',
  rejected = 'rejected',
}

export const MilestoneLabels = {
  [MilestoneType.pending]: 'Pending',
  [MilestoneType.customerAgreement]: 'Customer Agreement',
  [MilestoneType.initialAccountDetails]: 'Initial Account Details',
  [MilestoneType.noticeToProceed]: 'Notice to Proceed',
  [MilestoneType.install]: 'Install',
  [MilestoneType.activation]: 'Activation',
  [MilestoneType.postActivation]: 'Post Activation',
  [MilestoneType.title]: 'Title',
  [MilestoneType.installComplete]: 'Install Complete',
  [MilestoneType.installPackageComplete]: 'Install Package Complete',
  [MilestoneType.noticeToProceedIssued]: 'Notice to Proceed Issued',
  [MilestoneType.systemActivated]: 'System Activated',
  [MilestoneType.systemActivation]: 'System Activation',
};

export interface MilestoneStage {
  milestone: MilestoneType;
  accountStatuses: AccountStatus[];
  milestoneWebhookEvent?: WebhookEventType;
}

export type MilestoneTemplateType =
  | MilestoneType.customerAgreement
  | MilestoneType.initialAccountDetails
  | MilestoneType.noticeToProceed
  | MilestoneType.install
  | MilestoneType.activation
  | MilestoneType.postActivation;

export interface MilestoneDocument {
  label: string;
  type: MilestoneTemplateType;
}

export interface RequirementDocument {
  label: string;
  type: MilestoneRequirement;
}

export enum MilestoneRequirement {
  activationDocuments = 'activationDocuments',
  activationPackage = 'activationPackage',
  acceptTermsAndConditions = 'acceptTermsAndConditions',
  confirmInstalledSystem = 'confirmInstalledSystem',
  consumerAccountSignUp = 'consumerAccountSignUp',
  consumerOrderReview = 'consumerOrderReview',
  contractSigned = 'contractSigned',
  contractFinalized = 'contractFinalized',
  creditApproved = 'creditApproved',
  customerInfo = 'customerInfo',
  customerRequirements = 'customerRequirements',
  customerSignOff = 'customerSignOff',
  dxfApproved = 'dxfApproved',
  domesticContentConfirmation = 'domesticContentConfirmation',
  electricalPhotosApproved = 'electricalPhotosApproved',
  electricianSignOff = 'electricianSignOff',
  existingSystemDocumentation = 'existingSystemDocumentation',
  extendedWarranty = 'extendedWarranty',
  finalDesignApproved = 'finalDesignApproved',
  incentives = 'incentives',
  incentiveDocumentsApproved = 'incentiveDocumentsApproved',
  identityVerified = 'identityVerified',
  installPackage = 'installPackage',
  installPhotosApproved = 'installPhotosApproved',
  jobCloseOutApproved = 'jobCloseOutApproved',
  monitoringActive = 'monitoringActive',
  monitoringSiteId = 'monitoringSiteId',
  ntpPackage = 'ntpPackage',
  paymentMethodSubmitted = 'paymentMethodSubmitted',
  permitApproved = 'permitApproved',
  planSetApproved = 'planSetApproved',
  productionModelApproved = 'productionModelApproved',
  projectSitePhotosApproved = 'projectSitePhotosApproved',
  proposal = 'proposal',
  ptoConfirmation = 'ptoConfirmation',
  quoteActive = 'quoteActive',
  roofPhotosApproved = 'roofPhotosApproved',
  shadeReportApproved = 'shadeReportApproved',
  siteImprovements = 'siteImprovements',
  storagePhotosApproved = 'storagePhotosApproved',
  systemCommissioningApproved = 'systemCommissioningApproved',
  systemDetailsSubmitted = 'systemDetailsSubmitted',
  titleApproved = 'titleApproved',
  underwriting = 'underwriting',
  utilityBill = 'utilityBill',
  utilityPowerTurnedOnApproved = 'utilityPowerTurnedOnApproved',
  proofOfLoad = 'proofOfLoad',
  workOrderApproved = 'workOrderApproved',
  postActivationPackage = 'postActivationPackage',
  proofOfInstallation = 'proofOfInstallation',
  hvacInstallation = 'hvacInstallation',
  voltageReadings = 'voltageReadings',
  staticPressureReadings = 'staticPressureReadings',
  laserThermometerReading = 'laserThermometerReading',
  salesRepVerification = 'salesRepVerification',
  siteAssessment = 'siteAssessment',
  fsmChecklistApproved = 'fsmChecklistApproved',
  measureQuickSystemVitalsApproved = 'measureQuickSystemVitalsApproved',
  bluetoothHVACToolApproved = 'bluetoothHVACToolApproved',
  indoorSystemApproved = 'indoorSystemApproved',
  outdoorSystemApproved = 'outdoorSystemApproved',
  zoningSystemApproved = 'zoningSystemApproved',
  thermostatApproved = 'thermostatApproved',
  accessoriesApproved = 'accessoriesApproved',
  deltaTApproved = 'deltaTApproved',
  externalStaticPressureApproved = 'externalStaticPressureApproved',
  installationCommissioningDocumentsApproved = 'installationCommissioningDocumentsApproved',
}

export const MilestoneRequirementsToLabels = {
  [MilestoneRequirement.creditApproved]: 'Credit Approved',
  [MilestoneRequirement.titleApproved]: 'Title Approved',
  [MilestoneRequirement.systemDetailsSubmitted]: 'System Details Submitted',
  [MilestoneRequirement.contractSigned]: 'Contract Signed',
  [MilestoneRequirement.contractFinalized]: 'Contract Finalized',
  [MilestoneRequirement.finalDesignApproved]: 'Final Design Approved',
  [MilestoneRequirement.dxfApproved]: 'CAD Drawing Approved',
  [MilestoneRequirement.productionModelApproved]: 'Production Model Approved',
  [MilestoneRequirement.permitApproved]: 'Permit Approved',
  [MilestoneRequirement.shadeReportApproved]: 'Shade Report Approved',
  [MilestoneRequirement.planSetApproved]: 'Plan Set Approved',
  [MilestoneRequirement.projectSitePhotosApproved]: 'Project Site Photos Approved',
  [MilestoneRequirement.roofPhotosApproved]: 'Roof Photos Approved',
  [MilestoneRequirement.electricalPhotosApproved]: 'Electrical Photos Approved',
  [MilestoneRequirement.storagePhotosApproved]: 'Storage Photos Approved',
  [MilestoneRequirement.systemCommissioningApproved]: 'System Commissioning Approved',
  [MilestoneRequirement.jobCloseOutApproved]: 'Job Close Out Approved',
  [MilestoneRequirement.paymentMethodSubmitted]: 'Payment Method Submitted',
  [MilestoneRequirement.identityVerified]: 'Identity Verified',
  [MilestoneRequirement.utilityBill]: 'Utility Bill',
  [MilestoneRequirement.utilityPowerTurnedOnApproved]: 'Utility Power Turned On Approved',
  [MilestoneRequirement.monitoringActive]: 'Monitoring Active',
  [MilestoneRequirement.incentiveDocumentsApproved]: 'Incentive Documents Approved',
  [MilestoneRequirement.proofOfLoad]: 'Proof of Load Approved',
  [MilestoneRequirement.proofOfInstallation]: 'Proof of Installation',
  [MilestoneRequirement.hvacInstallation]: 'HVAC Installation',
  [MilestoneRequirement.voltageReadings]: 'Voltage Readings',
  [MilestoneRequirement.staticPressureReadings]: 'Static Pressure Readings',
  [MilestoneRequirement.laserThermometerReading]: 'Laser Thermometer Reading',
  [MilestoneRequirement.salesRepVerification]: 'Sales Rep Verification',
  [MilestoneRequirement.siteAssessment]: 'Site Assessment',
  [MilestoneRequirement.fsmChecklistApproved]: 'FSM Checklist Approved',
  [MilestoneRequirement.measureQuickSystemVitalsApproved]: 'Measure Quick System Vitals Approved',
  [MilestoneRequirement.bluetoothHVACToolApproved]: 'Bluetooth HVAC Tool Approved',
  [MilestoneRequirement.indoorSystemApproved]: 'Indoor System Photos Approved',
  [MilestoneRequirement.outdoorSystemApproved]: 'Outdoor System Photos Approved',
  [MilestoneRequirement.zoningSystemApproved]: 'Zoning System Photos Approved',
  [MilestoneRequirement.thermostatApproved]: 'Thermostat Photos Approved',
  [MilestoneRequirement.accessoriesApproved]: 'Accessories Photos Approved',
  [MilestoneRequirement.deltaTApproved]: 'Delta T Photos Approved',
  [MilestoneRequirement.externalStaticPressureApproved]: 'External Static Pressure Photos Approved',
  [MilestoneRequirement.installationCommissioningDocumentsApproved]: 'Installation Commissioning Documents',
};

export const ExternalControlledRequirements = [
  MilestoneRequirement.consumerAccountSignUp,
  MilestoneRequirement.consumerOrderReview,
  MilestoneRequirement.customerSignOff,
  MilestoneRequirement.identityVerified,
];

export const IndependentlyUpdatedRequirements = [
  MilestoneRequirement.ptoConfirmation,
  MilestoneRequirement.domesticContentConfirmation,
  MilestoneRequirement.electricianSignOff,
];

export interface MilestoneRequirementObject {
  type: MilestoneRequirement;
  completed: boolean;
  completedAt?: Date;
}

export enum MilestoneStatus {
  approved = 'approved',
  rejected = 'rejected',
  resubmitted = 'resubmitted',
  submitted = 'submitted',
  pending = 'pending',
  paused = 'paused',
}

export interface Milestone {
  name?: string;
  completedAt?: Date;
  requirements: MilestoneRequirementObject[];
  status?: MilestoneStatus;
  type: MilestoneType;
}

export const MilestoneRequirementToStipulationTypeMap = {
  [MilestoneRequirement.paymentMethodSubmitted]: StipulationType.achRequirement,
  [MilestoneRequirement.identityVerified]: StipulationType.identityVerification,
  [MilestoneRequirement.titleApproved]: StipulationType.titleVerification,
};

export type StipulationRequirementType = keyof typeof MilestoneRequirementToStipulationTypeMap;
