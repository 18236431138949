import { PaymentPlan, PaymentPlanOrgs, ProgramType, Clientize } from 'types';
import { api } from './api';

export const paymentPlansApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaymentPlans: build.query<Clientize<PaymentPlan>[], { programType: ProgramType }>({
      query: ({ programType }) => ({ url: `payment-plans?programType=${programType}` }),
      providesTags: (_result, _error, args) => {
        return [{ type: 'PaymentPlans', id: 'LIST' }];
      },
    }),
    getPaymentPlan: build.query<Clientize<PaymentPlan>, { id: string }>({
      query: ({ id }) => ({ url: `payment-plans/${id}` }),
      providesTags: (result, _error, args) => {
        return [{ type: 'PaymentPlans', id: result?.id }];
      },
    }),
    getOrganizationPaymentPlan: build.query<Clientize<PaymentPlan>, { orgAlias: string }>({
      query: ({ orgAlias }) => ({ url: `organizations/${orgAlias}/payment-plan` }),
      providesTags: (result, _error, args) => {
        return [{ type: 'PaymentPlans', id: result?.id }];
      },
    }),
    getPaymentPlanOrgs: build.query<Clientize<PaymentPlanOrgs>[], { id: string }>({
      query: ({ id }) => ({ url: `admin/payment-plan/${id}/orgs` }),
      providesTags: (_result, _error, args) => {
        return [{ type: 'PaymentPlansOrgs', id: 'LIST' }];
      },
    }),
    createPaymentPlan: build.mutation<Clientize<PaymentPlan>, PaymentPlan>({
      query: (body) => ({ url: `payment-plans`, method: 'POST', body }),
      invalidatesTags: [{ type: 'PaymentPlans', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetPaymentPlansQuery,
  useGetPaymentPlanQuery,
  useGetPaymentPlanOrgsQuery,
  useCreatePaymentPlanMutation,
} = paymentPlansApi;
