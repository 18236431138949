import { Helmet } from 'react-helmet';
import { MainContainer } from '../MainContainer';
import { Badge, Box, Card, Pagination, Table, useOpenClose } from '@palmetto/palmetto-components';
import {
  useGetRequirementFlagSettingListFiltersQuery,
  useLazyGetRequirementFlagSettingsQuery,
} from '@/services/requirementFlagSettings';
import { AppliedDataFilter, MilestoneRequirement, ProgramType, ProgramTypeLabels } from 'types';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SkeletonBox } from '../SkeletonBox';
import { FilterBar } from '../filters';
import { AccountsListLoading } from '../AccountsListLoading';
import { EditFlagCriteriaModal } from './EditFlagCriteriaModal';
import { AddFilterButtonDropdown } from '../filters/AddFilterButton';

export const RequirementFlagSettings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelected] = useState<any>(null);
  const [trigger, { data: searchResponse, isLoading, isFetching }] = useLazyGetRequirementFlagSettingsQuery();
  const { requirementFlagSettings, total } = searchResponse || { requirementFlagSettings: [], total: 0 };
  const [appliedFilters, setAppliedFilters] = useState<Array<any>>([]);
  // const { data = [], isLoading: isFiltersLoading } = useGetRequirementFlagSettingListFiltersQuery();
  const {
    isOpen: isEditCriteriaModalOpen,
    handleClose: handleEditCriteriaModalClose,
    handleOpen: handleOpenEditMarketModal,
  } = useOpenClose();

  const handleItemClick = useCallback(
    (row: any) => {
      setSelected(row);
      handleOpenEditMarketModal();
    },
    [handleOpenEditMarketModal],
  );

  const ReviewPackageLabels: Partial<Record<MilestoneRequirement, string>> = {
    [MilestoneRequirement.installPackage]: 'Install Package',
    [MilestoneRequirement.activationPackage]: 'Activation Package',
    [MilestoneRequirement.postActivationPackage]: 'Post-Activation Package',
    [MilestoneRequirement.ntpPackage]: 'Notice to Proceed Package',
  };

  const currentPage = searchParams.get('page');
  const advancedFilters = searchParams.get('advancedFilters');
  const searchTerm = searchParams.get('search') || undefined;

  useEffect(() => {
    const pageNum = currentPage ? Number(currentPage) : 1;
    const filters = advancedFilters || JSON.stringify([]);

    setAppliedFilters(JSON.parse(filters));
    trigger({
      pageNum,
      advancedFilters: filters,
      searchTerm,
    });
  }, [trigger, currentPage, advancedFilters, searchTerm]);

  const handleApplyAdvancedFilters = useCallback(
    (filtersToApply: AppliedDataFilter[]) => {
      if (filtersToApply.some((filter) => filter.selectedValues && filter.selectedValues.length)) {
        searchParams.set('advancedFilters', JSON.stringify(filtersToApply));
        setSearchParams(searchParams);
      }
      searchParams.delete('page');
      setAppliedFilters(filtersToApply);
    },
    [setAppliedFilters, searchParams, setSearchParams],
  );

  const handleRemoveAdvancedFilter = useCallback(
    (filterId: string) => {
      const newFilters = appliedFilters.filter((filter) => filter.id !== filterId);
      if (newFilters.length) {
        searchParams.set('advancedFilters', JSON.stringify(newFilters));
      } else {
        searchParams.delete('advancedFilters');
      }
      searchParams.delete('page');
      setSearchParams(searchParams);
      setAppliedFilters(newFilters);
    },
    [appliedFilters, setAppliedFilters, searchParams, setSearchParams],
  );

  const handleClearAdvancedFilters = useCallback(() => {
    searchParams.delete('advancedFilters');
    searchParams.delete('page');
    setSearchParams(searchParams);
    setAppliedFilters([]);
  }, [setAppliedFilters, searchParams, setSearchParams]);

  const onPageChange = (newPage: number) => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };

  const onSearchChange = (searchTerm: string) => {
    if (searchTerm && searchTerm !== '') {
      searchParams.set('search', searchTerm);
    } else {
      searchParams.delete('search');
    }
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  const columns = [
    {
      heading: 'Status',
      dataKey: 'status',
      width: 100,
      render: (_cell: any, row: any) => (
        <Badge
          variant={row.status === 'active' ? 'success' : row.status === 'inactive' ? 'warning' : 'danger'}
          message={row.status}
        />
      ),
    },
    {
      heading: 'Name',
      width: 120,
      render: (_cell: any, row: any) => (
        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleItemClick(row)}>
          {row.name}
        </span>
      ),
    },
    {
      heading: 'Category',
      width: 120,
      headerClassName: 'wrap',
      render: (_cell: any, row: any) => row.baseCategory,
    },
    {
      heading: 'Found In',
      width: 120,
      headerClassName: 'wrap',
      render: (_cell: any, row: any) =>
        row.meetsAccountRequirements
          ?.map((requirement: MilestoneRequirement) => ReviewPackageLabels[requirement])
          ?.join(', '),
    },
    {
      heading: 'Program Types',
      width: 120,
      headerClassName: 'wrap',
      render: (_cell: any, row: any) =>
        row.programTypes?.map((programType: ProgramType) => ProgramTypeLabels[programType])?.join(', '),
    },
    {
      heading: 'Acceptance Criteria Count',
      width: 120,
      headerClassName: 'wrap',
      render: (_cell: any, row: any) => row.acceptanceCriteria?.length,
    },
  ];

  return (
    <>
      <EditFlagCriteriaModal
        isOpen={isEditCriteriaModalOpen}
        handleClose={handleEditCriteriaModalClose}
        flagSetting={selected}
      />
      <Helmet>
        <title>Manage Requirement Flag Settings</title>
      </Helmet>
      <MainContainer>
        <Card padding="sm">
          {isLoading ? (
            <Box height="52px" alignItems="center" direction="row" childGap="xl">
              <SkeletonBox width="80px" height="14px" />
              <SkeletonBox width="170px" height="14px" />
            </Box>
          ) : (
            <>
              <Card.Header>
                <Box direction="row" justifyContent="space-between">
                  <Box fontSize="md" fontWeight="medium">
                    Requirement Flag Settings
                  </Box>
                </Box>
              </Card.Header>
              <Box margin="0 lg 0 lg">
                <FilterBar
                  filtersState={{
                    searchTerm,
                  }}
                  handleSearchTerm={(searchTerm: string) => onSearchChange(searchTerm)}
                  placeholder="Search name, decription, base category..."
                >
                  {/* {!isFiltersLoading && (
                    <AddFilterButtonDropdown
                      dataFilters={data}
                      appliedFilters={appliedFilters}
                      onRemoveFilter={handleRemoveAdvancedFilter}
                      onApplyFilters={handleApplyAdvancedFilters}
                      onClearFilters={handleClearAdvancedFilters}
                    />
                  )} */}
                </FilterBar>
                {isFetching || isLoading ? (
                  <AccountsListLoading rows={12} />
                ) : (
                  <Table rowKey="id" columns={columns} rows={requirementFlagSettings} isScrollable={{ x: true }} />
                )}
              </Box>
              {requirementFlagSettings?.length > 0 && (
                <Pagination
                  activePage={Number(currentPage) || 1}
                  arePagesVisible
                  itemsPerPage={20}
                  numberOfPagesDisplayed={3}
                  onChange={(page) => onPageChange(page)}
                  totalItemsCount={total ?? 0}
                  isCompact
                />
              )}
            </>
          )}
        </Card>
      </MainContainer>
    </>
  );
};
