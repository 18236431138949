import { CreateProjectTransactionPayload, PaginationElement, PayeeType, UserPermissions } from 'types';
import {
  useGetProjectTransactionsQuery,
  useAddTransactionToBatchMutation,
  useCreateProjectTransactionMutation,
} from '@/services/payouts';
import { Box, Button, Card, Pagination, toast, useOpenClose } from '@palmetto/palmetto-components';
import { FilterableProjectTransactionTable } from '../FilterableProjectTransactionTable';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { AddTransactionToBatchModal } from './AddTransactionToBatchModal';
import { isPalmettoFinanceUser } from '@/components/auth/RequirePricingAdminUser';
import { isErrorWithData, isErrorWithErrors } from '@/services/helpers';
import { useAuth } from '@/components/auth/authProvider';
import { AddProjectTransactionModal } from './AddProjectTransactionModal';
import { RequirePayoutsAccountingAdminUser } from '../../auth/RequirePayoutsAccountingAdminUser';
import { TableLoading } from '@/components/TableLoading';

const defaultPagination = {
  count: 0,
  page: 1,
  limit: 1000,
};

export const AllTransactions = () => {
  const [pagination, setPagination] = useState<PaginationElement>(defaultPagination);
  const { claims } = useAuth();
  const { data, isLoading: isTransactionsLoading } = useGetProjectTransactionsQuery({
    page: `${pagination.page}`,
    limit: `${pagination.limit}`,
  });
  const transactions = data?.transactions;
  const [addTransactionToBatch, { isLoading }] = useAddTransactionToBatchMutation();
  const {
    isOpen: isAddProjectTransactionToBatchModalOpen,
    handleOpen: handleOpenAddProjectTransactionToBatchModal,
    handleClose: handleCloseAddProjectTransactionToBatchModal,
  } = useOpenClose();

  const {
    isOpen: isAddProjectTransactionModalOpen,
    handleOpen: handleOpenAddProjectTransactionModal,
    handleClose: handleCloseAddProjectTransactionModal,
  } = useOpenClose();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [createTransaction] = useCreateProjectTransactionMutation();
  const isLightReachAccountingAdmin =
    isPalmettoFinanceUser(claims) && claims?.permissions?.includes(UserPermissions.lightReachAccountingAdmin);
  const hideColumns = useMemo(() => {
    const columns = ['projectExternalId'];
    if (!isLightReachAccountingAdmin) {
      columns.push('payeeName');
      columns.push('isManual');
      columns.push('tags');
      columns.push('payoutOnHold');
      columns.push('clawbackCategory');
    }
    return columns;
  }, [claims]);

  const handleAddTransactionToBatch = useCallback(async () => {
    try {
      // todo should there be a check for if there are no selectedIds?
      await addTransactionToBatch({ transactionIds: selectedIds }).unwrap();
      toast.success('Transaction added successfully');
      handleCloseAddProjectTransactionToBatchModal();
      setSelectedIds([]);
    } catch (error) {
      if (isErrorWithErrors(error)) {
        const errorMessage = error.data.message;
        toast.error(errorMessage);
      } else if (isErrorWithData(error)) {
        const errorMessage = error.data.message;
        toast.error(errorMessage);
      } else {
        console.error(error);
        toast.error('Could not add transaction(s) to batch');
      }
    }
  }, [selectedIds, addTransactionToBatch, handleCloseAddProjectTransactionModal]);

  const handleAddTransaction = useCallback(async (values: CreateProjectTransactionPayload) => {
    try {
      await createTransaction({
        ...values,
        ...(values.clawbackCategory
          ? { description: `${values.clawbackCategory} - ${values.description ?? ''}` }
          : {}),
      }).unwrap();
      handleCloseAddProjectTransactionModal();
      toast.success('Transaction added successfully');
    } catch (error) {
      console.log(error);
      toast.error('Could not add transaction');
    }
  }, []);

  const handleRowSelected = useCallback(
    (ids: string[], selected: boolean) => {
      if (selected) {
        setSelectedIds([...new Set([...selectedIds, ...ids])]);
      } else {
        setSelectedIds(selectedIds.filter((selectedId) => !ids.includes(selectedId)));
      }
    },
    [selectedIds, setSelectedIds],
  );

  useEffect(() => {
    if (data?.pagination) {
      setPagination(data.pagination);
    }
  }, [data?.pagination]);

  const onPageChange = (newPage: number) => {
    setPagination({ ...pagination, page: newPage });
  };

  return (
    <>
      <AddTransactionToBatchModal
        isOpen={isAddProjectTransactionToBatchModalOpen}
        handleClose={handleCloseAddProjectTransactionToBatchModal}
        modalTitle="Add Transaction to Batch"
        submitButtonText="Add To Batch"
        modalId="addTransactionModal"
        handleSubmit={handleAddTransactionToBatch}
        isAddingBatchToTransaction={isLoading}
      />

      <AddProjectTransactionModal
        isOpen={isAddProjectTransactionModalOpen}
        handleClose={handleCloseAddProjectTransactionModal}
        modalTitle="Add Payout"
        submitButtonText="Add Payout"
        modalId="addPaymentModal"
        handleSubmit={handleAddTransaction}
        batchDate={undefined}
        payeeType={undefined as unknown as PayeeType}
      />

      <Card padding="xs" marginTop="0">
        <Card.Header>
          <Box direction="row" justifyContent="space-between" alignItems="center">
            <Box fontSize="xl" fontWeight="medium">
              All Transactions
            </Box>
            <RequirePayoutsAccountingAdminUser>
              <Box direction="row" childGap="sm" alignItems="center">
                {selectedIds?.length > 0 && (
                  <Button
                    size="sm"
                    prefix="trash"
                    variant="primary"
                    onClick={handleOpenAddProjectTransactionToBatchModal}
                  >
                    Add transaction{selectedIds.length > 1 ? 's' : ''} to Batch
                  </Button>
                )}

                <Button
                  iconPrefix="add"
                  size="sm"
                  variant="secondary"
                  tone="neutral"
                  disabled={isLoading}
                  onClick={handleOpenAddProjectTransactionModal}
                >
                  Add Transaction
                </Button>
              </Box>
            </RequirePayoutsAccountingAdminUser>
          </Box>
        </Card.Header>
        <Box margin="0 lg 0 lg">
          {isTransactionsLoading && <TableLoading rows={12} />}
          {!isTransactionsLoading && transactions && (
            <FilterableProjectTransactionTable
              transactions={transactions}
              handleRowSelected={isLightReachAccountingAdmin ? handleRowSelected : undefined}
              selectedIds={selectedIds}
              hideColumns={hideColumns}
              isCompact
              pagination={
                pagination.count &&
                pagination.limit &&
                pagination.page &&
                Math.ceil(pagination.count / pagination.limit) > 1 && (
                  <Pagination
                    activePage={pagination.page || 1}
                    arePagesVisible={true}
                    itemsPerPage={pagination.limit}
                    numberOfPagesDisplayed={Math.ceil(pagination.count / pagination.limit)}
                    onChange={onPageChange}
                    totalItemsCount={pagination.count}
                    isCompact
                  />
                )
              }
            />
          )}
        </Box>
      </Card>
    </>
  );
};
