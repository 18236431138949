import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Card, Details, Spinner, useBreakpoint, useOpenClose } from '@palmetto/palmetto-components';
import { useGetAccountQuery } from '../../services/accounts';
import { useGetQuotesQuery, useVoidQuoteMutation } from '../../services/quotes';
import { QuoteListItem } from './QuoteListItem';
import QuoteModal from './QuoteModal';
import { MilestoneRequirement, ProgramType, QuoteStatus, UserPermissions } from 'types';
import {
  useGetCurrentContractQuery,
  useSendContractMutation,
  useVoidContractMutation,
} from '../../services/contract';
import RequirePermissions from '../auth/requirePermissions';
import { HvacQuoteListItem } from './HvacQuoteListItem';
import { useMemo } from 'react';
import { StatusIcon } from '../StatusIcon';

export function Quotes({ className, hideHeader }: { className?: string; hideHeader?: boolean }) {
  const navigate = useNavigate();
  const { id } = useParams<{ id: any }>();
  const { data: account } = useGetAccountQuery(id);
  const { data: quotes, isLoading: quotesIsLoading, isError: quotesIsError } = useGetQuotesQuery(
    { id, programType: account?.programType },
    { skip: !account?.programType },
  );
  const { data: contract } = useGetCurrentContractQuery(id);

  const { isOpen: isModalOpen, handleOpen: openModal, handleClose: closeModal } = useOpenClose();
  const { isPhone } = useBreakpoint();
  const quotesWithContract = quotes?.reduce((acc, quote) => {
    return [...acc, { ...quote, contractStatus: contract?.status || undefined, programType: account?.programType }];
  }, []);
  const [_voidQuote, { isLoading: isVoidingQuote }] = useVoidQuoteMutation({
    fixedCacheKey: 'useVoidQuoteMutationKey',
  });
  const [_sendContract, { isLoading: isSendingContract }] = useSendContractMutation({
    fixedCacheKey: 'useSendContractMutationKey',
  });
  const [_voidContract, { isLoading: isVoidingContract }] = useVoidContractMutation({
    fixedCacheKey: 'useVoidContractMutationKey',
  });
  const status = useMemo(
    () =>
      account?.milestones.reduce(
        (acc: any, milestone: any) => {
          const quote = milestone.requirements.find((req: any) => req.type === MilestoneRequirement.quoteActive);
          if (quote) {
            acc.quote = quote.status;
          }
          return acc;
        },
        {} as Record<any, any>,
      ),
    [account],
  );
  const hasActiveOrContractedQuotes = quotesWithContract?.some(
    (quote: any) => quote.status === QuoteStatus.active || quote.status === QuoteStatus.contracted,
  );
  const disableActions = isVoidingQuote || isSendingContract || isVoidingContract;

  return (
    <>
      <Card className={className}>
        {!hideHeader && (
          <Box childGap="lg" padding="lg" alignItems="center" direction="row" justifyContent="space-between">
            <Box alignItems="center" direction="row">
              <Box padding="0 xs 0 0">
                <StatusIcon status={status?.quote} size="md" />
              </Box>
              <Box as="h3" fontWeight="medium" fontSize="md">
                Quotes {quotesWithContract?.length > 0 && `(${quotesWithContract?.length})`}
              </Box>
            </Box>

            {hasActiveOrContractedQuotes ? (
              <RequirePermissions
                permissions={[UserPermissions.admin, UserPermissions.editor]}
                checkAllPermissions={false}
              >
                <Button
                  as="button"
                  onClick={() => {
                    openModal();
                  }}
                  size="sm"
                  variant="primary"
                  tone="neutral"
                  iconPrefix="add"
                  isDisabled={disableActions}
                >
                  {!isPhone && 'Quote'}
                </Button>
              </RequirePermissions>
            ) : (
              <RequirePermissions
                permissions={[UserPermissions.admin, UserPermissions.editor]}
                checkAllPermissions={false}
              >
                <Button
                  as="a"
                  navigate={() =>
                    navigate(
                      account?.programType === ProgramType.hvac
                        ? `/accounts/${id}/quote-calculator`
                        : `/accounts/${id}/quote/create`,
                    )
                  }
                  size="sm"
                  variant="primary"
                  tone="neutral"
                  iconPrefix="add"
                  isDisabled={disableActions}
                >
                  {!isPhone && 'Quote'}
                </Button>
              </RequirePermissions>
            )}
          </Box>
        )}
        <QuotesList isLoading={quotesIsLoading} items={quotesWithContract as any} programType={account?.programType} isError={quotesIsError} />
      </Card>
      <QuoteModal isModalOpen={isModalOpen} closeModal={closeModal} />
    </>
  );
}

export interface QuotesListProps {
  isLoading: boolean;
  isError: boolean;
  items: any[];
  programType: ProgramType;
}

export function QuotesList({ isLoading, items = [], programType, isError }: QuotesListProps) {
  const totalCount = items?.length || 0;
  const mainQuote = items?.[0];
  const sortedQuotes =
    programType === ProgramType.hvac
      ? [...items].sort((a: any, b: any) => {
        const orderA = a.status === QuoteStatus.active || a.status === QuoteStatus.contracted ? 0 : 1;
        const orderB = b.status === QuoteStatus.active || b.status === QuoteStatus.contracted ? 0 : 1;
        return orderA - orderB;
      })
      : items;
  const { isOpen: isShowAllQuotesOpen, handleToggle: handleShowAllQuotesToggle } = useOpenClose();

  if (isError) {
    return (
      <Box
        display="block"
        textAlign="center"
        padding="lg"
        childGap="xl"
        borderColor="separator"
        borderWidth="xs 0 0 0"
      >
        <Box fontSize="sm" color="button-secondary-danger">
          There was an error loading quotes. Please try again or contact support.
        </Box>
      </Box>
    );
  }

  if (isLoading) {
    return <Spinner size="lg" />;
  }

  return (
    <Box width="100">
      {totalCount > 0 ? (
        <Box>
          {programType === ProgramType.hvac && (items?.length > 0 || sortedQuotes?.length > 0) ? (
            <>
              <HvacQuoteListItem item={sortedQuotes?.[0]} isMainQuote={mainQuote} />
              {sortedQuotes?.length > 1 && (
                <Details isOpen={isShowAllQuotesOpen} borderWidth="xs 0 0 0" borderColor="separator">
                  <Details.Summary
                    isDetailsOpen={isShowAllQuotesOpen}
                    onToggle={handleShowAllQuotesToggle}
                    display="flex"
                    padding="sm lg sm lg"
                  >
                    <Box textAlign="center">
                      <Box>{isShowAllQuotesOpen ? 'Hide All Quotes' : 'Show All Quotes'}</Box>
                    </Box>
                  </Details.Summary>
                  {sortedQuotes.slice(1).map((quote: any, index: number) => (
                    <Box key={`quote-${index}`} padding={'0 0 xs 0'}>
                      <HvacQuoteListItem item={quote} />
                    </Box>
                  ))}
                </Details>
              )}
            </>
          ) :
            items.map((item) => <QuoteListItem item={item} key={item.id} />)
          }
        </Box>
      ) : (
        <Box
          display="block"
          textAlign="center"
          padding="lg"
          childGap="xl"
          borderColor="separator"
          borderWidth="xs 0 0 0"
        >
          <Box fontSize="sm" color="body-secondary">
            No quotes exist for this account
          </Box>
        </Box>
      )}
    </Box>
  );
}
