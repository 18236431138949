import {
  IdProviderUser,
  RequirementFlagSettingsDocument,
  RequirementFlagSettingsType,
  RequirementFlagSettingUpdateData,
} from 'types';
import { api } from './api';

interface RequirementFlagSettingsSearchResponse {
  pageNum: number;
  total: number;
  requirementFlagSettings: RequirementFlagSettingsDocument[];
}

export const requirementFlagSettingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRequirementFlagSettings: build.query<RequirementFlagSettingsSearchResponse, Record<any, any>>({
      query: (queryParams) => {
        return {
          url: `admin/quality-control/requirement-flag-settings${
            queryParams &&
            '?' +
              new URLSearchParams({
                ...queryParams,
              })
          }`,
        };
      },
      transformResponse: (response: any) => {
        const { data, pageNum, total } = response || {};
        return {
          pageNum,
          total,
          requirementFlagSettings: data?.requirementFlagSettings || [],
        };
      },
      providesTags: () => [{ type: 'RequirementFlagSettings', id: 'LIST' }],
    }),
    getRequirementFlagSetting: build.query<RequirementFlagSettingsDocument, { id: string }>({
      query: ({ id }) => ({ url: `quality-control/requirement-flag-settings/${id}` }),
      providesTags: (_result, _error, { id }) => [{ type: 'RequirementFlagSettings', id }],
    }),
    updateRequirementFlagSetting: build.mutation<
      RequirementFlagSettingsDocument,
      { id: string; body: RequirementFlagSettingUpdateData }
    >({
      query: ({ id, body }) => ({
        url: `admin/quality-control/requirement-flag-settings/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'RequirementFlagSettings', id: 'LIST' }],
    }),
    getRequirementFlagSettingByType: build.query<
      RequirementFlagSettingsDocument,
      { type?: RequirementFlagSettingsType }
    >({
      query: ({ type }) => ({
        url: `admin/quality-control/requirement-flag-settings/type${type && '?flagSettingType=' + type}`,
      }),
    }),
    getRequirementFlagSettingListFilters: build.query<any, void>({
      query: () => {
        return {
          url: `admin/quality-control/requirement-flag-settings/filters`,
        };
      },
    }),
  }),
});

export const {
  useGetRequirementFlagSettingsQuery,
  useLazyGetRequirementFlagSettingsQuery,
  useGetRequirementFlagSettingListFiltersQuery,
  useUpdateRequirementFlagSettingMutation,
  useGetRequirementFlagSettingQuery,
  useGetRequirementFlagSettingByTypeQuery,
} = requirementFlagSettingsApi;
