import { useAuth } from '@/components/auth/authProvider';
import { isPalmettoFinanceUser } from '@/components/auth/RequirePalmettoFinanceUser';
import { useGetInvoiceNeededProjectsQuery } from '@/services/payouts';
import { Badge, Box, Column, Table, Spinner } from '@palmetto/palmetto-components';
import { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import {
  AccountRequirementStatus,
  AppliedDataFilter,
  DataFilterOperation,
  UserPermissions,
  MilestoneStatus,
} from 'types';
import { InvoiceNeededFilter, invoiceNeededSortOptions } from './InvoiceNeededFilter';

const milestoneStatusMap: {
  [key in MilestoneStatus]: {
    label: string;
    variant: BadgeVariant;
  };
} = {
  [MilestoneStatus.approved]: {
    label: 'Approved',
    variant: 'success',
  },
  [MilestoneStatus.rejected]: {
    label: 'Rejected',
    variant: 'danger',
  },
  [MilestoneStatus.pending]: {
    label: 'Pending',
    variant: 'warning',
  },
  [MilestoneStatus.submitted]: {
    label: 'Submitted',
    variant: 'warning',
  },
  [MilestoneStatus.resubmitted]: {
    label: 'Resubmitted',
    variant: 'warning',
  },
  [MilestoneStatus.paused]: {
    label: 'Paused',
    variant: 'warning',
  },
} as const;
export const InvoiceNeeded = () => {
  const { data: projectsData, isLoading: isProjectDataLoading } = useGetInvoiceNeededProjectsQuery();
  const [appliedFilters, setAppliedFilters] = useState<AppliedDataFilter[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const advancedFilters = searchParams.get('advancedFilters') ?? '';
  const searchTerm = searchParams.get('search') || undefined;
  const sort = (searchParams.get('sort') as any) || 'eventDateAsc';
  const appliedSort = sort ? invoiceNeededSortOptions.find((opt) => opt.id === sort) : invoiceNeededSortOptions[0];
  const handleSortSelection = useCallback(
    (sortSelection: { id: string; label: string }) => {
      searchParams.set('sort', sortSelection.id);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );
  const { claims } = useAuth();
  const isPalmettoAccountingAdmin =
    isPalmettoFinanceUser(claims) && claims?.permissions?.includes(UserPermissions.lightReachAccountingAdmin);
  const columns: Column[] = useMemo(() => {
    const baseColumns: Column[] = [
      { heading: 'Account Id', dataKey: 'accountId' },
      {
        heading: 'Account Name',
        dataKey: 'name',
        render: (cell: any, row: any) => (
          <Link to={`/accounts/${row.accountId}`} target="_blank">
            {cell}
          </Link>
        ),
      },
      {
        heading: 'Materials Project Id',
        dataKey: 'materialsProjectId',
        emptyCellPlaceholder: '-',
        align: 'center',
      },
      {
        heading: 'Install Milestone Status',
        dataKey: 'installMilestoneStatus',
        align: 'center',
        render: (cell: any, row: any) => {
          const { installMilestoneStatus } = row;
          return (
            <Badge
              message={milestoneStatusMap[installMilestoneStatus].label}
              variant={milestoneStatusMap[installMilestoneStatus].variant}
            />
          );
        },
      },
      {
        heading: 'Activation Milestone Status',
        dataKey: 'activationMilestoneStatus',
        align: 'center',
        render: (cell: any, row: any) => {
          const { activationMilestoneStatus } = row;
          return (
            <Badge
              message={milestoneStatusMap[activationMilestoneStatus].label}
              variant={milestoneStatusMap[activationMilestoneStatus].variant}
            />
          );
        },
      },
    ];
    if (isPalmettoAccountingAdmin) {
      baseColumns.splice(2, 0, {
        heading: 'Licensed Organization',
        dataKey: 'primaryPayeeName',
      });
    }
    return baseColumns;
  }, [isPalmettoAccountingAdmin]);
  const displayInvoiceNeededProjects = useMemo(
    () =>
      projectsData
        ?.filter((transaction) =>
          appliedFilters.every((filter) =>
            filter.operation === DataFilterOperation.Equal
              ? filter.selectedValues?.some(
                  (selectedValue) => `${(transaction as any)[filter.id]}` === selectedValue,
                )
              : !filter.selectedValues?.includes((transaction as any)[filter.id]),
          ),
        )
        .filter(
          (transaction) =>
            !searchTerm ||
            Object.values(transaction).some((value) => {
              if (typeof value === 'string') {
                return value.toLowerCase().includes(searchTerm.toLowerCase());
              }
            }),
        )
        .sort((a: any, b: any) => {
          switch (sort) {
            case 'accountIdAsc':
              return a.accountId?.localeCompare(b.accountId);
            case 'accountIdDesc':
              return b.accountId?.localeCompare(a.accountId);
            case 'materialsProjectIdAsc':
              return a.materialsProjectId?.localeCompare(b.materialsProjectId || '');
            case 'materialsProjectIdDesc':
              return b.materialsProjectId?.localeCompare(a.materialsProjectId || '');
            case 'installStatusAsc':
              return a.installMilestoneStatus?.localeCompare(b.installMilestoneStatus);
            case 'installStatusDesc':
              return b.installMilestoneStatus?.localeCompare(a.installMilestoneStatus);
            case 'activationStatusAsc':
              return a.activationMilestoneStatus?.localeCompare(b.activationMilestoneStatus);
            case 'activationStatusDesc':
              return b.activationMilestoneStatus?.localeCompare(a.activationMilestoneStatus);
            case 'payeeNameAsc':
              return a.primaryPayeeName?.localeCompare(b.primaryPayeeName);
            case 'payeeNameDesc':
              return b.primaryPayeeName?.localeCompare(a.primaryPayeeName);
            case 'nameAsc':
              return a.name?.localeCompare(b.name);
            case 'nameDesc':
              return b.name?.localeCompare(a.name);
            default:
              return '';
          }
        }),
    [projectsData, appliedFilters, appliedSort, searchTerm],
  );
  return (
    <>
      <Helmet>
        <title>Accounts Without Invoices</title>
      </Helmet>
      {isProjectDataLoading && (
        <Box alignItems="center">
          <Spinner size="lg" />
        </Box>
      )}
      {!isProjectDataLoading && projectsData && projectsData?.length > 0 && (
        <Box gap="md" background="primary" radius="md" padding="md 0">
          <InvoiceNeededFilter
            invoiceNeededData={projectsData}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            setSearchParams={setSearchParams}
            searchTerm={searchTerm}
            advancedFilters={advancedFilters}
            handleSortSelection={handleSortSelection}
            appliedSort={appliedSort}
            hideSortOptions={isPalmettoAccountingAdmin ? [] : ['payeeNameAsc', 'payeeNameDesc']}
            hideFilterOptions={isPalmettoAccountingAdmin ? [] : ['primaryPayeeName']}
            placeHolderText={
              isPalmettoAccountingAdmin
                ? 'Search by Account Id, Account Name, Materials Project Id, Licensed Organization, Install Status, Activation Status'
                : 'Search by Account Id,Account Name, Materials Project Id, Install Status, Activation Status'
            }
          />
          <Table
            rowKey="id"
            isLoading={isProjectDataLoading}
            columns={columns as Column[]}
            rows={displayInvoiceNeededProjects || []}
          />
        </Box>
      )}
      {!isProjectDataLoading && projectsData && projectsData.length === 0 && (
        <Box textAlign={'center'}>There are no accounts without invoices</Box>
      )}
    </>
  );
};
