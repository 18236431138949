import { Box, Button, Card, FormikTextareaInput, Modal, toast, Toggle } from '@palmetto/palmetto-components';
import { ChangeEvent, useMemo, useState } from 'react';
import { Form } from 'react-router-dom';
import * as yup from 'yup';
import { Field, Formik } from 'formik';
import {
  FlagDisplayTextUpdate,
  RequirementAcceptanceCriterion,
  RequirementAcceptanceProblem,
  RequirementFlagSettingsStatus,
  RequirementFlagSettingsDocument,
  RequirementFlagSettingsItemType,
  RequirementFlagSettingUpdateData,
} from 'types';
import { isErrorWithData } from '../../services/helpers';
import { useUpdateRequirementFlagSettingMutation } from '../../services/requirementFlagSettings';

const flagSettingSchema = yup.object().shape({});

const generateNewProblemFields = ({ count }: { count: number }) => {
  const newFields = [];
  for (let i = 0; i < count; i++) {
    const problemKey = `newProblem-${i}`;
    newFields.push(
      <Card childGap="sm" key={problemKey}>
        <Card.Header>
          <Field
            type="text"
            label={`New Problem ${i + 1}`}
            name={problemKey}
            id={problemKey}
            component={FormikTextareaInput}
          />
        </Card.Header>
      </Card>,
    );
  }
  return newFields;
};

const NewCriterionForm = ({ name }: { name: string }) => (
  <Card overflow="scroll">
    <Card.Header childGap="sm">
      <Box childGap="sm" fontSize="lg" fontWeight="bold" padding="0 0 lg 0">
        {`New ${name} Requirement`}
      </Box>
      <Field type="text" label="Display Text" name="displayText" id="display" component={FormikTextareaInput} />
    </Card.Header>
    <Card.Section childGap="sm">
      <Box borderWidth="0 0 xs 0" borderColor="grey-100" padding="0 0 sm 0" fontWeight="bold">
        New Problems
      </Box>
      {generateNewProblemFields({ count: 5 })}
    </Card.Section>
  </Card>
);

const NewProblemForm = ({ name }: { name: string; parentId: string }) => (
  <Card>
    <Card.Header childGap="sm">
      <Box childGap="sm" fontSize="lg" fontWeight="bold" padding="0 0 lg 0">
        {`New ${name} Problem`}
      </Box>
      <Field type="text" label="Display Text" name="displayText" id="display" component={FormikTextareaInput} />
    </Card.Header>
  </Card>
);

export const EditFlagCriteriaModal = ({
  isOpen,
  handleClose,
  flagSetting,
}: {
  isOpen: boolean;
  handleClose: () => void;
  flagSetting: RequirementFlagSettingsDocument & { id: string };
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [addNewCriterion, setAddNewCriterion] = useState(false);
  const [addNewProblemForCriterion, setNewProblemForCriterion] = useState('');
  const [removingItem, setRemovingItem] = useState('');
  const criteriaProblems: Record<string, Record<string, string>[]> = {};
  const criterionProps: string[] = [];

  const editableSettings = flagSetting?.acceptanceCriteria?.reduce((acc: Record<string, string>, criterion) => {
    const criterionProp = `criterion-${criterion.id.toString()}`;
    criterionProps.push(criterionProp);
    const problems = [];
    acc[criterionProp] = criterion.displayText;

    for (const problem of criterion.problems) {
      const problemProp = `problem-${problem.id.toString()}-${criterionProp}`;
      acc[problemProp] = problem.displayText;
      problems.push({ [problemProp]: problem.displayText });
    }

    if (problems.length > 0) {
      criteriaProblems[criterionProp] = problems;
    }
    return acc;
  }, {});

  const totalCriteriaCount = criterionProps.length;

  const handleDisplayAddCriterion = () => {
    setAddNewCriterion(true);
  };

  const handleDisplayAddProblem = (parentKey: string) => {
    setNewProblemForCriterion(parentKey);
  };

  const handleRemoveItem = (itemKey: string) => {
    setRemovingItem(itemKey);
  };

  const initialValues: any = useMemo(
    () => ({
      name: flagSetting?.name,
      ...editableSettings,
      status: flagSetting?.status,
    }),
    [flagSetting],
  );

  const [updateFlagSetting] = useUpdateRequirementFlagSettingMutation();

  const handleUpdate = async (updates: RequirementFlagSettingUpdateData) => {
    try {
      await updateFlagSetting({
        id: flagSetting?.id,
        body: updates,
      }).unwrap();
      toast.success('Flag setting updated');
      setIsEditing(false);
      handleClose();
    } catch (e) {
      if (isErrorWithData(e)) {
        toast.error(e.data.message);
      } else {
        console.error(e);
        toast.error('Error updating flag Setting');
      }
    }
  };

  const handleSubmit = async (values: any) => {
    let updates = {} as RequirementFlagSettingUpdateData;

    // If changing status, then send it.
    if (initialValues.status !== values.status) {
      updates.status = values.status;
    }

    // Only send display text diffs when status is not being set or is being set to active from inactive.
    if (
      !updates.status ||
      updates.status === RequirementFlagSettingsStatus.active ||
      !addNewCriterion ||
      !addNewProblemForCriterion
    ) {
      const acceptanceCriteria: FlagDisplayTextUpdate[] = [];
      const problems: FlagDisplayTextUpdate[] = [];

      Object.keys(values).forEach((key: string) => {
        if (values[key] !== initialValues[key]) {
          if (key.startsWith('criterion-')) {
            const criterionId = key.split('-')[1];
            acceptanceCriteria.push({ id: criterionId, displayText: values[key] });
          }

          if (key.startsWith('problem-')) {
            const parts = key.split('-');
            problems.push({ id: parts[1], parentId: parts[3], displayText: values[key] });
          }
        }
      });
      updates = {
        ...updates,
        ...(acceptanceCriteria.length > 0 || problems.length > 0
          ? {
              displayTextDiffs: {
                acceptanceCriteria,
                problems,
              },
            }
          : {}),
      };
    }

    if (addNewCriterion && values.displayText) {
      const newProblems: string[] = [];
      Object.keys(values).filter((key) => {
        if (key.startsWith('newProblem-')) {
          newProblems.push(values[key]);
        }
      });
      updates = {
        addItem: {
          itemType: RequirementFlagSettingsItemType.acceptanceCriterion,
          item: {
            displayText: values.displayText,
            ...(newProblems.length > 0
              ? { problems: newProblems.map((problem) => ({ displayText: problem })) }
              : {}),
          },
        },
      };
    }

    if (addNewProblemForCriterion && values.displayText) {
      const newProblems: string[] = [];
      Object.keys(values).filter((key) => {
        if (key.startsWith('newProblem-')) {
          newProblems.push(values[key]);
        }
      });
      updates = {
        addItem: {
          parentId: addNewProblemForCriterion.split('-')[1],
          itemType: RequirementFlagSettingsItemType.problem,
          item: {
            displayText: values.displayText,
            ...(newProblems.length > 0
              ? { problems: newProblems.map((problem) => ({ displayText: problem })) }
              : {}),
          },
        },
      };
    }

    await handleUpdate(updates);

    if (addNewCriterion || addNewProblemForCriterion) {
      setAddNewCriterion(false);
      setNewProblemForCriterion('');
    }
  };

  const handleSubmitRemoval = async () => {
    if (removingItem) {
      const parts = removingItem.split('-');
      const itemType =
        parts.length === 4
          ? RequirementFlagSettingsItemType.problem
          : RequirementFlagSettingsItemType.acceptanceCriterion;
      const update = { removeItem: { itemType, id: parts[1] } };
      await handleUpdate(update);
      setRemovingItem('');
    }
  };

  return (
    initialValues && (
      <Formik
        validationSchema={flagSettingSchema}
        initialValues={initialValues}
        validateOnChange={false}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting, resetForm, setFieldValue, submitForm, values }) => {
          const disableEditing = isSubmitting || values.status === RequirementFlagSettingsStatus.inactive;
          return (
            <Form noValidate>
              <Modal
                isOpen={isOpen}
                maxWidth="4xl"
                ariaLabelledBy="editSettingHeader"
                fullScreenMobile
                onDismiss={() => {
                  resetForm();
                  handleClose();
                }}
              >
                <Modal.Header
                  id="editSettingHeader"
                  title={`"${values.name}" Flag Settings`}
                  onDismiss={() => {
                    resetForm();
                    handleClose();
                  }}
                />
                <Modal.Body background="secondary" childGap="lg">
                  {removingItem ? (
                    <Box as="p">Are you sure you want to remove this item?</Box>
                  ) : addNewCriterion ? (
                    <NewCriterionForm name={values.name} />
                  ) : addNewProblemForCriterion ? (
                    <NewProblemForm name={values.name} parentId={addNewProblemForCriterion} />
                  ) : isEditing ? (
                    <Box childGap={{ base: 'lg', desktop: 'xl' }}>
                      <Toggle
                        id="status"
                        label="Active"
                        isChecked={values?.status === RequirementFlagSettingsStatus.active}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(
                            'status',
                            event.target.checked
                              ? RequirementFlagSettingsStatus.active
                              : RequirementFlagSettingsStatus.inactive,
                          );
                        }}
                      />
                      {totalCriteriaCount > 0 && (
                        <Box fontWeight="bold">{`Total Acceptance Criteria: ${totalCriteriaCount}`}</Box>
                      )}
                      {criterionProps &&
                        criterionProps?.map((prop: string, i: number) => {
                          const problems = criteriaProblems[prop];
                          return (
                            <Card key={prop}>
                              <Card.Header childGap="sm">
                                <Box childGap="sm" fontSize="lg" fontWeight="bold" padding="0 0 lg 0">
                                  {`${values.name} Requirement ${i + 1}`}
                                </Box>
                                <Field
                                  type="text"
                                  label="Display Text"
                                  name={prop}
                                  id={prop}
                                  component={FormikTextareaInput}
                                  isDisabled={disableEditing}
                                />
                              </Card.Header>
                              <Card.Section childGap="sm">
                                {problems.length > 0 && (
                                  <Box
                                    borderWidth="0 0 xs 0"
                                    borderColor="grey-100"
                                    padding="0 0 sm 0"
                                    fontWeight="bold"
                                  >{`Total ${values.name} Problems: ${problems.length} `}</Box>
                                )}
                                {problems.map((problem: any, j: number) => {
                                  const problemKey = Object.keys(problem)[0];
                                  return (
                                    <Card childGap="sm" key={problemKey}>
                                      <Card.Header>
                                        <Field
                                          type="text"
                                          label={`Problem ${j + 1}`}
                                          name={problemKey}
                                          id={problemKey}
                                          component={FormikTextareaInput}
                                          isDisabled={disableEditing}
                                        />
                                      </Card.Header>
                                      <Card.Footer>
                                        <Button
                                          size="xs"
                                          iconPrefix="remove"
                                          variant="secondary"
                                          tone="danger"
                                          isDisabled={disableEditing}
                                          onClick={() => handleRemoveItem(problemKey)}
                                        >
                                          Remove Problem
                                        </Button>
                                      </Card.Footer>
                                    </Card>
                                  );
                                })}
                                <Button
                                  size="sm"
                                  iconPrefix="add"
                                  variant="secondary"
                                  isDisabled={disableEditing}
                                  onClick={() => handleDisplayAddProblem(prop)}
                                >
                                  Add Problem
                                </Button>
                              </Card.Section>
                              <Card.Footer>
                                <Button
                                  size="xs"
                                  variant="primary"
                                  tone="danger"
                                  isDisabled={disableEditing}
                                  onClick={() => handleRemoveItem(prop)}
                                >
                                  Remove Requirement
                                </Button>
                              </Card.Footer>
                            </Card>
                          );
                        })}

                      <Button
                        size="sm"
                        iconPrefix="add"
                        isDisabled={disableEditing}
                        onClick={handleDisplayAddCriterion}
                      >
                        Add Acceptance Criteria
                      </Button>
                    </Box>
                  ) : (
                    <Box childGap="md">
                      <Box childGap="sm" borderColor="grey-100" borderWidth="0 0 xs 0" padding="0 0 md 0">
                        <Box as="h3">Description</Box>
                        <Box>{flagSetting?.description}</Box>
                      </Box>
                      <Box fontWeight="bold" fontSize="md" padding="md 0 0 0">
                        {`${flagSetting?.acceptanceCriteria && flagSetting.acceptanceCriteria.length > 0 ? 'Acceptance Criteria and Problems' : 'No Requirement Criteria Defined'}`}
                      </Box>
                      {flagSetting?.acceptanceCriteria?.map((criterion: RequirementAcceptanceCriterion) => (
                        <Card key={`criterion-list-${criterion?.id}`}>
                          <Card.Header fontWeight="bold">{criterion?.displayText}</Card.Header>
                          <Card.Section padding="0 sm sm sm">
                            <Box as="ul" childGap="sm">
                              {criterion?.problems?.map((problem: RequirementAcceptanceProblem) => (
                                <li key={`problem-list${problem?.id}}`}>{problem?.displayText}</li>
                              ))}
                            </Box>
                          </Card.Section>
                        </Card>
                      ))}
                    </Box>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  {isEditing ? (
                    <>
                      <Button
                        variant="secondary"
                        tone="neutral"
                        isLoading={isSubmitting}
                        isDisabled={isSubmitting}
                        onClick={() => {
                          resetForm();
                          if (removingItem || addNewCriterion || addNewProblemForCriterion) {
                            setRemovingItem('');
                            setAddNewCriterion(false);
                            setNewProblemForCriterion('');
                          } else {
                            setIsEditing(false);
                            handleClose();
                          }
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        isLoading={isSubmitting}
                        isDisabled={isSubmitting}
                        type="submit"
                        onClick={removingItem ? handleSubmitRemoval : submitForm}
                      >
                        {removingItem ? 'Yes' : 'Save Settings'}
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="primary"
                      isLoading={isSubmitting}
                      isDisabled={isSubmitting}
                      type="button"
                      onClick={() => setIsEditing(true)}
                    >
                      Edit
                    </Button>
                  )}
                </Modal.Footer>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    )
  );
};
