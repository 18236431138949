import { Button, FormikTextInput, Modal } from '@palmetto/palmetto-components';
import { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { AddProjectTransactionForm } from '@/components/Payouts/admin/AddProjectTransactionForm';
import { CreateProjectTransactionPayload, IsoDateString, PayeeType } from 'types';

const projectTransactionSchema = yup.object({
  amount: yup
    .number()
    .required('Amount is a required field.')
    .test('isNonZero', 'Amount must not be zero', (value) => value !== 0),
  projectExternalId: yup.string().required('Account ID is a required field.'),
  event: yup.string().required('Payout Event is a required field.'),
  description: yup.string().optional().nullable(),
  clawbackCategory: yup.string().nullable().optional(),
});

export const AddProjectTransactionModal = ({
  isOpen,
  handleClose,
  handleSubmit,
  modalTitle,
  submitButtonText,
  modalId,
  batchDate,
  payeeType,
  accountId,
  hideAccountSelection,
  allowHistoricalAdjustment = false,
}: {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (values: CreateProjectTransactionPayload) => Promise<void>;
  modalTitle: string;
  submitButtonText: string;
  modalId: string;
  payeeType?: PayeeType;
  batchDate?: IsoDateString;
  accountId?: string;
  hideAccountSelection?: boolean;
  allowHistoricalAdjustment?: boolean;
}) => {
  const initialValues: CreateProjectTransactionPayload = useMemo(() => {
    return {
      projectExternalId: accountId ?? '', //accountId
      amount: 0,
      prePayment: false,
      event: '' as unknown as undefined,
      description: '',
      clawbackCategory: '' as unknown as undefined,
    };
  }, []);

  const onSubmit = useCallback(
    async (
      values: CreateProjectTransactionPayload,
      { setSubmitting, resetForm }: FormikHelpers<CreateProjectTransactionPayload>,
    ) => {
      await handleSubmit({ ...values, batchDate });
      setSubmitting(false);
      resetForm();
    },
    [],
  );

  return (
    <Formik
      validationSchema={projectTransactionSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validateOnBlur
    >
      {({ isSubmitting, resetForm, submitForm, values }) => (
        <Form>
          <Modal
            isOpen={isOpen}
            maxWidth="4xl"
            ariaLabelledBy={modalId}
            onDismiss={() => {
              if (!isSubmitting) {
                resetForm();
                handleClose();
              }
            }}
          >
            <Modal.Header
              id={modalId}
              title={modalTitle}
              onDismiss={() => {
                if (!isSubmitting) {
                  resetForm();
                  handleClose();
                }
              }}
            />
            <Modal.Body background="secondary" childGap="lg">
              {!hideAccountSelection && (
                <Field
                  type="text"
                  label="Account ID"
                  name="projectExternalId"
                  id="projectExternalId"
                  component={FormikTextInput}
                  isRequired={true}
                />
              )}
              {values.projectExternalId?.length === 24 && (
                <AddProjectTransactionForm
                  payeeType={payeeType}
                  hideAccountSelection={hideAccountSelection}
                  allowHistoricalAdjustment={allowHistoricalAdjustment}
                />
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                tone="neutral"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                onClick={() => {
                  resetForm();
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                type="submit"
                onClick={submitForm}
              >
                {submitButtonText}
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
