import { IsoDateString } from './Helpers';

export interface PostActivationPackage {
  ptoGrantedDate?: IsoDateString;
}

export enum PostActivationPackageActions {
  create = 'create',
  edit = 'edit',
  review = 'review',
}
