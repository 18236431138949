import { UserWithDate } from './UserTypes';
import { ObjectId } from 'mongodb';
import { RequirementFlagSettingsType } from './';

export enum FlagReason {
  missingDocumentation = 'Missing Documentation',
  illegibleDocumentation = 'Illegible Documentation',
  incorrectDocumentation = 'Incorrect Documentation',
  other = 'Other',
}

/** Used for changedFrom and changedTo in history */
export enum FlagHistoryStatus {
  flagged = 'flagged',
  resolved = 'resolved',
}

export enum FlagPurposeType {
  rejection = 'rejection',
  stipulation = 'stipulation',
  additionalReview = 'additionalReview',
}

export enum FlagPurposeLabel {
  rejection = 'Rejection',
  stipulation = 'Stipulation',
  additionalReview = 'Additional Review',
}

export enum FlagStatus {
  open = 'open',
  closed = 'closed',
  closedExceptionGranted = 'closedExceptionGranted',
  closedNoIssuesFound = 'closedNoIssuesFound',
}

export interface FlagProblem {
  id: ObjectId | string;
  displayText: string;
  acceptanceCriteriaId: ObjectId | string;
}

export interface Flag extends UserWithDate {
  id?: ObjectId;
  reason?: FlagReason;
  problems?: FlagProblem[];
  dataKey: string;
  status?: FlagStatus;
  purposeType?: FlagPurposeType;
  note?: string;
  fileUpload?: string;
  fileName?: string;
  resolved?: boolean;
  baseCategory?: string;
  documentType?: string;
  category?: string;
  type?: RequirementFlagSettingsType;
}

export type FlagPayload = Omit<Flag, 'user' | 'date' | 'resolved'>;
