import { Helmet } from 'react-helmet';
import { MainContainer } from '../MainContainer';
import { Box, Card, Column, Table } from '@palmetto/palmetto-components';
import PageHeader from '../PageHeader';
import { FundType } from 'types';
import { useGetFundsQuery, useGetFundsStatsQuery } from '../../services/funds';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

export function Funds() {
  const { data: fundData = [], isFetching: isFundDataFetching } = useGetFundsQuery({});
  const { data: fundStats = [], isError: isStatsError, isFetching: isFetchingStats } = useGetFundsStatsQuery({});

  const funds = useMemo(() => {
    const defaultStats = {
      inOpenTranche: isFetchingStats ? '-' : 0,
      inClosedTranche: isFetchingStats ? '-' : 0,
      totalAccounts: isFetchingStats ? '-' : 0,
    };

    return fundData.map((fund) => {
      const stats = fundStats.find((stat) => stat._id === fund.fundKey) || defaultStats;
      return {
        ...fund,
        ...stats,
      };
    });
  }, [fundData, fundStats, isFundDataFetching, isStatsError]);
  const fundTypeDisplayName = {
    [FundType.strandedAsset]: 'Stranded Asset',
    [FundType.taxEquity]: 'Tax Equity',
    [FundType.debt]: 'Debt',
    [FundType.jointStrandedAsset]: 'Joint Stranded Asset',
  };
  const columnConfig = [
    {
      heading: 'Name',
      dataKey: 'description',
      render: (_cell: any, row: any) => <Link to={`/admin/funds/${row.fundKey}`}>{row.description}</Link>,
    },
    {
      heading: 'Type',
      dataKey: 'fundType',
      render: (_cell: any, row: any) => <Box>{fundTypeDisplayName[row.fundType as FundType]}</Box>,
      width: 200,
    },
    {
      heading: 'In Tranche',
      dataKey: 'inOpenTranche',
      width: 150,
    },
    {
      heading: 'Sold',
      dataKey: 'inClosedTranche',
      width: 150,
    },
    {
      heading: 'Total Accounts',
      dataKey: 'totalAccounts',
      width: 150,
    },
    {
      heading: 'Tranches',
      dataKey: 'trancheCount',
      width: 200,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Funds</title>
      </Helmet>
      <MainContainer>
        <PageHeader title="Funds"></PageHeader>
        <Card padding="sm">
          {funds.length > 0 ? (
            <Table
              rowKey="fundKey"
              columns={columnConfig as Column[]}
              rows={funds || []}
              isLoading={isFundDataFetching}
              isScrollable={{
                x: true,
              }}
            />
          ) : (
            <Box padding="xl" textAlign="center">
              No funds found
            </Box>
          )}
        </Card>
      </MainContainer>
    </>
  );
}
