import { Box } from '@palmetto/palmetto-components';

interface AdderSectionProps {
  title: string;
  children: React.ReactNode;
}

export const AdderSection: React.FC<AdderSectionProps> = ({ children, title }) => {
  return (
    <Box
      direction={{ base: 'column', tablet: 'row' }}
      alignItems={{ tablet: 'center' }}
      childGap={{ base: 'lg', tablet: '3xl' }}
      width="100"
      radius="md"
      padding="lg"
      background="primary"
      margin="0 0 lg 0"
      borderColor="separator"
      borderWidth="xs"
    >
      <Box width={{ base: '100', tablet: '30' }}>
        {title ? (
          <Box fontSize="md" fontWeight="medium">
            {title}
          </Box>
        ) : null}
      </Box>
      <Box direction={{ base: 'column', tablet: 'column' }} childGap="lg" width={{ base: '100', tablet: '70' }}>
        {children}
      </Box>
    </Box>
  );
};
