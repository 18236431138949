import { useNavigate } from 'react-router-dom';
import { useCreateAccountMutation } from '../../services/accounts';
import { createAccountRequestBody } from '../../helpers/accountRequestHelpers';
import { Box, toast } from '@palmetto/palmetto-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../PageHeader';
import { AccountForm } from './AccountForm';
import { isErrorWithData } from '../../services/helpers';
import { MainContainer } from '../MainContainer';
import { useGetOrganizationDetailsQuery } from '@/services/organizations';
import { useAuth } from '../auth/authProvider';
import { Locale, ProgramType } from 'types';

export const CreateAccount = () => {
  const [createAccount] = useCreateAccountMutation();
  const { claims } = useAuth();
  const { data: organizationData } = useGetOrganizationDetailsQuery(
    {
      alias: claims?.org,
    },
    { skip: !claims?.org },
  );
  const navigate = useNavigate();
  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      await createAccount(createAccountRequestBody(values)).unwrap();
      toast.success('Account created successfully');
      navigate('/accounts');
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(errorMessage);
      } else {
        console.error(e);
        toast.error('Error creating account');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const doePrOnlyOrg =
    organizationData?.programTypes?.length === 1 && organizationData?.programTypes[0] === ProgramType.doePr;
  const defaultInitialLanguage = doePrOnlyOrg ? Locale.es : Locale.en;

  const initialValues = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    lat: '',
    lon: '',
    // applicant info
    primary: {
      firstName: '',
      lastName: '',
      middleName: '',
      phoneNumber: '',
      email: '',
    },
    applicantAddress1: '',
    applicantAddress2: '',
    applicantCity: '',
    applicantState: '',
    applicantZip: '',
    // co-applicant info
    secondary: {
      firstName: '',
      lastName: '',
      middleName: '',
      phoneNumber: '',
      email: '',
      applicantAddress1: '',
      applicantAddress2: '',
      applicantCity: '',
      applicantState: '',
      applicantZip: '',
    },
    // sales rep info
    salesRepName: '',
    salesRepLicenseNumber: '',
    salesRepEmail: '',
    reference: '',
    programReferenceId: '',
    programType: '',
    language: defaultInitialLanguage,
    // hvac property details
    homeSize: '',
    existingSystems: '',
    // utility info
    lseId: '',
    tariffId: '',
    rate: '',
  };

  return (
    <>
      <Helmet>
        <title>Create Account</title>
      </Helmet>
      <MainContainer>
        <PageHeader title="Create a New Account" />
        <Box
          padding={{
            base: '0 lg',
            tablet: '0',
          }}
        >
          <AccountForm
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            hasAdditionalApplicantData={false}
            programTypes={organizationData?.programTypes ?? []}
          />
        </Box>
      </MainContainer>
    </>
  );
};
