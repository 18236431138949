import { useParams } from 'react-router-dom';
import { useGetAccountQuery } from '../../services/accounts';
import { useGetAddersQuery } from '../../services/adders';
import { Box } from '@palmetto/palmetto-components';
import { PropertyBox } from '../Account/components/PropertyBox';
import { ProgressStatusHeader } from '../Account/components/ProgressStatusHeader';
import { AdderType } from 'types';

interface AddersSidebarContentProps {
  selectedAddersMap: Record<AdderType, boolean>;
}
export const AddersSidebarContent = ({ selectedAddersMap }: AddersSidebarContentProps) => {
  const { id } = useParams<{ id: string }>();
  const accountId = id as string;
  const { data: account } = useGetAccountQuery(accountId);
  const { data: addersData } = useGetAddersQuery(accountId);
  const { arbitrageBattery, backupBattery, electricalUpgrade, solarReadiness } = selectedAddersMap || {};

  return (
    <>
      <ProgressStatusHeader milestones={account?.milestones} />
      {(arbitrageBattery || backupBattery) && (
        <>
          {addersData?.adders
            .filter(
              (batteryAdder) =>
                batteryAdder.type === AdderType.backupBattery || batteryAdder.type === AdderType.arbitrageBattery,
            )
            .map((adder, index) => {
              const { type, manufacturer, model, quantity } = adder;
              const typeLabel = type === AdderType.arbitrageBattery ? 'Arbitrage' : 'Backup';

              return (
                <PropertyBox
                  key={`${type}-${model}-${index}`}
                  title={
                    <>
                      <Box fontWeight="medium">{typeLabel} Battery</Box>
                      <Box fontSize="xs" color="body-secondary">
                        {quantity ?? '--'}
                      </Box>
                    </>
                  }
                >
                  <Box fontSize="xs" color="body-secondary">
                    {`${manufacturer} ${model}`}
                  </Box>
                </PropertyBox>
              );
            })}
        </>
      )}
      {electricalUpgrade && <PropertyBox title={<Box fontWeight="medium">Electrical Upgrade</Box>} />}
      {solarReadiness && <PropertyBox title={<Box fontWeight="medium">Solar Readiness</Box>} />}
    </>
  );
};
