import { useGetAccountQuery } from '@/services/accounts';
import { useGetDOEPRPricingQuery, useGetPricingQuery } from '@/services/pricing';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { AdderType, BatteryPricingMethodOption, BatteryType, ProgramType } from 'types';
import { CreateQuoteFormikContext } from '../CreateQuote';

export const useGetAccountPricing = (values?: CreateQuoteFormikContext) => {
  const { id } = useParams<{ id: any }>();
  const { data: accountData } = useGetAccountQuery(id);

  // this is only used to fetch doePR pricing
  const selectedAdders = useMemo(() => {
    const adders = [];
    if (accountData?.programType === ProgramType.doePr) {
      if (values?.electricalUpgradeIncluded) {
        adders.push(AdderType.electricalUpgrade);
      }
      if (values?.solarReadinessIncluded) {
        adders.push(AdderType.solarReadiness);
      }
      if (values?.battery) {
        if (values.battery.type === BatteryType.backupBattery) {
          if (values.battery.pricingMethod === BatteryPricingMethodOption.rawCost) {
            adders.push(AdderType.backupBattery);
          } else {
            adders.push(AdderType.backupBatteryKwh);
          }
        } else if (values.battery.type === BatteryType.arbitrageBattery) {
          if (values.battery.pricingMethod === BatteryPricingMethodOption.rawCost) {
            adders.push(AdderType.backupBattery);
          } else {
            adders.push(AdderType.backupBatteryKwh);
          }
        }
      }
    }
    return adders;
  }, [values, accountData]);

  const {
    data: productsST,
    isLoading: pricingIsLoadingST,
    error: pricingLoadingErrorST,
  } = useGetPricingQuery(
    {
      id,
      ...(values?.battery && values.battery.type && values.battery.pricingMethod
        ? {
            batteryType: values?.battery?.type,
            batteryPricingMethod: values?.battery?.pricingMethod,
            batteryCost: values.debouncedBatteryCost,
          }
        : {}),
      electricalUpgradeIncluded: values?.electricalUpgradeIncluded,
      includeDomesticContentPricing: true,
    },
    {
      skip: typeof accountData?.programType === undefined || accountData?.programType === ProgramType.doePr,
    },
  );
  const {
    data: productsWithoutDcST,
    isLoading: pricingIsLoadingWithoutDcST,
    error: pricingLoadingErrorWithoutDcST,
  } = useGetPricingQuery(
    {
      id,
      ...(values?.battery && values.battery.type && values.battery.pricingMethod
        ? {
            batteryType: values?.battery?.type,
            batteryPricingMethod: values?.battery?.pricingMethod,
            batteryCost: values.debouncedBatteryCost,
          }
        : {}),
      electricalUpgradeIncluded: values?.electricalUpgradeIncluded,
      includeDomesticContentPricing: false,
    },
    {
      skip: typeof accountData?.programType === undefined || accountData?.programType === ProgramType.doePr,
    },
  );

  const {
    data: productsPR,
    isLoading: pricingIsLoadingPR,
    error: pricingLoadingErrorPR,
  } = useGetDOEPRPricingQuery(
    {
      id,
      adders: selectedAdders,
      backupBatteryManufacturer: (values?.battery?.manufacturer as any)?.value,
      backupBatteryModelNumber: (values?.battery?.models?.[0]?.modelNumber as any)?.value,
      backupBatteryQuantity: (values?.battery?.models?.[0]?.quantity as any)?.value,
      solarReadinessCost: values?.solarReadinessCost,
    },
    {
      skip: typeof accountData?.programType === 'undefined' || accountData?.programType !== ProgramType.doePr,
    },
  );

  const isPricingLoading = pricingIsLoadingST || pricingIsLoadingWithoutDcST || pricingIsLoadingPR;
  const isPricingError = pricingLoadingErrorST || pricingLoadingErrorWithoutDcST || pricingLoadingErrorPR;

  const standardSolarProducts = useMemo(() => {
    if (!productsST || !productsWithoutDcST) return [];

    const combinedProducts = productsST.reduce((acc: any, product: any) => {
      const matchingProduct = productsWithoutDcST.find((p: any) => p.productId === product.productId);
      if (!matchingProduct) return acc;
      return [
        ...acc,
        {
          ...product,
          ppwWithoutDC: matchingProduct.ppwRate,
          solarOnlyEPCPaymentWithoutDC: matchingProduct.solarOnlyEPCPayment,
          totalEPCPaymentWithoutDC: matchingProduct.totalEPCPayment,
        },
      ];
    }, []);
    return combinedProducts;
  }, [productsST, productsWithoutDcST]);

  const products: any[] | undefined =
    accountData?.programType === ProgramType.doePr ? productsPR : standardSolarProducts;

  const escalationRates = useMemo(() => {
    const rates = new Set<number>();
    if (products) {
      products.forEach((product) => {
        if (product.escalationRate !== undefined) {
          rates.add(product.escalationRate);
        }
      });
    }
    return Array.from(rates).sort((a: number, b: number) => a - b);
  }, [products]);

  const showDCComparison = useMemo(() => {
    return productsST?.find((product) => product.domesticContentEnabled);
  }, [productsST]);

  return { escalationRates, products, isLoading: isPricingLoading, error: isPricingError, showDCComparison };
};
