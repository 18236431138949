import { RuleProperties } from 'json-rules-engine';
import { ObjectId, WithId } from 'mongodb';
import {
  AccountMilestoneStatus,
  Applicant,
  ClawbackCategory,
  IsoDateString,
  Meta,
  MilestoneType,
  PayoutHold,
  PayoutHoldHistory,
  ProgramType,
  QuoteDocument,
  WithObjectIdsAsStrings,
} from '.';

export enum PayeeType {
  organization = 'organization',
  vendor = 'vendor',
}

export interface PayeePlanHistoryEntry {
  date: Date;
  planId: string;
  user: { id: string; name: string };
}

export interface PayeeDocument {
  id?: string;
  externalId: string; // org alias
  type: PayeeType; // this needs to be the enum we create to define org types
  name: string;
  planId?: string;
  planHistory?: Array<PayeePlanHistoryEntry>;
  payoutHold?: PayoutHold;
  payoutHoldHistory?: PayoutHoldHistory;
  metadata?: Record<string, any>;
  meta?: Meta;
}

export interface PaymentPlanDocument {
  id?: string;
  /** LR accountId */
  externalId: string;
  name: string;
  programType: ProgramType;
  meta?: Meta;
  payments: any[];
}

export enum PayeeProjectRole {
  primary = 'primary',
  materials = 'materials',
  //inspection = 'inspection',?
}

export enum MaterialsInvoiceStatus {
  pending = 'pending',
  missing = 'missing', // project has reached a milestone but materials invoice is missing, so we can't pay
  received = 'received',
}

export interface ProjectDocument {
  id?: string;
  /** LR accountId */
  externalId: string;
  name: string;
  totalCost: number;
  metadata?: Record<string, any>;
  meta?: Meta;
  programType: ProgramType;
  planId?: string;
  projectDetails?: SolarProjectDetails | Record<string, any>;
  payees: Array<{ id: ObjectId; role: PayeeProjectRole; name: string }>;
  materials?: { invoiceStatus?: MaterialsInvoiceStatus };
}
export interface ProjectTransactionRejection {
  date: Date;
  reason: string;
  rejectedBy: { id: string; name: string };
}

export enum ProjectTransactionStatus {
  open = 'open', // not in a batch
  paid = 'paid',
  pending = 'pending', // added to batch
  approved = 'approved', // approved to be paid
  migrated = 'migrated', // migrated from past events
  historical = 'historical', // historical estimate
  adjustment = 'adjustment', // historical adjustment
  paused = 'paused', // paused by accounting
  // cancelled?
  // error?
}

export const ProjectTransactionStatusMap: Record<ProjectTransactionStatus, string> = {
  [ProjectTransactionStatus.open]: 'Open',
  [ProjectTransactionStatus.paid]: 'Paid',
  [ProjectTransactionStatus.pending]: 'Pending',
  [ProjectTransactionStatus.approved]: 'Approved',
  [ProjectTransactionStatus.migrated]: 'Migrated',
  [ProjectTransactionStatus.historical]: 'Historical Est.',
  [ProjectTransactionStatus.adjustment]: 'Historical Adjustment',
  [ProjectTransactionStatus.paused]: 'Paused',
};

export enum PayoutEventType {
  milestone = 'milestone',
  materials = 'materials',
}

export enum PayoutEvent {
  noticeToProceed = 'noticeToProceed',
  noticeToProceedPlus = 'noticeToProceedPlus', // notice to proceed and permit submitted
  estimate = 'estimate',
  invoice = 'invoice',
  installSubmitted = 'installSubmitted',
  installApproved = 'installApproved',
  activationApproved = 'activationApproved',
  other = 'other',
}

export const PayoutEventGroups: { [K in PayoutEvent]?: Array<PayoutEvent> } = {
  [PayoutEvent.noticeToProceed]: [PayoutEvent.noticeToProceed, PayoutEvent.noticeToProceedPlus],
  [PayoutEvent.noticeToProceedPlus]: [PayoutEvent.noticeToProceed, PayoutEvent.noticeToProceedPlus],
  [PayoutEvent.installSubmitted]: [PayoutEvent.installSubmitted, PayoutEvent.installApproved],
  [PayoutEvent.installApproved]: [PayoutEvent.installSubmitted, PayoutEvent.installApproved],
  [PayoutEvent.activationApproved]: [PayoutEvent.activationApproved],
};

export const MilestonePayoutEventMap: { [K in MilestoneType]?: Array<PayoutEvent> } = {
  [MilestoneType.noticeToProceed]: [PayoutEvent.noticeToProceed, PayoutEvent.noticeToProceedPlus],
  [MilestoneType.install]: [PayoutEvent.installSubmitted, PayoutEvent.installApproved],
  [MilestoneType.activation]: [PayoutEvent.activationApproved],
};

export const PayoutEventMap: Record<
  ProgramType,
  Array<{
    event: PayoutEvent;
    name: string;
    order: number;
    badgeVariant: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'warning' | 'info' | 'success' | 'default'; //BadgeVariant from palmetto-components
  }>
> = {
  [ProgramType.solar]: [
    { event: PayoutEvent.noticeToProceed, name: 'NTP', order: 1, badgeVariant: 'warning' },
    { event: PayoutEvent.noticeToProceedPlus, name: 'NTP+', order: 2, badgeVariant: 'danger' },
    { event: PayoutEvent.installSubmitted, name: 'Install Submitted', order: 3, badgeVariant: 'tertiary' },
    { event: PayoutEvent.installApproved, name: 'Install Approved', order: 4, badgeVariant: 'secondary' },
    { event: PayoutEvent.activationApproved, name: 'Activation Approved', order: 5, badgeVariant: 'primary' },
    { event: PayoutEvent.other, name: 'Other', order: 6, badgeVariant: 'default' },
  ],
  [ProgramType.doePr]: [
    { event: PayoutEvent.noticeToProceed, name: 'NTP', order: 1, badgeVariant: 'warning' },
    { event: PayoutEvent.noticeToProceedPlus, name: 'NTP+', order: 2, badgeVariant: 'danger' },
    { event: PayoutEvent.installSubmitted, name: 'Install Submitted', order: 3, badgeVariant: 'tertiary' },
    { event: PayoutEvent.installApproved, name: 'Install Approved', order: 4, badgeVariant: 'secondary' },
    { event: PayoutEvent.activationApproved, name: 'Activation Approved', order: 5, badgeVariant: 'primary' },
    { event: PayoutEvent.other, name: 'Other', order: 6, badgeVariant: 'default' },
  ],
  [ProgramType.hvac]: [
    { event: PayoutEvent.installApproved, name: 'Install Approved', order: 1, badgeVariant: 'secondary' },
    { event: PayoutEvent.other, name: 'Other', order: 2, badgeVariant: 'default' },
  ],
  [ProgramType.newHomes]: [
    { event: PayoutEvent.noticeToProceed, name: 'NTP', order: 1, badgeVariant: 'warning' },
    { event: PayoutEvent.noticeToProceedPlus, name: 'NTP+', order: 2, badgeVariant: 'danger' },
    { event: PayoutEvent.installSubmitted, name: 'Install Submitted', order: 3, badgeVariant: 'tertiary' },
    { event: PayoutEvent.installApproved, name: 'Install Approved', order: 4, badgeVariant: 'secondary' },
    { event: PayoutEvent.activationApproved, name: 'Activation Approved', order: 5, badgeVariant: 'primary' },
    { event: PayoutEvent.other, name: 'Other', order: 6, badgeVariant: 'default' },
  ],
};

export interface ProjectTransactionHistory {
  date: Date;
  user: { id: string; name: string };
  summary?: string;
  changes?: Record<string, any>;
}

export interface PayoutCalculationDetails {
  remainingProjectFunds: number;
  currentTotalCost: number;
  asString: string;
  variables: Record<string, number>;
}

export enum PayoutsCollection {
  payees = 'payees',
  projects = 'projects',
  projectClawbacks = 'projectClawbacks',
  projectTransactions = 'projectTransactions',
  batches = 'batches',
}

export interface PayoutRuleProperties extends RuleProperties {
  appliesToEvents: Array<PayoutEvent>;
}

export interface SolarProjectDetails {
  inverterManufacturer?: string;
  inverterModel?: string;
}

export enum ProjectTransactionDeleteReason {
  planChange = 'planChange',
  deleteHistoricOrgMaterialsPayoutAndCreateNewTransaction = 'deleteHistoricOrgMaterialsPayoutAndCreateNewTransaction',
}

export interface ProjectTransactionDocument {
  id?: string;
  projectId: ObjectId;
  /** LR accountId */
  projectExternalId: string;
  payeeId: ObjectId;
  payeeType: PayeeType;
  payeeName?: string; // not in mongo collection, might want to move this to another type
  /** org alias */
  payeeExternalId?: string;
  programType: ProgramType;
  amount: number;
  calculation?: PayoutCalculationDetails;
  batchId?: ObjectId;
  planId?: ObjectId;
  rejections?: Array<ProjectTransactionRejection>;
  status: ProjectTransactionStatus;
  meta?: Meta;
  prePayment: boolean;
  eventType: PayoutEventType;
  event: PayoutEvent; // should restrict by payout type
  originRule?: string; // rule that created this transaction
  description?: string;
  history?: Array<ProjectTransactionHistory>;
  eventDate: Date;
  approvedDate?: Date;
  /** @deprecated */
  clawbackCategory?: ClawbackCategory;
  clawbackRuleName?: ProjectClawbackRuleNames;
  isManual?: boolean;
  payeePlanId?: string;
}

export type AdditionalPayoutData = {
  payeeName?: string;
  projectName?: string;
  batchDate?: string;
  payoutOnHold?: boolean;
  organizationPlanId?: string;
  organizationPlanName?: string;
};

export type ProjectTransactionWithAdditionalData = ProjectTransactionDocument & AdditionalPayoutData;

export type ProjectDocumentWithAdditionalData = ProjectDocument & AdditionalPayoutData & { payeePlanId?: string };

export type PaginationElement = {
  count: number;
  page: number;
  limit: number;
};

export interface BatchReview {
  status: BatchReviewStatus.approved | BatchReviewStatus.rejected | BatchReviewStatus.unapproved;
  user: { id: string; name: string };
  date: Date;
  changedFrom: any;
  changedTo: any;
}

export enum PaymentTransactionProvider {
  bofa = 'bofa',
}

export enum bofaTransactionStatus { // this should come from the bofa payments package in the future
  processingByBank = 'Processing By Bank',
  rejected = 'Rejected',
  settlementComplete = 'Settlement Complete',
  approved = 'Approved',
  cancelled = 'Cancelled',
  receivedByBank = 'Received By Bank',
}

export enum BatchReviewStatus {
  approved = 'approved',
  unapproved = 'unapproved',
  rejected = 'rejected',
}

export interface BatchDocument {
  id?: string;
  payeeId: ObjectId;
  payeeExternalId: string;
  payeeType: PayeeType;
  bankTransaction?: {
    provider: PaymentTransactionProvider;
    id: string;
    status: bofaTransactionStatus;
    statusHistory: Array<{ id: string; status: bofaTransactionStatus; statusDate: Date }>;
  };
  review?: BatchReview;
  reviewHistory?: Array<BatchReview>;
  batchDate: IsoDateString;
  cutoffDate?: Date; // only batches created by automation will have a cutoff
  meta?: Meta;
}

export interface BatchInfo {
  id: IsoDateString;
  batchDate: IsoDateString;
  payeeType: PayeeType;
  payees: number;
  transactions: number;
  amount: number;
}

export interface BatchGroupInfo {
  batchDate: IsoDateString;
  payeeType: PayeeType;
  batchIds: ObjectId[];
}

export interface BatchGroupCandidate {
  _id: ObjectId; // payeeId
  payee: WithId<PayeeDocument>;
  count: number;
  total: number;
  payeeType: PayeeType;
  transactions: Array<WithId<ProjectTransactionDocument>>;
}

export interface BatchExportInfo {
  finCoAccountId: string;
  projectTransactionId: string;
  transactionDate: string;
  payoutDate: string;
  amountToPay: string;
  paymentPlanEventPercentage: string;
  paymentPlanName: string;
  payee: string;
  licensedOrganizationName: string;
  organizationAlias: string;
  salesRepName: string;
  primaryApplicant: Applicant;
  quoteDetails: QuoteDocument;
  kwhRate: string;
  cedInvoiceAmountSum: string;
  accountCreatedAtDate: string;
  contractSignedAt: string;
  inverterManufacturer: string;
  utilityName: string;
  hasBattery: string;
  cedAlphaId: string;
  noticeToProceedApprovedAtDate: string;
  ntpPlusSubmittedAtDate: string;
  installApprovedAtDate: string;
  installSubmittedAtDate: string;
  systemActivationApprovedAtDate: string;
  organizationPlanId?: string;
  planDifference: string;
  approvedDate: string;
  payoutEvent: string;
  description: string;
}

export type CreateProjectTransactionPayload = Partial<
  WithObjectIdsAsStrings<
    Pick<
      ProjectTransactionDocument,
      | 'projectId'
      | 'projectExternalId'
      | 'event'
      | 'description'
      | 'amount'
      | 'payeeId'
      | 'payeeType'
      | 'clawbackCategory'
      | 'isManual'
    > & { batchDate?: IsoDateString; historicalAdjustment?: boolean; eventDate?: Date }
  >
>;

export enum ProjectClawbackStatus {
  /** The clawback could happen, it's waiting for the trigger date. */
  pending = 'pending',
  /** The clawback trigger date was passed, and it was processed (but a transaction was not necessarily created). */
  completed = 'completed',
  /** This potential clawback no longer applies. The account was cancelled, etc. */
  cancelled = 'cancelled',
  /** An updated clawback has replaced this one, with a new trigger date, etc. */
  replaced = 'replaced',
}

export enum ProjectClawbackRuleNames {
  /** NTP payout event occurred, but install has not occurred within the threshold */
  noticeToProceed = 'noticeToProceed',
  /** Install payout was made, but activation has not occurred within the threshold */
  install = 'install',
  /** We have paid a materials vendor (estimate or invoice), but EPC has not submitted install milestone within the threshold (typically 14 days) */
  partnerInstallSubmittal = 'partnerInstallSubmittal',
  /** We have paid a materials vendor (estimate or invoice) and the EPC has reached Install Submitted, but has not reached install approved within the threshold (typically 30 days) */
  partnerInstallApproval = 'partnerInstallApproval',
}

export type ProjectClawbackRule = {
  name: ProjectClawbackRuleNames;
  /** The source payment event that causes this rule to be run. Some rules are triggered via external means (eg. CED ship notifications). */
  sourceEvent?: PayoutEvent;
  sourceEvents?: PayoutEvent[];
  /** The target event each clawback rule 'timer' is measured against.
   * Eg. The NTP rule causes a clawback transaction if the project doesn't reach Install Approved before the trigger date.
   */
  targetEvent: PayoutEvent;
  /** If the rule criteria is met, these are the payout events that would have clawback transactions generated. */
  clawsBackEvents: PayoutEvent[];
}

/** Source events that trigger the next clawback rule */
export const payoutEventRuleTriggers: Record<PayoutEvent, ProjectClawbackRuleNames | null> = {
  [PayoutEvent.noticeToProceed]: ProjectClawbackRuleNames.noticeToProceed,
  // No NTP+, NTP event handles it for the purposes of clawbacks
  [PayoutEvent.noticeToProceedPlus]: null,
  [PayoutEvent.installSubmitted]: ProjectClawbackRuleNames.partnerInstallApproval,
  [PayoutEvent.installApproved]: ProjectClawbackRuleNames.install,
  [PayoutEvent.estimate]: ProjectClawbackRuleNames.partnerInstallSubmittal,
  [PayoutEvent.invoice]: ProjectClawbackRuleNames.partnerInstallSubmittal,
  [PayoutEvent.activationApproved]: null,
  [PayoutEvent.other]: null,
};

export const clawbackRuleDefinitions: ProjectClawbackRule[] = [{
  name: ProjectClawbackRuleNames.noticeToProceed,
  sourceEvent: PayoutEvent.noticeToProceed,
  targetEvent: PayoutEvent.installApproved,
  clawsBackEvents: [PayoutEvent.noticeToProceed, PayoutEvent.noticeToProceedPlus],
}, {
  name: ProjectClawbackRuleNames.install,
  sourceEvent: PayoutEvent.installApproved,
  targetEvent: PayoutEvent.activationApproved,
  clawsBackEvents: [
    PayoutEvent.noticeToProceed,
    PayoutEvent.noticeToProceedPlus,
    PayoutEvent.installSubmitted,
    PayoutEvent.installApproved,
    PayoutEvent.estimate,
    PayoutEvent.invoice,
  ]
}, {
  name: ProjectClawbackRuleNames.partnerInstallSubmittal,
  sourceEvents: [PayoutEvent.invoice, PayoutEvent.estimate],
  targetEvent: PayoutEvent.installSubmitted,
  clawsBackEvents: [PayoutEvent.estimate, PayoutEvent.invoice],
}, {
  name: ProjectClawbackRuleNames.partnerInstallApproval,
  sourceEvent: PayoutEvent.installSubmitted,
  targetEvent: PayoutEvent.installApproved,
  clawsBackEvents: [PayoutEvent.estimate, PayoutEvent.invoice],
}];

export interface ProjectClawbackHistory {
  date: Date;
  user: { id: string; name: string };
  status: ProjectClawbackStatus;
  replacedBy?: string; // or something more generic?
}

export interface ProjectClawbackDocument {
  id?: string;
  projectId: ObjectId;
  /** LR accountId */
  projectExternalId: string;
  sourceRuleName: ProjectClawbackRuleNames;
  sourceEvent?: PayoutEvent;
  sourceEventDate: Date;
  targetEvent: PayoutEvent;
  triggerDate: Date;
  /** Days after sourceEventDate that will trigger a clawback transaction. @see triggerDate. */
  triggerDays: number; // days after source event to trigger clawback
  clawsBackEvents: PayoutEvent[];
  status: ProjectClawbackStatus;
  /** A transaction that may have been created as a result of processing this clawback. */
  transactionId?: ObjectId;
  meta?: Meta;
  history?: Array<ProjectClawbackHistory>;
}

export interface InvoiceNeededInfo {
  accountId: string;
  name: string;
  materialsProjectId?: string;
  primaryPayeeName?: string;
  installMilestoneStatus?: AccountMilestoneStatus;
  activationMilestoneStatus?: AccountMilestoneStatus;
}
