import { trimFileNames } from '@/helpers/trimFileNames';
import { Box, Button, Modal, RadioGroup } from '@palmetto/palmetto-components';
import { useState } from 'react';
import { FlagStatus } from 'types';

interface ResolveFlagModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  onResolve: (status: string) => void;
  dataKey: string;
  fileName?: string;
}

const ResolveFlagModal = ({ isOpen, onDismiss, onResolve, dataKey, fileName }: ResolveFlagModalProps) => {
  const [closingStatus, setClosingStatus] = useState<string>('closed');

  const truncatedFileName = trimFileNames(fileName, dataKey);

  return (
    <Modal isOpen={isOpen} maxWidth="4xl" ariaLabelledBy="resolveFlagHeader" onDismiss={onDismiss}>
      <Modal.Header id="resolveFlagHeader" title={`Resolve ${truncatedFileName} Flag`} onDismiss={onDismiss} />
      <Modal.Body background="secondary" childGap="lg">
        <Box as="p" fontWeight="bold" color="body-primary">
          {`Resolve this flagged item as...`}
        </Box>
        <Box childGap="md">
          <RadioGroup
            name="resolvedStatus"
            id="resolvedStatus"
            value={closingStatus}
            onChange={(event) => setClosingStatus(event.target.value)}
            options={[
              { id: FlagStatus.closed, value: FlagStatus.closed, label: 'Closed' },
              {
                id: FlagStatus.closedExceptionGranted,
                value: FlagStatus.closedExceptionGranted,
                label: 'Closed - Exception Granted',
              },
              {
                id: FlagStatus.closedNoIssuesFound,
                value: FlagStatus.closedNoIssuesFound,
                label: 'Closed - No Issue Found',
              },
            ]}
            isRequired
          />
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onDismiss} variant="secondary" tone="neutral" size="md">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onResolve(closingStatus);
          }}
          color="primary"
          size="md"
        >
          Resolve Flag
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResolveFlagModal;
