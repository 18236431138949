import { Box, Icon, Popover, useOpenClose } from '@palmetto/palmetto-components';
import { NavLink } from 'react-router-dom';
import RequirePermissions from './auth/requirePermissions';

export function DropdownMenu({
  items,
  title,
}: {
  items: { name: string; path: string; permissions?: string[]; defaultFilters?: any[] }[];
  title: string;
}) {
  const { isOpen, handleClose, handleToggle } = useOpenClose();

  const content = (
    <Box childGap="md" fontSize="sm">
      {items?.map((item) => {
        const searchParams = item.defaultFilters
          ? `?advancedFilters=${encodeURIComponent(JSON.stringify(item.defaultFilters))}`
          : '';

        const fullPath = `${item.path}${searchParams}`;

        return item.permissions ? (
          <RequirePermissions permissions={item.permissions} key={item.path}>
            <NavLink to={fullPath} onClick={handleClose} className="nav-link">
              {item.name}
            </NavLink>
          </RequirePermissions>
        ) : (
          <NavLink key={item.name} to={fullPath} className="nav-link" onClick={handleClose}>
            {item.name}
          </NavLink>
        );
      })}
    </Box>
  );

  return (
    <Popover
      content={content}
      isOpen={isOpen}
      placement="bottom-start"
      offsetFromTarget={4}
      trapFocus
      contentContainerProps={{
        padding: 'md',
        shadow: 'sm',
        borderColor: 'separator',
        borderWidth: 'xs',
        background: 'primary',
        radius: 'sm',
        color: 'body-primary',
        fontSize: 'sm',
        maxWidth: '3xl',
        minWidth: '3xl',
      }}
      arrowColor="white"
      onClickOutside={handleClose}
      portalTarget={document.body}
      withPortal
      hasArrow={false}
    >
      <Box
        as="button"
        fontSize={{ base: 'sm', desktop: 'md' }}
        textAlign="left"
        type="button"
        childGap="2xs"
        cursor="pointer"
        direction="row"
        alignItems="center"
        onClick={handleToggle}
        borderWidth="0"
        background="primary"
        color="body-primary"
        padding={{ base: 'xs sm', desktop: 'sm' }}
        radius="md"
        hover={{
          color: 'contrast',
        }}
      >
        <>
          <Box fontSize="sm">{title}</Box>
          <Icon name="caret-sm-down" />
        </>
      </Box>
    </Popover>
  );
}
