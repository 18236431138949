import { ChangeEvent, FC } from 'react';
import { TextInput } from '@palmetto/palmetto-components';

interface TextBoxFilterProps {
  selectedValues?: string[];
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const TextBoxFilter: FC<TextBoxFilterProps> = ({ selectedValues, handleChange }) => {
  return (
    <TextInput
      id="textBoxFilter"
      type="text"
      value={selectedValues}
      autoComplete="off"
      hideLabel
      label="Filter"
      placeholder="Enter value"
      onChange={handleChange}
      size="sm"
    />
  );
};
