import { Box, Icon, Table } from '@palmetto/palmetto-components';
import { Link } from 'react-router-dom';
import '../AccountsSummaryTable.css';

// TODO: Add stricter typing around Account milestone on FE

enum AccountMilestoneStatus {
  approved = 'approved',
  conditionallyApproved = 'conditionallyApproved',
  pending = 'pending',
  rejected = 'rejected',
  resubmitted = 'resubmitted',
  submitted = 'submitted',
}

const accountMilestoneStatus: Record<AccountMilestoneStatus, string> = {
  approved: 'Approved',
  conditionallyApproved: 'Conditionally Approved',
  pending: 'Pending Submittal',
  rejected: 'Rejected',
  resubmitted: 'Resubmitted',
  submitted: 'Under Review',
};

const getStatusIcon = (currentMilestone: any, milestone: any) => {
  const { status, type } = milestone;
  if (currentMilestone.type === type && status === 'pending') {
    return <Icon name="circle" size="md" color="success" />;
  } else if ([AccountMilestoneStatus.submitted, AccountMilestoneStatus.resubmitted].includes(status)) {
    return <Icon name="c-in-progress" size="md" color="success" />;
  } else if (status === 'approved') {
    return <Icon name="c-check" size="md" color="success" />;
  } else if (status === 'rejected') {
    return <Icon name="c-remove" size="md" color="danger" />;
  } else {
    return <Icon name="circle" size="md" color="grey-300" />;
  }
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const numberFormatter = new Intl.NumberFormat('en-US');

const columnConfig = [
  {
    cellClassName: 'milestoneStatus',
    width: 150,
    render: (_: any, { currentMilestone, milestones }: any) => {
      if (currentMilestone && milestones) {
        const sortedMilestones: any[] = [...milestones].sort((a: any, b: any) => a.order - b.order);
        return (
          <Box
            childGap="md"
            alignItems="center"
            className={currentMilestone.status === 'rejected' ? 'column-rejected' : ''}
          >
            <Box alignItems="center" childGap="xs">
              <Box fontSize="xs" display="inline-block">
                {currentMilestone.name}
              </Box>
              <Box textAlign="center" fontSize="sm" fontWeight="medium">
                {accountMilestoneStatus[currentMilestone.status as AccountMilestoneStatus]}
              </Box>
            </Box>
            <Box direction="row" childGap="xs">
              {sortedMilestones.map((milestone: any) => (
                <Box as="li" position="relative" display="inline-block" key={milestone.name}>
                  <Box>{getStatusIcon(currentMilestone, milestone)}</Box>
                </Box>
              ))}
            </Box>
          </Box>
        );
      } else {
        return <Box>Unknown Current Milestone</Box>;
      }
    },
  },
  {
    heading: 'Account',
    render: (_: any, row: any) => {
      return (
        <Box display="block" childGap="2xs" fontSize="xs" padding="sm 0" color="body-secondary">
          <Box display="block" fontWeight="medium" fontSize="sm">
            <Link to={`/accounts/${row._id}`}>{row.primaryApplicantName}</Link>
          </Box>
          {row.address && (
            <Box childGap="2xs">
              <Box>{row.address.address1}</Box>
              <Box>
                {row.address.city} {row.address.state} {row.address.zip}
              </Box>
            </Box>
          )}
          {row.applicants[0]?.email && <Box>{row.applicants[0].email}</Box>}
        </Box>
      );
    },
  },
  {
    heading: 'Tranche',
    width: 160,
    render: (_: any, row: any) => (
      <Box display="block" childGap="sm" fontSize="xs">
        <Box>{row.tranches.join(', ')}</Box>
      </Box>
    ),
  },
  {
    heading: 'System Size (kW)',
    dataKey: 'systemSizeKw',
    width: 95,
    render: (cell: any) => numberFormatter.format(cell),
  },
  {
    heading: 'Amount',
    dataKey: 'totalSystemCost',
    width: 165,
    render: (cell: any) => currencyFormatter.format(cell),
  },
];

export const FundAccountsSummaryTable = ({ data }: any) => (
  <Table
    rowKey="id"
    columns={columnConfig}
    className="fade-in"
    rows={data}
    align="left"
    isScrollable={{
      x: true,
      y: false,
    }}
  />
);
