import { BaseSpacing, BorderColor, BorderSize, Box, Icon, toast } from '@palmetto/palmetto-components';
import { RequirementFlagSettingsType } from 'types';
import { useCallback, useContext } from 'react';
import { QueueFlagsContext } from './QueueFlagsContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FlagStatus } from 'types';

export interface FlagProps {
  dataKey: string;
  fileName?: string;
  baseCategory?: string;
  documentType?: string;
  type?: RequirementFlagSettingsType;
  padding?: BaseSpacing;
  borderColor?: BorderColor;
  borderWidth?: BorderSize | string;
}

export const Flag = ({
  dataKey,
  fileName,
  baseCategory,
  type,
  documentType,
  padding,
  borderColor,
  borderWidth,
}: FlagProps) => {
  const { flagDrivenPackageReview } = useFlags();
  const { showModal, showResolveModal, getFlag, resolveFlag, isLoading, setLoading } = useContext(QueueFlagsContext);
  const currentFlag = getFlag(dataKey, fileName, false);
  const handleFlagClick = useCallback(async () => {
    if (currentFlag) {
      if (!isLoading) {
        if (flagDrivenPackageReview) {
          showResolveModal(dataKey, fileName);
        } else {
          setLoading(true);
          try {
            await resolveFlag(dataKey, FlagStatus.closed, fileName);
          } catch (e) {
            toast.error('Something went wrong. Please try again.');
            console.log({ e });
          }
          setLoading(false);
        }
      }
    } else {
      showModal(dataKey, type, fileName, baseCategory, documentType);
    }
  }, [currentFlag, resolveFlag, dataKey, fileName, showModal, baseCategory, isLoading]);

  return (
    <Box
      onClick={handleFlagClick}
      cursor={isLoading ? '' : 'pointer'}
      padding={padding}
      borderColor={borderColor}
      borderWidth={{ tablet: borderWidth }}
    >
      <Icon size="xl" name="flag-outline" color={isLoading ? 'disabled' : 'body-primary'} />
    </Box>
  );
};
