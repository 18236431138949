import { api } from './api';
import { ProductClassification, ProductDTO } from 'types';

export const productCatalogApi = api.injectEndpoints({
  endpoints: (build) => ({
    getClassifications: build.query<ProductClassification[], any>({
      query: (queryParams) => {
        return {
          url: `product-catalog/classifications${
            queryParams &&
            '?' +
              new URLSearchParams({
                ...queryParams,
              })
          }`,
        };
      },
    }),
    getProducts: build.query<ProductDTO[], any>({
      query: ({ classification, ...rest }) => ({
        url: `product-catalog/products/${classification}`,
        params: { ...rest },
      }),
    }),
    searchProducts: build.mutation<ProductDTO[], any>({
      query: (search) => ({
        url: 'product-catalog/products/search',
        method: 'POST',
        body: search,
      }),
    }),
  }),
});

export const { useGetProductsQuery, useGetClassificationsQuery, useSearchProductsMutation } = productCatalogApi;
