import {
  Badge,
  BadgeVariant,
  Box,
  Button,
  Details,
  DimensionSize,
  toast,
  useOpenClose,
} from '@palmetto/palmetto-components';
import { QuoteStatus } from '../../types/Quotes';
import { useVoidQuoteMutation } from '../../services/quotes';
import { currencyFormatter, kwhPriceFormatter } from '../../helpers/currencyFormatter';
import RequirePermissions from '../auth/requirePermissions';
import { isErrorWithData } from '../../services/helpers';
import {
  AdderType,
  AdditionalCostAdder,
  AdditionalCostAdderType,
  BatteryPricingMethodOption,
  ConstructionAdderType,
  ProgramType,
  Quote,
  UserPermissions,
} from 'types';
import { useSendContractMutation } from '../../services/contract';
import { getAdderTypeLabel } from '../../helpers/addersHelpers';
import {
  getDomesticElements,
  getEpcRatePPWLabel,
  getEpcTotalPaymentLabel,
  ListItemElement,
  ListItemElementPropsWithTooltip,
  ListItemElementWithMultipleProps,
} from './QuoteListItem.utils';

const getAdderLabel = (adder: AdderType, batteryDetails: any): string => {
  if (adder === AdderType.backupBattery && batteryDetails?.pricingMethod === 'kwhRate') {
    return getAdderTypeLabel(AdderType.backupBatteryKwh) ?? adder;
  }
  if (adder === AdderType.arbitrageBattery && batteryDetails?.pricingMethod === 'rawCost') {
    return getAdderTypeLabel(AdderType.arbitrageBatteryCost) ?? adder;
  }

  return getAdderTypeLabel(adder) ?? adder;
};

interface QuoteListItemProps {
  item: Quote;
}

const CustomerFacingSection = ({
  item,
  isHVACQuote,
  hasArbitrageBattery,
  hasCostBasedBattery,
  hasBackupBatteryKWH,
  systemPricingDetails,
  hasSolarReadiness,
  solarReadinessCost,
  totalAmountPaid,
}: {
  item: Quote;
  isHVACQuote: boolean;
  hasArbitrageBattery: boolean;
  hasCostBasedBattery: boolean;
  hasBackupBatteryKWH: boolean;
  systemPricingDetails: any;
  hasSolarReadiness: boolean;
  solarReadinessCost: number;
  totalAmountPaid: number;
}) => (
  <>
    <Box direction="row" padding="sm lg" gap="5xl">
      <Box width={{ base: 'xs' as DimensionSize, tablet: 'lg' }} />
      <Box as="h3" fontWeight="medium" fontSize="md">
        Customer Facing
      </Box>
    </Box>

    {!isHVACQuote && (
      <ListItemElement
        label={
          hasArbitrageBattery
            ? 'Solar with Arbitrage Battery Rate ($/kWh)'
            : hasBackupBatteryKWH
              ? 'Solar with Backup Battery Rate ($/kWh)'
              : 'Solar-Only Rate ($/kWh)'
        }
        value={kwhPriceFormatter.format(item.kwhPrice)}
      />
    )}
    <ListItemElement
      label={
        hasArbitrageBattery
          ? 'Estimated Solar with Arbitrage Battery Year-1 Monthly Payment'
          : hasBackupBatteryKWH
            ? 'Estimated Solar with Backup Battery Year-1 Monthly Payment'
            : !isHVACQuote
              ? 'Estimated Solar-Only Year-1 Monthly Payment'
              : 'Estimated Year-1 Monthly Payment'
      }
      value={currencyFormatter.format(systemPricingDetails[0].monthlyPayment)}
    />
    {hasCostBasedBattery && (
      <>
        <ListItemElement
          label="Estimated Battery Year-1 Monthly Payment"
          value={currencyFormatter.format(systemPricingDetails[0].batteryPayment || 0)}
        />
      </>
    )}
    <ListItemElement
      label={'Total Year-1 Monthly Payment'}
      value={currencyFormatter.format(systemPricingDetails[0].totalMonthlyPayment)}
    />
    {hasSolarReadiness && (
      <ListItemElement label="Solar Readiness" value={currencyFormatter.format(solarReadinessCost)} />
    )}
    <ListItemElement
      label={!isHVACQuote ? 'Total Lifetime Solar Lease Payments' : 'Total Lease Monthly Payments'}
      value={currencyFormatter.format(totalAmountPaid)}
    />
  </>
);

const EPCFacingSection = ({
  isHVACQuote,
  epcRatePPW,
  epcTotalPaymentLabel,
  epcRatePPWDomesticContentElements,
  epcTotalPaymentDomesticContentElements,
  totalSystemCost,
  hasCostBasedBattery,
  totalBatteryCost,
  totalEPCPaymentElements,
  pricePerWatt,
  totalAdderCost,
}: {
  isHVACQuote: boolean;
  epcRatePPW: string;
  epcTotalPaymentLabel: string;
  epcRatePPWDomesticContentElements: ListItemElementPropsWithTooltip[];
  epcTotalPaymentDomesticContentElements: ListItemElementPropsWithTooltip[];
  totalSystemCost: number;
  hasCostBasedBattery: boolean;
  totalBatteryCost: number | undefined;
  totalEPCPaymentElements: ListItemElementPropsWithTooltip[];
  pricePerWatt: number;
  totalAdderCost: number;
}) => (
  <>
    <Box direction="row" padding="lg lg sm" gap="5xl">
      <Box width={{ base: 'xs' as DimensionSize, tablet: 'lg' }} />
      <Box as="h3" fontWeight="medium" fontSize="md">
        EPC Facing
      </Box>
    </Box>

    {!isHVACQuote &&
      (epcRatePPWDomesticContentElements.length ? (
        <ListItemElementWithMultipleProps baseLabel={epcRatePPW} values={epcRatePPWDomesticContentElements} />
      ) : (
        <ListItemElement label={epcRatePPW} value={currencyFormatter.format(pricePerWatt)} />
      ))}

    {epcTotalPaymentDomesticContentElements.length ? (
      <ListItemElementWithMultipleProps
        baseLabel={epcTotalPaymentLabel}
        values={epcTotalPaymentDomesticContentElements}
      />
    ) : (
      <ListItemElement
        label={epcTotalPaymentLabel}
        value={currencyFormatter.format(totalSystemCost - totalAdderCost)}
      />
    )}
    {hasCostBasedBattery && totalBatteryCost ? (
      <ListItemElement label="Battery EPC Payment" value={currencyFormatter.format(totalBatteryCost)} />
    ) : null}
    {hasCostBasedBattery &&
      (totalEPCPaymentElements.length ? (
        <ListItemElementWithMultipleProps baseLabel={'Total EPC Payment'} values={totalEPCPaymentElements} />
      ) : (
        <ListItemElement label="Total EPC Payment" value={currencyFormatter.format(totalSystemCost)} />
      ))}
  </>
);

export function QuoteListItem({ item }: QuoteListItemProps): JSX.Element {
  const {
    totalAmountPaid,
    kwhPrice,
    systemPricingDetails,
    totalSystemCost,
    pricePerWatt,
    productName,
    status,
    adders,
    additionalCostAdders,
    batteryDetails,
    programType,
  } = item as any; // TODO: This will be updated once we move to v3 routes. This is to support legacy backup battery data structure
  const showVoidButton = status === QuoteStatus.active || status === QuoteStatus.contracted;
  const [voidQuote, { isLoading: isVoidingQuote }] = useVoidQuoteMutation({
    fixedCacheKey: 'useVoidQuoteMutationKey',
  });
  const [_sendContract, { isLoading: isSendingContract }] = useSendContractMutation({
    fixedCacheKey: 'useSendContractMutationKey',
  });
  const { isOpen: isDetailsOpen, handleToggle: handleDetailsToggle } = useOpenClose({
    defaultIsOpen: showVoidButton,
  });
  const quoteStatus: {
    [key in QuoteStatus]: {
      label: string;
      variant: BadgeVariant;
    };
  } = {
    [QuoteStatus.contracted]: {
      label: 'Contracted',
      variant: 'success',
    },
    [QuoteStatus.active]: {
      label: 'Active',
      variant: 'success',
    },
    [QuoteStatus.voided]: {
      label: 'Voided',
      variant: 'warning',
    },
  };
  const getAdderList = (adders?: string[]) => {
    return adders ? adders.map((adder) => getAdderLabel(adder as AdderType, batteryDetails)).join(', ') : '';
  };
  const handleVoidQuote = async () => {
    const toastId = toast.loading('Voiding quote');

    try {
      const confirmed = window.confirm('Are you sure you want to Void this Quote? This action cannot be undone.');

      if (!confirmed) return;
      await voidQuote({ accountId: item.accountId, quoteId: item.id }).unwrap();
      toast.success('Quote voided successfully');
    } catch (e) {
      if (isErrorWithData(e)) {
        const errorMessage = e.data.message;
        toast.error(errorMessage);
      } else {
        console.error(e);
        toast.error('Error voiding quote');
      }
    } finally {
      toast.dismiss(toastId);
    }
  };

  const hasCostBasedBattery = adders
    ? (adders.includes(AdderType.backupBattery) || adders?.includes(AdderType.arbitrageBattery)) &&
      batteryDetails?.pricingMethod === BatteryPricingMethodOption.rawCost
    : false;
  const hasBackupBatteryKWH = adders
    ? adders.includes(AdderType.backupBattery) &&
      batteryDetails?.pricingMethod === BatteryPricingMethodOption.kwhRate
    : false;
  const hasSolarReadiness = adders ? adders.includes(ConstructionAdderType.solarReadiness) : false;
  const hasArbitrageBattery =
    (adders?.includes(AdderType.arbitrageBattery) &&
      batteryDetails?.pricingMethod === BatteryPricingMethodOption.kwhRate) ||
    false;
  const isHVACQuote = programType === ProgramType.hvac;
  const totalBackupBatteryCost = additionalCostAdders?.find(
    (adder: any) => adder.type === AdderType.backupBattery,
  )?.cost;
  const totalArbitrageBatteryCost = additionalCostAdders?.find(
    (adder: any) => adder.type === AdderType.arbitrageBattery,
  )?.cost;
  const totalBatteryCost = totalBackupBatteryCost || totalArbitrageBatteryCost || 0;
  const totalAdderCost =
    additionalCostAdders?.reduce((acc: number, adder: any) => {
      acc += adder.cost;
      return acc;
    }, 0) ?? 0;
  const solarReadinessCost = hasSolarReadiness
    ? additionalCostAdders.find(
        (element: AdditionalCostAdder) => element.type === AdditionalCostAdderType.solarReadiness,
      )?.cost || 0
    : 0;
  let title = productName;
  if (!isHVACQuote) {
    title += `, ${kwhPriceFormatter.format(kwhPrice)}/kWh`;
  }
  const epcTotalPaymentLabel = getEpcTotalPaymentLabel({
    hasArbitrageBattery,
    hasBackupBatteryKWH,
    isHVACQuote,
  });
  const epcRatePPW = getEpcRatePPWLabel({
    hasArbitrageBattery,
    hasBackupBatteryKWH,
  });

  const { epcTotalPaymentDomesticContentElements, epcRatePPWDomesticContentElements, totalEPCPaymentElements } =
    getDomesticElements({
      quote: item,
      totalSystemCost,
      totalAdderCost,
      pricePerWatt,
    });

  return (
    <Details isOpen={isDetailsOpen} gap="sm" borderWidth="xs 0 0 0" borderColor="separator">
      <Details.Summary
        isDetailsOpen={isDetailsOpen}
        onToggle={showVoidButton ? undefined : handleDetailsToggle}
        display="inline"
        cursor={'pointer'}
        width="100"
      >
        <Box width="100" gap="5xl-tablet 5xl-desktop" columnGap={'md'} direction="row" padding={'sm lg'}>
          <Box width="lg">
            <Badge
              message={quoteStatus[status as keyof typeof QuoteStatus]?.label}
              variant={quoteStatus[status as keyof typeof QuoteStatus]?.variant}
              style={{ width: 'fit-content' }}
            />
          </Box>
          <Box gap="xs" flex="auto">
            <Box>{title || '---'}</Box>
            <Box fontSize="xs" color="body-secondary">
              {getAdderList(adders)}
            </Box>
          </Box>
          <Box display={'flex'}>
            {showVoidButton ? (
              <RequirePermissions
                permissions={[UserPermissions.admin, UserPermissions.editor]}
                checkAllPermissions={false}
              >
                <Button
                  size="sm"
                  variant="secondary"
                  tone="danger"
                  disabled={isVoidingQuote || isSendingContract}
                  onClick={handleVoidQuote}
                >
                  Void
                </Button>
              </RequirePermissions>
            ) : (
              <Box fontSize="xs">{isDetailsOpen ? 'Hide Details' : 'Show Details'}</Box>
            )}
          </Box>
        </Box>
      </Details.Summary>

      <Box padding="0 0 lg 0">
        <CustomerFacingSection
          item={item}
          isHVACQuote={isHVACQuote}
          hasArbitrageBattery={hasArbitrageBattery}
          hasBackupBatteryKWH={hasBackupBatteryKWH}
          hasCostBasedBattery={hasCostBasedBattery}
          systemPricingDetails={systemPricingDetails}
          hasSolarReadiness={hasSolarReadiness}
          solarReadinessCost={solarReadinessCost}
          totalAmountPaid={totalAmountPaid}
        />

        <EPCFacingSection
          isHVACQuote={isHVACQuote}
          epcRatePPW={epcRatePPW}
          epcTotalPaymentLabel={epcTotalPaymentLabel}
          epcRatePPWDomesticContentElements={epcRatePPWDomesticContentElements}
          epcTotalPaymentDomesticContentElements={epcTotalPaymentDomesticContentElements}
          totalSystemCost={totalSystemCost}
          hasCostBasedBattery={hasCostBasedBattery}
          totalBatteryCost={totalBatteryCost}
          totalEPCPaymentElements={totalEPCPaymentElements}
          pricePerWatt={pricePerWatt}
          totalAdderCost={totalAdderCost}
        />
      </Box>
    </Details>
  );
}
