import { Box, Button } from '@palmetto/palmetto-components';
import { FilterBar } from '@/components/filters';
import { useCallback, useEffect, useMemo } from 'react';
import { AddFilterButtonDropdown } from '@/components/filters/AddFilterButton';
import { AppliedDataFilter, ProjectTransactionStatus, ProjectTransactionStatusMap, PayoutEventMap } from 'types';
import { DataFilterDisplay, DataFilterOperation } from '@/components/filters/FilterButton';
import { DataFilterType } from '@/components/filters/SearchableCheckboxFilterList';
import { flattenedPaymentEventLabelInfo, ProjectTransactionClientData, materialsLabels } from '@/types/Payouts';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import { SortButtonDropdown, SortItem } from '@/components/filters/SortButton';
import { clawbackCategoryOptions } from '@/components/Payouts/admin/AddProjectTransactionForm';

interface ProjectTransactionsFilterProps {
  transactions: ProjectTransactionClientData[];
  appliedFilters?: AppliedDataFilter[];
  setAppliedFilters: (filters: AppliedDataFilter[]) => void;
  searchParams?: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
  searchTerm?: string;
  advancedFilters?: string;
  appliedSort?: SortItem;
  handleSortSelection: (sort: SortItem) => void;
  exportUrl?: string;
  hideSortOptions?: ProjectTransactionSort[];
  hideFilterOptions?: string[];
}

export const ProjectTransactionColumnNames = ['payeeName', 'projectName', 'amount', 'event', 'eventDate'];
export type ProjectTransactionColumns = (typeof ProjectTransactionColumnNames)[number];
export type ProjectTransactionSort =
  | 'payeeNameAsc'
  | 'payeeNameDesc'
  | 'amountAsc'
  | 'amountDesc'
  | 'eventDateAsc'
  | 'eventDateDesc'
  | 'projectNameAsc'
  | 'projectNameDesc'
  | 'eventAsc'
  | 'eventDesc'
  | 'statusAsc'
  | 'statusDesc'
  | 'projectExternalIdDesc'
  | 'projectExternalIdAsc';

interface SortOption {
  id: ProjectTransactionSort;
  label: string;
}
export const transactionSortOptions: Array<SortOption> = [
  {
    id: 'payeeNameAsc',
    label: 'Payee A-Z',
  },
  { id: 'payeeNameDesc', label: 'Payee Z-A' },
  { id: 'amountAsc', label: 'Amount Low-High' },
  { id: 'amountDesc', label: 'Amount High-Low' },
  { id: 'eventDateAsc', label: 'Event Date Old-New' },
  { id: 'eventDateDesc', label: 'Event Date New-Old' },
  { id: 'projectNameAsc', label: 'Account A-Z' },
  { id: 'projectNameDesc', label: 'Account Z-A' },
  { id: 'eventAsc', label: 'Event A-Z' },
  { id: 'eventDesc', label: 'Event Z-A' },
  { id: 'statusAsc', label: 'Status A-Z' },
  { id: 'statusDesc', label: 'Status Z-A' },
  { id: 'projectExternalIdAsc', label: 'Account Id A-Z' },
  { id: 'projectExternalIdDesc', label: 'Account Id Z-A' },
];
const mapAndSortValueSet = (valueSet: Set<string>) =>
  Array.from(valueSet)
    .map((value) => ({ value, label: value }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const ProjectTransactionsFilter = ({
  transactions,
  setAppliedFilters,
  appliedFilters = [],
  searchParams = new URLSearchParams(),
  setSearchParams,
  searchTerm,
  advancedFilters = '',
  appliedSort,
  handleSortSelection,
  exportUrl,
  hideSortOptions,
  hideFilterOptions,
}: ProjectTransactionsFilterProps) => {
  // const isFiltered = !!Array.from(searchParams.keys()).filter((key) => key !== 'sort').length;
  const filters: DataFilterDisplay[] = useMemo(() => {
    const valueSets = transactions?.reduce(
      (acc, cur) => {
        if (cur.payeeName) acc.payeeSet.add(cur.payeeName);
        if (cur.projectName) acc.accountNameSet.add(cur.projectName);
        if (cur.amount) acc.amountSet.add(cur.amount);
        if (cur.event) acc.eventSet.add(cur.event);
        if (cur.projectExternalId) acc.fincoId.add(cur.projectExternalId);
        return acc;
      },
      {
        payeeSet: new Set<string>(),
        accountNameSet: new Set<string>(),
        amountSet: new Set<number>(),
        eventSet: new Set<string>(),
        fincoId: new Set<string>(),
      },
    );
    return [
      {
        id: 'projectExternalId',
        label: 'Finco Account ID',
        type: DataFilterType.Select,
        operations: [DataFilterOperation.Equal, DataFilterOperation.NotEqual],
        selectOptions: mapAndSortValueSet(valueSets?.fincoId),
      },
      {
        id: 'projectName',
        label: 'Account',
        type: DataFilterType.Select,
        operations: [DataFilterOperation.Equal, DataFilterOperation.NotEqual],
        selectOptions: mapAndSortValueSet(valueSets?.accountNameSet),
      },
      {
        id: 'payeeName',
        label: 'Payee',
        type: DataFilterType.Select,
        operations: [DataFilterOperation.Equal, DataFilterOperation.NotEqual],
        selectOptions: mapAndSortValueSet(valueSets.payeeSet),
      },
      {
        id: 'amount',
        label: 'Amount',
        type: DataFilterType.Text,
        operations: [DataFilterOperation.LessThan, DataFilterOperation.GreaterThan],
        selectOptions: [],
      },
      {
        id: 'dateTime',
        label: 'Event Date',
        type: DataFilterType.DateRange,
        operations: [DataFilterOperation.InRange],
        selectOptions: [],
      },
      {
        id: 'event',
        label: 'Payout Event',
        type: DataFilterType.Select,
        operations: [DataFilterOperation.Equal, DataFilterOperation.NotEqual],
        selectOptions: Array.from(valueSets?.eventSet)
          .map((value) => ({
            value,
            label: flattenedPaymentEventLabelInfo.find((item) => item.event === value)?.name ?? 'Unknown Event',
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      },
      {
        id: 'status',
        label: 'Status',
        type: DataFilterType.Select,
        operations: [DataFilterOperation.Equal],
        selectOptions: Object.entries(ProjectTransactionStatusMap)
          .filter(
            ([key]) =>
              ![ProjectTransactionStatus.paid, ProjectTransactionStatus.migrated].includes(
                key as ProjectTransactionStatus,
              ),
          )
          .map(([key, value]) => ({
            value: key,
            label: value,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      },
      {
        id: 'clawbackCategory',
        label: 'Clawback',
        type: DataFilterType.Select,
        operations: [DataFilterOperation.Equal],
        selectOptions: clawbackCategoryOptions,
      },
      {
        id: 'payoutOnHold',
        label: 'Payout Hold',
        type: DataFilterType.Select,
        operations: [DataFilterOperation.Equal],
        selectOptions: [
          {
            value: 'true',
            label: 'Yes',
          },
        ],
      },
      {
        id: 'isManual',
        label: 'Manual Transactions',
        type: DataFilterType.Select,
        operations: [DataFilterOperation.Equal],
        selectOptions: [
          {
            value: 'true',
            label: 'Yes',
          },
        ],
      },
    ].filter((filter) => !hideFilterOptions?.includes(filter.id));
  }, [transactions, hideFilterOptions]);

  const sortOptions = useMemo(
    () => transactionSortOptions.filter((option) => !hideSortOptions?.includes(option.id)),
    [hideSortOptions],
  );

  const onSearchChange = useCallback(
    (newSearchTerm: string) => {
      if (newSearchTerm && newSearchTerm !== '') {
        searchParams.set('search', newSearchTerm);
      } else {
        searchParams.delete('search');
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const handleApplyAdvancedFilters = useCallback(
    (filtersToApply: AppliedDataFilter[]) => {
      if (filtersToApply.some((filter) => filter.selectedValues && filter.selectedValues.length)) {
        searchParams.set('advancedFilters', JSON.stringify(filtersToApply));
        setSearchParams(searchParams);
      }
      setAppliedFilters(filtersToApply);
    },
    [setAppliedFilters, searchParams, setSearchParams],
  );

  const handleRemoveAdvancedFilter = useCallback(
    (filterId: string) => {
      const newFilters = appliedFilters.filter((filter) => filter.id !== filterId);
      if (newFilters.length) {
        searchParams.set('advancedFilters', JSON.stringify(newFilters));
      } else {
        searchParams.delete('advancedFilters');
      }
      setSearchParams(searchParams);
      setAppliedFilters(newFilters);
    },
    [appliedFilters, setAppliedFilters, searchParams, setSearchParams],
  );

  const handleClearAdvancedFilters = useCallback(() => {
    searchParams.delete('advancedFilters');
    setSearchParams(searchParams);
    setAppliedFilters([]);
  }, [setAppliedFilters, searchParams, setSearchParams]);

  useEffect(() => {
    const filters = advancedFilters || JSON.stringify([]);
    setAppliedFilters(JSON.parse(filters));
  }, [advancedFilters, searchTerm]);

  return (
    <Box background="grey-100" radius="sm" direction="row" display="block">
      <FilterBar
        filtersState={{
          searchTerm,
        }}
        handleSearchTerm={onSearchChange}
        placeholder="Search by Payee, Account, Amount"
      >
        <Box direction="row" width="100" gap="md" alignItems="center">
          <AddFilterButtonDropdown
            dataFilters={filters}
            appliedFilters={appliedFilters}
            onRemoveFilter={handleRemoveAdvancedFilter}
            onApplyFilters={handleApplyAdvancedFilters}
            onClearFilters={handleClearAdvancedFilters}
          />
          <SortButtonDropdown
            appliedSort={appliedSort || sortOptions[0]}
            sortOptions={sortOptions}
            onSortChange={handleSortSelection}
            className="sortButton"
          />
          {exportUrl && (
            <Button as="a" href={exportUrl} target="_blank" rel="noopener noreferrer">
              Export
            </Button>
          )}
        </Box>
      </FilterBar>
    </Box>
  );
};
