import { InterconnectionAgreementDocumentType } from "types"
import { DocumentList } from "./Documents/DocumentList";
import FormSection from "../Forms/FormSection";
import { InterconnectionAgreementDescription } from "../InstallMilestonePackage/InstallationFormSectionDescriptions";

type UtilityBillProps = {
    allowArchive?: boolean;
    account: any;
}

export const ApprovedInterconnectionAgreement = ({ allowArchive, account }: UtilityBillProps) => {
    const isCA = account?.address?.state === 'CA';

    return (
        isCA ?
            (<FormSection title="Approved Interconnection Agreement" description={InterconnectionAgreementDescription()}>
                <DocumentList
                    documentTypeFilter={[InterconnectionAgreementDocumentType.interconnectionAgreement]}
                    title="Approved Interconnection Agreement"
                    showTypeLabelOnItems={false}
                    baseCategory={'Interconnection Agreement'}
                    allowArchive={allowArchive}
                />
            </FormSection>)
            : null
    )
}