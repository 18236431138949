import { useCallback, useEffect, useState } from 'react';
import { Box, Card, Pagination } from '@palmetto/palmetto-components';
import { useSearchParams } from 'react-router-dom';
import { FilterBar } from '../filters';
import { AppliedDataFilter } from '../filters/FilterButton';
import { AddFilterButtonDropdown } from '../filters/AddFilterButton';
import { FundAccountsSummaryTable } from './FundAccountsSummaryTable';
import { useGetFundListFiltersQuery, useLazySearchFundAccountsQuery } from '../../services/funds';
import { FundAccountsListLoading } from './FundAccountsListLoading';

export function FundAccountsSummary({ fundKey }: { fundKey: string }) {
  const [trigger, { data: summaryResponse, isLoading, isFetching }] = useLazySearchFundAccountsQuery();
  const { data = [], isLoading: isFiltersLoading } = useGetFundListFiltersQuery(fundKey);
  const [appliedFilters, setAppliedFilters] = useState<Array<any>>([]);

  const { accounts, totalCount } = summaryResponse || { accounts: [], totalCount: 0 };
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get('page');
  const advancedFilters = searchParams.get('advancedFilters');
  const searchTerm = searchParams.get('search') || undefined;

  useEffect(() => {
    const pageNum = currentPage ? Number(currentPage) : 1;
    const filters = advancedFilters || JSON.stringify([]);
    setAppliedFilters(JSON.parse(filters));
    trigger({
      pageNum,
      advancedFilters: filters,
      searchTerm,
      fundKey,
    });
  }, [trigger, currentPage, advancedFilters, searchTerm, fundKey]);

  const handleApplyAdvancedFilters = useCallback(
    (filtersToApply: AppliedDataFilter[]) => {
      if (filtersToApply.some((filter) => filter.selectedValues && filter.selectedValues.length)) {
        searchParams.set('advancedFilters', JSON.stringify(filtersToApply));
        setSearchParams(searchParams);
      }
      searchParams.delete('page');
      setAppliedFilters(filtersToApply);
    },
    [setAppliedFilters, searchParams, setSearchParams],
  );

  const handleRemoveAdvancedFilter = useCallback(
    (filterId: string) => {
      const newFilters = appliedFilters.filter((filter) => filter.id !== filterId);
      if (newFilters.length) {
        searchParams.set('advancedFilters', JSON.stringify(newFilters));
      } else {
        searchParams.delete('advancedFilters');
      }
      searchParams.delete('page');
      setSearchParams(searchParams);
      setAppliedFilters(newFilters);
    },
    [appliedFilters, setAppliedFilters, searchParams, setSearchParams],
  );

  const handleClearAdvancedFilters = useCallback(() => {
    searchParams.delete('advancedFilters');
    searchParams.delete('page');
    setSearchParams(searchParams);
    setAppliedFilters([]);
  }, [setAppliedFilters, searchParams, setSearchParams]);

  const onPageChange = (newPage: number) => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };

  const onSearchChange = (searchTerm: string) => {
    if (searchTerm && searchTerm !== '') {
      searchParams.set('search', searchTerm);
    } else {
      searchParams.delete('search');
    }
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  return (
    <Card>
      <Box margin="lg lg 0 lg">
        <FilterBar
          filtersState={{
            searchTerm,
          }}
          handleSearchTerm={(searchTerm: string) => onSearchChange(searchTerm)}
        >
          {!isFiltersLoading && (
            <AddFilterButtonDropdown
              dataFilters={data}
              appliedFilters={appliedFilters}
              onRemoveFilter={handleRemoveAdvancedFilter}
              onApplyFilters={handleApplyAdvancedFilters}
              onClearFilters={handleClearAdvancedFilters}
            />
          )}
        </FilterBar>
        {isFetching || isLoading ? (
          <FundAccountsListLoading rows={6} />
        ) : (
          <FundAccountsSummaryTable data={accounts} />
        )}
      </Box>
      {accounts?.length > 0 && (
        <Pagination
          activePage={Number(currentPage) || 1}
          arePagesVisible
          itemsPerPage={20}
          numberOfPagesDisplayed={3}
          onChange={(page) => onPageChange(page)}
          totalItemsCount={totalCount ?? 0}
          isCompact
        />
      )}
    </Card>
  );
}
