import { ProjectTransactionStatus, ProjectTransactionStatusMap } from 'types';
import {
  Badge,
  BadgeVariant,
  Box,
  Button,
  ButtonTone,
  Column,
  Icon,
  Popover,
  Table,
} from '@palmetto/palmetto-components';
import { SyntheticEvent, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ProjectTransactionClientData } from '@/types/Payouts';
import { currencyFormatter } from '@/helpers/currencyFormatter';
import DateTimeDisplay from '@/components/DateTime';
import { TransactionEventBadge } from '@/components/Payouts/TransactionEventBadge';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from '@/helpers/dates';
import { TransactionPlanIdsDifferWarningIcon } from '@/components/Tooltip';
import { useAuth } from '@/components/auth/authProvider';
import { isPalmettoFinanceUser } from '@/components/auth/RequirePricingAdminUser';
import { UserPermissions } from 'types';
import { ProjectTransactionDetailsView } from './admin/ProjectTransactionDetailsView';

export interface TransactionContextMenuItem {
  label: string;
  onClick: () => void;
  tone?: ButtonTone;
}
export interface ProjectTransactionTableProps {
  transactions: ProjectTransactionClientData[];
  selectedIds?: string[];
  handleRowSelected?(ids: string[], selected: boolean): void;
  hideColumns?: string[];
  hidePlanDiff?: boolean;
  showExpandedRow?: boolean;
  isCompact?: boolean;
}

const statusBadgeMap: Record<ProjectTransactionStatus, BadgeVariant> = {
  [ProjectTransactionStatus.open]: 'default',
  [ProjectTransactionStatus.paid]: 'success',
  [ProjectTransactionStatus.pending]: 'warning',
  [ProjectTransactionStatus.approved]: 'primary',
  [ProjectTransactionStatus.migrated]: 'info',
  [ProjectTransactionStatus.historical]: 'tertiary',
  [ProjectTransactionStatus.adjustment]: 'tertiary',
  [ProjectTransactionStatus.paused]: 'danger',
};

const renderContextMenu = (
  transaction: ProjectTransactionClientData & { contextMenu?: TransactionContextMenuItem[] },
) => {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };
  return (
    <Box>
      <Popover
        trapFocus
        trigger="click"
        placement="bottom-start"
        onClickOutside={() => setMenuOpen(false)}
        contentContainerProps={{
          padding: 'sm',
          shadow: 'lg',
          radius: 'sm',
          overflow: 'hidden',
          borderColor: 'separator',
          borderWidth: 'xs',
          color: 'body-primary',
          fontSize: 'sm',
          maxWidth: '3xl',
        }}
        hasArrow
        isOpen={isMenuOpen}
        content={
          <Box gap="sm" direction="column">
            {transaction.contextMenu?.map((item) => (
              <Button
                tone={item.tone}
                onClick={(_event: SyntheticEvent) => {
                  item.onClick();
                  setMenuOpen(false);
                }}
                key={item.label}
              >
                {item.label}
              </Button>
            ))}
          </Box>
        }
      >
        <Button
          iconPrefix="dots"
          variant="tertiary"
          size="md"
          tone="neutral"
          aria-label="transactionActions"
          onClick={() => handleMenuToggle()}
        />
      </Popover>
    </Box>
  );
};

const getTransactionColumns = (
  hideColumns: string[],
  handleRowSelected: ProjectTransactionTableProps['handleRowSelected'],
  selectedIds: string[],
  transactions: ProjectTransactionClientData[],
) =>
  [
    handleRowSelected && {
      heading: (
        <input
          type="checkbox"
          name="selectedTransaction"
          checked={Boolean(transactions?.length && transactions.length === selectedIds?.length)}
          onChange={() =>
            handleRowSelected(
              transactions?.map((transaction) => `${transaction.id}`) || [],
              !Boolean(transactions?.length && transactions.length === selectedIds?.length),
            )
          }
        />
      ),
      dataKey: 'id',
      render: (_cell?: any, row?: any) =>
        row ? (
          <input
            type="checkbox"
            name="selectedTransaction"
            checked={selectedIds?.includes(row.id)}
            onChange={() => handleRowSelected([row.id], Boolean(!selectedIds?.includes(row.id)))}
          />
        ) : (
          <></>
        ),
    },
    {
      heading: 'Event Date & Time',
      dataKey: 'eventDate',
      render: (cell?: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          <DateTimeDisplay value={cell} fontSize="xs" dateFormat="DD h:mm:ss a" inline />
        </Box>
      ),
    },
    {
      heading: 'Finco Account Id',
      dataKey: 'projectExternalId',
      render: (cell?: any, _row?: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          {cell}
        </Box>
      ),
    },
    {
      heading: 'Account',
      dataKey: 'projectName',
      render: (cell?: any, row?: any) => {
        let plansDiffer: boolean;
        if (!row?.planId) {
          plansDiffer = false;
        } else {
          plansDiffer = row?.planId.toString() !== row?.payeePlanId?.toString();
        }
        return (
          <Box fontWeight="medium" as="span" display="inline">
            <Link to={`/accounts/${row?.projectExternalId}/funding`} target="_blank">
              {cell}
              {plansDiffer ? (
                <TransactionPlanIdsDifferWarningIcon organizationPlanName={row?.organizationPlanName} />
              ) : null}
            </Link>
          </Box>
        );
      },
    },
    {
      heading: 'Payee',
      dataKey: 'payeeName',
      render: (cell?: any, _row?: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          {cell}
        </Box>
      ),
    },
    {
      heading: '',
      dataKey: 'tags',
      render: (cell?: any, row?: any) => (
        <Box direction="row" fontWeight="medium" childGap="xs">
          {row?.isManual ? <Badge variant="secondary" message="M" size="sm" /> : null}
          {[ProjectTransactionStatus.open, ProjectTransactionStatus.pending].includes(row?.status) &&
          row?.payoutOnHold ? (
            <Badge variant="danger" message="H" size="sm" />
          ) : null}
        </Box>
      ),
    },
    {
      heading: 'Payout Event',
      dataKey: 'event',
      render: (_cell?: any, row?: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          <TransactionEventBadge transaction={row} />
        </Box>
      ),
    },
    {
      heading: 'Description',
      dataKey: 'description',
      render: (cell?: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          {cell}
        </Box>
      ),
    },
    {
      heading: 'Batch',
      dataKey: 'batchDate',
      render: (cell?: any, row?: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          {cell ? (
            <Link to={`/admin/payouts/batches/${row?.batchId}`} target="_blank">
              {DateTime.fromISO(cell).toFormat(DATE_FORMAT)}
            </Link>
          ) : (
            '-'
          )}
        </Box>
      ),
    },
    {
      heading: 'Status',
      dataKey: 'status',
      render: (cell?: any, _row?: any) => (
        <Box fontWeight="medium" as="span" display="inline">
          {cell && (
            <Badge
              variant={statusBadgeMap[cell as ProjectTransactionStatus]}
              message={ProjectTransactionStatusMap[cell as ProjectTransactionStatus]}
            />
          )}
        </Box>
      ),
    },
    {
      heading: <Box alignItems="flex-start">Amount</Box>,
      dataKey: 'amount',
      render: (cell?: any, row?: any) => {
        const isClawback = row?.eventType === 'clawback' || !!row.clawbackCategory;
        let textColor: any = '';
        if (isClawback) {
          textColor = 'danger-500';
        }
        return (
          <Box alignItems="center" direction="row" justifyContent="flex-end">
            {isClawback ? <Icon name="t-warning" size="xl" color="danger-500" /> : null}
            <Box fontWeight="medium" color={textColor} alignContent="flex-end">
              {cell ? `${currencyFormatter.format(cell)}` : '-'}
            </Box>
          </Box>
        );
      },
    },
    {
      heading: '',
      dataKey: 'contextMenu',
      width: 20,
      render: (cell?: any, row?: any) => (cell ? renderContextMenu(row) : null),
    },
  ].filter((column) => column && !hideColumns.includes(column.dataKey));

export const ProjectTransactionTable = ({
  transactions,
  selectedIds,
  handleRowSelected,
  hideColumns,
  isCompact,
  showExpandedRow = false,
}: ProjectTransactionTableProps) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const { claims } = useAuth();

  const isLightReachAccountingAdmin =
    isPalmettoFinanceUser(claims) && claims?.permissions?.includes(UserPermissions.lightReachAccountingAdmin);

  const columns = useMemo(
    () => getTransactionColumns(hideColumns ?? [], handleRowSelected, selectedIds ?? [], transactions),
    [handleRowSelected, transactions, selectedIds, hideColumns],
  );

  return transactions ? (
    <Table
      rowKey="id"
      isScrollable={{ x: true }}
      columns={columns as Column[]}
      isCompact={isCompact}
      rows={transactions}
      {...(isLightReachAccountingAdmin && showExpandedRow
        ? {
            expandedRow,
            onExpandedRowChange: setExpandedRow,
            expandedRowRender: (row: any) => <ProjectTransactionDetailsView transaction={row} />,
            expandBoxProps: {
              cursor: 'pointer',
              fontSize: 'xs',
            },
          }
        : {})}
    />
  ) : null;
};
