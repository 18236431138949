import { PaymentPlanTable } from '@/components/Payouts/PaymentPlanTable';
import { useGetPaymentPlansQuery } from '@/services/paymentPlans';
import { Button, Modal, toast, useOpenClose } from '@palmetto/palmetto-components';
import { useEffect, useState } from 'react';
import { AccountDocument, PaymentPlan, ProgramType, ProjectDocument, WithObjectIdsAsStrings } from 'types';
import { ChangePlanConfirmationModal } from './ChangePlanConfirmationModal';
import { useUpdateProjectPaymentPlanMutation } from '@/services/payouts';
interface AccountPaymentPlanModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  project: ProjectDocument | undefined;
  paymentPlan: WithObjectIdsAsStrings<PaymentPlan> | undefined;
  refetchProjectAndTransactions: () => void;
  account: AccountDocument;
}

export const AccountPaymentPlanModal = ({
  isModalOpen,
  handleClose,
  project,
  paymentPlan,
  refetchProjectAndTransactions,
  account,
}: AccountPaymentPlanModalProps) => {
  const programType = paymentPlan?.programType ?? account.programType;
  const { data: paymentPlans, isLoading: isLoadingPaymentPlans } = useGetPaymentPlansQuery({ programType });
  const [paymentPlanId, setPaymentPlanId] = useState(paymentPlan?.id ?? '');

  const {
    isOpen: isConfirmationModalOpen,
    handleOpen: handleOpenConfirmationModal,
    handleClose: handleCloseConfirmationModal,
  } = useOpenClose();

  useEffect(() => {
    setPaymentPlanId(paymentPlan?.id ?? '');
  }, [setPaymentPlanId, paymentPlan?.id]);

  const [updateProjectPaymentPlan, { isLoading: isUpdating }] = useUpdateProjectPaymentPlanMutation();

  const handleConfirmPlanChange = async () => {
    try {
      await updateProjectPaymentPlan({
        planId: paymentPlanId,
        externalId: project?.externalId ?? account.id,
      }).unwrap();
      toast.success('Payment plan changed successfully');
      handleCloseConfirmationModal();
      handlePaymentPlanModalClose();
      refetchProjectAndTransactions();
    } catch (error) {
      handleCloseConfirmationModal();
      toast.error(`Could not change payment plan.`);
    }
  };

  const handlePaymentPlanModalClose = () => {
    setPaymentPlanId(paymentPlan?.id ?? '');
    handleClose();
  };

  return (
    <>
      <ChangePlanConfirmationModal
        isOpen={isConfirmationModalOpen}
        isUpdating={isUpdating}
        onClose={handleCloseConfirmationModal}
        onConfirm={handleConfirmPlanChange}
      />
      <Modal isOpen={isModalOpen} onDismiss={handlePaymentPlanModalClose}>
        <Modal.Header id="modal-title" title="Change Plan" onDismiss={handlePaymentPlanModalClose} />
        {!isLoadingPaymentPlans && paymentPlans && (
          <Modal.Body>
            <PaymentPlanTable
              programType={programType}
              paymentPlans={paymentPlans}
              selectedPaymentPlanId={paymentPlanId}
              selectPaymentPlan={setPaymentPlanId}
            />
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button variant="secondary" tone="neutral" onClick={handlePaymentPlanModalClose}>
            Cancel
          </Button>
          <Button isLoading={isUpdating} variant="primary" type="submit" onClick={handleOpenConfirmationModal}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
