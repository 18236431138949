import {
  AccountDetails,
  AccountDocument,
  AccountRequirementStatus,
  Cancellation,
  DeepPartial,
  MilestoneRequirement,
} from 'types';
import { AccountMilestoneStipulation } from '../types/Accounts';

import { api } from './api';

export interface TransformedAccount {
  address: string;
  externalReference: string;
  friendlyName: string;
  organizationId: string;
  id: number;
  status: string;
  systemDetails: string;
  utility: string;
  programType: string;
}

type AccountResponse = any;
type AccountActivityResponse = any;

interface Milestone {
  completedAt: string;
  requirements: Array<any>;
  type: string;
}

interface AccountMilestonesResponse {
  milestones: Array<Milestone>;
}

type MilestoneSummary = {
  count: number;
  type: string;
  name: string;
};

interface AccountsSummaryResponse {
  pageNum: number;
  total: number;
  milestoneSummary: {
    totalCount: number;
    milestones: Array<MilestoneSummary>;
  };
  accounts: Array<any>; // TODO: Define type
}

export const accountsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAccountWithToken: build.query<AccountDocument, any>({
      query: ({ id, inviteToken }) => ({
        url: `v2/accounts/${id}`,
        params: inviteToken ? { inviteToken } : undefined,
      }),
      providesTags: (_result, _error, id) => [{ type: 'Accounts', id }],
    }),
    getAccount: build.query<AccountResponse, string>({
      query: (id) => ({ url: `v2/accounts/${id}` }),
      providesTags: (_result, _error, id) => [{ type: 'Accounts', id }],
    }),
    getAccountCedDetails: build.query<AccountResponse, string>({
      query: (id) => ({ url: `accounts/${id}/ced-details` }),
      providesTags: (_result, _error, id) => [{ type: 'Accounts', id }],
    }),
    getAccountMilestones: build.query<AccountMilestonesResponse, string>({
      query: (id) => ({ url: `accounts/${id}/milestone-progress` }),
      providesTags: (_result, _error, id) => [{ type: 'AccountMilestones', id }],
    }),
    getAccountStipulations: build.query<AccountMilestoneStipulation[], void>({
      query: (accountId) => ({ url: `accounts/${accountId}/stipulations` }),
      providesTags: () => [{ type: 'AccountStipulations', id: 'LIST' }],
    }),

    getAccountsSummary: build.query<AccountsSummaryResponse, Record<any, any>>({
      query: (queryParams) => {
        return {
          url: `accounts/summary${
            queryParams &&
            '?' +
              new URLSearchParams({
                ...queryParams,
              })
          }`,
        };
      },
      transformResponse: (response: any) => {
        const { data, pageNum, total } = response || {};
        const sortedMilestones = data?.milestoneSummary?.milestones?.sort((mA: any, mB: any) => mA.order - mB.order);
        return {
          pageNum,
          total,
          accounts: data?.accounts,
          milestoneSummary: { totalCount: data?.milestoneSummary.totalCount, milestones: sortedMilestones },
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...(result.accounts?.map(({ id }: { id: string }) => ({ type: 'Accounts' as const, id })) ?? {}),
              { type: 'Accounts', id: 'LIST' },
            ]
          : [{ type: 'Accounts', id: 'LIST' }],
    }),
    createAccount: build.mutation<AccountResponse, AccountDetails<any>>({
      query: (account) => ({
        url: `v2/accounts`,
        method: 'POST',
        body: account,
      }),
      invalidatesTags: [{ type: 'Accounts', id: 'LIST' }],
    }),
    createAccountStipulation: build.mutation<
      AccountResponse,
      {
        id: string;
        stipulation: DeepPartial<AccountMilestoneStipulation>;
      }
    >({
      query: ({ stipulation, id }) => ({
        url: `accounts/${id}/stipulations`,
        method: 'POST',
        body: stipulation,
      }),
      invalidatesTags: (result) => [
        { type: 'AccountStipulations', id: 'LIST' },
        { type: 'Accounts', id: result?.accountId },
      ],
    }),
    editAccountStipulation: build.mutation<
      AccountResponse,
      { stipulation: DeepPartial<AccountMilestoneStipulation>; stipulationId: string; accountId: string }
    >({
      query: ({ stipulation, stipulationId, accountId }) => ({
        url: `accounts/${accountId}/stipulations/${stipulationId}`,
        method: 'PATCH',
        body: stipulation,
      }),
      invalidatesTags: (result) => [
        { type: 'AccountStipulations', id: 'LIST' },
        { type: 'Accounts', id: result?.accountId },
      ],
    }),
    editAccount: build.mutation<
      AccountResponse,
      { account: DeepPartial<AccountDetails<any>>; id: string; inviteToken?: string | null }
    >({
      query: ({ account, id, inviteToken }) => ({
        url: `v2/accounts/${id}`,
        method: 'PATCH',
        params: {
          ...(inviteToken ? { inviteToken } : {}),
        },
        body: account,
      }),
      invalidatesTags: (_result, _error, { id }) => [
        { type: 'Accounts', id },
        { type: 'Pricing', id },
        { type: 'AccountMilestones', id },
      ],
    }),
    editAccountDirectPay: build.mutation<AccountResponse, { provider: string; enabled: boolean; id: string }>({
      query: ({ id, provider, enabled }) => ({
        url: `v2/accounts/${id}/direct-pay`,
        method: 'PATCH',
        body: { provider, enabled },
      }),
      invalidatesTags: (_result, _error, { id }) => [{ type: 'Accounts', id }],
    }),
    updateMilestoneRequirement: build.mutation<
      void,
      {
        accountId: string;
        requirementType: MilestoneRequirement;
        status: AccountRequirementStatus;
      }
    >({
      query: ({ accountId, requirementType, status }) => ({
        url: `accounts/${accountId}/requirements/${requirementType}`,
        method: 'PATCH',
        body: { requirementType, status },
      }),
      invalidatesTags: (_result, _error, { accountId }) => [
        { type: 'Accounts', id: accountId },
        { type: 'AccountMilestones', id: accountId },
      ],
    }),
    cancelAccount: build.mutation<AccountResponse, { cancelDetails: DeepPartial<Cancellation>; id: string }>({
      query: ({ cancelDetails, id }) => ({
        url: `v2/accounts/${id}/cancel`,
        method: 'PATCH',
        body: cancelDetails,
      }),
      invalidatesTags: (result) => [{ type: 'Accounts', id: result?.id }],
    }),
    reactivateAccount: build.mutation<AccountResponse, { cancelDetails: DeepPartial<Cancellation>; id: string }>({
      query: ({ cancelDetails, id }) => ({
        url: `v2/accounts/${id}/reactivate`,
        method: 'PATCH',
        body: cancelDetails,
      }),
      invalidatesTags: (result) => [{ type: 'Accounts', id: result?.id }],
    }),
    sendInvite: build.mutation<void, { accountId: string; email: string }>({
      query: ({ accountId, email }) => ({
        url: `accounts/${accountId}/send-invite`,
        method: 'POST',
        body: { email },
      }),
    }),
    getInviteLink: build.query<{ inviteLink?: string; userId?: string; signedUpAt?: Date }, string>({
      query: (id) => ({
        url: `accounts/${id}/app-invite`,
      }),
    }),
    getSalesRepVerificationUrl: build.query<{ url: string }, string>({
      query: (id) => ({
        url: `accounts/${id}/sales-rep-verification`,
      }),
    }),
    createSalesRepVerificationOverride: build.mutation<
      AccountResponse,
      { accountId: string; overrideReasons: string[] }
    >({
      query: ({ accountId, overrideReasons }) => ({
        url: `accounts/${accountId}/sales-rep-verification/override`,
        method: 'POST',
        body: { overrideReasons },
      }),
      invalidatesTags: (result) => [{ type: 'Accounts', id: result?.id }],
    }),
    sendSalesRepVerificationUrl: build.mutation<
      void,
      { accountId: string; salesRepEmail: string; salesRepName: string }
    >({
      query: ({ accountId, salesRepEmail, salesRepName }) => ({
        url: `accounts/${accountId}/sales-rep-verification/send`,
        method: 'POST',
        body: { salesRepEmail, salesRepName },
      }),
    }),
    getAccountActivity: build.query<AccountActivityResponse, { id: string; cursor?: string }>({
      query: ({ id, cursor }) => {
        const url = cursor ? `accounts/${id}/activity?cursor=${cursor}` : `accounts/${id}/activity`;
        return { url };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data?.map(({ id }: { id: string }) => ({ type: 'AccountActivityEvent' as const, id })),
              { type: 'AccountActivity', id: 'LIST' },
            ]
          : [{ type: 'AccountActivity', id: 'LIST' }],
    }),
    getAccountListFilters: build.query<any, Record<any, any>>({
      query: (queryParams) => {
        return {
          url: `accounts/filters${
            queryParams &&
            '?' +
              new URLSearchParams({
                ...queryParams,
              })
          }`,
        };
      },
    }),
  }),
});

export const {
  useGetAccountQuery,
  useGetAccountCedDetailsQuery,
  useGetAccountWithTokenQuery,
  useGetAccountMilestonesQuery,
  useGetAccountStipulationsQuery,
  useGetAccountsSummaryQuery,
  useLazyGetSalesRepVerificationUrlQuery,
  useCreateSalesRepVerificationOverrideMutation,
  useLazyGetAccountsSummaryQuery,
  useCancelAccountMutation,
  useReactivateAccountMutation,
  useCreateAccountMutation,
  useCreateAccountStipulationMutation,
  useEditAccountMutation,
  useEditAccountStipulationMutation,
  useSendInviteMutation,
  useSendSalesRepVerificationUrlMutation,
  useLazyGetInviteLinkQuery,
  useGetAccountActivityQuery,
  useGetAccountListFiltersQuery,
  useUpdateMilestoneRequirementMutation,
  useEditAccountDirectPayMutation,
} = accountsApi;
