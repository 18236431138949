import { Box, Button, Icon, MediaModal, toast, useBreakpoint } from '@palmetto/palmetto-components';
import { ReactNode, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { QueueFlagsContext } from '../QueueFlags/QueueFlagsContext';
import RequirePalmettoFinanceUser from '../auth/RequirePalmettoFinanceUser';
import { documentTitles, BillingDocumentTypes, FlagDocumentTypes, FlagStatus } from 'types';
import { useParams } from 'react-router-dom';
import { useGetDocumentsQuery } from '../../services/documents';
import { trimFileNames } from '@/helpers/trimFileNames';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface DocumentPreview {
  id: string;
  url: string;
  contentType: string;
  fileName: string;
  type?: string;
  hasError?: boolean;
  createdAt?: Date;
}

interface DocumentPreviewModalProps {
  targetDoc: DocumentPreview;
  setTargetDoc: (doc: DocumentPreview) => void;
  isFilePreviewModalOpen: boolean;
  documentList: DocumentPreview[];
  closeFilePreviewModal: () => void;
  title?: string;
  baseCategory?: string;
}

const supportedPreviewContentTypes = [
  'image/png',
  'image/jpeg',
  'application/pdf',
  'image/gif',
  'image/webp',
  'image/jfif',
  'image/svg+xml',
  'text/html',
];
interface PrevNextBtnWrapperProps {
  children: ReactNode;
  onClick: () => void;
  ariaLabel: string;
}

const PrevNextBtnWrapper = ({ children, onClick, ariaLabel }: PrevNextBtnWrapperProps) => (
  <Button
    onClick={onClick}
    aria-label={ariaLabel}
    tone="neutral"
    variant="primary"
    style={{ margin: '0 1em 0 1em', padding: '2em 1em', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
  >
    {children}
  </Button>
);

export const DocumentPreviewModal = ({
  targetDoc,
  setTargetDoc,
  isFilePreviewModalOpen,
  documentList,
  closeFilePreviewModal,
  title,
  baseCategory,
}: DocumentPreviewModalProps) => {
  const { flagDrivenPackageReview } = useFlags();
  const { isPhone } = useBreakpoint();
  const { id = '' } = useParams<{ id: string }>();
  const [isSingleFile, setIsSingleFile] = useState(documentList?.length === 1);
  const { getFlag, shouldHide, resolveFlag, showResolveModal, showModal, isModalShowing } =
    useContext(QueueFlagsContext);
  const currentFlag = getFlag(targetDoc?.id, targetDoc?.fileName, false);
  const uploadedFlagDocuments = useGetDocumentsQuery({
    accountId: id,
    documentTypeFilter: [FlagDocumentTypes.flag],
  });

  const uploadedDocument = useMemo(() => {
    return uploadedFlagDocuments?.data?.find((d) => d.id === currentFlag?.fileUpload);
  }, [currentFlag?.fileUpload, uploadedFlagDocuments?.data]);
  const isSupportedPreviewContentType = supportedPreviewContentTypes.includes(targetDoc?.contentType?.toLowerCase());

  const onNext = useCallback(() => {
    let newTargetDocIndex = documentList.findIndex((doc) => doc.id === targetDoc?.id);
    if (newTargetDocIndex === documentList.length - 1 || newTargetDocIndex === -1) {
      newTargetDocIndex = 0;
    } else {
      newTargetDocIndex += 1;
    }
    setTargetDoc(documentList[newTargetDocIndex]);
  }, [documentList, setTargetDoc, targetDoc?.id]);

  const onPrev = useCallback(() => {
    let newTargetDocIndex = documentList.findIndex((doc) => doc.id === targetDoc?.id);
    if (newTargetDocIndex === 0) {
      newTargetDocIndex = documentList.length - 1;
    } else if (newTargetDocIndex === -1) {
      newTargetDocIndex = 0;
    } else {
      newTargetDocIndex -= 1;
    }
    setTargetDoc(documentList[newTargetDocIndex]);
  }, [documentList, setTargetDoc, targetDoc?.id]);

  const keyDownAndSwipeHandler = (event: any) => {
    // If we have the rename or tag modal open, we don't want to allow navigating of the images via the keyboard
    if (isModalShowing) {
      return;
    }

    if (event?.key === 'ArrowRight' || event?.dir === 'Left') {
      onNext();
    } else if (event?.key === 'ArrowLeft' || event?.dir === 'Right') {
      onPrev();
    }
  };

  /*
    Because we want to display previous and next buttons, we have to tweak the styling so that we effectively use up 100% of the width and height of the modal body
    Because of this, the built-in dismissal behavior of the modal, where clicking anywhere in the modal grey background, won't work
    This code mimics that functionality
    */
  const onModalBodyClick = useCallback(
    (event: SyntheticEvent) => {
      if (event.target instanceof HTMLDivElement) {
        closeFilePreviewModal();
      }
    },
    [closeFilePreviewModal],
  );

  useEffect(() => {
    document.addEventListener('keydown', keyDownAndSwipeHandler);
    return () => document.removeEventListener('keydown', keyDownAndSwipeHandler);
  });
  const swipeHandlers = useSwipeable({ onSwiped: keyDownAndSwipeHandler });

  // Whenever a document is archived or unarchived, we want to refresh the actions possible, hence we need to refresh the targetDoc object
  useEffect(() => {
    const newTargetDocIndex = documentList?.findIndex((doc) => doc.id === targetDoc?.id);
    // If we're only showing active documents, and archive a document, it's not going to be in documentList
    if (newTargetDocIndex !== -1) {
      setTargetDoc(documentList[newTargetDocIndex]);
    }

    setIsSingleFile(documentList?.length === 1);
  }, [documentList, setTargetDoc, targetDoc?.id]);

  const handleFlagClick = useCallback(async () => {
    if (currentFlag) {
      if (flagDrivenPackageReview) {
        showResolveModal(targetDoc?.id, targetDoc?.fileName);
      } else {
        try {
          await resolveFlag(targetDoc?.id, FlagStatus.closed, targetDoc?.fileName);
        } catch (e) {
          toast.error('Something went wrong. Please try again.');
          console.log({ e });
        }
      }
    } else {
      showModal(targetDoc?.id, targetDoc?.fileName, baseCategory);
    }
  }, [currentFlag, resolveFlag, targetDoc?.id, targetDoc?.fileName, showModal, baseCategory]);

  const truncatedFileName = trimFileNames(targetDoc?.fileName);

  const headerContent = useMemo(
    () =>
      targetDoc && (
        <Box direction="row" alignItems="center" flex="auto">
          <Box gap="sm" style={{ flex: 1, minHeight: '47px' }}>
            <Box fontWeight="bold">
              {title}
              {title && targetDoc?.type ? ' - ' : ''}
              {targetDoc?.type ? documentTitles[targetDoc.type as BillingDocumentTypes] : ''}
            </Box>
            <Box title={targetDoc.fileName}>{truncatedFileName}</Box>
          </Box>
          {!isPhone && (
            <Box direction="row" gap="xl" justifyContent="center" alignItems="center">
              <RequirePalmettoFinanceUser>
                <Button
                  style={{ color: 'white' }}
                  tone={currentFlag && !shouldHide ? 'danger' : 'neutral'}
                  variant={currentFlag && !shouldHide ? 'primary' : 'tertiary'}
                  size="sm"
                  iconPrefix="flag"
                  onClick={handleFlagClick}
                ></Button>
              </RequirePalmettoFinanceUser>
              <Button
                as="a"
                tone="neutral"
                variant="tertiary"
                size="sm"
                href={targetDoc.url}
                iconPrefix="download"
                download={targetDoc.fileName}
                style={{ color: 'white' }}
              ></Button>
              <Button
                as="a"
                tone="neutral"
                variant="tertiary"
                size="sm"
                iconPrefix="launch-app"
                href={targetDoc.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'white' }}
              ></Button>
            </Box>
          )}
          {!isPhone && <Box style={{ flex: 1 }} />}
        </Box>
      ),
    [currentFlag, handleFlagClick, isPhone, shouldHide, targetDoc, title],
  );
  return (
    targetDoc && (
      <MediaModal
        isOpen={isFilePreviewModalOpen}
        onDismiss={closeFilePreviewModal}
        headerContent={headerContent}
        ariaLabel="File Preview Modal"
        {...swipeHandlers}
      >
        {currentFlag ? (
          <Box alignSelf="stretch">
            <Box
              alignSelf="stretch"
              alignItems="flex-start"
              justifyContent="center"
              direction="row"
              padding="md"
              background="secondary"
              color="black"
              gap="sm"
              margin="md 0 0 0"
            >
              <Icon name="flag" size="md" color="danger" />
              {currentFlag.reason && <Box fontWeight="medium">{currentFlag.reason}</Box>}
              {currentFlag.reason && currentFlag.note && <Box fontWeight="medium">-</Box>}
              {currentFlag.note && <Box fontWeight="light">{currentFlag.note}</Box>}
            </Box>
            {uploadedDocument && uploadedDocument?.files?.length > 0 && (
              <Box
                alignSelf="stretch"
                alignItems="flex-start"
                justifyContent="center"
                direction="row"
                padding="0 0 sm 0"
                background="secondary"
              >
                <Box fontWeight="light" direction="row" gap="xs">
                  {uploadedDocument?.files?.map((file, index) => (
                    <Box key={index} direction="row" gap="xs" alignItems="center">
                      <Icon name="paperclip" color="body-secondary" aria-label="attachment" size="sm" />
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href={file?.viewUrls?.[0]?.url}
                        title={file.originalName}
                      >
                        {trimFileNames(file.originalName)}
                      </a>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        ) : null}
        <Box
          direction="row"
          alignItems="center"
          style={{
            width: '100vw',
            height: isPhone ? 'calc(100vh - 170px)' : 'calc(100vh - 105px)',
            marginTop: '40px',
          }}
          onClick={onModalBodyClick}
        >
          {!isSingleFile && (
            <PrevNextBtnWrapper ariaLabel="Previous file" onClick={onPrev}>
              <Icon name="caret-left" color="inverse-secondary" size="xl" />
            </PrevNextBtnWrapper>
          )}
          <Box style={{ flex: 1 }}>
            <Box display="flex" alignItems="center">
              {targetDoc.hasError && (
                <Box display="flex" background="secondary" padding="lg">
                  {targetDoc.fileName} could not be loaded.
                </Box>
              )}
              {!isSupportedPreviewContentType && (
                <Box background="secondary" padding="lg">
                  No preview available.
                </Box>
              )}
              {!targetDoc.hasError && isSupportedPreviewContentType && (
                <>
                  {targetDoc.contentType.startsWith('image/') && (
                    <img
                      src={targetDoc.url}
                      alt={targetDoc.fileName}
                      style={{
                        width: 'max-content',
                        height: 'max-content',
                        maxWidth: '100%',
                        maxHeight: isPhone ? 'calc(100vh - 185px)' : 'calc(100vh - 120px)',
                        objectFit: 'scale-down',
                      }}
                    />
                  )}
                  {!targetDoc.contentType.startsWith('image/') && (
                    <iframe
                      src={targetDoc.url}
                      title={targetDoc.fileName}
                      style={{
                        width: '100%',
                        height: isPhone ? 'calc(100vh - 185px)' : 'calc(100vh - 120px)',
                      }}
                    />
                  )}
                </>
              )}
            </Box>
          </Box>
          {!isSingleFile && (
            <PrevNextBtnWrapper ariaLabel="Next file" onClick={onNext}>
              <Icon name="caret-right" color="inverse-secondary" size="xl" />
            </PrevNextBtnWrapper>
          )}
        </Box>
      </MediaModal>
    )
  );
};
