import { Box, Card, Table } from '@palmetto/palmetto-components';
import { useEffect, useMemo, useRef } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { DocumentTypes, LegacyReviewQueueType, ReviewQueueRoute } from 'types';
import { ReviewTaskType, TaskQueueSortField } from '../../types/Queues';
import { useGetReviewTasksByTypeQuery } from '../../services/tasks';
import { QueueItemStatus } from './QueueItemStatus';
import { QueueItemAge } from './QueueItemAge';
import PageHeader from '../PageHeader';
import { applyDocumentFilters, applyGlobalFilters } from './helpers';
import { Filters } from './Filters/Filters';
import { sortAscending, sortDescending } from '../../helpers/sort';
import { TaskAssigneeType } from '../../types/Tasks';
import { MainContainer } from '../MainContainer';
import './Queue.css';

type queueDefinition = {
  title: string;
  type: ReviewTaskType;
  replacementRoute?: ReviewQueueRoute | null;
  getReviewLink?: (accountId: string, status?: 'ready' | 'rejected') => string;
};

type queue = {
  [key in LegacyReviewQueueType]: queueDefinition;
};

type PartialEnumKeys<T extends string> = {
  [K in T]?: (searchParams: URLSearchParams, data: any[]) => any[];
};

type PartialFunctionMap = PartialEnumKeys<LegacyReviewQueueType>;

const TaskQueueSortMapping: Record<TaskQueueSortField, string> = {
  [TaskQueueSortField.CREATED_ASC]: 'createdAt',
  [TaskQueueSortField.CREATED_DESC]: 'createdAt',
};

const queueFilterMap: PartialFunctionMap = {
  [LegacyReviewQueueType.uploadedDocument]: applyDocumentFilters,
} as const;

/** @deprecated Old queue based on tasks */
export function Queue() {
  const isInitialRenderRef = useRef(true);
  const { queue = LegacyReviewQueueType.identity } = useParams<{ queue: LegacyReviewQueueType }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = (searchParams.get('sort') as TaskQueueSortField) || TaskQueueSortField.CREATED_ASC;
  const isFiltered = !!Array.from(searchParams.keys()).filter((key) => key !== 'sort').length;

  const documentType = searchParams.get('documentType');

  useEffect(() => {
    if (isInitialRenderRef.current) {
      isInitialRenderRef.current = false;
      const search = Array.from(searchParams.entries());
      // if no search has been specified on initial render, default to ready filter
      if (!search.length) {
        searchParams.set('status', 'ready');
        setSearchParams(searchParams);
      }
    }
  }, [searchParams, isInitialRenderRef, setSearchParams]);

  const queueDefinitions: queue = useMemo(() => {
    let newRoute;
    switch (documentType) {
      case DocumentTypes.supporting:
        newRoute = ReviewQueueRoute.reviewSupporting;
        break;
      case DocumentTypes.disclosure:
        newRoute = ReviewQueueRoute.reviewDisclosure;
        break;
      case DocumentTypes.voidedCheck:
        newRoute = ReviewQueueRoute.voidedCheck;
        break;
      default:
        newRoute = null;
        break;
    }
    return {
      [LegacyReviewQueueType.identity]: {
        title: 'Review Identity',
        type: ReviewTaskType.reviewIdentity,
        replacementRoute: ReviewQueueRoute.reviewIdentity,
      },
      [LegacyReviewQueueType.title]: {
        title: 'Review Title',
        type: ReviewTaskType.reviewTitle,
        replacementRoute: ReviewQueueRoute.reviewTitle,
      },
      [LegacyReviewQueueType.uploadedDocument]: {
        title: 'Review Document',
        replacementRoute: newRoute,
        type: ReviewTaskType.reviewUploadedDocument,
      },
      [LegacyReviewQueueType.install]: {
        title: 'Review Install Package',
        type: ReviewTaskType.reviewInstall,
        replacementRoute: ReviewQueueRoute.installPackage,
        getReviewLink: (accountId: string, status) =>
          `/accounts/${accountId}/installation-package/${status === 'rejected' ? 'edit' : 'review'}`,
      },
      [LegacyReviewQueueType.systemActivation]: {
        title: 'Review System Activation',
        type: ReviewTaskType.reviewSystemActivation,
        replacementRoute: ReviewQueueRoute.activationPackage,
        getReviewLink: (accountId: string, status) =>
          `/accounts/${accountId}/activation-package/${status === 'rejected' ? 'edit' : 'review'}`,
      },
      [LegacyReviewQueueType.voidedCheck]: {
        title: 'Review Voided Check',
        type: ReviewTaskType.reviewVoidedCheck,
        replacementRoute: ReviewQueueRoute.voidedCheck,
      },
    };
  }, [documentType]);

  const { title, type, getReviewLink, replacementRoute } = queueDefinitions[queue] || {};
  const { data: items = [], isFetching: isLoading } = useGetReviewTasksByTypeQuery({
    type,
    assigneeType: TaskAssigneeType.internal,
  });

  const sortedData = useMemo(() => {
    return sort?.toLowerCase().includes('ascending')
      ? sortAscending(items, TaskQueueSortMapping[sort])
      : sortDescending(items, TaskQueueSortMapping[sort]);
  }, [items, sort]);

  const filteredData = useMemo(() => {
    const globalFilteredData = applyGlobalFilters(searchParams, sortedData);
    const queueFilteredData =
      Object.values(LegacyReviewQueueType).includes(queue as LegacyReviewQueueType) && queueFilterMap[queue]
        ? queueFilterMap[queue]?.(searchParams, globalFilteredData)
        : globalFilteredData;
    return queueFilteredData;
  }, [sortedData, searchParams, queue]);

  const columnData = [
    {
      heading: (
        <p style={{ textTransform: 'none', letterSpacing: 0 }} className="font-size-sm">
          {isFiltered ? (
            <>
              <>{`${filteredData?.length} match${filteredData?.length && filteredData.length > 1 ? 'es' : ''}`}</>{' '}
              <span style={{ color: 'inherit' }}>{`of ${items.length}`}</span>
            </>
          ) : (
            `${items.length} Items`
          )}
        </p>
      ),
      cellClassName: (_columnData: any, item: any) => {
        return `${item?.status === 'rejected' ? 'queueStatus background-color-danger' : 'queueStatus'}`;
      },
      dataKey: 'status',
      render: (status: any) => <QueueItemStatus status={status} />,
      width: 165,
    },
    {
      heading: ``,
      dataKey: 'accountDisplay',
      render: ({ accountId, address, linkPath, name }: any) => (
        <Box childGap="xs">
          <Link
            style={{ color: 'var(--color-text-body-primary)' }}
            className="font-size-md font-weight-bold"
            to={linkPath}
            state={{ shouldRedirect: false, action: 'review' }}
          >
            {name}
          </Link>
          <Box fontSize="xs" fontWeight="bold">
            {address}
          </Box>
          <Box fontSize="xs">Account: {accountId}</Box>
        </Box>
      ),
    },
    {
      heading: 'Action',
      dataKey: 'description',
    },
    {
      heading: 'Age',
      dataKey: 'createdAt',
      render: (createdAt: any) => <QueueItemAge createdAt={createdAt} />,
    },
  ];

  return (
    <MainContainer>
      <PageHeader
        title={title}
        eyebrow={replacementRoute ? <Link to={`/queue/${replacementRoute}`}>Go to new queues</Link> : null}
      />

      <Card position="relative" width="100">
        <Box
          display="block"
          childGap="xs"
          position="sticky"
          zIndex="sticky"
          style={{ top: '0' }}
          margin="lg lg 0 lg"
        >
          <Filters queue={queue} />
        </Box>
        <Box padding="0 lg lg lg">
          <Table
            rowKey="id"
            columns={columnData}
            rows={(filteredData ?? []).map((item) => {
              const documentReference = item?.referenceIds?.find(
                (ref: { type: string; id: string }) => ref.type === 'documents',
              );
              const account = item?.accounts?.[0];
              const linkPath = getReviewLink
                ? getReviewLink(account?.id, item?.status)
                : `/accounts/${account?.id}${documentReference ? '?scrollTo=documents' : ''}`;

              const primaryApplicant = account?.applicants?.find((applicant: any) => applicant?.type === 'primary');
              const propertyAddress = account?.address;

              const accountDisplay = {
                linkPath,
                accountId: account?.id,
                name: `${primaryApplicant?.firstName} ${primaryApplicant?.lastName}`,
                address: `${propertyAddress?.address1}, ${propertyAddress?.city}, ${propertyAddress?.state} ${propertyAddress?.zip}`,
              };
              return { ...item, accountDisplay };
            })}
            isLoading={isLoading}
            isScrollable={{
              x: true,
            }}
          />
        </Box>
      </Card>
    </MainContainer>
  );
}
