import { AccountAdders, AdderDetails } from 'types';
import { api } from './api';

export const addersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAdders: build.query<AccountAdders, string>({
      query: (id) => ({ url: `accounts/${id}/adders` }),
      providesTags: (_result, _error, id) => [{ type: 'AccountAdders', id }],
    }),
    updateAdders: build.mutation<AccountAdders, { accountId: string; adders: { adders: AdderDetails[] } }>({
      query: ({ accountId, adders }) => ({
        url: `accounts/${accountId}/adders`,
        method: 'PUT',
        body: adders,
      }),
      invalidatesTags: (_result, _error, { accountId }) => [
        { type: 'AccountAdders', id: accountId },
        { type: 'Accounts', id: accountId },
      ],
    }),
  }),
});

export const { useGetAddersQuery, useUpdateAddersMutation } = addersApi;
