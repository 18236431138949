import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Icon } from '@palmetto/palmetto-components';
import DateTimeDisplay from '../DateTime';
import { Packages } from 'types';

const milestoneLinkMap: {
	[Key in Packages]?: string;
} = {
	[Packages.ntpPackage]: 'ntp-package/create',
	[Packages.installPackage]: 'installation-package/create',
	[Packages.activationPackage]: 'activation-package/create',
	[Packages.postActivationPackage]: 'post-activation-package/create',
};
interface CompletionBannerProps {
	completedPackage?: { type: Packages, name?: string };
	currentPackage: { type: Packages, name?: string };
	className?: string;
	customViewPathLink?: string;
	completedAt?: Date;
}
const MilestoneCompletionBanner = ({
	completedPackage,
	currentPackage,
	customViewPathLink,
	className,
	completedAt,
}: CompletionBannerProps) => {
	const navigate = useNavigate();
	const handleViewNavigate = () => {
		if (customViewPathLink) {
			return navigate(customViewPathLink);
		}
		return navigate(`${milestoneLinkMap[currentPackage.type]}`);
	};

	return (
		<Box
			borderWidth="xs"
			borderColor="primary"
			radius="md"
			direction={{ base: 'column', tablet: 'row' }}
			alignItems="stretch"
			fontSize="sm"
			overflow="hidden"
			background="button-secondary-active"
			shadow="xs"
			height="auto"
			className={className}
		>
			<Box
				padding="lg"
				direction="row"
			>
				<Icon name="celebration" size="2xl" color="primary" />
			</Box>
			<Box flex="auto" childGap="2xs" padding="lg" fontSize="md" color='primary' >
				<Box fontWeight="bold">{completedPackage?.name} Approved</Box>
				<Box>Good work! Please submit your {currentPackage.name} for the next milestone.</Box>
				<Box fontSize='xs' direction={{ base: 'column', tablet: 'row' }} childGap="2xs" color="grey-500">
					{completedAt && <Box direction="row">
						<DateTimeDisplay fontSize='xs' value={completedAt.toString()} dateFormat={'DD'} excludeTime />&nbsp;
						<DateTimeDisplay fontSize='xs' value={completedAt.toString()} dateFormat="DD" showDuration />&nbsp;
						ago</Box>}
				</Box>
			</Box>
			<Box padding={{ base: '0 lg lg lg', tablet: 'lg' }} justifyContent="center">
				<Button
					as="a"
					variant="primary"
					tone="primary"
					size={{ base: 'sm', desktop: 'md' }}
					aria-label="view completed milestone"
					navigate={handleViewNavigate}
				>
					Submit Package
				</Button>
			</Box>
		</Box>
	);
};

export default MilestoneCompletionBanner;
