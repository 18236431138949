import { ObjectId } from 'mongodb';
import { MilestoneRequirement, ProgramType } from './';

export enum RequirementFlagSettingsType {
  accessoriesHVAC = 'accessoriesHVAC',
  ahriNumber = 'ahriNumber',
  approvedPermit = 'approvedPermit',
  arbitrageBattery = 'arbitrageBattery',
  backupBattery = 'backupBattery',
  batteryMonitoringId = 'batteryMonitoringId',
  designPackagePlanSet = 'designPackagePlanSet',
  designPackageProductionModel = 'designPackageProductionModel',
  designPackageShareReport = 'designPackageShareReport',
  electrical = 'electrical',
  electricalUpgrade = 'electricalUpgrade',
  electricianSignOff = 'electricianSignOff',
  equipmentHVAC = 'equipmentHVAC',
  incentive = 'incentive',
  indoorSystem = 'indoorSystem',
  inverterMonitoringId = 'inverterMonitoringId',
  installationHVAC = 'installationHVAC',
  installedMountingHardwareIssue = 'installedMountingHardwareIssue',
  laserThermometerReadings = 'laserThermometerReadings',
  outdoorSystem = 'outdoorSystem',
  mountingHardware = 'mountingHardware',
  photoVoltaicSystem = 'photoVoltaicSystem',
  projectSite = 'projectSite',
  projectSiteHVAC = 'projectSiteHVAC',
  proofOfExtendedWarranty = 'proofOfExtendedWarranty',
  proofOfInstallationHVAC = 'proofOfInstallationHVAC',
  proofOfLoadCalculation = 'proofOfLoadCalculation',
  proofOfPermissionToOperate = 'proofOfPermissionToOperate',
  roof = 'roof',
  serialNumber = 'serialNumber',
  siteImprovement = 'siteImprovement',
  solarReadiness = 'solarReadiness',
  staticPressureReadings = 'staticPressureReadings',
  storage = 'storage',
  systemCommissioning = 'systemCommissioning',
  systemCostDescrepancyHVAC = 'systemCostDescrepancyHVAC',
  systemDesignIssueHVAC = 'systemDesignIssueHVAC',
  systemInstallDateHVAC = 'systemInstallDateHVAC',
  temperatureDiffOfReturnAndSupply = 'temperatureDiffOfReturnAndSupply',
  thermostat = 'thermostat',
  utilityBill = 'utilityBill',
  voltageReadings = 'voltageReadings',
  workOrderHVAC = 'workOrderHVAC',
  yearOneProductionEstimate = 'yearOneProductionEstimate',
  zoningSystem = 'zoningSystem',
}

export interface RequirementAcceptanceProblem {
  id: ObjectId;
  index?: number;
  displayText: string;
  description?: string;
}

export interface RequirementAcceptanceCriterion {
  id: ObjectId;
  index?: number;
  displayText: string;
  description?: string;
  problems: RequirementAcceptanceProblem[];
}

export enum RequirementFlagSettingsStatus {
  active = 'active',
  inactive = 'inactive',
}

export enum RequirementFlagSettingsItemType {
  acceptanceCriterion = 'acceptanceCriterion',
  problem = 'problem',
}

export interface RequirementFlagSettingsDocument {
  id: ObjectId;
  type: RequirementFlagSettingsType;
  name: string;
  description?: string;
  meetsAccountRequirements?: MilestoneRequirement[];
  baseCategory?: string;
  dataKey?: string;
  acceptanceCriteria?: RequirementAcceptanceCriterion[];
  programTypes: ProgramType[];
  status: RequirementFlagSettingsStatus;
}

export interface FlagDisplayTextUpdate {
  id: string;
  parentId?: string;
  displayText: string;
}

export interface RequirementFlagSettingUpdateData {
  displayTextDiffs?: {
    acceptanceCriteria: FlagDisplayTextUpdate[];
    problems: FlagDisplayTextUpdate[];
  };
  addItem?: {
    itemType: RequirementFlagSettingsItemType;
    item: Partial<RequirementAcceptanceCriterion> | Partial<RequirementAcceptanceProblem>;
    parentId?: string;
  };
  removeItem?: { itemType: RequirementFlagSettingsItemType; id: string };
  status?: RequirementFlagSettingsStatus;
}
