import {
  Box,
  DateInput,
  FormikCheckboxInput,
  FormikSelectInputNative,
  FormikTextInput,
  FormLabel,
} from '@palmetto/palmetto-components';
import { useCallback, useEffect, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { useGetAccountQuery } from '@/services/accounts';
import {
  ClawbackCategory,
  CreateProjectTransactionPayload,
  PayeeProjectRole,
  PayeeType,
  PayoutEventMap,
  ProgramType,
} from 'types';
import { useGetProjectByExternalIdQuery } from '@/services/payouts';
import { DATE_FORMAT } from '@/helpers/dates';
import { skipToken } from '@reduxjs/toolkit/query';
import { DateTime } from 'luxon';

export const clawbackCategoryOptions = [
  { value: ClawbackCategory.ntp, label: 'NTP' },
  { value: ClawbackCategory.cancellation, label: 'Cancellation' },
  { value: ClawbackCategory.install, label: 'Install' },
  { value: ClawbackCategory.partnerInstallNotComplete, label: 'Partner Install Not Completed' },
];

export const AddProjectTransactionForm = ({
  payeeType,
  hideAccountSelection,
  allowHistoricalAdjustment = false,
}: {
  payeeType?: PayeeType;
  hideAccountSelection?: boolean;
  allowHistoricalAdjustment?: boolean;
}) => {
  const now = new Date();
  const { values, touched, errors, setFieldValue, isSubmitting } =
    useFormikContext<CreateProjectTransactionPayload>();
  const { projectExternalId: accountId } = values;
  const { data: account, isLoading: isAccountLoading } = useGetAccountQuery(accountId || skipToken);
  const { data: project, isLoading: isProjectLoading } = useGetProjectByExternalIdQuery(accountId || skipToken);
  const primaryApplicant = account?.applicants.find((applicant: any) => applicant.type === 'primary');
  const isLoading = isAccountLoading || isProjectLoading;
  const payoutEventOptions = useMemo(
    () => [
      ...(!isLoading && account?.programType
        ? PayoutEventMap[account.programType as ProgramType].map((event: any) => ({
            value: event.event,
            label: event.name,
          }))
        : []),
    ],
    [account?.programType, isLoading],
  );

  const payeeOptions = useMemo(
    () => [
      ...(!isProjectLoading && project?.payees
        ? project?.payees.map((event: any) => ({
            value: event.id,
            label: event.name,
          }))
        : []),
    ],
    [project?.payees, isLoading],
  );

  const payee = useMemo(() => {
    if (payeeType) {
      const payeeRole = payeeType === PayeeType.organization ? PayeeProjectRole.primary : PayeeProjectRole.materials;
      return project?.payees?.find((payee) => payee.role === payeeRole) || null;
    }
  }, [project?.payees]);

  const handleDateChange = useCallback(
    (date: Date | [Date, Date] | null) => {
      if (!date || !(date instanceof Date)) return;
      setFieldValue('eventDate', DateTime.fromJSDate(date).toLocal().toFormat(DATE_FORMAT));
    },
    [setFieldValue],
  );
  useEffect(() => {
    setFieldValue('payeeId', payee?.id);
  }, [payee?.id, setFieldValue]);

  return (
    <Box childGap={{ base: 'lg', desktop: 'xl' }}>
      {!hideAccountSelection && (
        <Box fontSize="sm" padding="md 0" childGap="2xs">
          {primaryApplicant && account && (
            <>
              <Box fontWeight="medium" fontSize="md">
                {account.primaryApplicantName}
              </Box>
              <Box>{primaryApplicant.address.address1}</Box>
              <Box>
                {primaryApplicant.address.city}, {primaryApplicant.address.state} {primaryApplicant.address.zip}
              </Box>
              <Box padding="sm 0 0 0">{primaryApplicant.email}</Box>
            </>
          )}
        </Box>
      )}

      {payee ? (
        <Box childGap={{ base: 'xs', desktop: 'xs' }}>
          <FormLabel inputId="payee">Payee</FormLabel>
          <Box>{payee.name}</Box>
          <br />
        </Box>
      ) : (
        <Field
          label="Payee"
          name="payeeId"
          id="payeeId"
          component={FormikSelectInputNative}
          isRequired
          options={payeeOptions}
        />
      )}

      <Field
        label="Payout Event"
        name="event"
        id="event"
        component={FormikSelectInputNative}
        isRequired
        options={payoutEventOptions}
      />
      {allowHistoricalAdjustment && (
        <Box>
          <Field
            label="Historical Adjustment"
            name="historicalAdjustment"
            id="historicalAdjustment"
            component={FormikCheckboxInput}
            onChange={(e: any) => setFieldValue('historicalAdjustment', e.target.checked)}
          />
          {values.historicalAdjustment && (
            <DateInput
              label="Transaction Date"
              name="transactionDate"
              id="transactionDate"
              placeholder={'Pick a date'}
              dateFormat={DATE_FORMAT}
              isDisabled={isSubmitting}
              width="2xl"
              value={values.eventDate}
              error={touched.eventDate && errors.eventDate ? errors.eventDate : undefined}
              datePickerProps={{
                onChange: handleDateChange,
                openToDate: values.eventDate ?? now,
              }}
              textInputProps={{
                onClear: () => setFieldValue('ptoGrantedDate', ''),
              }}
            />
          )}
        </Box>
      )}
      <Field
        type="number"
        name="amount"
        id="amount"
        label="Payout Amount"
        component={FormikTextInput}
        isRequired
        prefix="$"
      />
      <Field
        name="clawbackCategory"
        id="clawbackCategory"
        label="Clawback Category"
        component={FormikSelectInputNative}
        options={clawbackCategoryOptions}
      />
      <Field name="description" id="description" label="Reason" component={FormikTextInput} />
    </Box>
  );
};
