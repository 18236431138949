export enum OrganizationType {
  epc = 'epc',
  integratedPlatform = 'integratedPlatform',
  materials = 'materials',
}
export const OrganizationTypes: OrganizationType[] = Object.values(OrganizationType);
export const OrganizationTypeLabels: Record<OrganizationType, string> = {
  epc: 'EPC',
  integratedPlatform: 'Integrated Platform',
  materials: 'Materials',
};
